import React from "react";

export const CircleCheckSvg = (props) => (
  <svg
    width={props.height || 50}
    height={props.width || 50}
    viewBox="0 0 51 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.045 20.655L11.475 24.225L22.95 35.7L48.45 10.2L44.88 6.63L22.95 28.56L15.045 20.655ZM45.9 25.5C45.9 36.72 36.72 45.9 25.5 45.9C14.28 45.9 5.1 36.72 5.1 25.5C5.1 14.28 14.28 5.1 25.5 5.1C27.54 5.1 29.325 5.355 31.11 5.865L35.1901 1.785C32.13 0.765 28.815 0 25.5 0C11.475 0 0 11.475 0 25.5C0 39.525 11.475 51 25.5 51C39.525 51 51 39.525 51 25.5H45.9Z"
      fill={props.fill || "red"}
    />
  </svg>
);
