export const DownloadIconSVG = (props) => (
	<svg
		width="16"
		height="18"
		viewBox="0 0 16 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9 10H12L8 14L4 10H7V0H9V10ZM0 18V16H16V18H0Z"
			fill="#1F2041"
			fillOpacity="0.2"
		/>
	</svg>
);
