import React, { useEffect, useRef, useState } from "react";
import Axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoginWelcome from "./components/login-welcome/LoginWelcome";
import AccountHome from "./containers/account/AccountHome";

// import components
import HomePage from "./home-page/HomePage";
import V from "./metadata.json";
import ReservationSummary from "./reserve/reservation-summary/ReservationSummary";
import ReservePage from "./reserve/Reserve";

import thankYouPage from "./thank-you/thankYou";

import "./swiperCustomization.scss";
import WelcomePage from "./welcome/Welcome";
import ScrollIntoView from "./ScrollIntoView";
import ToastContainer from "./components/toast/ToastContainer";
import DocumentSignature from "./containers/document-signature/DocumentSignature";
import Contact from "./containers/contact/Contact";
import V2 from "./reserve/v2/V2";
import openSocket from "socket.io-client";
import {
  CLEAR_AUTH,
  CURRENT_USER,
  GET_WEBSITE_PROFILE,
  SET_FEATURES,
} from "./store/actions/types";
const socket = openSocket("http://localhost:5008");

console.log(
  `Running Version:, ${V.buildMajor}.${V.buildMinor}.${V.buildRevision}`
);
// import { } from "./use-google-login";

function LightenDarkenColor(col, amt) {
  if (!col?.length) return;
  var usePound = false;

  if (col[0] === "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const ThemeContext = React.createContext({ name: "shmili" });

const App = () => {
  const pageRef = useRef();
  const dispatch = useDispatch();

  const { websiteProfile, auth } = useSelector(({ websiteProfile, auth }) => ({
    websiteProfile,
    auth,
  }));

  // const [myWebsiteProfile, setMyWebsiteProfile] = useState(undefined);
  // const [headerOpacity, setHeadingOpacity] = useState(0);
  const [loading, setLoading] = useState(true);

  const getWebsiteProfile = async () => {
    const mappings = {
      "localhost:3002": "649dd3224cf9d5e3ec059bd4",
    };

    const host = window.location.host;
    let res = await Axios.get(`/api/website-profile/${mappings[host]}`, {
      headers: { "Access-Control-Allow-Origin": "*" },
    });

    dispatch({ type: GET_WEBSITE_PROFILE, payload: res.data.data });
  };

  const getUser = async () => {
    let token = await localStorage.getItem("x-auth");

    let user = await Axios.get("/api/web-user", {
      headers: { "x-auth": token },
    });

    if (user.data.status === 401) {
      dispatch({ type: CLEAR_AUTH, payload: user.data });
    } else {
      dispatch({ type: CURRENT_USER, payload: user.data });
    }
  };

  const getFeatures = async () => {
    let token = await localStorage.getItem("x-auth");

    let localFeatures = await Axios.get("/api/feature", {
      headers: { "x-auth": token },
    });

    if (localFeatures.data.message === "User not found") return;
    dispatch({ type: SET_FEATURES, payload: localFeatures?.data?.data });
  };

  useEffect(() => {
    socket.on("lol", (data) => {
      console.log("LOL", data);
    });

    getWebsiteProfile();
    getUser();
    getFeatures();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!Object.keys(websiteProfile).length) return;

    // setMyWebsiteProfile(websiteProfile);
    setLoading(false);

    // eslint-disable-next-line
  }, [websiteProfile]);

  const setupChatRooms = async () => {
    let chatClients;

    if (auth.status === "logged-out") {
      chatClients = localStorage.getItem("chatClients");

      if (chatClients?.length) {
        console.log(chatClients);
      } else {
        let client = await Axios.post("/api/communication/chat-client", {
          webProfileId: websiteProfile._id,
        });

        localStorage.setItem("chatClients", `["${client.data._id}"]`);
      }
    } else if (auth.status === "logged-in") {
      chatClients = auth?.user?.chatClients?.length
        ? JSON.stringify(auth.user.chatClients.map((cl) => `"${cl._id}"`))
        : "[]";
      localStorage.setItem("chatClients", chatClients);
    }

    // let chats = await Axios.get(
    //   `/api/communications/all-chats?chatClients=${chatClients}`
    // );
  };

  useEffect(() => {
    if (auth?.status === "loading" || !Object.keys(websiteProfile).length)
      return;

    // let id;

    setupChatRooms();
    // let idFromStorage = localStorage.getItem("chatId");

    // if (!idFromStorage) {
    //   id = `${generateString(16)}${Number(new Date())}`;
    //   localStorage.setItem("chatId", id);
    // } else {
    //   id = idFromStorage;
    // }

    // socket.emit(
    //   "registerClient",
    //   {
    //     userId: auth?.user?._id || id,
    //   }
    //   // (cb) => {
    //   //   console.log("LOL!!!");
    //   // }
    // );

    // eslint-disable-next-line
  }, [auth, websiteProfile]);

  // const checkScroll = () => {
  //   const value = pageRef.current.scrollTop / 1.8 / 100;
  //   let opacityValue = 0;

  //   if (value <= 1) {
  //     opacityValue = value;
  //   } else {
  //     opacityValue = 1;
  //   }

  //   // setHeadingOpacity(opacityValue);
  // };

  return (
    <>
      {!loading && (
        <ThemeContext.Provider value={websiteProfile?.theme?.primaryTextColor}>
          <div
            test-tag="test-tag"
            className="App"
            ref={pageRef}
            // onScroll={this.checkScroll}
          >
            {/* primaryTextColor */}

            <style>
              {`.ptc {
          color: ${websiteProfile?.theme?.primaryTextColor};
        }
        
        .pbgc {
          background-color: ${
            websiteProfile?.theme?.primaryTextColor
          } !important;
  }

  .pborderc {
          border-color: ${websiteProfile?.theme?.primaryTextColor} !important;
  }
  
  .spbgc {
    background-color: ${LightenDarkenColor(
      websiteProfile?.theme?.primaryTextColor,
      25
    )}
  }
        
        button.primary, .button.primary {
          background-color: ${websiteProfile?.theme?.primaryTextColor};
        }
        
        button.secondary, .button.secondary {
          color: ${websiteProfile?.theme?.primaryTextColor};
  }
  
  button.secondary_primary, .button.secondary_primary {
          background-color:  ${LightenDarkenColor(
            websiteProfile?.theme?.primaryTextColor,
            25
          )};
        } `}
            </style>
            <BrowserRouter>
              {auth?.user?.requirePasswordReset ? (
                <Redirect to="/account/settings/reset-password" />
              ) : undefined}
              {!auth?.user?.requirePasswordReset &&
              auth?.user?.requireOnboarding ? (
                <Redirect to="/account/rentals" />
              ) : undefined}
              <ScrollIntoView>
                <Switch>
                  <Route path={`/v2`} component={V2}></Route>
                  <Route
                    path="/external-doc-sign/:reservationId"
                    component={DocumentSignature}
                  ></Route>
                  <Route path="/welcome" component={WelcomePage}></Route>

                  <Route
                    path="/login-welcome/:token/:redirectUrl"
                    component={LoginWelcome}
                  ></Route>
                  <Route path="/thank-you/:id" component={thankYouPage}></Route>
                  <Route
                    path="/reserve/:page/:sizeId?/:unitId?/:selectedDate?/:locationId?"
                    component={ReservePage}
                  ></Route>
                  <Route
                    path="/reservation/summary/:id"
                    component={ReservationSummary}
                  ></Route>
                  <Route path="/account" component={AccountHome}></Route>
                  <Route path="/contact" component={Contact}></Route>
                  <Route
                    path="/"
                    component={HomePage}
                    // headerOpacity={this.state.headerOpacity}
                  ></Route>
                </Switch>
              </ScrollIntoView>
            </BrowserRouter>

            <ToastContainer />
          </div>
        </ThemeContext.Provider>
      )}
    </>
  );
};

export default App;
