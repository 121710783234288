import React from "react";

export const SizesSvg = (props) => (
  <svg
    width="90"
    height="91"
    viewBox="0 0 90 91"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#sizesClip)">
      <path
        d="M81.4461 20.1918L53.2506 2.62818C48.6963 -0.208812 41.3071 -0.209976 36.7507 2.62818L8.55516 20.1918C4.00089 23.0288 3.99879 27.6318 8.55516 30.4699L36.7507 48.0336C41.305 50.8706 48.6942 50.8717 53.2506 48.0336L81.4461 30.4699C86.0004 27.6329 86.0025 23.03 81.4461 20.1918ZM49.255 39.7642C46.9317 41.2385 43.1555 41.2324 40.842 39.7642L18.127 25.3493L40.842 10.9783C43.1652 9.50848 46.9415 9.51476 49.255 10.9783L71.97 25.3493L49.255 39.7642Z"
        fill={props.fill || "red"}
      />
      <path
        d="M81.4464 40.6658L80.8581 40.2994L51.2304 58.9935L51.2195 58.9919L49.2552 60.2382C46.932 61.7125 43.1557 61.7064 40.8422 60.2382L18.1272 45.8233L18.1323 45.82L9.25586 40.2291L8.55516 40.6656C4.00089 43.5026 3.99879 48.1055 8.55516 50.9437L36.7507 68.5073C41.305 71.3443 48.6942 71.3455 53.2506 68.5073L81.4461 50.9437C86.0006 48.1069 86.0027 43.504 81.4464 40.6658Z"
        fill={props.fill || "red"}
      />
      <path
        d="M81.4461 60.5304L80.8579 60.1639L51.2302 78.858L51.2192 78.8564L49.255 80.1027C46.9317 81.577 43.1555 81.5709 40.842 80.1027L18.1272 65.6878L18.1323 65.6845L9.25586 60.0939L8.55516 60.5304C4.00089 63.3673 3.99879 67.9703 8.55516 70.8084L36.7507 88.3721C41.305 91.2091 48.6942 91.2103 53.2506 88.3721L81.4461 70.8084C86.0004 67.9714 86.0025 63.3685 81.4461 60.5304Z"
        fill={props.fill || "red"}
      />
    </g>
    <defs>
      <clipPath id="sizesClip">
        <rect
          width="90"
          height="90"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
