import Axios from "axios";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  DownloadIconSVG,
  EditIcon,
  // ViewIconSVG
} from "../../../../assets/images/svgs";
import { AddItemButton } from "../../../../components/ui/buttons/add-item-button/AddItemButton";
import AnvilDocument from "../documents/anvil/AnvilDocumnet";
import styles from "./RentalDetails.module.scss";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import AutoPay from "../auto-pay/AutoPay";
import ordinal from "ordinal";
import PaymentPopup from "../payment/Payment";
import { LateSVGIcon } from "../../../../assets/images/svgs/late.svg";

import Scroll from "react-scroll";
// import NewFeature from "../../../../components/ui/new-feature/NewFeature";
import UnitIconSVG from "../../../../assets/images/svgs/unit-icon.svg";
import RCPopup from "../../../../reserve/returning-customer-popup/RCPopup";
import plur from "plur";
import { formatFromCurrencyToMoney } from "../../../../helper";
var Element = Scroll.Element;
var scroller = Scroll.scroller;

// import { Document, Page } from "react-pdf";

// const DocView = (props) => {
//   const [numPages, setNumPages] = useState(null);
//   const [pageNumber, setPageNumber] = useState(1);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }
//   return (
//     <>
//       <Document file={props.fileUrl} onLoadSuccess={onDocumentLoadSuccess}>
//         <Page pageNumber={pageNumber} />
//       </Document>
//       <p>
//         Page {pageNumber} of {numPages}
//       </p>
//     </>
//   );
// };
const Line = ({ label, value, actions }) => (
  <div className={`df acsa ${styles.line}`}>
    <p className={`ffmr fs13_5 ${styles.label}`}>{label}</p>

    <p className={`df acsa ffmr fs13_5 ${styles.value}`}>
      <span style={{ minWidth: "0px" }}>{value}</span>
    </p>

    {/* hideMobile */}

    {actions?.length ? (
      <div className={`df acsa mla ${styles.lineActions}`}>
        {actions.map((action, i) => (
          <AddItemButton
            key={i}
            text="Set Up Autopay"
            action={action}
          ></AddItemButton>
        ))}
      </div>
    ) : (
      ""
    )}
  </div>
);

const RentalDetails = (props) => {
  const history = useHistory();
  const params = useParams();
  const elementRef = useRef(null);

  // const { feature } = useSelector(({ feature }) => ({ feature }));

  const [reservationDetails, setReservationDetails] = useState(undefined);
  // const [docId, setDocId] = useState(null);
  // const [showDocPopup, setShowDocPopup] = useState(false);
  // const [redirectTo, setRedirectTo] = useState(null);
  // const [loadingDocs, setLoadingDocs] = useState([]);
  // eslint-disable-next-line
  const [signedDocs, setSignedDocs] = useState([]);
  const [showAutoPayPopup, setShowAutoPayPopup] = useState(false);
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  // const [showInvoiceDay, setShowInvoiceDay] = useState(false);
  const [scrolled, setScrolled] = useState(0);
  const [showRCPopup, setShowRCPopup] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const { auth, feature } = useSelector(({ auth, feature }) => ({
    auth,
    feature,
  }));

  const getFeature = (_id) => feature.find((el) => el._id === _id);

  const fullAddress = () => {
    if (!auth.user || !auth.user.defaultAddress) {
      return "";
    }

    const { deliveryLine1, lastLine } = auth.user.defaultAddress;

    return `${deliveryLine1} ${lastLine}`;
  };

  const getReservationDetails = async () => {
    let reservation = await Axios.get(
      `/api/detailed-reservation/${params.id}`,
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );
    setReservationDetails(reservation.data.data);
  };

  useEffect(
    () => {
      // console.log(history, params);
      if (!params.id) return;
      // setRedirectTo(history.location.pathname);
      getReservationDetails();
      // props.setSelectedReservation(params.id);
    },
    // eslint-disable-next-line
    [params.id]
  );

  // const setDocSigned = (documentId) =>
  //   setSignedDocs([...signedDocs, documentId]);

  const getDocStatus = async (documentId) => {
    // console.log(documentId);
    let status = await Axios.get(`/api/document/status/${documentId}`);

    if (status.data === "incomplete" || status.data === "notReady") {
      return;
    } else {
      let copy = cloneDeep(reservationDetails);
      let document = copy.documents.find((doc) => doc._id === documentId);
      let documentIndex = copy.documents.findIndex(
        (doc) => doc._id === documentId
      );
      // console.log(document);
      document.completed = true;
      document.finalDocumentUrl = status.data;

      copy.documents[documentIndex] = document;
      setReservationDetails(copy);
    }
  };

  const toggleShowAutoPay = () => setShowAutoPayPopup(!showAutoPayPopup);

  // const toggleShowInvoiceDay = () => setShowInvoiceDay(!showInvoiceDay);

  const setAutoPay = (data) => {
    const copy = cloneDeep(reservationDetails);
    setReservationDetails({
      ...copy,
      autoPay: data.autoPay,
      autoPayDay: data.autoPayDay,
      autoPayMethod: data.autoPayMethod,
      autoPaymentFrequency: data.autoPaymentFrequency,
    });
  };

  // const setInvoiceDay = (invoiceDay) => {
  //   const copy = cloneDeep(reservationDetails);
  //   setReservationDetails({
  //     ...copy,
  //     invoiceDay,
  //   });
  // };

  const handleRect = useCallback((node) => {
    scroller.scrollTo("autoPayButton", {
      duration: 1500,
      delay: 100,
      smooth: true,
      containerId: "ContainerElementID",
      offset: 0, // Scrolls to element + 50 pixels down the page
    });
  }, []);

  const isContractSigned = () => {
    if (reservationDetails?.documents?.length === 0) return false;
    return reservationDetails.documents.find(
      (doc) => doc.documentType === "contract"
    )?.completed;

    // documentType === "contract"
  };

  const setDocSignedAction = async (docId) => {
    // console.log("Hello");

    await Axios.put(`/api/document-details/${docId}`, {
      completed: true,
    });
    // setSignatureCompleted(res.data.data.completed);
  };

  return (
    <>
      {reservationDetails !== undefined ? (
        <div
          className={styles.detailsWrpr}
          id="ContainerElementID"
          onScroll={() => setScrolled(scrolled + 1)}
        >
          <div className={`${styles.rentalDetails}`}>
            {reservationDetails.hasPastDue ? (
              <div className={`df acsa ${styles.pastDue}`}>
                <div className="df acsa">
                  <LateSVGIcon />
                  <p className={`ffmr fs14 m-l-20`}>
                    This unit is past due please make a payment to get it
                    current
                  </p>
                </div>
                <div className={`mla ${styles.buttonWrpr}`}>
                  <AddItemButton
                    colorStyle="dark"
                    text="Make a Payment"
                    action={() => setShowPaymentPopup(true)}
                  ></AddItemButton>
                </div>
              </div>
            ) : undefined}

            {/* START RENTAL HEADER */}
            <div className={`df acsa ffmr fs14 ${styles.header}`}>
              <div
                className={`df acsa m-b-20 ${styles.mobileOnly} ${styles.unitWrpr}`}
              >
                <UnitIconSVG />{" "}
                <span className={`m-l-8 ffmb fs16`}>
                  Unit {reservationDetails.unit.unitId}
                </span>
              </div>
              <div className={`df acsa ${styles.sec}`}>
                <div className={`df acc ${styles.icon}`}></div>
                Rate:{" "}
                {formatFromCurrencyToMoney(reservationDetails.price.price)}
              </div>

              <div className={`df acsa ${styles.sec}`}>
                <div className={`df acc ${styles.icon}`}></div>
                Balance:
                {formatFromCurrencyToMoney(
                  reservationDetails?.reservationBalance
                ) || formatFromCurrencyToMoney(0)}
              </div>

              {reservationDetails.nextAutoPayRun ? (
                <div className={`df acsa ${styles.sec}`}>
                  <div className={`df acc ${styles.icon}`}></div>
                  Next auto pay:{" "}
                  {dayjs(reservationDetails?.nextAutoPayRun).format(
                    "MM/DD/YYYY"
                  )}
                </div>
              ) : undefined}

              {/* nextAutoPayRun */}

              <div className={`mla ${styles.payBtn}`}>
                {!reservationDetails.hasPastDue || true ? (
                  <AddItemButton
                    text="Make a Payment"
                    action={() => setShowPaymentPopup(true)}
                  ></AddItemButton>
                ) : undefined}

                {showPaymentPopup ? (
                  <PaymentPopup
                    unit={reservationDetails.unit}
                    close={() => setShowPaymentPopup(false)}
                  ></PaymentPopup>
                ) : undefined}
              </div>
            </div>
            {/* END RENTAL HEADER */}

            {!reservationDetails.initialPayment ||
            (!isContractSigned() && !reservationDetails.ignoreSignNow) ? (
              <div className={`aic pbgc p-b-34 p-t-34 ${styles.card}`}>
                <p className={`ffmsb m-b-8 fs14 ${styles.whiteText}`}>
                  Hi, {auth?.user?.fullName.split(" ")[0]}
                </p>
                <p className={`ffmr fs14 m-b-25 ${styles.whiteText}`}>
                  Get started,{" "}
                  {!reservationDetails.initialPayment
                    ? "make your initial payment and"
                    : ""}{" "}
                  sign your contract now.
                </p>
                <div className={`mla`}>
                  {!reservationDetails.initialPayment ? (
                    <AddItemButton
                      type="white"
                      text={"Sign and Pay now"}
                      action={() => setShowRCPopup(true)}
                    ></AddItemButton>
                  ) : (
                    <AddItemButton
                      type="white"
                      text={"Sign now"}
                      action={() => setShowDocument(true)}
                    ></AddItemButton>
                  )}
                </div>
                {showRCPopup ? (
                  <RCPopup
                    unitSize={reservationDetails?.unit?.unitSize}
                    unitId={reservationDetails?.unit?._id}
                    locationId={reservationDetails?.unit?.unitLocation}
                    selectedDate={reservationDetails?.expectedMoveInDate}
                    reservationId={reservationDetails?._id}
                    hidePayLaterButton={true}
                  ></RCPopup>
                ) : undefined}
              </div>
            ) : undefined}
          </div>
          <div className={`${styles.card}`}>
            <h3 className="ffmb fs14">General Info</h3>
            <Line
              label="Unit ID"
              value={reservationDetails.unit.unitId?.toUpperCase()}
            />
            <Line label="Gate Key" value="N/A" />
            <Line
              label="Unit Type"
              value={reservationDetails.unit.unitType?.label}
            />
            <Line
              label="Unit Size"
              value={`${reservationDetails.unit.unitSize.label} (${reservationDetails.unit.unitSize.length} x ${reservationDetails.unit.unitSize.width}) ${reservationDetails.unit.unitSize.calculatedSQF} sq ft`}
            />
            {/* <Line
              label="Status"
              value={
                reservationDetails.moveOutDate
                  ? "Moved out"
                  : reservationDetails.expectedMoveOutDate
                  ? "Moving out"
                  : reservationDetails.moveInDate
                  ? "Active"
                  : `Moving in on: ${dayjs(
                      reservationDetails.expectedMoveInDate
                    ).format("MM/DD/YYYY")}`
              }
            /> */}
            {reservationDetails?.moveInDate ? (
              <Line
                label="Move In Date"
                value={dayjs(reservationDetails.moveInDate).format(
                  "MM/DD/YYYY"
                )}
              />
            ) : undefined}

            {/* moveInDate */}

            {/* moveInDate || moveOutDate || expectedMoveInDate || expectedMoveOutDate */}

            <Line
              label="Unit Features"
              value={
                <>
                  {reservationDetails.unit.unitFeatures.map((feature, i) => (
                    <span className="flcap m-r-4" key={feature._id}>
                      {feature.label}
                      {i < reservationDetails.unit.unitFeatures.length - 1
                        ? ","
                        : ""}{" "}
                    </span>
                  ))}
                </>
              }
            />

            {/*  */}

            {/* unitFeatures */}
          </div>

          <div className={`${styles.card}`}>
            <h3 className="ffmb fs14">Billing</h3>
            {/* <Line
              label="Billing Cycle"
              value={
                reservationDetails.invoiceDay
                  ? `${ordinal(reservationDetails.invoiceDay)} of month`
                  : "N/A"
              }
            /> */}

            {/* ********* */}

            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs13_5 ${styles.label}`}>Billing Cycle</p>
              <p className={`df acsa ffmr fs13_5 ${styles.value}`}>
                {reservationDetails.invoiceDay
                  ? `${ordinal(reservationDetails.invoiceDay)} of month`
                  : `Not set up`}
              </p>

              {/* UNCOMMENT WHEN READY TO ADD BACK CAPABILITY TO CHANGE BILLING DATE */}
              {/* <div className={`df acsa mla ${styles.lineActions}`}>
                {reservationDetails.invoiceDay ? (
                  <div
                    className="df acc cursor_pointer"
                    onClick={() => toggleShowInvoiceDay()}
                  >
                    <EditIcon></EditIcon>
                  </div>
                ) : (
                  <AddItemButton
                    text={reservationDetails.invoiceDay ? "Change" : "Set Up"}
                    action={toggleShowInvoiceDay}
                  ></AddItemButton>
                )}

                {showInvoiceDay ? (
                  <InvoiceDay
                    selectedDay={reservationDetails.invoiceDay}
                    close={toggleShowInvoiceDay}
                    onClose={setInvoiceDay}
                    invoiceSchedule={reservationDetails.invoiceSchedule}
                  ></InvoiceDay>
                ) : undefined}
              </div> */}
            </div>

            {/* ********* */}

            <Line
              label="Monthly Rate"
              value={`${formatFromCurrencyToMoney(
                reservationDetails.price.price
              )}`}
            />

            {/* <Line label="Next Rate Change" value="N/A" /> */}

            <Line
              label="Insurance"
              value={
                reservationDetails?.insurance?.bracket?.premium
                  ? formatFromCurrencyToMoney(
                      reservationDetails?.insurance?.bracket?.premium
                    )
                  : "N/A"
              }
            />
          </div>

          <div className={`${styles.card}`}>
            <h3 className="ffmb fs14">Payment</h3>
            <Line
              label="Payment Method (last used)"
              value={
                reservationDetails?.lastPayment?.webPayment?.cardType
                  ? `${
                      reservationDetails.lastPayment.webPayment.cardType
                    } ●●●●${reservationDetails.lastPayment.webPayment.maskedCardNumber.substring(
                      reservationDetails.lastPayment.webPayment.maskedCardNumber
                        .length - 4,
                      reservationDetails.lastPayment.webPayment.maskedCardNumber
                        .length
                    )}`
                  : "N/A"
              }
            />
            <Line label="Billing Address" value={fullAddress()} />
            {/* <Line
              label="Auto Pay"
              value={
                reservationDetails.autoPay
                  ? `${reservationDetails.autoPaymentFrequency
                      .charAt(0)
                      .toUpperCase()}${reservationDetails.autoPaymentFrequency.slice(
                      1
                    )} (on the ${ordinal(reservationDetails.autoPayDay || 1)})`
                  : `Not set up`
              }
              actions={reservationDetails.autoPay ? null : [toggleShowAutoPay]}
            /> */}
            <div className={`df acsa ${styles.line}`}>
              <p className={`ffmr fs13_5 ${styles.label}`}>Auto Pay</p>
              <p
                className={`df acsa ffmr fs13_5 ${styles.value}  ${styles.hideMobile}`}
              >
                {/* `${Math.abs(day)} ${plur(
                                      "day",
                                      day
                                    )}  before invoice is due`
                                  : day === 0
                                  ? "On invoice due date"
                                  : `${day} ${plur(
                                      "day",
                                      day
                                    )} after invoice is due` */}

                {/* **************** */}
                {/* ${reservationDetails.autoPaymentFrequency
                      .charAt(0)
                      .toUpperCase()}${reservationDetails.autoPaymentFrequency.slice(
                      1
                    )} (on the ${ordinal(reservationDetails.autoPayDay || 1)}) */}

                {/* *************============********** */}
                {reservationDetails.autoPay
                  ? `${
                      reservationDetails.autoPayDay < 0
                        ? `${Math.abs(reservationDetails.autoPayDay)} ${plur(
                            "day",
                            reservationDetails.autoPayDay
                          )} before invoice is due`
                        : reservationDetails.autoPayDay === 0
                        ? "On invoice due date"
                        : `${Math.abs(reservationDetails.autoPayDay)} ${plur(
                            "day",
                            reservationDetails.autoPayDay
                          )} after invoice is due`
                    }`
                  : `Not set up`}
              </p>

              <div className={`df acsa mla ${styles.lineActions}`}>
                {" "}
                {reservationDetails.autoPay ? (
                  <div
                    ref={elementRef}
                    className="df acc cursor_pointer"
                    onClick={() => toggleShowAutoPay()}
                  >
                    {getFeature("6179b262a2a5fb1f91b536b2")
                      ?.show ? undefined : (
                      <>
                        {getFeature("6179ba0975b2620bc470481b")?.show ? (
                          <>
                            <Element name="autoPayButton"></Element>
                            <span ref={handleRect} />
                          </>
                        ) : undefined}
                        {/* <NewFeature
                          feature={getFeature("6179ba0975b2620bc470481b")}
                          // clickAction={(e) => updateUser()}
                          scrolled={scrolled}
                          position="top right"
                        /> */}
                      </>
                    )}
                    <div
                      className="hello_world"
                      style={{ padding: "8px 10px 7px 10px" }}
                    >
                      <EditIcon></EditIcon>
                    </div>
                  </div>
                ) : (
                  <>
                    <AddItemButton
                      text={
                        reservationDetails.autoPay ? "Edit" : "Set Up Autopay"
                      }
                      action={toggleShowAutoPay}
                    >
                      {getFeature("6179b262a2a5fb1f91b536b2")
                        ?.show ? undefined : (
                        <>
                          {getFeature("6179ba0975b2620bc470481b")?.show ? (
                            <>
                              <span ref={handleRect} />
                              <Element name="autoPayButton"></Element>
                            </>
                          ) : undefined}
                          {/* <NewFeature
                            feature={getFeature("6179ba0975b2620bc470481b")}
                            // clickAction={(e) => updateUser()}
                            scrolled={scrolled}
                            position="top right"
                          /> */}
                        </>
                      )}
                    </AddItemButton>
                  </>
                )}
              </div>
            </div>
            {showAutoPayPopup && (
              <AutoPay
                isAutoPay={reservationDetails.autoPay}
                selectedDay={reservationDetails.autoPayDay}
                payMethod={reservationDetails.autoPayMethod}
                reservationInvoiceDay={reservationDetails.invoiceDay}
                close={toggleShowAutoPay}
                onClose={setAutoPay}
              ></AutoPay>
            )}
            {/* component={(props) => (
								<PortfolioDetail {...props} />
							)} */}
            <div
              className={`df acc ffmr fs12 p-t-20 ${styles.moreOPener}`}
              onClick={() => {
                setShowPaymentDetails(!showPaymentDetails);
              }}
            >
              {showPaymentDetails ? "Hide" : "Show"} all payments
            </div>
            {showPaymentDetails ? (
              <div className={`m-t-15 ${styles.moreWrpr}`}>
                {reservationDetails.allPayments.map((payment) => {
                  return (
                    <div className={`${styles.more}`}>
                      <Line
                        label="Payment"
                        value={`${formatFromCurrencyToMoney(
                          payment.appliedAmount
                        )} - ${
                          payment.webPayment.cardType
                        } ●●●●${payment.webPayment.maskedCardNumber.substring(
                          payment.webPayment.maskedCardNumber.length - 4,
                          payment.webPayment.maskedCardNumber.length
                        )} From date: ${dayjs(
                          payment.invoiceItem.fromDate
                        ).format("MM/DD/YYYY")} To date: ${dayjs(
                          payment.invoiceItem.toDate
                        ).format("MM/DD/YYYY")}`}
                      />
                    </div>
                  );
                })}
              </div>
            ) : undefined}
          </div>

          <div className={`${styles.card}`}>
            <h3 className="ffmb fs14">Documents</h3>
            {reservationDetails.documents.map((doc, i) => (
              <React.Fragment key={i}>
                {reservationDetails.documents.some(
                  (x) => x.completed === true
                ) ? (
                  <div className={`df acsa ${styles.line}`}>
                    <p className={`ffmr fs13_5 ${styles.label}`}>
                      {doc.documentType}
                    </p>

                    <p className={`df acsa ffmr fs13_5 ${styles.value}`}>
                      {doc.completed ? "Singed" : "Waiting for signature"}
                    </p>

                    <div className={`df acsa mla ${styles.lineActions}`}>
                      {doc.completed && doc.azureDocumentUrl ? (
                        <>
                          {/* <div className={`df acc ${styles.btn}`}>
                              <ViewIconSVG />
                            </div> */}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            download
                            href={`${doc.azureDocumentUrl}`}
                            // href={`api.hellosign.com/v3/signature_request/files/${doc.signatureRequestId}`}
                            className={`df acc ${styles.btn}`}
                          >
                            <DownloadIconSVG />
                          </a>
                          <p></p>
                        </>
                      ) : doc.completed || signedDocs.includes(doc._id) ? (
                        <div className={`df acsa`}>
                          <p
                            className={`acsa ffmr fs10`}
                            style={{ marginRight: "10px" }}
                          >
                            Processing
                          </p>
                          <AddItemButton
                            text="Refresh"
                            action={() => getDocStatus(doc._id)}
                          ></AddItemButton>
                        </div>
                      ) : (
                        <AddItemButton
                          text={`Sign now`}
                          action={() => {
                            history.push(
                              `/account/rentals/${params.id}/documents/${doc.eSigSignatures[0].signature_id}/${doc._id}`
                            );
                          }}
                        ></AddItemButton>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    {signedDocs.includes(doc._id) ? (
                      <div className={`df acsa ${styles.line}`}>
                        <p className={`ffmr fs13_5 ${styles.label}`}>
                          {doc.documentType}
                        </p>

                        <p className={`df acsa ffmr fs13_5 ${styles.value}`}>
                          {doc.completed ? "Singed" : "Waiting for signature"}
                        </p>

                        <div className={`df acsa mla ${styles.lineActions}`}>
                          {" "}
                          <div className={`df acsa`}>
                            <p
                              className={`acsa ffmr fs10`}
                              style={{ marginRight: "10px" }}
                            >
                              Processing{" "}
                            </p>
                            <AddItemButton
                              text="Refresh"
                              action={() => getDocStatus(doc._id)}
                            ></AddItemButton>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <AddItemButton
                        text={`Sign my ${doc.documentType}`}
                        action={
                          () => setShowDocument(true)
                          // history.push(
                          //   `/account/rentals/${params.id}/documents/${doc.eSigSignatures[0].signature_id}/${doc._id}`
                          // )
                        }
                      ></AddItemButton>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      ) : undefined}
      {/* **************************** */}
      {/* **************************** */}
      {/* **************************** */}
      {/*   
        signerName={docDetails.eSigSignatures[0].name}
        signerEid={docDetails.eSigSignatures[0].eid}
      */}
      {showDocument ? (
        <AnvilDocument
          onFinish={() =>
            setDocSignedAction(
              reservationDetails.documents.find(
                (doc) => doc.documentType === "contract"
              )._id
            )
          }
          close={() => setShowDocument(false)}
          signerName={
            reservationDetails.documents.find(
              (doc) => doc.documentType === "contract"
            ).eSigSignatures[0].name
          }
          signerEid={
            reservationDetails.documents.find(
              (doc) => doc.documentType === "contract"
            ).eSigSignatures[0].eid
          }
        />
      ) : undefined}

      {/* **************************** */}
      {/* **************************** */}
      {/* **************************** */}
      {/* 
			<Route
				path="/account/rentals/documents/:sigid/:docid/"
				component={DocumentPopup}
			/> */}

      {/* <Route
        path={`/account/rentals/:id/documents/:sigid/:docid`}
        // component={PaymentsPopup}
        render={(props) => (
          <DocumentPopup
            {...props}
            docid={":::COME BACK TO THIS:::"}
            sigid={":::COME BACK TO THIS:::"}
            setDocSigned={setDocSigned}
            getDocStatus={getDocStatus}
            redirectTo={`/account/rentals/${params.id}`}
          />
        )}
      ></Route> */}

      {/* {showDocPopup ? <DocumentPopup></DocumentPopup> : undefined} */}
    </>
  );
};

export default RentalDetails;
//
