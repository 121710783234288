import axiosDefaults from "axios/lib/defaults";

// import LogRocket from "logrocket";
// LogRocket.init("pheeqz/self-storage");

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  axiosDefaults.baseURL = "https://api.slfstrg.com/";
}

export * from "./websiteProfileA";
export * from "./unitsA";
export * from "./authA";
export * from "./reservationA";
export * from "./paymentsA";
export * from "./featuresA";
