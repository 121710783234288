import { useEffect, useRef } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
// import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";

import styles from "./RentalTabs.module.scss";

SwiperCore.use([Navigation]);

const RentalTabs = (props) => {
  const params = useParams();
  const history = useHistory();

  const wrprRef = useRef(null);

  useEffect(() => {
    if (!params.id && props?.reservations?.length) {
      history.push(
        `/account/rentals/${props.reservations[// .filter(
//   (reservation) => reservation.isDraft !== true
// )
0]._id}`
      );
    }
    // eslint-disable-next-line
  }, [params.id]);

  return (
    <div className={`${styles.tabs_wrpr}`} ref={wrprRef}>
      <div>
        <Swiper
          centeredSlidesBounds
          slidesPerView={"auto"}
          spaceBetween={10}
          navigation
          pagination={{ clickable: true }}
          className={`customized`}
          // initialSlide={props.initialSlide}
        >
          {props.reservations
            // .filter((reservation) => reservation.isDraft !== true)
            .map((reservation) => (
              <SwiperSlide className={`${styles.tab}`} key={reservation._id}>
                <NavLink
                  className={`df acc ffmr fs14 ttuc  ${styles.tabText} ${
                    reservation.hasPastDue ? styles.hasPastDue : ""
                  }`}
                  activeClassName={`pbgc ${styles.selected}`}
                  to={`/account/rentals/${reservation._id}`}
                  onClick={() => props.setSelectedReservation(reservation._id)}
                >
                  {reservation.unit.unitId}
                  {reservation.hasPastDue ? (
                    <span className={styles.star}>*</span>
                  ) : undefined}
                  {/* {reservation.initialPayment ? "" : "*"} */}
                </NavLink>
              </SwiperSlide>
            ))}
          {/* <SwiperSlide>Slide 9</SwiperSlide> */}
        </Swiper>
      </div>
      {/* <p
				onClick={() => move("prev")}
				className={`${styles.prev} ${styles.nav_arrow}`}
			>
				Prev {wrprWidth}
			</p>
			<p
				onClick={() => move("next")}
				className={`${styles.next} ${styles.nav_arrow}`}
			>
				{innerWrprWidth} Next
			</p> */}
      {/* <div
        className={`df acsa ${styles.tabs_inner_wrpr}`}
        ref={innerWrprRef}
        style={{ left: `${0}px` }}
      > */}
      {/* {props.reservations.map((reservation) => (
        <NavLink
          activeClassName={`pbgc ${styles.selected}`}
          to={`/account/rentals/${reservation._id}`}
          key={reservation._id}
          className={`df acc ffmr fs14 ttuc ${styles.tab}`}
          onClick={() => props.setSelectedReservation(reservation._id)}
        >
          {reservation.unit.unitId}
        </NavLink>
      ))} */}
      {/* </div> */}
    </div>
  );
};

export default RentalTabs;

// 36 / 80
