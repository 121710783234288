import { RESERVATION_ERROR, SET_RESERVATION } from "../actions/types";

const reducer = (state = {}, action) => {
  switch (action.type) {
    case SET_RESERVATION:
      return action.payload;

    case RESERVATION_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;
