import { ADD_PIN, REMOVE_PIN } from "../actions/types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_PIN:
      return [...state, action.payload];
    case REMOVE_PIN:
      let copy = state.filter((item) => item.id !== action.payload);
      //  state.splice
      return [...copy];
    default:
      return state;
  }
};

export default reducer;
