import {GET_USER_PAY_METHODS} from "../actions/types";

const reducer = (state = [], action) => {
	switch (action.type) {
		case GET_USER_PAY_METHODS:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
