import React from "react";

//import scss
import "./DemoLine.scss";

export const DemoUnitLine = props => (
  <div className="demo_unit_line">
    <div className="line_icon">
      <div className="line"></div>
    </div>
  </div>
);
