export const MasterCard = (props) => (
  <svg
    width={props.width || "40"}
    height={props.height || "34"}
    viewBox="0 0 504 304"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M504 152.001C504 235.201 436.8 303.201 352.8 303.201C269.6 303.201 201.6 235.201 201.6 152.001C201.6 68.8008 268.8 0.800781 352 0.800781C436.8 0.800781 504 68.8008 504 152.001Z"
      fill="#FFB600"
    />
    <path
      d="M352.8 0.800781C436 0.800781 504 68.8008 504 152.001C504 235.201 436.8 303.201 352.8 303.201C269.6 303.201 201.6 235.201 201.6 152.001"
      fill="#F7981D"
    />
    <path
      d="M352.801 0.800781C436.001 0.800781 504.001 68.8008 504.001 152.001C504.001 235.201 436.801 303.201 352.801 303.201"
      fill="#FF8500"
    />
    <path
      d="M149.6 0.800781C67.2 1.60078 0 68.8008 0 152.001C0 235.201 67.2 303.201 151.2 303.201C190.4 303.201 225.6 288.001 252.8 264.001C258.4 259.201 263.2 253.601 268 248.001H236.8C232.8 243.201 228.8 237.601 225.6 232.801H279.2C282.4 228.001 285.6 222.401 288 216.801H216.8C214.4 212.001 212 206.401 210.4 200.801H293.6C298.4 185.601 301.6 169.601 301.6 152.801C301.6 141.601 300 131.201 298.4 120.801H205.6C206.4 115.201 208 110.401 209.6 104.801H292.8C291.2 99.2008 288.8 93.6008 286.4 88.8008H216C218.4 83.2008 221.6 78.4008 224.8 72.8008H278.4C275.2 67.2008 271.2 61.6008 266.4 56.8008H236.8C241.6 51.2008 246.4 46.4008 252 41.6008C225.6 16.8008 189.6 2.40078 150.4 2.40078C150.4 0.80078 150.4 0.800781 149.6 0.800781Z"
      fill="#FF5050"
    />
    <path
      d="M0 152C0 235.2 67.2 303.2 151.2 303.2C190.4 303.2 225.6 288 252.8 264C258.4 259.2 263.2 253.6 268 248H236.8C232.8 243.2 228.8 237.6 225.6 232.8H279.2C282.4 228 285.6 222.4 288 216.8H216.8C214.4 212 212 206.4 210.4 200.8H293.6C298.4 185.6 301.6 169.6 301.6 152.8C301.6 141.6 300 131.2 298.4 120.8H205.6C206.4 115.2 208 110.4 209.6 104.8H292.8C291.2 99.2004 288.8 93.6004 286.4 88.8004H216C218.4 83.2004 221.6 78.4004 224.8 72.8004H278.4C275.2 67.2004 271.2 61.6004 266.4 56.8004H236.8C241.6 51.2004 246.4 46.4004 252 41.6004C225.6 16.8004 189.6 2.40039 150.4 2.40039H149.6"
      fill="#E52836"
    />
    <path
      d="M151.2 303.2C190.4 303.2 225.6 288 252.8 264C258.4 259.2 263.2 253.6 268 248H236.8C232.8 243.2 228.8 237.6 225.6 232.8H279.2C282.4 228 285.6 222.4 288 216.8H216.8C214.4 212 212 206.4 210.4 200.8H293.6C298.4 185.6 301.6 169.6 301.6 152.8C301.6 141.6 300 131.2 298.4 120.8H205.6C206.4 115.2 208 110.4 209.6 104.8H292.8C291.2 99.2004 288.8 93.6004 286.4 88.8004H216C218.4 83.2004 221.6 78.4004 224.8 72.8004H278.4C275.2 67.2004 271.2 61.6004 266.4 56.8004H236.8C241.6 51.2004 246.4 46.4004 252 41.6004C225.6 16.8004 189.6 2.40039 150.4 2.40039H149.6"
      fill="#CB2026"
    />
    <path
      d="M204.8 190.4L207.2 176.8C206.4 176.8 204.8 177.6 203.2 177.6C197.6 177.6 196.8 174.4 197.6 172.8L202.4 144.8H211.2L213.6 129.6H205.6L207.2 120H191.2C191.2 120 181.6 172.8 181.6 179.2C181.6 188.8 187.2 192.8 194.4 192.8C199.2 192.8 203.2 191.2 204.8 190.4Z"
      fill="white"
    />
    <path
      d="M210.4 164.8C210.4 187.2 225.6 192.8 238.4 192.8C250.4 192.8 255.2 190.4 255.2 190.4L258.4 175.2C258.4 175.2 249.6 179.2 241.6 179.2C224 179.2 227.2 166.4 227.2 166.4H260C260 166.4 262.4 156 262.4 152C262.4 141.6 256.8 128.8 239.2 128.8C222.4 127.2 210.4 144.8 210.4 164.8ZM238.4 141.6C247.2 141.6 245.6 152 245.6 152.8H228C228 152 229.6 141.6 238.4 141.6Z"
      fill="white"
    />
    <path
      d="M340 190.401L343.2 172.801C343.2 172.801 335.2 176.801 329.6 176.801C318.4 176.801 313.6 168.001 313.6 158.401C313.6 139.201 323.2 128.801 334.4 128.801C342.4 128.801 348.8 133.601 348.8 133.601L351.2 116.801C351.2 116.801 341.6 112.801 332.8 112.801C314.4 112.801 296 128.801 296 159.201C296 179.201 305.6 192.801 324.8 192.801C331.2 192.801 340 190.401 340 190.401Z"
      fill="white"
    />
    <path
      d="M116.8 127.201C105.6 127.201 97.6004 130.401 97.6004 130.401L95.2004 144.001C95.2004 144.001 102.4 140.801 112.8 140.801C118.4 140.801 123.2 141.601 123.2 146.401C123.2 149.601 122.4 150.401 122.4 150.401C122.4 150.401 117.6 150.401 115.2 150.401C101.6 150.401 86.4004 156.001 86.4004 174.401C86.4004 188.801 96.0004 192.001 101.6 192.001C112.8 192.001 117.6 184.801 118.4 184.801L117.6 191.201H132L138.4 147.201C138.4 128.001 122.4 127.201 116.8 127.201ZM120 163.201C120 165.601 118.4 178.401 108.8 178.401C104 178.401 102.4 174.401 102.4 172.001C102.4 168.001 104.8 162.401 116.8 162.401C119.2 163.201 120 163.201 120 163.201Z"
      fill="white"
    />
    <path
      d="M153.601 192C157.601 192 177.601 192.8 177.601 171.2C177.601 151.2 158.401 155.2 158.401 147.2C158.401 143.2 161.601 141.6 167.201 141.6C169.601 141.6 178.401 142.4 178.401 142.4L180.801 128C180.801 128 175.201 126.4 165.601 126.4C153.601 126.4 141.601 131.2 141.601 147.2C141.601 165.6 161.601 164 161.601 171.2C161.601 176 156.001 176.8 152.001 176.8C144.801 176.8 137.601 174.4 137.601 174.4L135.201 188.8C136.001 190.4 140.001 192 153.601 192Z"
      fill="white"
    />
    <path
      d="M472.801 114.4L469.601 136C469.601 136 463.201 128 454.401 128C440.001 128 427.201 145.6 427.201 166.4C427.201 179.2 433.601 192.8 447.201 192.8C456.801 192.8 462.401 186.4 462.401 186.4L461.601 192H477.601L489.601 115.2L472.801 114.4ZM465.601 156.8C465.601 165.6 461.601 176.8 452.801 176.8C447.201 176.8 444.001 172 444.001 164C444.001 151.2 449.601 143.2 456.801 143.2C462.401 143.2 465.601 147.2 465.601 156.8Z"
      fill="white"
    />
    <path
      d="M29.6004 191.2L39.2004 133.6L40.8004 191.2H52.0004L72.8004 133.6L64.0004 191.2H80.8004L93.6004 114.4H67.2004L51.2004 161.6L50.4004 114.4H27.2004L14.4004 191.2H29.6004Z"
      fill="white"
    />
    <path
      d="M277.6 191.201C282.4 164.801 283.2 143.201 294.4 147.201C296 136.801 298.4 132.801 300 128.801C300 128.801 299.2 128.801 296.8 128.801C289.6 128.801 284 138.401 284 138.401L285.6 129.601H270.4L260 192.001H277.6V191.201Z"
      fill="white"
    />
    <path
      d="M376.8 127.201C365.6 127.201 357.6 130.401 357.6 130.401L355.2 144.001C355.2 144.001 362.4 140.801 372.8 140.801C378.4 140.801 383.2 141.601 383.2 146.401C383.2 149.601 382.4 150.401 382.4 150.401C382.4 150.401 377.6 150.401 375.2 150.401C361.6 150.401 346.4 156.001 346.4 174.401C346.4 188.801 356 192.001 361.6 192.001C372.8 192.001 377.6 184.801 378.4 184.801L377.6 191.201H392L398.4 147.201C399.2 128.001 382.4 127.201 376.8 127.201ZM380.8 163.201C380.8 165.601 379.2 178.401 369.6 178.401C364.8 178.401 363.2 174.401 363.2 172.001C363.2 168.001 365.6 162.401 377.6 162.401C380 163.201 380 163.201 380.8 163.201Z"
      fill="white"
    />
    <path
      d="M412 191.201C416.8 164.801 417.6 143.201 428.8 147.201C430.4 136.801 432.8 132.801 434.4 128.801C434.4 128.801 433.6 128.801 431.2 128.801C424 128.801 418.4 138.401 418.4 138.401L420 129.601H404.8L394.4 192.001H412V191.201Z"
      fill="white"
    />
    <path
      d="M180 179.2C180 188.8 185.6 192.8 192.8 192.8C198.4 192.8 203.2 191.2 204.8 190.4L207.2 176.8C206.4 176.8 204.8 177.6 203.2 177.6C197.6 177.6 196.8 174.4 197.6 172.8L202.4 144.8H211.2L213.6 129.6H205.6L207.2 120"
      fill="#DCE5E5"
    />
    <path
      d="M218.4 164.8C218.4 187.2 225.6 192.8 238.4 192.8C250.4 192.8 255.2 190.4 255.2 190.4L258.4 175.2C258.4 175.2 249.6 179.2 241.6 179.2C224 179.2 227.2 166.4 227.2 166.4H260C260 166.4 262.4 156 262.4 152C262.4 141.6 256.8 128.8 239.2 128.8C222.4 127.2 218.4 144.8 218.4 164.8ZM238.4 141.6C247.2 141.6 248.8 152 248.8 152.8H228C228 152 229.6 141.6 238.4 141.6Z"
      fill="#DCE5E5"
    />
    <path
      d="M340 190.401L343.2 172.801C343.2 172.801 335.2 176.801 329.6 176.801C318.4 176.801 313.6 168.001 313.6 158.401C313.6 139.201 323.2 128.801 334.4 128.801C342.4 128.801 348.8 133.601 348.8 133.601L351.2 116.801C351.2 116.801 341.6 112.801 332.8 112.801C314.4 112.801 304 128.801 304 159.201C304 179.201 305.6 192.801 324.8 192.801C331.2 192.801 340 190.401 340 190.401Z"
      fill="#DCE5E5"
    />
    <path
      d="M95.2004 144.801C95.2004 144.801 102.4 141.601 112.8 141.601C118.4 141.601 123.2 142.401 123.2 147.201C123.2 150.401 122.4 151.201 122.4 151.201C122.4 151.201 117.6 151.201 115.2 151.201C101.6 151.201 86.4004 156.801 86.4004 175.201C86.4004 189.601 96.0004 192.801 101.6 192.801C112.8 192.801 117.6 185.601 118.4 185.601L117.6 192.001H132L138.4 148.001C138.4 129.601 122.4 128.801 116 128.801L95.2004 144.801ZM128 163.201C128 165.601 118.4 178.401 108.8 178.401C104 178.401 102.4 174.401 102.4 172.001C102.4 168.001 104.8 162.401 116.8 162.401C119.2 163.201 128 163.201 128 163.201Z"
      fill="#DCE5E5"
    />
    <path
      d="M136 190.4C136 190.4 140.8 192 154.4 192C158.4 192 178.4 192.8 178.4 171.2C178.4 151.2 159.2 155.2 159.2 147.2C159.2 143.2 162.4 141.6 168 141.6C170.4 141.6 179.2 142.4 179.2 142.4L181.6 128C181.6 128 176 126.4 166.4 126.4C154.4 126.4 150.4 131.2 150.4 147.2C150.4 165.6 162.4 164 162.4 171.2C162.4 176 156.8 176.8 152.8 176.8"
      fill="#DCE5E5"
    />
    <path
      d="M469.601 136.001C469.601 136.001 463.201 128.001 454.401 128.001C440.001 128.001 435.201 145.601 435.201 166.401C435.201 179.201 433.601 192.801 447.201 192.801C456.801 192.801 462.401 186.401 462.401 186.401L461.601 192.001H477.601L489.601 115.201L469.601 136.001ZM468.801 156.801C468.801 165.601 461.601 176.801 452.801 176.801C447.201 176.801 444.001 172.001 444.001 164.001C444.001 151.201 449.601 143.201 456.801 143.201C462.401 143.201 468.801 147.201 468.801 156.801Z"
      fill="#DCE5E5"
    />
    <path
      d="M29.6004 191.2L39.2004 133.6L40.8004 191.2H52.0004L72.8004 133.6L64.0004 191.2H80.8004L93.6004 114.4H73.6004L51.2004 161.6L50.4004 114.4H41.6004L14.4004 191.2H29.6004Z"
      fill="#DCE5E5"
    />
    <path
      d="M260.801 191.201H277.601C282.401 164.801 283.201 143.201 294.401 147.201C296.001 136.801 298.401 132.801 300.001 128.801C300.001 128.801 299.201 128.801 296.801 128.801C289.601 128.801 284.001 138.401 284.001 138.401L285.601 129.601"
      fill="#DCE5E5"
    />
    <path
      d="M355.2 144.801C355.2 144.801 362.4 141.601 372.8 141.601C378.4 141.601 383.2 142.401 383.2 147.201C383.2 150.401 382.4 151.201 382.4 151.201C382.4 151.201 377.6 151.201 375.2 151.201C361.6 151.201 346.4 156.801 346.4 175.201C346.4 189.601 356 192.801 361.6 192.801C372.8 192.801 377.6 185.601 378.4 185.601L377.6 192.001H392L398.4 148.001C398.4 129.601 382.4 128.801 376 128.801L355.2 144.801ZM388 163.201C388 165.601 378.4 178.401 368.8 178.401C364 178.401 362.4 174.401 362.4 172.001C362.4 168.001 364.8 162.401 376.8 162.401C380 163.201 388 163.201 388 163.201Z"
      fill="#DCE5E5"
    />
    <path
      d="M395.201 191.201H412.001C416.801 164.801 417.601 143.201 428.801 147.201C430.401 136.801 432.801 132.801 434.401 128.801C434.401 128.801 433.601 128.801 431.201 128.801C424.001 128.801 418.401 138.401 418.401 138.401L420.001 129.601"
      fill="#DCE5E5"
    />
  </svg>
);
