const NavSVG = (props) => (
  <svg
    width="6"
    height="9"
    viewBox="0 0 6 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.634587 7.73651L1.62535 8.72728L5.85263 4.5L1.62535 0.27273L0.634587 1.2635L3.87109 4.5L0.634587 7.73651Z"
      fill={props.fill || "#1F2041"}
      fillOpacity={props.fillOpacity || "0.5"}
    />
  </svg>
);

export default NavSVG;
