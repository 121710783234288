import Axios from "axios";
import {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {CURRENT_USER} from "../../store/actions/types";

const LoginWelcome = (props) => {
	const dispatch = useDispatch();
	let [user, setUser] = useState(null);

	const getUser = async () => {
		await localStorage.setItem("x-auth", props.match.params.token);

		let user = await Axios.get("/api/web-user", {
			headers: {"x-auth": props.match.params.token},
		});

		if (user.data._id) {
			console.log(user.data);
			setUser(user.data);
			dispatch({type: CURRENT_USER, payload: user.data});
		} else {
			setUser(null);
		}
	};

	useEffect(
		() => {
			getUser();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(
		() => {
			if (!user) return;

			let tempUrl = props.match.params.redirectUrl;

			let split = tempUrl.split("*");
			split.splice(0, 3);
			let combined = split
				.join("/")
				.replaceAll("(", ":")
				.replaceAll("!", "?")
				.replaceAll("@", "&");
			setTimeout(() => {
				console.log("|||||||||||||||||||||||", combined);
			}, 1000);

			props.history.push("/" + combined);
			window.location = props.match.params.redirectUrl
				.replaceAll("(", ":")
				.replaceAll("*", "/")
				.replaceAll("!", "?")
				.replaceAll("@", "&");
		},

		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user]
	);
	return "Login Welcome";
};

export default LoginWelcome;
