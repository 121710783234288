import React from "react";

export const FacebookLoginSVG = (props) => (
	<svg
		width="14"
		height="25"
		viewBox="0 0 14 25"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.0023 0.45628L9.81885 0.451172C6.24238 0.451172 3.9311 2.82247 3.9311 6.49267V9.27821H0.7303C0.453713 9.27821 0.229736 9.50244 0.229736 9.77903V13.815C0.229736 14.0915 0.453969 14.3155 0.7303 14.3155H3.9311V24.4994C3.9311 24.776 4.15508 25 4.43166 25H8.6078C8.88438 25 9.10836 24.7758 9.10836 24.4994V14.3155H12.8508C13.1274 14.3155 13.3514 14.0915 13.3514 13.815L13.3529 9.77903C13.3529 9.64622 13.3001 9.51904 13.2063 9.42506C13.1126 9.33107 12.9849 9.27821 12.8521 9.27821H9.10836V6.91687C9.10836 5.78192 9.37882 5.20576 10.8573 5.20576L13.0018 5.205C13.2781 5.205 13.5021 4.98076 13.5021 4.70443V0.956844C13.5021 0.680767 13.2784 0.45679 13.0023 0.45628Z"
			fill="white"
		/>
	</svg>
);
