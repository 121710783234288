import { useState } from "react";
import { useSelector } from "react-redux";
import DatePicker from "../../../components/datepicker/DatePicker";
import SAInput from "../../../components/ui/form-elements/stand-alone-input/Input";
import Portal from "../../../components/ui/portal/Portal";
import { formatFromCurrencyToMoney, getToken } from "../../../helper";
import axios from "axios";
import Address from "./address/Address";
import AlternateContact from "./alternate-contact/AlternateContact";
import InsuranceSelection from "./insurance-selection/insuranceSelection";
import PayMethod from "./pay-method/PayMethod";
import PhoneNumber from "./phone-number/PhoneNumber";
import styles from "./Reserve.module.scss";
import ServiceMember from "./service-member/ServiceMember";
import StoredContent from "./stored-content/StoredContent";
import { useHistory } from "react-router-dom";
import AnvilDocument from "../../../containers/account/rentals/documents/anvil/AnvilDocumnet";
import { useEffect } from "react";

const inputs = (input, value, update, fields) => {
  const updateAction = (data) => {
    update(data);
  };

  switch (input.type) {
    case "string":
      return (
        <SAInput
          label={input.label}
          onChange={(v) => updateAction({ [input.handle]: v.target.value })}
          value={value}
        />
      );
    case "number":
      return (
        <SAInput
          label={input.label}
          onChange={(v) => updateAction({ [input.handle]: v.target.value })}
          value={value}
        />
      );
    case "date":
      return (
        <DatePicker
          ifHasError={false}
          label={input.label}
          placeholder="Select date"
          startDate={Number(new Date())}
          onChange={console.log}
          value={value}
          readonly={true}
          selectedDate={value}
          updateDate={(d) => updateAction({ [input.handle]: Number(d) })}
          earliestDate={0}
        />
      );

    case "insuranceSelection":
      return (
        <InsuranceSelection
          value={fields?.insuranceBracket}
          insurancePolicyNumber={fields?.outsideInsurancePolicyNumber}
          onUpdate={(v) => {
            updateAction({
              outsideInsurancePolicyNumber: v?.outsideInsurancePolicyNumber,

              insuranceBracket: v?.bracketId,
            });
          }}
        />
      );

    case "phoneNumber":
      return (
        <PhoneNumber
          value={value}
          onUpdate={(v) => updateAction({ [input.handle]: v })}
        />
      );
    case "address":
      return (
        <Address
          value={value}
          label={input.label}
          onUpdate={(v) => updateAction({ [input.handle]: v })}
        />
      );
    case "serviceMember":
      return (
        <ServiceMember
          value={value}
          label={input.label}
          onUpdate={(v) => {
            updateAction({
              [input.handle]: v?.serviceMember,
            });
          }}
        />
      );
    case "alternateContact":
      return (
        <AlternateContact
          value={value}
          label={input.label}
          altNameState={fields["altName"]}
          alt_delivery_line_1State={fields["alt_delivery_line_1"]}
          alt_last_lineState={fields["alt_last_line"]}
          altPhoneState={fields["altPhone"]}
          onUpdate={(v) => {
            updateAction({
              [input.handle]: v?.alternateContact,
              altName: v?.altName,
              alt_delivery_line_1: v?.alt_delivery_line_1,
              alt_last_line: v?.alt_last_line,
              altPhone: v?.altPhone,
            });
          }}
        />
      );
    case "storedContent":
      return (
        <StoredContent
          label={input.label}
          value={fields["contentToBeStored"]}
          onUpdate={(v) =>
            updateAction({
              [input.handle]: v.storedContent,
              vehicleType: v.vehicleType,
              vehicleMake: v.vehicleMake,
              vehicleModel: v.vehicleModel,
              vehicleVin: v.vehicleVin,
              vehicleLength: v.vehicleLength,
              vehicleColor: v.vehicleColor,
              vehicleDescription: v.vehicleDescription,
            })
          }
        />
      );

    case "payMethod":
      return (
        <PayMethod
          value={fields["payMethod"]}
          autoPay={fields["autoPay"]}
          onUpdate={(v) => {
            updateAction(v);
          }}
        />
      );

    default:
      return "Type not supported";
  }
  // return <div>Input type {type}</div>;
};

const Reserve = ({ flow, params, unitId, reservation = {} }) => {
  const history = useHistory();

  const { auth, reservationUnit } = useSelector(
    ({ auth, reservationUnit }) => ({
      auth,
      reservationUnit,
    })
  );

  const [currentPage, setCurrentPage] = useState(0);
  const [fields, setFields] = useState(reservation);
  const [showSignaturePopup, setShowSignaturePopup] = useState(false);
  const [docId, setDocId] = useState(undefined);
  const [sigId, setSigId] = useState(undefined);

  const updateField = (update) => {
    setFields({ ...fields, ...update });
  };

  const save = async () => {
    await axios.put(
      `/api/reservation/${reservation._id}`,
      {
        ...fields,
        autoPayMethod: fields.autoPay ? fields.payMethod : undefined,
      },
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );

    history.push(
      `/reserve/summary/${reservation._id}/${fields.payMethod}/${fields.mailingAddress}`
    );
  };

  // useEffect(() => {
  //   getReservationUnit();
  // }, [unitId]);

  const getOrCreateContract = async () => {
    if (!reservation.address || !reservation.phoneNumber) {
      await axios.put(
        `/api/reservation/${reservation._id}`,
        {
          address: auth?.user?.defaultAddress?._id,
          phoneNumber: auth?.user?.defaultPhoneNumber?._id,
        },

        { headers: { "x-auth": getToken() } }
      );
    }

    let doc = await axios.post(`/api/create-get-contract/${reservation._id}`);

    // console.log(
    //   doc.data.data._id,
    //   doc.data.data.eSigSignatures[0].signature_id
    // );

    setDocId(doc.data.data._id);
    setSigId(doc.data.data.eSigSignatures[0].eid);
  };

  // const setDocSignedAction = () => console.log("OOK");

  useEffect(() => {
    if (reservation?.initialPayment) {
      getOrCreateContract();
    }
    // eslint-disable-next-line
  }, [reservation]);

  const signatureCompleted = async () => {
    await axios.put(`/api/document-details/${docId}`, {
      completed: true,
    });
    // setSignatureCompleted(res.data.data.completed);

    history.push(`/thank-you/${reservation._id}`);
  };

  return (
    <>
      {/* {JSON.stringify(fields)} */}

      {flow?.length && reservation?._id ? (
        <div>
          <Portal
            opacity="0.3"
            height="auto"
            width="400px"
            title="Reserve"
            customHeader={true}
            zIndex="101"
          >
            {reservation?.documents?.length &&
            reservation?.documents[0].completed ? (
              "LOL1"
            ) : (
              <>
                {" "}
                <div
                  className={`df acc m-t-10 m-b-20 p-t-30 p-l-40 p-r-40 ${styles.dotsWrpr}`}
                >
                  {flow.map((page, idx) => (
                    <div
                      title={page.title}
                      className={`${styles.dot} ${
                        currentPage === idx ? styles.active : ""
                      } ${currentPage > idx ? styles.past : ""}`}
                    ></div>
                  ))}
                </div>
                <div className={`ffqsb p-b-30 p-l-40 p-r-40 ${styles.header}`}>
                  <p className={`fs14 m-b-6`}>
                    Hi {auth?.user?.fullName?.split(" ")[0]},
                  </p>
                  <p className={`fs22 m-b-2`}>
                    Reserve Your Unit {reservationUnit.unitId}
                  </p>
                  <p className={`fs12 ttac`}>
                    Total $ {formatFromCurrencyToMoney(reservationUnit?.total)}
                  </p>
                </div>
                {console.log("KOK", reservation)}
                {reservation?.initialPayment ? (
                  "undefined"
                ) : (
                  <div className={`p-t-10 p-l-40 p-r-40`}>
                    {flow[currentPage].steps.map((step) => (
                      <div className={`m-t-20`}>
                        <div>
                          {inputs(
                            step,
                            fields[step.handle],
                            updateField,
                            fields
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {reservation?.initialPayment ? (
                  <>
                    <div
                      className={`df m-t-20 p-b-20 p-l-40 p-r-40 p-t-30 ${styles.footer}`}
                    >
                      <button
                        className={`df ffmb fs14 ttuc fwbold srch_btn button primary w100 m-b-12 m-r-10`}
                        onClick={() => {
                          setShowSignaturePopup(true);
                        }}
                      >
                        Please Sign your contract
                      </button>
                    </div>{" "}
                    {showSignaturePopup ? (
                      <AnvilDocument
                        close={() => setShowSignaturePopup(false)}
                        signerName={auth.user.fullName}
                        signerEid={sigId}
                        onFinish={signatureCompleted}
                      ></AnvilDocument>
                    ) : undefined}
                  </>
                ) : (
                  <div
                    className={`df m-t-20 p-b-20 p-l-40 p-r-40 p-t-30 ${styles.footer}`}
                  >
                    <div className={`df mla`}>
                      {currentPage > 0 ? (
                        <button
                          className={`df ffmb fs14 ttuc fwbold srch_btn button secondary w100 m-b-12 m-r-10`}
                          onClick={() => {
                            if (currentPage > 0) {
                              setCurrentPage(currentPage - 1);
                            }
                          }}
                        >
                          Back
                        </button>
                      ) : undefined}
                      <button
                        className={`df ffmb fs14 ttuc fwbold srch_btn button primary w100 m-b-12`}
                        onClick={() => {
                          if (currentPage + 1 < flow.length) {
                            setCurrentPage(currentPage + 1);
                          } else {
                            save();
                          }
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
            {/* <div className={``}> */}

            {/* </div> */}
          </Portal>
        </div>
      ) : undefined}
    </>
  );
};

export default Reserve;
