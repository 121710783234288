import React from "react";
import LandingPage from "../components/landing/Landing";
import {Explore} from "../components/explore-sec/Explore";
import {useSelector} from "react-redux";

export const LandingWrpr = (props) => {
	const websiteProfile = useSelector(({websiteProfile}) => ({
		websiteProfile,
	}));

	return (
		<>
			<LandingPage></LandingPage>
			<Explore websiteProfile={websiteProfile}></Explore>
		</>
	);
};

// props.websiteProfile.locations[0]
