import Axios from "axios";
import {
  GET_BEST_UNIT_MATCH,
  GET_UNIT,
  GET_UNIT_SIZES,
  SET_UNIT_SEARCH,
} from "./types";

export const getUnitSizes = () => async (dispatch, getState) => {
  let { websiteProfile } = getState();

  // console.log(websiteProfile.locations);

  let ids = websiteProfile?.locations?.map((el) => el._id);
  let res = await Axios.get(
    `/api/website/storage-sizes/${JSON.stringify(ids)}`
  );

  // console.log(res);
  dispatch({
    type: GET_UNIT_SIZES,
    payload: res.data.data,
  });
};

export const setUnitSearch = (date, size, unitLocation) => async (dispatch) => {
  // console.log(date, size);

  localStorage.setItem("moveInDate", Number(date));
  localStorage.setItem("desiredSize", size);
  localStorage.setItem("unitLocation", unitLocation);

  dispatch({
    type: SET_UNIT_SEARCH,
    payload: { desiredSize: size, moveInDate: date, unitLocation },
  });
};

export const getUnitsFromSearch = (filter) => async (dispatch, getState) => {
  // let { unitSearch } = getState();

  // if (!unitSearch.moveInDate) {
  //   let date = await localStorage.getItem("moveInDate");
  //   let size = await localStorage.getItem("desiredSize");
  //   let location = await localStorage.getItem("unitLocation");

  //   dispatch({
  //     type: SET_UNIT_SEARCH,
  //     payload: { desiredSize: size, moveInDate: date, unitLocation: location }
  //   });

  //   unitSearch = getState().unitSearch;
  // }

  let unitList = await Axios.get(
    `/api/units-list/?desiredSize=${filter.size}&moveInDate=${filter.date}&unitLocation=${filter.location}`
  );

  dispatch({
    type: GET_BEST_UNIT_MATCH,
    payload: unitList.data.data,
  });
};

export const getUnit = (unitId, moveinDate) => async (dispatch, getState) => {
  let unit = await Axios.get(
    `/api/reserve-unit/?unitid=${unitId}&moveindate=${moveinDate}`
  );

  dispatch({
    type: GET_UNIT,
    payload: unit.data.data,
  });
};
