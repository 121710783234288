import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";

import AmexImg from "../../../../assets/images/credit-cards/american-express.svg";
import CitiImg from "../../../../assets/images/credit-cards/citi.svg";
import Discover from "../../../../assets/images/credit-cards/discover.svg";
import MasterImg from "../../../../assets/images/credit-cards/mastercard.svg";
import VisaImg from "../../../../assets/images/credit-cards/visa.svg";
import { ExpandMore } from "../../../../assets/images/svgs/expand-more.svg";
import { AddItemButton } from "../../../../components/ui/buttons/add-item-button/AddItemButton";
import Portal from "../../../../components/ui/portal/Portal";
import SelectPayment from "../../../../containers/account/payments/select-payment/SelectPayment";

import styles from "./PayMethod.module.scss";

const images = {
  Visa: VisaImg,
  Amex: AmexImg,
  Citi: CitiImg,
  MasterCard: MasterImg,
  Discover,
};

const PayMethod = ({ value, autoPay, onUpdate }) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  const [payMethod, setPayMethod] = useState(value || null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [localAutoPay, setLocalAutoPay] = useState(autoPay || undefined);
  const [showSelectPayMethod, setShowSelectPayMethod] = useState(false);

  const getDefaultPayMethod = async () => {
    // console.log("Payment action");

    let method = await axios.get(`/api/default-pay-method/${auth.user._id}`);
    // console.log(method.data);

    setPayMethod(method?.data?.data?._id);
  };

  useEffect(() => {
    getDefaultPayMethod();
    // eslint-disable-next-line
  }, []);

  const getPaymentDetails = async () => {
    let details = await axios.get(`/api/payment-method/${payMethod}`);

    // console;

    setPaymentDetails(details.data.data);
  };

  useEffect(() => {
    if (!payMethod) return;
    getPaymentDetails();
    // eslint-disable-next-line
  }, [payMethod]);

  useEffect(() => {
    if (!payMethod || localAutoPay === undefined) return;
    onUpdate({ payMethod, autoPay: localAutoPay });
    // eslint-disable-next-line
  }, [payMethod, localAutoPay]);

  const setShowSelectPayMethodAction = async (id) => {
    if (!id) return setShowSelectPayMethod(false);

    // await getPaymentDetails(id);

    setPayMethod(id);
    setShowSelectPayMethod(false);
  };

  return (
    <div>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-30`}>
        Payment Method
        {payMethod ? (
          <span className="mla" onClick={() => setShowSelectPayMethod(true)}>
            <ExpandMore></ExpandMore>
          </span>
        ) : undefined}
      </h5>
      {paymentDetails ? (
        <div>
          <div className={`df acsa ${styles.cardWrpr}`}>
            <img
              src={images[paymentDetails.xCardType]}
              alt={paymentDetails.xCardType}
            />
            <div className={`df acsa ${styles.dotsWrpr}`}>
              <div className={`${styles.dot}`}></div>
              <div className={`${styles.dot}`}></div>
              <div className={`${styles.dot}`}></div>
              <div className={`${styles.dot}`}></div>
            </div>

            <p className={`ffmr fs14`}>
              {paymentDetails.xMaskedCardNumber.substring(
                paymentDetails.xMaskedCardNumber.length - 4
              )}
            </p>
          </div>
        </div>
      ) : (
        // text,
        // action,
        // loading,
        // setLoading,
        // children,
        // colorStyle,
        // type,
        <AddItemButton
          text="Add Payment Method"
          action={() => setShowSelectPayMethod(true)}
        />
      )}
      <div className={`df acsa m-t-20`}>
        <input
          type="checkbox"
          id="autoPay"
          checked={localAutoPay}
          onChange={(e) => setLocalAutoPay(!localAutoPay)}
        />
        <label htmlFor="autoPay" className={`m-l-5 m-t-2 ffmr fs12`}>
          Auto pay
        </label>
      </div>

      {showSelectPayMethod ? (
        <Portal
          zIndex="102"
          title="Select Card"
          noCustomScrollbar
          opacity=".5"
          width="498px"
          close={() => setShowSelectPayMethodAction(false)}
          height="auto"
        >
          <SelectPayment
            selectedId={payMethod}
            userId={auth?.user?._id}
            selectAction={setShowSelectPayMethodAction}
            close={null}
            // closeOnselect={false}
            // includeAutopays={true}
          ></SelectPayment>
        </Portal>
      ) : undefined}
    </div>
  );
};

export default PayMethod;
