import Axios from "axios";
import React, { useEffect, useState } from "react";
import Media from "react-media";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import credit card images
import NoRentalsSVG from "../../../assets/images/no-rentals.svg";
import AmexImg from "../../../assets/images/credit-cards/american-express.svg";
import CitiImg from "../../../assets/images/credit-cards/citi.svg";
import Discover from "../../../assets/images/credit-cards/discover.svg";
import MasterImg from "../../../assets/images/credit-cards/mastercard.svg";
import VisaImg from "../../../assets/images/credit-cards/visa.svg";
import { DeleteIcon } from "../../../assets/images/svgs/delete.svg";
import { PlusSVG } from "../../../assets/images/svgs/plus.svg";
import Portal from "../../../components/ui/portal/Portal";
import AddCard from "./add-card/AddCard";
import "./Payments.scss";

const images = {
  Visa: VisaImg,
  Amex: AmexImg,
  Citi: CitiImg,
  MasterCard: MasterImg,
  Discover,
};

const Payments = (props) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [showAddCard, setShowAddCard] = useState(false);
  // const [cardLoaded, setCardLoaded] = useState(false);

  const auth = useSelector((state) => state.auth);

  const getPaymentMethods = async () => {
    let pms = await Axios.get(`/api/user-pay-methods/${auth.user._id}`, {
      headers: { "x-auth": localStorage.getItem("x-auth") },
    });

    setPaymentMethods(pms.data.paymentMethods);
  };

  const deletePaymentMethod = async (id) => {
    await Axios.delete(`/api/payment-method/${id}`);
    getPaymentMethods();
  };

  const toggleAddPopup = () => setShowAddCard(!showAddCard);

  useEffect(
    () => {
      getPaymentMethods();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return (
    <div className="payments_page page">
      <div className=" acsa hdr">
        <div className="df acsa ffmr fs14 breadcrumbs">
          <Link to="/account">Account</Link>
          <span className="f_slash">/</span>
          <p>Payments</p>
        </div>
        <div className="df acsa">
          <div className="left_wrpr">
            <h5 className="ffqsb fs25">Payments</h5>
          </div>

          <div className="df acc pbgc mba mla add_btn">
            <div
              className="df acc add_btn_wrpr"
              onClick={() => setShowAddCard(true)}
            >
              <Media queries={{ small: { maxWidth: 599 } }}>
                {(matches) =>
                  matches.small ? (
                    <PlusSVG fill="white" height="10" width="10"></PlusSVG>
                  ) : (
                    <PlusSVG fill="white" height="20" width="20"></PlusSVG>
                  )
                }
              </Media>
            </div>

            {showAddCard ? (
              <Portal
                title="Add Card"
                noCustomScrollbar
                opacity=".08"
                width="498px"
                close={() => setShowAddCard(false)}
                height="auto"
              >
                <AddCard
                  close={toggleAddPopup}
                  refreshCards={getPaymentMethods}
                ></AddCard>
              </Portal>
            ) : undefined}
          </div>
        </div>
      </div>
      {paymentMethods <= 0 ? (
        <div className={`df ffc acc no_rentals_wrpr m-t-50`}>
          <img src={NoRentalsSVG} alt="No Rentals" />

          <p className={`ffmr fs14 m-t-30`}>No payment methods found!</p>
          <p className={`ffmr fs14 m-t-10`}>
            <span
              onClick={() => setShowAddCard(true)}
              className={`ffmb tdn ptc cursor_pointer`}
            >
              Click here
            </span>{" "}
            to add a payment method
          </p>
        </div>
      ) : (
        <div className="df fww payments_list">
          {paymentMethods
            ? paymentMethods.map((pm, index) => (
                <div key={index} className="payment_wrpr">
                  {/* <img src={images[pm.xCardType]} alt="hello" /> */}
                  <div className="df acsa pmnt_hdr">
                    <div className="logo_wrpr">
                      <img src={images[pm.xCardType]} alt="hello" />
                    </div>

                    <div className="fs16 df acsa card_info">
                      <p className="ffmb">{pm.xCardType} </p>
                      <div className="df dots_wrpr">
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                        <div className="dot"></div>
                      </div>
                      <p className="ffmr">
                        {pm.xMaskedCardNumber.substring(
                          pm.xMaskedCardNumber.length - 4
                        )}
                      </p>
                    </div>
                  </div>

                  {/* <div className="billing_info">
                <h4 className="ffmm ttuc fs14">
                  Billing Information
                </h4>

                For now using users default address 
                <div className="ffmr fs14 bill_info_wrpr">
                  <p className="">{auth.user.fullName}</p>
                  <p className="">
                    {auth.user.defaultAddress.line1}{" "}
                    {auth.user.defaultAddress.line2}
                  </p>
                  <p className="">
                    {auth.user.defaultAddress.city}{" "}
                    {auth.user.defaultAddress.state}{" "}
                    {auth.user.defaultAddress.zip}
                  </p>
                </div>
              </div> */}

                  <div className="df acssa card_ftr">
                    <div className="df acsa mla">
                      <div className="df acc button_wrpr">
                        <button onClick={() => deletePaymentMethod(pm._id)}>
                          <DeleteIcon></DeleteIcon>
                        </button>
                      </div>
                      {/* <div className="df acc button_wrpr">
                    <button>
                      <EditIcon />
                    </button>
                  </div> */}
                      {/* <div className="df acc button_wrpr">
                    <input type="checkbox" checked={pm.primary} />
                  </div> */}
                    </div>
                  </div>
                </div>
              ))
            : undefined}
        </div>
      )}
    </div>
  );
};

export default Payments;
