import React, { useContext } from "react";

// import scss
import "./StorageAddress.scss";
import { MapPin } from "../../../assets/images/svgs/map-pin.svg";

import { ThemeContext } from "../../../App";

export const StorageLocation = (props) => {
  const bgColor = useContext(ThemeContext);

  return (
    <div className="df ffqsb fs16 location_wrpr">
      <div className="icon_wrpr">
        <MapPin fill={bgColor}></MapPin>
      </div>
      <div className="text_wrpr">
        {/* <p>Plaza 15 storage</p>
      <p>123 Street st.</p>
      <p>Spring Valley, NY, 10977</p> */}

        <p>{props.location.name}</p>
        <p>{props.location.address?.deliveryLine1}</p>
        <p>{props.location.address?.lastLine}</p>
      </div>
    </div>
  );
};
