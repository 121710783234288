// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import { logout, setAuth } from "../../store/actions";
// import { Popup } from "../ui/popup/popup";
// import SignIn from "../ui/signin-popup/SignInPopup";
// // import images
// // import logoWhite from "../../assets/images/demo-logo-white.png";
// // import logoGreen from "../../assets/images/demo-logo-green.png";
// // import scss
// import "./Header.scss";

// class Header extends Component {
//   state = { showDD: false, signinPopup: false };
//   componentDidMount() {
//     // this.auth = window.gapi.auth2.getAuthInstance();
//     // this.auth.isSignedIn.listen(this.updateGoogleAuth);
//   }

//   updateGoogleAuth = () =>
//     this.props.setAuth(this.auth.currentUser.get().jc, "google");

//   toggleSignIn = () =>
//     this.setState((prevState) => ({ signinPopup: !prevState.signinPopup }));
//   render() {
//     // let navColor = `${
//     // 	this.props.headerOpacity > 0.7 ? "#30BE76" : "#30BE76"
//     // }`;

//     return (
//       <header
//         className="page_hdr"
//         style={{
//           backgroundColor: `rgba(256,256,256, ${
//             this.props.headerOpacity - 0.035
//           })`,

//           boxShadow: `0px 10px 20px rgb(31, 32, 65,  0.05) `,
//         }}
//       >
//         {/* {console.log(`${window.location.href}`, process.env.NODE_ENV)} */}
//         <div className="df acsa content_wrpr">
//           <Link to="/" className="df logo_wrpr">
//             <img src={this.props?.websiteProfile?.theme?.logoURL} alt="Logo"></img>
//           </Link>
//           <div className="df mla ffmsb fs14 navs_wrpr">
//             <div className="df innerNavsWrpr">
//               <div className="df sec nav ptc">About</div>
//               <div className="df sec nav ptc">FAQ</div>
//               <Link to="/account/payments" className="df sec nav ptc">
//                 Payment
//               </Link>
//             </div>
//             {this.props.auth &&
//             this.props.auth.user &&
//             this.props.auth.user.email ? (
//               <div
//                 className={`sec profileWrpr ${
//                   this.state.showDD ? "dd_open" : ""
//                 }`}
//               >
//                 <div
//                   className="df ffmr inner_profileWrpr"
//                   onClick={() =>
//                     this.setState((prevState) => ({
//                       showDD: !prevState.showDD,
//                     }))
//                   }
//                 >
//                   <p className="name_wrpr">{this.props.auth.user.fullName}</p>
//                   {this.props.auth.user.profileImageUrl ? (
//                     <div
//                       className="profileImageWrpr"
//                       style={{
//                         backgroundImage: `url(${this.props.auth.user.profileImageUrl})`,
//                       }}
//                     ></div>
//                   ) : (
//                     <div className="df acc ffqsr fs14 profileImageWrpr dummy pbgc ttuc">
//                       {this.props.auth.user.fullName.substring(0, 2)}
//                     </div>
//                   )}
//                 </div>
//                 {this.state.showDD && (
//                   <div className="profileDdWrpr">
//                     <p
//                       className="df acsa ffmr fs14 line"
//                       onClick={this.props.logout}
//                     >
//                       Log Out
//                     </p>
//                     <p
//                       className="df acsa ffmr fs14 line"
//                       onClick={() =>
//                         this.props.allProps.history.push("/account")
//                       }
//                     >
//                       Manage Account
//                     </p>
//                   </div>
//                 )}
//               </div>
//             ) : (
//               <div className="df sec profileWrpr">
//                 {this.props.websiteProfile ? (
//                   <p
//                     className="login_link"
//                     // href={`/auth/google/?redirectUrl=${window.location.href}`}
//                     onClick={this.toggleSignIn}
//                   >
//                     Login
//                   </p>
//                 ) : undefined}
//               </div>
//             )}
//           </div>
//         </div>

//         {this.state.signinPopup && (
//           <Popup
//             customClass="signin_popup"
//             zIndex="6"
//             overlay={true}
//             closeAction={this.toggleSignIn}
//           >
//             <SignIn
//               continueAction={console.log}
//               closeAction={this.toggleSignIn}
//               accountId={this.props.websiteProfile.account}
//               webProfileId={this.props.websiteProfile._id}
//               showForgot={true}
//               showSignup={true}
//               showSocial={true}
//             ></SignIn>
//           </Popup>
//         )}
//       </header>
//     );
//   }
// }

// const mapStateToProps = ({ websiteProfile, auth }) => ({
//   websiteProfile,
//   auth,
// });

// export default connect(mapStateToProps, { setAuth, logout })(Header);

import axios from "axios";
import { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Popup } from "../ui/popup/popup";
import SignIn from "../ui/signin-popup/SignInPopup";
import { CLEAR_AUTH } from "../../store/actions/types";

import styles from "./Header.module.scss";
import { useEffect } from "react";

const Header = ({ headerOpacity }) => {
  const menuRef = useRef(null);

  const { websiteProfile, auth } = useSelector(({ websiteProfile, auth }) => ({
    websiteProfile,
    auth,
  }));

  const history = useHistory();
  const dispatch = useDispatch();

  const [showDD, setShowDD] = useState(false);
  const [signinPopup, setSigninPopup] = useState(false);

  const toggleSignIn = () => setSigninPopup(!signinPopup);

  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShowDD(false);
    }
  };

  useEffect(() => {
    // addeventlistener to close menu if user clicks outside of it
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  });

  return (
    <header
      className={styles.pageHdr}
      style={{
        backgroundColor: `rgba(256,256,256, ${headerOpacity - 0.035})`,

        boxShadow: `0px 10px 20px rgb(31, 32, 65,  0.05) `,
      }}
    >
      <div className={`df acsa content_wrpr`}>
        <Link to="/" className={`df ${styles.logoWrpr}`}>
          <img src={websiteProfile?.theme?.logoURL} alt="Logo"></img>
        </Link>
        <div className={`df mla ffmsb fs14 ${styles.navsWrpr}`}>
          <div className={`df ${styles.innerNavsWrpr}`}>
            {/* <div className={`df ptc ${styles.sec} ${styles.nav}`}>About</div>
            <div className={`df ptc ${styles.sec} ${styles.nav}`}>FAQ</div> */}
            <Link
              to="/account"
              className={`df ptc p-l-15 p-r-15 ${styles.sec} ${styles.nav}`}
            >
              Make A Payment
            </Link>
            <Link
              to="/contact"
              className={`df ptc p-l-15 p-r-15 ${styles.sec} ${styles.nav}`}
            >
              Contact Us
            </Link>
          </div>
          {auth && auth.user && auth.user.email ? (
            <div
              ref={menuRef}
              className={`${styles.sec} ${styles.profileWrpr} ${
                showDD ? styles.ddOpen : ""
              }`}
            >
              <div
                className={`df ffmr ${styles.innerProfileWrpr}`}
                onClick={() => {
                  setShowDD(!showDD);
                }}
                // this.setState((prevState) => ({
                //   showDD: !prevState.showDD,
                // }))
                // }
              >
                <p className={`${styles.nameWrpr}`}>{auth.user.fullName}</p>
                {auth?.user?.profileImageUrl ? (
                  <div
                    className={`${styles.profileImageWrpr}`}
                    style={{
                      backgroundImage: `url(${auth.user.profileImageUrl})`,
                    }}
                  ></div>
                ) : (
                  <div
                    className={`df acc ffqsr fs14 ${styles.profileImageWrpr} ${styles.dummy} pbgc ttuc`}
                  >
                    {auth.user.fullName.substring(0, 2)}
                  </div>
                )}
              </div>
              {showDD && (
                <div className={`${styles.profileDdWrpr}`}>
                  <p
                    className={`df acsa ffmr fs14 ${styles.line}`}
                    onClick={async () => {
                      setShowDD(false);
                      let logOut = await axios.get("/api/logout-auth");

                      localStorage.removeItem("x-auth");

                      dispatch({ type: CLEAR_AUTH, payload: logOut.data });
                    }}
                  >
                    Log Out
                  </p>
                  <p
                    className={`df acsa ffmr fs14 ${styles.line}`}
                    onClick={() => {
                      setShowDD(false);
                      history.push("/account");
                    }}
                  >
                    Manage Account
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className={`df ${styles.sec} ${styles.profileWrpr}`}>
              {websiteProfile ? (
                <p
                  className="login_link"
                  // href={`/auth/google/?redirectUrl=${window.location.href}`}
                  onClick={toggleSignIn}
                >
                  Login
                </p>
              ) : undefined}
            </div>
          )}
        </div>
      </div>
      {signinPopup && (
        <Popup
          customClass="signin_popup"
          zIndex="6"
          overlay={true}
          closeAction={toggleSignIn}
        >
          <SignIn
            continueAction={console.log}
            closeAction={toggleSignIn}
            accountId={websiteProfile.account}
            webProfileId={websiteProfile._id}
            webProfile={websiteProfile}
            showForgot={true}
            showSignup={true}
            showSocial={false}
          ></SignIn>
        </Popup>
      )}
    </header>
  );
};

export default Header;
