import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";
import { CloseXSVG } from "../../../assets/images/svgs/cloce-x.svg";

//import scss
import "./Portal.scss";

// import Scrollbars from "react-custom-scrollbars";

class Portal extends Component {
  constructor(props) {
    super(props);
    this.rootSelector = document.getElementById("root");
    this.container = document.createElement("div");
    this.container.classList.add("popup");
    this.popupRef = createRef(null);
  }
  componentDidMount() {
    this.rootSelector.appendChild(this.container);
  }

  componentWillUnmount() {
    this.rootSelector.removeChild(this.container);
  }

  render() {
    return ReactDOM.createPortal(
      <>
        <div
          className="__popup df ffc"
          ref={this.popupRef}
          style={{
            height: this.props.height || "400px",
            width: this.props.width,
            zIndex: this.props.zIndex || 1,
            minHeight: this.props.minHeight,
          }}
        >
          {!this.props.customHeader ? (
            <div className="df acsa __popup_hdr">
              <div className="ffmb fs16 __popup_title">{this.props.title}</div>
              <div
                className="df acc mla __popup_close cursor_pointer"
                onClick={() => {
                  console.log("CLOSING POPUP SHOP", this.props.close);
                  return this.props.close();
                }}
              >
                <CloseXSVG></CloseXSVG>
                {/* {console.log(this.props.close)} */}
              </div>
            </div>
          ) : null}

          <div
            className={`__popup_cntnt_wrpr ${
              this.props.customHeader ? "no-header" : ""
            }`}
            style={{
              overflowX: `${this.props.noCustomScrollbar ? "auto" : "initial"}`,
              overflow: this.props.height === "auto" ? "auto" : "",
            }}
          >
            {/* {this.props.noCustomScrollbar ? ( */}
            <>{this.props.children}</>
            {/* ) : (
							<Scrollbars
								className=" __scrollbar"
								style={{height: "100%"}}
								renderTrackVertical={this.renderTrackVertical}
								renderTrackHorizontal={
									this.renderTrackHorizontal
								}
							>
								{this.props.children}
							</Scrollbars>
						)} */}
          </div>

          {/* <div className="__popup_cntnt"> {this.props.children}</div> */}
        </div>
        <div
          onClick={() =>
            (this.props.overlayClick && this.props.overlayClick()) || null
          }
          className="__overlay"
          style={{
            zIndex: this.props.zIndex - 1 || 0,
            opacity: this.props.opacity || 0,
          }}
        ></div>
      </>,
      this.container
    );
  }
}

export default Portal;
