import { useState, useEffect } from "react";

import styles from "./StoredContent.module.scss";

// Content types
import HouseholdItemsTypeSVG from "../../../../assets/images/svgs/storage-types/household-items.svg";
import FurnitureTypeSVG from "../../../../assets/images/svgs/storage-types/furniture.svg";
import BoxesTypeSVG from "../../../../assets/images/svgs/storage-types/boxes.svg";
import DocumentTypeSVG from "../../../../assets/images/svgs/storage-types/document.svg";
import ToolsTypeSVG from "../../../../assets/images/svgs/storage-types/tools.svg";
import MotorVehicleSVG from "../../../../assets/images/svgs/motor-vehicle.svg";

import TrailerSVG from "../../../../assets/images/svgs/trailer.svg";
import WatercraftSVG from "../../../../assets/images/svgs/watercraft.svg";
import RandomSVG from "../../../../assets/images/svgs/random.svg";

import SAInput from "../../../../components/ui/form-elements/stand-alone-input/Input";

const contentTypes = [
  {
    label: "Household items",
    value: "householdItems",
  },
  {
    label: "Furniture",
    value: "furniture",
  },
  {
    label: "Documents",
    value: "documents",
  },
  {
    label: "Tools",
    value: "tools",
  },
  {
    label: "Boxes",
    value: "boxes",
  },
  {
    label: "Motor vehicle",
    value: "motorVehicle",
  },
];

const getIcon = (value, isSelected) => {
  switch (value) {
    case "householdItems":
      return <HouseholdItemsTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
    case "furniture":
      return <FurnitureTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
    case "documents":
      return <DocumentTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
    case "tools":
      return <ToolsTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
    case "boxes":
      return <BoxesTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
    case "motorVehicle":
      return <MotorVehicleSVG fill={isSelected ? "#fff" : "#15698e"} />;
    default:
      return null;
  }
};

const StoredContent = ({ label, value = [], onUpdate }) => {
  const [storedContent, setStoredContent] = useState(value);
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleVin, setVehicleVin] = useState("");
  const [vehicleLength, setVehicleLength] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleDescription, setVehicleDescription] = useState("");

  const isTypeSelected = (type) => {
    return storedContent.includes(type);
  };

  const handleSelectStoredContent = (type) => {
    let includes = storedContent.includes(type);
    if (includes) {
      setStoredContent(storedContent.filter((item) => item !== type));
    } else {
      setStoredContent([...storedContent, type]);
    }
  };

  //   const showWhat = (field) => {
  //     return isTypeSelected("motorVehicle") ? field : "";
  //   };

  useEffect(() => {
    onUpdate({
      storedContent,
      vehicleType,
      vehicleMake,
      vehicleModel,
      vehicleVin,
      vehicleLength,
      vehicleColor,
      vehicleDescription,
    });
    // eslint-disable-next-line
  }, [
    storedContent,
    vehicleType,
    vehicleMake,
    vehicleModel,
    vehicleVin,
    vehicleLength,
    vehicleColor,
    vehicleDescription,
  ]);

  const isVehicleType = (type) => vehicleType === type;

  useEffect(() => {
    if (isTypeSelected("motorVehicle")) return;
    setVehicleType("");
    setVehicleMake("");
    setVehicleModel("");
    setVehicleVin("");
    setVehicleLength("");
    setVehicleColor("");
    setVehicleDescription("");
    // eslint-disable-next-line
  }, [storedContent]);

  return (
    <div>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>{label}</h5>
      <div className={`w100 ${styles.itemTypesWrpr}`}>
        {contentTypes.map((item, index) => (
          <div
            className={`df ffc acc ${styles.typeItem} ${
              isTypeSelected(item.value) ? styles.selected : ""
            }`}
            key={index}
            onClick={() => {
              handleSelectStoredContent(item.value);
            }}
          >
            {getIcon(item.value, isTypeSelected(item.value))}
            <p className={`ttuc fs8 ffmb m-t-8`}>{item.label}</p>
          </div>
        ))}
      </div>

      {isTypeSelected("motorVehicle") ? (
        <div className={`${styles.vehicleDetailsWrpr}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-20 m-t-30`}>
            Vehicle Information
            {/* <span className="mla" onClick={() => setShowAddressPopup(true)}>
              <ExpandMore></ExpandMore>
            </span> */}
          </h5>
          <p className={`ffmb fs12 ttuc m-t-20`}>Vehicle type</p>
          <div className={`df aic ${styles.vehicleTypes}`}>
            <div
              className={`df acc ffc ${styles.typeOption} ${
                isVehicleType("car") ? "pbgc" : ""
              }`}
              onClick={() => setVehicleType("car")}
            >
              <MotorVehicleSVG
                fill={isVehicleType("car") ? "#fff" : "#15698e"}
              />
              <p
                className={`m-t-8 fs9 ttuc ffmb tac`}
                style={{
                  color: `${isVehicleType("car") ? "#fff" : "#000"}`,
                }}
              >
                Car
              </p>
            </div>
            <div
              className={`df acc ffc ${styles.typeOption} ${
                isVehicleType("trailer") ? "pbgc" : ""
              }`}
              onClick={() => setVehicleType("trailer")}
            >
              <TrailerSVG
                fill={isVehicleType("trailer") ? "#fff" : "#15698e"}
              />
              <p
                className={`m-t-8 fs9 ttuc ffmb tac`}
                style={{
                  color: `${isVehicleType("trailer") ? "#fff" : "#000"}`,
                }}
              >
                Trailer
              </p>
            </div>
            <div
              className={`df acc ffc ${styles.typeOption} ${
                isVehicleType("watercraft") ? "pbgc" : ""
              }`}
              onClick={() => setVehicleType("watercraft")}
            >
              <WatercraftSVG
                fill={isVehicleType("watercraft") ? "#fff" : "#15698e"}
              />
              <p
                className={`m-t-8 fs9 ttuc ffmb tac`}
                style={{
                  color: `${isVehicleType("watercraft") ? "#fff" : "#000"}`,
                }}
              >
                Watercraft
              </p>
            </div>
            <div
              className={`df acc ffc ${styles.typeOption} ${
                isVehicleType("other") ? "pbgc" : ""
              }`}
              onClick={() => setVehicleType("other")}
            >
              <RandomSVG fill={isVehicleType("other") ? "#fff" : "#15698e"} />
              <p
                className={`m-t-8 fs9 ttuc ffmb tac`}
                style={{
                  color: `${isVehicleType("other") ? "#fff" : "#000"}`,
                }}
              >
                Other
              </p>
            </div>
          </div>
          <div className={`df aic `}>
            <div className={`m-t-10 m-r-5`}>
              <SAInput
                label="Make"
                value={vehicleMake}
                onChange={(e) => setVehicleMake(e.target.value)}
              />
            </div>
            <div className={`m-t-10 m-l-5`}>
              <SAInput
                label="Model"
                value={vehicleModel}
                onChange={(e) => setVehicleModel(e.target.value)}
              />
            </div>
          </div>

          <div className={`m-t-10`}>
            <SAInput
              label="VIN"
              value={vehicleVin}
              onChange={(e) => setVehicleVin(e.target.value)}
            />
          </div>
          <div className={`df aic `}>
            <div className={`m-t-10 m-r-5`}>
              <SAInput
                label="Length"
                value={vehicleLength}
                onChange={(e) => setVehicleLength(e.target.value)}
              />
            </div>

            <div className={`m-t-10 m-l-5`}>
              <SAInput
                label="Color(s)"
                value={vehicleColor}
                onChange={(e) => setVehicleColor(e.target.value)}
              />
            </div>
          </div>

          <div className={`m-t-10`}>
            <SAInput
              label="Description"
              value={vehicleDescription}
              onChange={(e) => setVehicleDescription(e.target.value)}
            />
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default StoredContent;
