import Axios from "axios";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThemeContext } from "../../App";
import { MiniCalendarSvg } from "../../assets/images/svgs/mini-calendar.svg";
import { MiniSizeSvg } from "../../assets/images/svgs/mini-size-icon.svg";
import DatePicker from "../../components/datepicker/DatePicker";
import SADropDown from "../../components/ui/form-elements/drop-down/DropDown";
import { GET_UNIT_SIZES } from "../../store/actions/types";
import "./LocationsFilter.scss";

const LocationsFilter = (props) => {
  const theme = useContext(ThemeContext);
  // eslint-disable-next-line no-unused-vars
  const [zipValue, updateZipValue] = useState("");
  // const [dateValue, updateDateValue] = useState("");
  // const [sizeValue, updateSizeValue] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [amntsValue, updateAmntsValue] = useState("");
  const [setUnitSizes, updateSetUnitSizes] = useState(false);
  const [unitSizeList, updateUnitSizeList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedUnitSizeId, updateSelectedUnitSizeId] = useState(undefined);

  const [selectedUnitSizeDisplay, updateSelectedUnitSizeIdDisplay] =
    useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [startDate, updateStartDate] = useState(Number(new Date()));
  const [selectedDate, updateSelectedDate] = useState();
  // const onChange = (e) => updateZipValue(e.target.value);

  const state = useSelector(({ websiteProfile, units }) => ({
    websiteProfile,
    units,
  }));

  const dispatch = useDispatch();

  const setSizes = async () => {
    let data = [...state.units.unitSizes];

    data = data.sort((a, b) => b.score - a.score);

    let transformed = await data.map((el) => ({
      display: `${el.label} staring at $${el.price}/mo`,
      value: el._id,
      unitLocationId: el.locationId,
    }));
    updateUnitSizeList(transformed);

    // updateSelectedUnitSize(
    // 	transformed[_.findIndex(transformed, ["value", props.filters.size])]
    // 		.display
    // );
  };

  useEffect(
    () => {
      // websiteProfile.locations.map((el) => el._id);
      const getUnitSizes = async () => {
        let ids = await state.websiteProfile.locations.map((el) => el._id);

        let res = await Axios.get(
          `/api/website/storage-sizes/${JSON.stringify(ids)}`
        );

        await dispatch({
          type: GET_UNIT_SIZES,
          payload: res.data.data,
        });

        updateSetUnitSizes(true);
        // let data = [...this.props.units.unitSizes];
      };
      getUnitSizes();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (!state.units.unitSizes) return;
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setSizes();
    },
    // eslint-disable-next-line
    [setUnitSizes]
  );

  useEffect(() => {
    if (!props.filters) return;
    if (!props.filters.date) return;
    updateSelectedDate(Number(props.filters.date));
  }, [props.filters]);

  useEffect(
    () => {
      if (!props.filters) return;
      if (!props.filters.size) return;

      let itm =
        unitSizeList[_.findIndex(unitSizeList, ["value", props.filters.size])];

      updateSelectedUnitSizeIdDisplay(itm ? itm.display : "");
    },
    // eslint-disable-next-line
    [unitSizeList]
  );

  useEffect(
    () => {
      if (selectedDate) props.updateSearch("date", selectedDate);
    },
    // eslint-disable-next-line
    [selectedDate]
  );

  useEffect(
    () => {
      // console.log("*****", selectedUnitSizeId);
      if (!selectedUnitSizeId) return;
      props.updateSearch("size", selectedUnitSizeId);
    },
    // eslint-disable-next-line
    [selectedUnitSizeId]
  );

  return (
    <div className="df acsa locations_filter_wrpr">
      {/* {selectedUnitSizeId ? ( */}
      <>
        <div className="df acsa content_wrpr">
          <div className="inpt_sec">
            <DatePicker
              hideLabel
              ifHasError={!selectedDate}
              placeholder="Select date"
              startDate={startDate}
              onChange={console.log}
              value={selectedDate || ""}
              readonly={true}
              selectedDate={selectedDate}
              updateDate={updateSelectedDate}
              earliestDate={new Date()}
              icon={<MiniCalendarSvg fill={theme}></MiniCalendarSvg>}
            />
          </div>

          <div className="inpt_sec">
            <div className="inpt_wrpr">
              <SADropDown
                hideLabel
                placeholder="Select size"
                click={(val) => updateSelectedUnitSizeId(val)}
                list={unitSizeList}
                icon={<MiniSizeSvg fill={theme}></MiniSizeSvg>}
                initialValue={selectedUnitSizeDisplay}
              />
            </div>
          </div>

          {/* {console.log(props)} */}

          {/* <div className="inpt_sec">
					<SAInput
						value={amntsValue}
						ifHasError={console.log}
						onChange={(e) => updateAmntsValue(e.target.value)}
						icon={<MiniAmtsSvg fill={theme}></MiniAmtsSvg>}
						placeholder="Amenities"
					></SAInput>
				</div> */}
        </div>
      </>
    </div>
  );
};

export default LocationsFilter;
