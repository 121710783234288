import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";

import styles from "./insuranceSelection.module.scss";
import { formatFromCurrencyToMoney } from "../../../../helper";
import SAInput from "../../../../components/ui/form-elements/stand-alone-input/Input";

const InsuranceSelection = ({ onUpdate, value, insurancePolicyNumber }) => {
  const { reservationUnit } = useSelector(({ reservationUnit }) => ({
    reservationUnit,
  }));

  const [insuranceBracket, setInsuranceBracket] = useState(value);
  const [insuranceBrackets, setInsuranceBrackets] = useState([]);
  //   const [insuranceCompany, setInsuranceCompany] = useState(undefined);
  const [outsideInsurancePolicyNumber, setOutsideInsurancePolicyNumber] =
    useState(insurancePolicyNumber);

  const getInsuranceBrackets = async () => {
    // unitLocation

    let insuranceProvider = await axios.get(
      `/api/insurance-company/${reservationUnit?.unitLocation?._id}`
    );

    let insuranceProviderId = insuranceProvider?.data?.data[0]?._id;

    if (!insuranceProviderId) return;

    let localInsuranceBrackets = await axios.get(
      `/api/insurance-brackets/${insuranceProviderId}`
    );

    setInsuranceBrackets(localInsuranceBrackets?.data?.data);
  };

  useEffect(() => {
    getInsuranceBrackets();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    console.log("CCC");
    onUpdate({
      bracketId: insuranceBracket,
      outsideInsurancePolicyNumber: outsideInsurancePolicyNumber,
    });
    // eslint-disable-next-line
  }, [insuranceBracket, outsideInsurancePolicyNumber]);
  return (
    <div>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
        Tenant protection
        {/* <span className="mla" onClick={() => setShowAddressPopup(true)}>
              <ExpandMore></ExpandMore>
            </span> */}
      </h5>
      {insuranceBrackets.map((bracket) => (
        <div
          className={`df acsa ffqsb fs16 ${styles.insuranceBracket} ${
            bracket._id === insuranceBracket ? styles.selectedBracket : ""
          }`}
          onClick={() => {
            // setInsuranceCompany(bracket.insuranceCompanyId);
            setInsuranceBracket(bracket._id);
            setOutsideInsurancePolicyNumber("");
          }}
        >
          <div>
            <p className={`ffmr fs12 m-b-4`}>Coverage</p>
            <p>{formatFromCurrencyToMoney(bracket.coverageAmount)}</p>
          </div>
          <div className={`mla tar`}>
            <p className={`ffmr fs12 m-b-4`}>Monthly premium</p>
            <p>{formatFromCurrencyToMoney(bracket.premium)}</p>
          </div>
        </div>
      ))}
      <div
        onClick={() => {
          //   setInsuranceCompany(null);
          setInsuranceBracket(null);
        }}
        className={`df acsa ffqsb fs16 p-t-20 p-b-20 ${
          styles.insuranceBracket
        } ${insuranceBracket === null ? styles.selectedBracket : ""}`}
      >
        <div>I have my own protection</div>
      </div>
      {insuranceBracket === null ? (
        <div className={`m-t-20`}>
          <SAInput
            label="Insurance policy number"
            value={outsideInsurancePolicyNumber}
            onChange={(e) => setOutsideInsurancePolicyNumber(e.target.value)}
          />
        </div>
      ) : undefined}
    </div>
  );
};

export default InsuranceSelection;
