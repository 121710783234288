import React from "react";

export const MiniCalendarSvg = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#miniCalClip)">
      <path
        d="M18.1989 1.69988H15.7151V0.705742C15.7151 0.315977 15.3991 0 15.0093 0C14.6196 0 14.3036 0.315977 14.3036 0.705742V1.69988H5.6968V0.705742C5.6968 0.315977 5.38078 0 4.99105 0C4.60133 0 4.28531 0.315977 4.28531 0.705742V1.69988H1.80145C1.41168 1.69988 1.0957 2.01586 1.0957 2.40562V5.39902H18.9046V2.40562C18.9046 2.01586 18.5887 1.69988 18.1989 1.69988Z"
        fill={props.fill || "red"}
      />
      <path
        d="M1.0957 6.81055V19.2943C1.0957 19.6841 1.41168 20.0001 1.80145 20.0001H18.1989C18.5887 20.0001 18.9046 19.6841 18.9046 19.2943V6.81055H1.0957ZM11.2116 17.1154C11.2116 17.4882 10.7573 17.6746 10.303 17.6746C9.84867 17.6746 9.39441 17.4882 9.39441 17.1154V11.1866L9.0566 11.3962C8.36953 11.8542 7.8102 10.5833 8.53246 10.1499L10.07 9.21809C10.417 9.00113 11.2116 9.23137 11.2116 9.70727V17.1154Z"
        fill={props.fill || "red"}
      />
    </g>
    <defs>
      <clipPath id="miniCalClip">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
