export const Discover = (props) => (
  <svg
    width={props.width || "40"}
    height={props.height || "34"}
    viewBox="0 0 512 354"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M512 322.831C512 339.547 498.45 353.098 481.735 353.098H30.265C13.55 353.098 0 339.545 0 322.831V30.266C0 13.55 13.55 0 30.265 0H481.734C498.45 0 511.999 13.551 511.999 30.266L512 322.831Z"
      fill="#34495E"
    />
    <path
      opacity="0.15"
      d="M21.517 322.831V30.266C21.517 13.55 35.069 0 51.784 0H30.264C13.55 0 0 13.552 0 30.266V322.831C0 339.547 13.55 353.098 30.265 353.098H51.785C35.07 353.098 21.517 339.545 21.517 322.831Z"
      fill="#202121"
    />
    <path
      d="M309.388 176.349C309.429 166.713 305.816 157.063 298.545 149.791C291.258 142.504 281.584 138.894 271.928 138.952C271.882 138.952 271.837 138.949 271.789 138.949C250.821 138.949 234.189 155.577 234.189 176.551C234.189 197.318 251.026 214.15 271.789 214.15C292.763 214.15 309.393 197.519 309.393 176.551C309.393 176.482 309.388 176.417 309.388 176.349Z"
      fill="#F26E21"
    />
    <path
      d="M227.198 192.459C221.578 198.085 216.391 200.283 210.804 200.402C197.193 200.28 187.186 190.2 187.186 175.829C187.186 168.595 189.925 162.666 194.264 157.601C198.333 153.738 203.575 151.242 209.603 151.242C216.11 151.242 221.174 153.411 226.955 159.196V142.565C221.175 139.674 216.109 138.227 209.603 138.227C200.411 138.884 191.744 142.598 185.096 148.43C183.18 150.154 181.344 152.057 179.787 154.235C174.931 160.529 171.996 168.236 171.996 176.555C171.996 197.522 188.633 213.43 209.602 213.43C209.704 213.43 209.805 213.421 209.904 213.42C210.045 213.422 210.184 213.43 210.324 213.43C216.108 213.43 221.174 211.987 227.681 209.094L227.198 192.459C226.954 192.703 227.44 192.93 227.198 193.161V192.459Z"
      fill="#E7E8E3"
    />
    <path
      d="M356.863 148.582L340.487 188.844L321.685 141.115H306.502L336.148 214.15H344.102L375.196 141.115H360.013L356.863 148.582Z"
      fill="#E7E8E3"
    />
    <path
      d="M380.984 172.933V211.984H420.034H420.754V200.41H408.462H395.446V187.397V180.891H420.034V168.594H395.446V153.41H420.754V141.115H380.984V172.933Z"
      fill="#E7E8E3"
    />
    <path
      d="M54.1358 141.115H33.8848V211.984H54.1348C64.9798 211.984 72.9328 209.089 79.4408 204.027C87.3938 197.519 92.4578 187.398 92.4578 176.553C92.4588 155.577 77.2708 141.115 54.1358 141.115ZM70.7658 194.629C66.4268 198.243 60.6418 200.41 51.9638 200.41H47.6248V153.409H51.2398C59.9178 153.409 65.7028 154.855 70.0428 159.192C75.1038 163.528 77.9978 170.04 77.9978 176.55C78.7208 183.058 75.8288 190.286 70.7658 194.629Z"
      fill="#E7E8E3"
    />
    <path d="M112.71 141.109H98.9707V211.976H112.71V141.109Z" fill="#E7E8E3" />
    <path
      d="M147.415 168.594C138.739 165.702 136.567 163.531 136.567 159.917C136.567 155.578 140.906 151.963 146.691 151.963C151.03 151.963 154.645 153.41 158.261 157.749L165.494 148.349C159.707 143.285 152.479 140.396 144.524 140.396C132.228 140.396 122.104 149.074 122.104 160.64C122.104 170.766 126.447 175.104 139.461 180.166C145.246 182.332 147.416 183.058 148.865 184.504C151.752 185.948 153.201 188.843 153.201 191.732C153.201 197.518 148.865 201.858 142.353 201.858C135.839 201.858 130.059 198.243 127.166 192.457L118.488 201.135C124.999 210.535 132.953 214.873 143.077 214.873C157.538 214.873 167.657 205.473 167.657 191.732C167.659 179.442 163.324 174.38 147.415 168.594Z"
      fill="#E7E8E3"
    />
    <path
      d="M459.804 182.332C470.647 180.166 476.434 172.932 476.434 162.088C476.434 149.074 467.032 141.115 451.126 141.115H430.154V211.984H443.893V183.779H446.065L465.584 211.984H482.218L459.804 182.332ZM448.23 173.654H443.894V151.963H448.23C456.908 151.963 461.972 155.577 461.972 162.813C461.972 170.041 456.909 173.654 448.23 173.654Z"
      fill="#E7E8E3"
    />
  </svg>
);
