import { useState } from "react";
import plur from "plur";
import { useDispatch, useSelector } from "react-redux";
import Portal from "../../../../components/ui/portal/Portal";
import SADropDown from "../../../../components/ui/form-elements/drop-down/DropDown";
import SelectPayment from "../../payments/select-payment/SelectPayment";
import { CloseXSVG } from "../../../../assets/images/svgs/cloce-x.svg";

import { CircleCheckSvg } from "../../../../assets/images/svgs/circle-check.svg";

import styles from "./AutoPay.module.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ThemeContext } from "../../../../App";
import { ADD_TOAST } from "../../../../store/actions/types";
import ordinal from "ordinal";

// /assets/images/svgs/cloce-x.svg
const AutoPay = (props) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(0);
  const [payMethod, setPayMethod] = useState(props.payMethod || null);
  const [selectedDay, setSelectedDay] = useState(props.selectedDay || 1);
  const [loading, setLoading] = useState(false);
  const [closeTimeoutId, setCloseTimeoutID] = useState(undefined);
  // eslint-disable-next-line
  const [isAutoPay, setIsAutoPay] = useState(props.isAutoPay);
  // const [message, setMessage] = useState(null);
  const auth = useSelector((state) => state.auth);

  const params = useParams();

  const cardSelected = async (pm) => {
    setPayMethod(pm);
    setStep(1);
  };

  const setAutoPay = async () => {
    const token = await localStorage.getItem("x-auth");
    // console.log("I am seeting up auto pay", payMethod, selectedDay, params.id);

    //
    setLoading(true);

    let response;

    if (props.isAutoPay === true && isAutoPay === false) {
      response = await axios.put(
        `/api/reservation-auto-pay/${params.id}`,
        {
          autoPay: false,
          autoPayDay: null,
          autoPayMethod: null,
          autoPaymentFrequency: null,
        },
        { headers: { "x-auth": token } }
      );
    } else {
      response = await axios.put(
        `/api/reservation-auto-pay/${params.id}`,
        {
          autoPay: true,
          autoPayDay: selectedDay,
          autoPayMethod: payMethod,
          autoPaymentFrequency: "monthly",
        },
        { headers: { "x-auth": token } }
      );
    }

    if (response.data.message === "ok") {
      dispatch({
        type: ADD_TOAST,
        payload: {
          type: "success",
          message: `Your auto pay was set.`,
          id: Number(new Date()),
        },
      });
      props.onClose(response.data.data);

      let closeTimeout = setTimeout(() => {
        console.log("CLOSING AUTO PAY POPUP");
        props.close();
      }, 3000);

      setTimeout(() => {
        setLoading(false);
        setStep("saving");
        return; //setMessage("Autopay successfully added");
      }, 1000);

      console.log("timeout id is now ", closeTimeout);
      setCloseTimeoutID(closeTimeout);
    }
  };

  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          {!auth ? undefined : (
            <Portal
              close={() => {
                props.close();
                clearTimeout(closeTimeoutId);
              }}
              zIndex="99"
              width="494px"
              // height="460px"
              height="auto"
              customHeader={true}
              opacity="0.6"
            >
              {step !== 2 ? (
                <div className={`df acsa __popup_hdr`}>
                  <div className={`ffmb fs16 __popup_title`}>
                    Select Payment Method
                  </div>

                  <div
                    className={`df acc mla __popup_close cursor_pointer`}
                    onClick={() => {
                      props.close();
                      clearTimeout(closeTimeoutId);
                    }}
                  >
                    <CloseXSVG></CloseXSVG>
                  </div>
                </div>
              ) : undefined}
              <div className={styles.cntntWrpr}>
                {/* {props.isAutoPay && step !== "saving" ? (
                  <div
                    className={`p-l-40 p-r-40 df aic`}
                    onClick={() => setIsAutoPay(!isAutoPay)}
                  >
                    <p className={`ffmb fs14 ptc`}>Turn off autopay</p>{" "}
                    <div
                      className={`m-l-10 toggleWrpr ${isAutoPay ? "on" : ""}`}
                    ></div>
                  </div>
                ) : undefined} */}

                {props.isAutoPay === true &&
                isAutoPay === false &&
                step !== "saving" ? (
                  <p onClick={setAutoPay}>Save</p>
                ) : step === 0 ? (
                  <>
                    <SelectPayment
                      selectedId={payMethod}
                      userId={auth.user?._id}
                      selectAction={cardSelected}
                      close={null}
                      closeOnselect={false}
                      includeAutopays={true}
                    ></SelectPayment>
                    {props.isAutoPay ? (
                      <div
                        className={`df acc p-l-40 p-r-40 p-b-20`}
                        style={{ marginTop: "-22px" }}
                      >
                        <button className="df ffmsb  fs14 ttuc button secondary warning w100">
                          Turn off autopay
                        </button>
                      </div>
                    ) : undefined}
                  </>
                ) : (
                  <>
                    {step === 1 ? (
                      <div className={`df ffc ${styles.daysSelectionWrpr}`}>
                        <h4 className={`ffmb fs12 m-b-25 ttuc`}>
                          Which day of the month should we charge your card?
                        </h4>

                        <div style={{ height: "44px" }} className="m-b-25">
                          <SADropDown
                            initialValue={
                              !props.selectedDay && props.selectedDay !== 0
                                ? undefined
                                : `${
                                    props.selectedDay < 0
                                      ? `${Math.abs(props.selectedDay)} ${plur(
                                          "day",
                                          props.selectedDay
                                        )}  before invoice is due`
                                      : props.selectedDay === 0
                                      ? "On invoice due date"
                                      : `${props.selectedDay} ${plur(
                                          "day",
                                          props.selectedDay
                                        )} after invoice is due`
                                  }`
                            }
                            list={[
                              -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9,
                              10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
                              22, 23, 24, 25, 26, 27, 28, 29, 30,
                            ].map((day) => ({
                              display: `${
                                day < 0
                                  ? `${Math.abs(day)} ${plur(
                                      "day",
                                      day
                                    )}  before invoice is due`
                                  : day === 0
                                  ? "On invoice due date"
                                  : `${day} ${plur(
                                      "day",
                                      day
                                    )} after invoice is due`
                              }`,
                              subText: ` (on the ${ordinal(
                                props.reservationInvoiceDay + day
                              )} of every month)`,
                              value: day,
                            }))}
                            click={(thisDay) => setSelectedDay(thisDay)}
                          />
                        </div>

                        <p className="tac ffmm fs16 m-t-15">
                          Pick any date to charge your card
                        </p>

                        <div className={`df ffc jcfe ftr mta m-b-10`}>
                          <button
                            disabled={!payMethod || loading}
                            className={`df ffmsb  fs14 ttuc button primary ${
                              loading ? "loading" : ""
                            }`}
                            onClick={() => {
                              // props.selectAction(payMethod);
                              setAutoPay();
                            }}
                          >
                            {loading ? "" : "Confirm"}
                          </button>
                        </div>
                      </div>
                    ) : step === "saving" ? (
                      <div className={`df ffc ${styles.successWrpr}`}>
                        <div className={`df ffc acc ${styles.messageWrpr}`}>
                          <div className={styles.circleWrpr}>
                            <CircleCheckSvg height="44" width="44" fill={val} />
                          </div>

                          <h5 className={`ptc ffqsb fs22 m-t-10`}>
                            Thank You, {auth.user.fullName?.split(" ")[0]}
                          </h5>

                          <p className={`ffmr fs14 m-t-6 m-b-15`}>
                            Your autopay inforamtion has been set.
                          </p>
                        </div>
                        <div className={`df mta ffc jcfe ftr m-b-10`}>
                          <button
                            disabled={!payMethod || loading}
                            className={`df ffmsb  fs14 ttuc button primary`}
                            onClick={() => {
                              // props.selectAction(payMethod);
                              props.close();
                              clearTimeout(closeTimeoutId);
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : undefined}
                  </>
                )}
              </div>
            </Portal>
          )}
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default AutoPay;
