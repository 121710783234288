import React from "react";
import { useSelector } from "react-redux";
import Toast from "./Toast";
import styles from "./Toast.module.scss";

const ToastContainer = (props) => {
  const { toast } = useSelector(({ toast }) => ({ toast }));
  // console.log(toast);
  return (
    <div className={styles.toastContainer}>
      {toast.map((t) => (
        <React.Fragment key={t.id}>
          <Toast data={t} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default ToastContainer;
