import React from "react";

// import scss
import "./overlay.scss";

export const Overlay = props => (
  <div
    className="overlay"
    style={{
      backgroundColor: props.bgColor,
      zIndex: props.zIndex
    }}
  ></div>
);
