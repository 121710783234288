import React, { Component } from "react";
// import scss
import "./Input.scss";

class SAInput extends Component {
  formatValue = (val) => {
    return val;
  };

  state = {
    active: false,
  };

  render() {
    return (
      <div className="__inpt_wrpr df ffc">
        {!this.props.hideLabel ? (
          <label
            className={`ffmb fs12  ${
              this.props.ifHasError ? "error" : "clean"
            }`}
          >
            {this.props.label}
          </label>
        ) : undefined}

        <div className={`inner_inpt_wrpr ${this.props.icon ? "has_icon" : ""}`}>
          {this.props.icon ? (
            <div className="df acc icon_wrpr">{this.props.icon}</div>
          ) : undefined}
          <input
            onKeyPress={this.props.onKeyPress}
            className={`ffmr fs14 ${
              this.props.ifHasError ? "error" : "clean"
            } `}
            value={this.formatValue(this.props.value)}
            type={this.props.type || `text`}
            placeholder={this.props.placeholder}
            onChange={(e) => this.props.onChange && this.props.onChange(e)}
            readOnly={this.props.readonly}
            onClick={() => this.props.onClick && this.props.onClick()}
            disabled={this.props.disabled}
          />
        </div>

        {this.props.updateButtonAction && (
          <div
            onClick={() => {
              this.props.updateDisabled(this.props.field);
              if (this.state.active) {
                this.setState({ active: false });
                return this.props.updateButtonAction("opopopop");
              } else {
                this.setState({ active: true });
              }
            }}
            className={`input_btn ffqsb fs14 ${
              this.state.active ? "active" : ""
            }`}
          ></div>
        )}
      </div>
    );
  }
}

export default SAInput;
