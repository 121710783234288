import Axios from "axios";
import { CLEAR_AUTH, CURRENT_USER } from "./types";

export const setAuth = (val, type, token) => async (dispatch, getState) => {
  // let res = await Axios.get(`/api/web-user`);
  // console.log("IM HERE");
  // if (!val) {
  // 	// console.log("Removing)))))");
  // 	localStorage.removeItem("x-auth");
  // 	return dispatch({
  // 		type: GOOGLE_LOGIN,
  // 		payload: {},
  // 	});
  // }
  // // console.log("==========", token);
  // const {_id, account} = getState().websiteProfile;
  // // console.log(_id, account);
  // let res;
  // if (val && type === "google") {
  // 	// console.log("LOL");
  // 	res = await Axios.get(
  // 		`/api/web-user/${type}/${token}/${val}/${_id}/${account}`
  // 	);
  // }
  // if (val && type === "token") {
  // 	// console.log("TOKEN");
  // 	res = await Axios.get(`/api/web-user/${type}/${token}`);
  // }
  // localStorage.setItem("x-auth", res.headers["x-auth"]);
  // setTimeout(() => {
  // 	// console.log("///////", res.headers["x-auth"]);
  // }, 3000);
  // dispatch({
  // 	type: GOOGLE_LOGIN,
  // 	payload: {val, user: res.data && res.data.data},
  // });
};

export const getUser = () => async (dispatch) => {
  let token = await localStorage.getItem("x-auth");

  let user = await Axios.get("/api/web-user", {
    headers: { "x-auth": token },
  });

  dispatch({ type: CURRENT_USER, payload: user.data });
};

export const logout = () => async (dispatch) => {
  let logOut = await Axios.get("/api/logout-auth");

  localStorage.removeItem("x-auth");

  dispatch({ type: CLEAR_AUTH, payload: logOut.data });
};
