import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRouteMatch, Link } from "react-router-dom";
import { ThemeContext } from "../App";
import { CircleCheckSvg } from "../assets/images/svgs/circle-check.svg";
import Header from "../components/header/Header";
import { getToken } from "../helper";
import RCPopup from "../reserve/returning-customer-popup/RCPopup";
// import scss
import "./thankYou.scss";

const ThankYouPage = (props) => {
  const match = useRouteMatch();
  // const [showAutoPay, setShowAutoPay] = useState(false);
  const [reservationStatus, setReservationStatus] = useState(undefined);
  const [reservation, setReservation] = useState();
  const [showRCPopup, setShowRCPopup] = useState(false);
  const [contract, setContract] = useState(undefined);

  const token = getToken();
  // const closePopup = () => {
  // 	// console.log("Closing");
  // 	setShowAutoPay(false);
  // };

  const getReservationDetails = async () => {
    // console.log("Getting reservation details!");
    let reservation = await Axios.get(`/api/reservation/${match.params.id}`, {
      headers: {
        "x-auth": token,
      },
    });

    setReservationStatus(
      reservation.data.data.initialPayment === true ? "paid" : "reserved"
    );

    setReservation(reservation.data.data);
  };

  const getContract = async () => {
    let contract = await Axios.get(`/api/contract/${match.params.id}`, {
      headers: {
        "x-auth": token,
      },
    });

    setContract(contract.data.data);
  };

  const { auth, websiteProfile } = useSelector(({ auth, websiteProfile }) => ({
    auth,
    websiteProfile,
  }));

  useEffect(
    () => {
      getReservationDetails();
      getContract();
    }, // eslint-disable-next-line
    []
  );

  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          {auth?.user ? (
            <>
              <Header
                headerOpacity="1"
                websiteProfile={websiteProfile}
                allProps={props}
              ></Header>
              <div className="df ffc thank_you_page">
                <div className="icon">
                  <CircleCheckSvg fill={val}></CircleCheckSvg>
                </div>
                <p className="ffqsb fs22">
                  Thank you, {auth?.user?.fullName?.split(" ")[0]}
                </p>
                <p className="ffmr fs14">Your order has been processed.</p>
                <p className="ffmr fs14 msg_txt">
                  Please check your inbox for a confirmation email with your
                  reservation details
                </p>

                <Link
                  to={`/account/rentals/${match.params.id}`}
                  className={`ffqsb fs14 ptc tdn`}
                  style={{ marginTop: "34px" }}
                >
                  View My Reservation
                </Link>

                {reservationStatus === "reserved" ? (
                  <p
                    style={{ minWidth: "280px" }}
                    className="df ffmb fs14 ttuc fwbold srch_btn button primary action_btn"
                    onClick={() => setShowRCPopup(!showRCPopup)}
                  >
                    make a payment
                  </p>
                ) : reservationStatus === "paid" && !contract?.completed ? (
                  <>
                    <p className={`ffmr fs14 tac m-t-34`}>
                      To complete your reservation process and for seamless
                      move-in you can now <br /> complete your contract form{" "}
                    </p>
                    <div className="df acsa">
                      <Link
                        to={`/account/rentals/${match.params.id}/documents/${contract?.eSigSignatures[0].signature_id}/${contract?._id}`}
                        // path={`/account/rentals/:id/documents/:sigid/:docid`}
                        style={{
                          minWidth: "280px",
                        }}
                        className="df ffmb fs14 ttuc fwbold srch_btn button primary action_btn"
                        // onClick={() => contract && setShowAutoPay(!showAutoPay)}
                      >
                        Sign my contract now
                      </Link>
                    </div>
                  </>
                ) : null}

                <p className="ffmr fs14 m-t-20 contact_opnr">
                  <Link to="/contact" className="ptc tdul">
                    contact us
                  </Link>{" "}
                  with your questions
                </p>
              </div>
            </>
          ) : (
            "loading!!!"
          )}

          {showRCPopup ? (
            <RCPopup
              unitSize={reservation?.unit?.unitSize}
              unitId={reservation?.unit?._id}
              locationId={reservation?.unit?.unitLocation}
              selectedDate={reservation?.expectedMoveInDate}
              reservationId={reservation?._id}
            ></RCPopup>
          ) : undefined}
        </>
      )}
    </ThemeContext.Consumer>
  );
};

// {showAutoPay && (
// 	<Popup
// 		closeAction={closePopup}
// 		zIndex="9"
// 		width="524px"
// 	>
// 		<AutoPayPopup
// 			reservationId={match.params.id}
// 			payMethods={payMethods}
// 			auth={auth}
// 			cancel={closePopup}
// 		></AutoPayPopup>
// 	</Popup>
// )}

export default ThankYouPage;
