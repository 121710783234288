import React from "react";
import Cleave from "cleave.js/react";

// import scss
import "./Input.scss";

// import images
import UserIcon from "../../../../assets/images/input-user.png";
import EmailIcon from "../../../../assets/images/input-email.png";
import PhoneIcon from "../../../../assets/images/input-phone.png";
import HomeIcon from "../../../../assets/images/input-home.png";
import CalIcon from "../../../../assets/images/input-cal.png";
import LockIcon from "../../../../assets/images/input-lock.png";
import CardIcon from "../../../../assets/images/input-card.png";

const images = {
  user: UserIcon,
  email: EmailIcon,
  phone: PhoneIcon,
  lock: LockIcon,
  home: HomeIcon,
  cal: CalIcon,
  card: CardIcon,
};

const FMKInput = ({ field, form, handleChange, inputStyle, ...props }) => (
  <div
    className={`__inpt_wrpr df ffc ${form.errors[field.name] ? "error" : ""}`}
  >
    <label className="ffmb fs12" htmlFor={props.id}>
      {props.label}{" "}
      <span
        className="sub ffmr"
        style={{ fontSize: "0.95em", textTransform: "lowercase" }}
      >
        {form.errors[field.name] && `(${form.errors[field.name]})`}
      </span>
    </label>
    <div className={`inner_inpt_wrpr ${props.icon ? "has_icon" : ""}`}>
      <div className="icon_wrpr">
        {images[props.icon] && <img src={images[props.icon]} alt="icon"></img>}
      </div>
      {inputStyle === "phone" ? (
        <Cleave
          disabled={props.disabled}
          className="ffmr fs14"
          type={props.type}
          id={props.id}
          {...field}
          placeholder={props.placeholder}
          options={{
            blocks: [0, 3, 3, 4],
            delimiters: ["(", ") ", "-"],
            numericOnly: true,
          }}
          onChange={(event) => {
            const tempEvent = event;
            tempEvent.target.value = event.target.rawValue;
            handleChange(tempEvent);
          }}
        />
      ) : (
        <input
          disabled={props.disabled}
          className="ffmr fs14"
          type={props.type}
          id={props.id}
          {...field}
          placeholder={props.placeholder}
        />
      )}
    </div>
  </div>
);

export default FMKInput;
