import { useState } from "react";
import { useSelector } from "react-redux";
import { WelcomeSplashSVG } from "../assets/images/svgs/welcome-splash.svg";
import { Popup } from "../components/ui/popup/popup";
import SignInPopup from "../components/ui/signin-popup/SignInPopup";
// import GoogleAuth from "../auth/auth";
// import SignUpPopup from "./setup/SetUp";

import styles from "./Welcome.module.scss";

export var auth;
const WelcomePage = (props) => {
  const { websiteProfile } = useSelector(({ websiteProfile }) => ({
    websiteProfile,
  }));

  // const [isSignedIn, setIsSignedIn] = useState(null);
  const [showSignUp, setShowSignUp] = useState(false);

  // useEffect(() => {
  //   window.gapi.load("client:auth2", () => {
  //     window.gapi.client
  //       .init({
  //         clientId:
  //           "386687066799-jf3f4dj580lbm15jl3hgu8bt703cavc2.apps.googleusercontent.com",
  //         scope: "email",
  //       })
  //       .then(() => {
  //         console.log("DONE!@!");
  //         auth = window.gapi.auth2.getAuthInstance();
  //         setIsSignedIn(auth?.isSignedIn?.get());
  //         auth.isSignedIn.listen(() => {
  //           setIsSignedIn(auth?.isSignedIn?.get());
  //         });
  //       });
  //   });
  // });

  const toggleSignUp = () => setShowSignUp(!showSignUp);
  return (
    <div className={`df acc ffc ${styles.welcomePage}`}>
      <h3 className={`tac ffqsb fs22`}>Welcome!</h3>
      <WelcomeSplashSVG />
      <p className={`ffmr fs14 tac ${styles.msg}`}>
        we are excited to announce that we are in the process of moving over our
        backoffice to a new software. this will give you as a customer better
        opertunities to manage your facility payments and ...
      </p>

      <button
        onClick={toggleSignUp}
        className={`df ffmsb fs14 ttuc button primary pbgc m-t-35`}
      >
        Sign Up
      </button>

      {showSignUp ? (
        <Popup
          customClass="signin_popup"
          zIndex="6"
          overlay={true}
          closeAction={toggleSignUp}
        >
          <SignInPopup
            continueAction={console.log}
            closeAction={toggleSignUp}
            accountId={websiteProfile.account}
            webProfileId={websiteProfile._id}
            webProfile={websiteProfile}
            showForgot={false}
            showSignup={false}
            showSocial={false}
          ></SignInPopup>
        </Popup>
      ) : (
        ""
      )}
    </div>
  );
};

export default WelcomePage;
