import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { getUnitsFromSearch } from "../store/actions";
import qs from "qs";

// import scss
import "./Locations.scss";
import { UnitLine } from "./line/UnitLine";
import { DemoUnitLine } from "./line/DemoLine";
// import SpecialBanner from "../components/special-banner/SpecialBanner";
import LocationsFilter from "./locations-filer/LocationsFilter";
import NotFoundLine from "./line/NotFoundLine";
import { useHistory } from "react-router";
import axios from "axios";
import { GET_BEST_UNIT_MATCH } from "../store/actions/types";

const LocationsPage = (props) => {
  const dispatch = useDispatch();

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState();

  const { units, websiteProfile } = useSelector(
    ({ units, websiteProfile }) => ({ units, websiteProfile })
  );

  //   constructor(props) {
  //     super(props);

  //     this.mapRef = createRef();
  //   }
  //   state = { loading: true };

  const getUnitsFromSearch = async () => {
    // console.log(":L:LKJIOJIJL", filter);
    //   **********************

    let unitList = await axios.get(
      `/api/units-list/?desiredSize=${filter.size}&moveInDate=${filter.date}&unitLocation=${filter.location}`
    );

    dispatch({
      type: GET_BEST_UNIT_MATCH,
      payload: unitList.data.data,
    });

    setLoading(false);

    // ***********************
  };

  const setFilterFromQuery = async () => {
    var search = history.location.search.substring(1);

    console.log("search", qs.parse(history.location.search.substring(1)));

    if (!search) {
      setFilter({
        date: Number(new Date()),
        location: websiteProfile.locations[0]._id,
        size: "Medium",
      });

      return setLoading(false);
    }

    setFilter(qs.parse(history.location.search.substring(1)));

    return;
  };

  const updateSearch = (key, val) => {
    setFilter({ ...filter, [key]: val });
    setLoading(true);
    // this.setState(
    //   (prevState) => {
    //     return {
    //       filter: {
    //         ...prevState.filter,
    //         [key]: val,
    //       },
    //     };
    //   },
    //   async () => {
    //     this.setState({ loading: true });
    //     await history.push(`/locations/?${qs.stringify(filter)}`);
    //     await getUnitsFromSearch(filter);
    //     this.setState({ loading: false });
    //   }
    // );
  };

  //   async componentDidMount() {

  //   }

  const loadedAction = async () => {
    await setFilterFromQuery();
    setLoading(false);
  };

  useEffect(() => {
    loadedAction();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filter) return;
    getUnitsFromSearch(filter);
    // eslint-disable-next-line
  }, [filter]);

  return (
    <div className="df ffc locations_page">
      {/* <MapTest /> */}
      {/* <SpecialBanner></SpecialBanner> */}
      <LocationsFilter
        filters={filter}
        updateSearch={updateSearch}
      ></LocationsFilter>
      {/* <MapWrapper /> */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* <AzureMapsProvider>
        <div style={{ height: "700px" }}>
          <AzureMap options={option} events={{ click: getCoordinates }}>
            <AzureMapDataSourceProvider id={"LayerExample1 DataSource "}>
              <AzureMapLayerProvider
                id={"LayerExample1 Layer"}
                options={{
                  url: "https://us.atlas.microsoft.com/tilesets/e0508d53-c2f9-673e-93bd-96a510a63647?api-version=2.0&&subscription-key=Ov7bziQQlxPj-NbYFq9ee9yk9NpmbLep8vxXojI2Qgo",
                  // * An array of positions for the corners of the image listed in clockwise order: [top left, top right, bottom right, bottom left].
                  // coordinates: [
                  //   // [-73.67575441272338, 43.30764681559234],
                  //   // [-15, 65],
                  //   // [-15, 55],
                  //   // [-30, 55],
                  //   [-73.67575441272338, 43.30764681559234],
                  //   [-73.67484246163009, 43.30764291200608],
                  //   [-73.67487196593017, 43.30682120150779],
                  //   [-73.67581342132354, 43.30686414152387],
                  // ],
                  // opacity: 0.8,
                }}
                type={"TileLayer"}
              />
            </AzureMapDataSourceProvider>
            <AzureMapDataSourceProvider id={"LayerExample1 DataSource2 "}>
              <AzureMapLayerProvider
                id={"LayerExample1 Layer2"}
                options={{
                  opacity: 0.8,
                  iconOptions: {
                    image: "pin-round-red",
                  },
                }}
                type={"SymbolLayer"}
              />
              <AzureMapFeature
                id={"LayerExample1 MapFeature"}
                type="Point"
                coordinate={point}
                properties={{
                  title: "My Title",
                }}
              />
            </AzureMapDataSourceProvider>
            <AzureMapHtmlMarker
              markerContent={<div className="pulseIcon"></div>}
              options={azureHtmlMapMarkerOptions}
              events={eventToMarker}
            />
          </AzureMap>
        </div>
      </AzureMapsProvider> */}
      {/* <AzureMapsProvider>
        <div style={{ height: "500px" }}>
          <AzureMap options={option}>
            <AzureMapDataSourceProvider id={"LayerExample2 DataSource"}>
              <AzureMapLayerProvider
                id={"LayerExample2 HeatMap"}
                options={{}}
                type={"HeatLayer"}
              />
              <AzureMapFeature
                id={"LayerExample2 MapFeature2"}
                key={"dddd"}
                type="Point"
                coordinate={point1}
              />
            </AzureMapDataSourceProvider>
          </AzureMap>
        </div>
      </AzureMapsProvider> */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      {/* ****************************** */}
      <div className="search_res_wrpr">
        <div className="content_wrpr">
          <div className="list_wrpr">
            <div className="sec_wrpr">
              <div className="sec_hdr">
                <h3 className="ffqsb fs25">
                  Your best match
                  {units?.bestMatches?.length > 1 ? "s" : undefined}
                </h3>
              </div>

              <div className="units_list_wrpr">
                {loading ? (
                  <>
                    <DemoUnitLine></DemoUnitLine>
                  </>
                ) : (
                  <>
                    {units.bestMatches?.length ? (
                      units.bestMatches.map((line, i) => (
                        <React.Fragment key={i}>
                          <UnitLine data={line} filters={filter}></UnitLine>
                        </React.Fragment>
                      ))
                    ) : (
                      <NotFoundLine />
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="sec_wrpr">
              <div className="sec_hdr">
                <h3 className="ffqsb fs25">More Available Units</h3>
              </div>

              <div className="units_list_wrpr">
                {(units.locationUnits && !units.locationUnits.length) ||
                  (loading && <DemoUnitLine></DemoUnitLine>)}

                {units.locationUnits && units.locationUnits.length
                  ? units.locationUnits.map((line, i) => (
                      <React.Fragment key={i}>
                        <UnitLine data={line} filters={filter}></UnitLine>
                      </React.Fragment>
                    ))
                  : undefined}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// const mapStateToProps = ({ units }) => ({ units });

export default LocationsPage;

// export default connect(mapStateToProps, { getUnitsFromSearch })(LocationsPage);
