const RandomSVG = (props) => (
  <svg
    width="26"
    height="31"
    viewBox="0 0 26 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.4399 5.49597C12.7842 5.50691 12.1582 5.77101 11.6927 6.23294C11.2273 6.69462 10.9584 7.31859 10.9424 7.97407L11.9416 8.01849V8.01872C11.9358 7.61017 12.0968 7.21682 12.3872 6.92969C12.6779 6.64237 13.073 6.4861 13.4814 6.49659C13.8897 6.50731 14.2764 6.68389 14.5517 6.98573C14.8269 7.28779 14.9671 7.68875 14.9401 8.09641C14.9524 8.50741 14.8015 8.90637 14.5204 9.20641C14.2391 9.50624 13.8507 9.68238 13.4399 9.69666C13.3073 9.69666 13.18 9.74935 13.0863 9.84312C12.9925 9.93688 12.9398 10.0641 12.9398 10.1967V12.9972H13.94V10.6446C14.5142 10.5118 15.0256 10.1867 15.3895 9.72324C15.7534 9.25978 15.9476 8.68582 15.9403 8.09642C15.9526 7.42043 15.6963 6.76696 15.2274 6.27963C14.7588 5.79205 14.1159 5.51031 13.4399 5.49602L13.4399 5.49597Z"
      fill={props.fill || "black"}
    />
    <path
      d="M13.4403 13.4972H13.4407C13.7169 13.4972 13.9406 13.7733 13.9406 13.997V13.9975C13.9406 14.2736 13.7167 14.4973 13.4407 14.4973H13.4403C13.1641 14.4973 12.9404 14.2212 12.9404 13.9975V13.997C12.9404 13.7209 13.1643 13.4972 13.4403 13.4972Z"
      fill={props.fill || "black"}
    />
    <path
      d="M18.4629 3.10523L19.4182 3.40126H19.418C19.4965 3.14787 19.6727 2.93601 19.9073 2.81231C20.142 2.68863 20.4163 2.6634 20.6697 2.74199C20.9231 2.82057 21.135 2.99649 21.2584 3.23135C21.3821 3.46598 21.4076 3.74013 21.329 3.99352C21.2504 4.24691 21.0745 4.45877 20.8396 4.58247C20.605 4.70615 20.3308 4.73138 20.0775 4.6528C19.9506 4.61328 19.8136 4.62578 19.6961 4.68785C19.5787 4.74968 19.4907 4.85573 19.4517 4.98253L19.0079 6.41532L19.9632 6.71135L20.2777 5.69577H20.2775C20.7785 5.71988 21.27 5.5549 21.6551 5.23364C22.0402 4.91239 22.2905 4.45832 22.3566 3.96133C22.4226 3.46416 22.2996 2.96049 22.0121 2.54975C21.7243 2.13897 21.2928 1.85141 20.8032 1.74379C20.3134 1.63596 19.8011 1.71611 19.3677 1.96838C18.9342 2.22043 18.6114 2.62631 18.4629 3.10518L18.4629 3.10523Z"
      fill={props.fill || "black"}
    />
    <path
      d="M19.0426 7.99637C19.1694 8.03567 19.3062 8.02294 19.4237 7.9611C19.5409 7.89926 19.6288 7.79344 19.6681 7.66686V7.66664C19.7074 7.54006 19.6947 7.40298 19.6329 7.28555C19.571 7.16834 19.4652 7.08039 19.3386 7.0411H19.3384C19.2118 7.0018 19.0747 7.01453 18.9573 7.07637C18.8401 7.13821 18.7521 7.24403 18.7128 7.37061V7.37083C18.6736 7.49764 18.6863 7.63449 18.7481 7.75192C18.81 7.86913 18.9158 7.95708 19.0424 7.99637H19.0426Z"
      fill={props.fill || "black"}
    />
    <path
      d="M8.3134 1.29692C8.06247 0.628929 7.47465 0.145427 6.77099 0.0275227C6.06734 -0.0903811 5.35426 0.175763 4.89951 0.725401C4.44475 1.27504 4.31725 2.02537 4.56463 2.69468L5.50226 2.34551V2.34529C5.40961 2.09681 5.41922 1.82155 5.5295 1.5802C5.63956 1.33864 5.84115 1.1509 6.08985 1.05825C6.33855 0.965375 6.61382 0.975199 6.85517 1.08548C7.09652 1.19577 7.28425 1.39736 7.3769 1.64606C7.46955 1.89454 7.45973 2.17003 7.34944 2.41138C7.23916 2.65271 7.03734 2.84024 6.78864 2.93289C6.53012 3.02955 6.39885 3.31732 6.49529 3.57607L7.01881 4.98189L7.95644 4.63272L7.58474 3.63657C7.94841 3.38252 8.21718 3.01482 8.34914 2.59133C8.48108 2.16782 8.46835 1.71239 8.31342 1.29696L8.3134 1.29692Z"
      fill={props.fill || "black"}
    />
    <path
      d="M7.66212 5.276C7.4036 5.37244 7.27211 5.66044 7.36855 5.91895L7.36877 5.9194C7.41498 6.04397 7.50875 6.1451 7.62953 6.20025C7.75008 6.25562 7.88805 6.26053 8.0124 6.21409C8.13675 6.16788 8.23765 6.07367 8.29257 5.95289C8.34771 5.83189 8.35218 5.69415 8.30552 5.5698L8.3053 5.56935V5.56958C8.25908 5.44522 8.1651 5.34454 8.04454 5.2894C7.92399 5.23448 7.78647 5.22957 7.66212 5.27601L7.66212 5.276Z"
      fill={props.fill || "black"}
    />
    <path
      d="M22.3418 28.0252V21.922L24.0612 21.4998V21.5C24.2374 21.4569 24.3764 21.3216 24.4244 21.1466C24.4725 20.9716 24.4218 20.7845 24.2921 20.6575L21.7716 18.183H21.7718C21.7028 18.1165 21.6162 18.0714 21.522 18.0533L21.5226 18.0499L16.4819 17.0081L16.2797 17.9877L18.9506 18.5398L13.4404 19.6788L7.92992 18.5398L10.6008 17.9877L10.3986 17.0081L5.35809 18.0499L5.35876 18.0533C5.26477 18.0714 5.17793 18.1165 5.10894 18.183L2.58844 20.6575H2.58867C2.45896 20.7845 2.40828 20.9716 2.45628 21.1466C2.50428 21.3216 2.64336 21.4569 2.81951 21.5L4.53897 21.9222L4.53919 28.0254C4.53919 28.2473 4.6852 28.4424 4.89795 28.5052L13.2992 30.9797C13.3912 31.0076 13.4892 31.0067 13.5808 30.9774L13.5814 30.9799L21.9827 28.5052C22.1955 28.4426 22.3415 28.2473 22.3417 28.0254L22.3418 28.0252ZM3.92922 20.7423L5.61942 19.0834L12.5799 20.522L11.1328 22.5103L3.92922 20.7423ZM12.9403 29.8312L5.53918 27.6511V22.1675L11.2206 23.5621L11.2208 23.5619C11.418 23.6103 11.6249 23.5346 11.7444 23.3705L12.9403 21.7268L12.9403 29.8312ZM21.2616 19.0838L22.9518 20.7428L15.7478 22.5109L14.3008 20.5224L21.2616 19.0838ZM21.3417 27.6514L13.9406 29.8315V21.7274L15.1366 23.3712C15.256 23.5352 15.4629 23.6109 15.6601 23.5625L21.3417 22.1681L21.3417 27.6514Z"
      fill={props.fill || "black"}
    />
    <path
      d="M25.9125 10.2614C25.9442 10.0796 25.8734 9.89546 25.7283 9.7816C25.2066 9.42642 24.8317 8.89375 24.6739 8.28269C24.6161 8.10766 24.4665 7.97862 24.285 7.94715C24.1035 7.91544 23.9193 7.98644 23.8057 8.13155C23.4505 8.65306 22.9178 9.02769 22.3068 9.18571C22.132 9.24353 22.0029 9.39289 21.9712 9.57439C21.9398 9.75589 22.0105 9.94029 22.1554 10.0539C22.6772 10.4091 23.052 10.942 23.2098 11.553C23.2676 11.728 23.417 11.8569 23.5985 11.8886C23.6268 11.8935 23.6556 11.8959 23.6844 11.8959C23.8383 11.8959 23.9834 11.8252 24.078 11.7042C24.433 11.1824 24.9659 10.8076 25.5769 10.65C25.7517 10.5919 25.8806 10.4426 25.9125 10.2613V10.2614ZM23.8452 10.4741V10.4739C23.7213 10.237 23.567 10.0176 23.3862 9.82111C23.6228 9.69721 23.8421 9.54272 24.0385 9.36166C24.1622 9.59853 24.3169 9.81821 24.4982 10.0147C24.2611 10.1384 24.0417 10.2928 23.8452 10.4739L23.8452 10.4741Z"
      fill={props.fill || "black"}
    />
    <path
      d="M1.02225 13.5367C1.05975 13.7171 1.19326 13.8624 1.37007 13.9149C1.41628 13.9285 1.46405 13.9354 1.51206 13.9354C1.64556 13.9354 1.7737 13.8821 1.86769 13.7872C2.28048 13.3101 2.85264 12.9996 3.47777 12.9134C3.65838 12.8761 3.80393 12.7424 3.85618 12.5658C3.90864 12.389 3.85952 12.1977 3.72825 12.068C3.25139 11.655 2.94088 11.0828 2.85469 10.4577C2.81741 10.2771 2.68368 10.1315 2.50709 10.0793C2.33028 10.0268 2.13896 10.0759 2.00925 10.2072C1.59624 10.6843 1.02384 10.995 0.398719 11.0812C0.218108 11.1185 0.0730006 11.2522 0.0205354 11.429C-0.0317046 11.6056 0.0174097 11.7967 0.148681 11.9264C0.625765 12.3392 0.936287 12.9116 1.02224 13.5367L1.02225 13.5367ZM2.09853 11.4561C2.1943 11.7057 2.32245 11.9414 2.47962 12.1577C2.23002 12.253 1.99427 12.381 1.77819 12.5381C1.68264 12.2885 1.5545 12.0528 1.39733 11.8367C1.64692 11.7414 1.88267 11.6132 2.09858 11.4561H2.09853Z"
      fill={props.fill || "black"}
    />
    <path
      d="M14 17.2612L14.8809 17.7345C15.9119 15.7973 17.4012 14.142 19.2191 12.913C20.1183 12.3134 21.0888 11.828 22.1082 11.4684L21.7751 10.5254C20.6774 10.9129 19.6319 11.4356 18.6632 12.0812C16.7096 13.4017 15.1087 15.1802 14 17.2613L14 17.2612Z"
      fill={props.fill || "black"}
    />
    <path
      d="M4.01683 11.4906C4.62607 11.391 5.24737 11.3908 5.8566 11.4897C9.74491 12.1378 11.6318 16.7519 11.9701 17.6707L12.9087 17.3244C12.5361 16.3149 10.4543 11.2418 6.02017 10.5034L6.02039 10.5036C5.30468 10.3864 4.57486 10.3859 3.85938 10.5027L4.01683 11.4906Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default RandomSVG;
