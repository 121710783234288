import React, { Component } from "react";

// import scss
import "./DropDown.scss";
import { backdrop as Backdrop } from "../../backdrop/backdrop";
import DDPortal from "./DDPortal";
import { createRef } from "react";

import { ThemeContext } from "../../../../App";

class SADropDown extends Component {
  constructor(props) {
    super(props);

    this.inputPos = createRef(null);
  }
  componentDidMount() {
    this.props.initialValue &&
      this.setState({ displayValue: this.props.initialValue });
  }
  state = { open: false, displayValue: undefined };

  toggleDD = () => this.setState((prevState) => ({ open: !prevState.open }));
  selectValue = (val) => this.setState({ displayValue: val });

  render() {
    return (
      <ThemeContext.Consumer>
        {(val) => (
          <>
            <style>
              {`
            .__dd_list_wrpr .line:hover {
              background-color: ${val}
            }
            `}
            </style>
            <div className="__inpt_wrpr drop_down">
              {!this.props.hideLabel ? (
                <div className="ffmb fs12 label">{this.props.label}</div>
              ) : undefined}

              <div
                className={`inner_inpt_wrpr ${
                  this.props.icon ? "has_icon" : ""
                }`}
              >
                {this.props.icon ? (
                  <div className="df acc icon_wrpr">{this.props.icon}</div>
                ) : undefined}
                <div
                  ref={this.inputPos}
                  className={`df ffmr fs14 input ${
                    this.state.open ? "open" : ""
                  } ${this.state.displayValue ? "dirty" : ""} ${
                    this.props.disabled ? "disabled" : ""
                  }`}
                  onClick={!this.props.disabled ? this.toggleDD : console.log()}
                >
                  {/* {console.log(this.props.disabled)} */}
                  <div
                    className={`value ${
                      !this.state.displayValue && !this.props.initialValue
                        ? "empty"
                        : ""
                    }`}
                  >
                    {this.state.displayValue ||
                      this.props.initialValue ||
                      this.props.placeholder}
                  </div>
                  {!this.props.disabled && !this.props.updateButtonAction && (
                    <div className={`opener`} onClick={this.toggleDD}></div>
                  )}

                  {this.props.updateButtonAction && (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        this.props.updateDisabled(this.props.field);
                        if (this.state.active) {
                          this.setState({
                            active: false,
                          });
                          this.props.updateButtonAction("opopopop");
                        } else {
                          this.setState({
                            active: true,
                          });
                        }
                      }}
                      className={`input_btn ffqsb fs14 ${
                        this.state.active ? "active" : ""
                      }`}
                    ></div>
                  )}
                </div>
              </div>
              {this.state.open && (
                <DDPortal>
                  <div
                    className="__dd_list_wrpr"
                    style={{
                      position: "fixed",
                      width: `${
                        this.inputPos.current.getBoundingClientRect().width
                      }px`,
                      top: `${
                        this.inputPos.current.getBoundingClientRect().top +
                        this.inputPos.current.clientHeight +
                        2
                      }px`,
                      left: `${
                        this.inputPos.current.getBoundingClientRect().left +
                        +this.inputPos.current.clientWidth / 2 +
                        1
                      }px`,
                      zIndex: 99999999,
                    }}
                  >
                    {this.props.list &&
                      this.props.list.map((li, i) => (
                        <div
                          key={i}
                          className="df ffc acma ffmr fs14 line"
                          onClick={() => {
                            this.selectValue(li.display);
                            this.props.click(li.value, li.unitLocationId);
                            this.toggleDD();
                          }}
                        >
                          <p>{li.display}</p>
                          {li.subText ? (
                            <p className="sub m-t-2">{li.subText}</p>
                          ) : undefined}
                        </div>
                      ))}
                  </div>
                </DDPortal>
              )}
            </div>
            {this.state.open && <Backdrop click={this.toggleDD}></Backdrop>}
          </>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default SADropDown;
