import { ADD_TOAST, REMOVE_TOAST } from "../actions/types";

const reducer = (state = [], action) => {
  switch (action.type) {
    case ADD_TOAST:
      return [...state, action.payload];
    case REMOVE_TOAST:
      let copy = state.filter((item) => item.id !== action.payload);
      //  state.splice
      return [...copy];
    default:
      return state;
  }
};

export default reducer;
