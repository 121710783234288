import React from "react";
import { Link } from "react-router-dom";

// import scss
import "./UnitLine.scss";
import { CircleArrowSvg } from "../../assets/images/svgs/circle-arrow.svg";

import { ThemeContext } from "../../App";
import { formatFromCurrencyToMoney } from "../../helper";

export const UnitLine = (props) => {
  const { data, filters } = props;
  return (
    <ThemeContext.Consumer>
      {(val) => (
        <div className="df unit_line">
          {data && data.unitSize && (
            <>
              <div className="fs16 size_price_sec_wrpr">
                <p className="ffmb">
                  {data.unitSize.width}' x {data.unitSize.length}'{" "}
                  <span className="ttcaps">{data.unitSize.label}</span>
                </p>
                <p className="ffmr">
                  {formatFromCurrencyToMoney(data.unitSize.price)}/mo
                </p>
              </div>
              <div className="df acsa left_sec_wrpr">
                <div className="df ffc fs14 ffmr features_wrpr">
                  {data.unitFeatures.map((ftr, i) => (
                    <div key={i} className="df feature ">
                      <div className="icon"></div>
                      <span className="flcap">{ftr.label}</span>
                    </div>
                  ))}
                </div>

                {filters && (
                  <Link
                    to={`/summary/${filters.size}/${data.unitId}/${filters.date}/${filters.location}`}
                    className="go_btn"
                  >
                    <CircleArrowSvg fill={val}></CircleArrowSvg>
                  </Link>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </ThemeContext.Consumer>
  );
};
