import { useEffect } from "react";
import { useState } from "react";
import Cleave from "cleave.js/react";
import SAInput from "../../../../components/ui/form-elements/stand-alone-input/Input";
import styles from "./AlternateContact.module.scss";
const AlternateContact = ({
  value,
  label,
  onUpdate,
  altNameState,
  alt_delivery_line_1State,
  alt_last_lineState,
  altPhoneState,
}) => {
  const [alternateContact, setAlternateContact] = useState(value);
  const [altName, setAltName] = useState(altNameState || "");
  const [alt_delivery_line_1, setAlt_delivery_line_1] = useState(
    alt_delivery_line_1State || ""
  );
  const [alt_last_line, setAlt_last_line] = useState(alt_last_lineState || "");
  const [altPhone, setAltPhone] = useState(altPhoneState || "");

  useEffect(() => {
    onUpdate({
      alternateContact,
      altName,
      alt_delivery_line_1,
      alt_last_line,
      altPhone,
    });
    // eslint-disable-next-line
  }, [alternateContact, altName, alt_delivery_line_1, alt_last_line, altPhone]);
  return (
    <div className={`${styles.sec}`}>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>{label}</h5>

      <div className={`df aic`}>
        <div className={`df acsa ${styles.radioWrpr}`}>
          <input
            id="yes"
            type="radio"
            name="alternateContact"
            value={true}
            checked={alternateContact}
            onChange={() => setAlternateContact(true)}
            className={`dn`}
          />
          <label
            htmlFor="yes"
            className={`cursor_pointer ${styles.radioIcon} ${
              alternateContact ? "pbgc" : undefined
            }`}
          ></label>
          <label className={`ffmr fs14 m-l-5 cursor_pointer`} htmlFor="yes">
            Yes
          </label>
        </div>
        <div className={`df acsa m-l-20 ${styles.radioWrpr}`}>
          <input
            id="no"
            type="radio"
            name="alternateContact"
            value={true}
            checked={!alternateContact}
            onChange={() => setAlternateContact(false)}
            className={`dn`}
          />
          <label
            htmlFor="no"
            className={`cursor_pointer ${styles.radioIcon} ${
              !alternateContact ? "pbgc" : undefined
            }`}
          ></label>
          <label className={`ffmr fs14 m-l-5 cursor_pointer`} htmlFor="no">
            No
          </label>
        </div>
      </div>
      {alternateContact ? (
        <div>
          <div className={`m-t-20 m-b-15`}>
            <SAInput
              label="Contact name"
              value={altName}
              onChange={(v) => setAltName(v.target.value)}
            />
          </div>
          <div className={`m-b-15`}>
            <SAInput
              label="Contact street address"
              value={alt_delivery_line_1}
              onChange={(v) => setAlt_delivery_line_1(v.target.value)}
            />
          </div>
          <div className={`m-b-15`}>
            <SAInput
              label="Contact city, state and zip"
              value={alt_last_line}
              onChange={(v) => setAlt_last_line(v.target.value)}
            />
          </div>
          <div className={`__inpt_wrpr df ffc`}>
            <label className={`ffmb fs12`}>Contacts phone number</label>
            <div className={`inner_inpt_wrpr `}>
              <Cleave
                className={`ffmr fs14 ${styles.input}`}
                options={{
                  blocks: [0, 3, 3, 4],
                  delimiters: ["(", ") ", "-"],
                  numericOnly: true,
                }}
                value={altPhone}
                onChange={(event) => {
                  // const tempEvent = event;
                  // tempEvent.target.value = `+${event.target.rawValue}`;
                  setAltPhone(`+${event.target.rawValue}`);
                }}
              />
            </div>
          </div>
        </div>
      ) : undefined}
    </div>
  );
};

export default AlternateContact;
