import { NotFoundSVG } from "../../assets/images/svgs/not-found.svg";
import styles from "./NotFoundLine.module.scss";

import { ThemeContext } from "../../App";
import { useContext } from "react";
import { PhoneIconSVG } from "../../assets/images/svgs/phone-icon.svg";

const NotFoundLine = (props) => {
  const themeContext = useContext(ThemeContext);
  return (
    <div className={`df acsa ${styles.notFoundLine}`}>
      <NotFoundSVG />

      <div className={`ffmr fs16 m-l-30`}>
        <p>Sorry, we couldn’t find an exact match for your search!</p>
        <p>
          Please see other available units below. or contact us for more
          acccomodations
        </p>
      </div>

      <button className={styles.button} style={{ borderColor: themeContext }}>
        <PhoneIconSVG fill={themeContext} />{" "}
      </button>
    </div>
  );
  //       {/* </>
  //     )}
  //   </ThemeContext.Consumer> */}
};

export default NotFoundLine;
