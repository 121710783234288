import React from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";
import Header from "../../components/header/Header";
import AccountNav from "./account-nav/AccountNav";

import Payments from "./payments/Payments";
import Rentals from "./rentals/Rentals";

// import scss
import styles from "./AccountHome.module.scss";
import SettingsPage from "./settings/Settings";

const AccountHome = (props) => {
  const { auth } = useSelector(({ auth }) => ({
    auth,
  }));

  return (
    <div className={`${styles.account_page}`}>
      <Header
        websiteProfile={props.websiteProfile}
        headerOpacity={props.route}
        auth={props.auth}
        allProps={props}
        location={props.location}
      ></Header>
      <div className={styles.page_cntnt}>
        <div className={`content_wrpr ${styles.content_wrpr}`}>
          <div className={`df inner_cntnt ${styles.inner_cntnt}`}>
            {auth?.user?._id?.length > 0 ? (
              <>
                <AccountNav></AccountNav>
                <Route path="/account/rentals/:id?" component={Rentals}></Route>

                <Route path="/account/payments" component={Payments}></Route>
                <Route
                  path="/account/settings"
                  component={SettingsPage}
                ></Route>
              </>
            ) : (
              <p>Please login</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountHome;
