import { useEffect } from "react";
import { useState } from "react";
import styles from "./ServiceMember.module.scss";

const ServiceMember = ({ value, label, onUpdate }) => {
  const [serviceMember, setServiceMember] = useState(value);

  useEffect(() => {
    onUpdate({ serviceMember });
    // eslint-disable-next-line
  }, [serviceMember]);
  return (
    <div className={`${styles.sec}`}>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>{label}</h5>

      <div className={`df aic`}>
        <div className={`df acsa ${styles.radioWrpr}`}>
          <input
            id="yes"
            type="radio"
            name="serviceMember"
            value={true}
            checked={serviceMember}
            onChange={() => setServiceMember(true)}
            className={`dn`}
          />
          <label
            htmlFor="yes"
            className={`cursor_pointer ${styles.radioIcon} ${
              serviceMember ? "pbgc" : undefined
            }`}
          ></label>
          <label className={`ffmr fs14 m-l-5 cursor_pointer`} htmlFor="yes">
            Yes
          </label>
        </div>
        <div className={`df acsa m-l-20 ${styles.radioWrpr}`}>
          <input
            id="no"
            type="radio"
            name="serviceMember"
            value={true}
            checked={!serviceMember}
            onChange={() => setServiceMember(false)}
            className={`dn`}
          />
          <label
            htmlFor="no"
            className={`cursor_pointer ${styles.radioIcon} ${
              !serviceMember ? "pbgc" : undefined
            }`}
          ></label>
          <label className={`ffmr fs14 m-l-5 cursor_pointer`} htmlFor="no">
            No
          </label>
        </div>
      </div>
      {/* {serviceMember ? <div>He is a service member</div> : undefined} */}
    </div>
  );
};

export default ServiceMember;
