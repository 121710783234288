import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route } from "react-router";
import { Link } from "react-router-dom";
import NoRentalsSVG from "../../../assets/images/no-rentals.svg";
// import { NoRentalsSVG } from "../../../assets/images/svgs/no-rentals.svg";
import RentalDetails from "./rental-details/RentalDetails";
import RentalTabs from "./rental-tabs/RentalTabs";
import "./Rentals.scss";
// import AnvilSignatureFrame from "@anvilco/react-signature-frame";
// import AnvilSignatureModal from "@anvilco/react-signature-modal";
// import "@anvilco/react-signature-modal/dist/styles.css";

const Rentals = (props) => {
  const [reservations, setResrvations] = useState([]);
  const [selectedReservation, setSelectedReservation] = useState(null);

  const { auth } = useSelector(({ auth }) => ({
    auth,
  }));

  // const getLength = (length) => {
  // 	let len = Number((length / 2).toFixed(0));

  // 	let arr = new Array(len).fill("a");

  // 	let str = "";

  // 	arr.map((a) => (str = str.concat("134px ")));

  // 	return str; //"100px 100px";
  // };

  const getReservations = async () => {
    let reservations = await Axios.get(
      `/api/user-reservations/${auth.user._id}`,
      {
        headers: { "x-auth": localStorage.getItem("x-auth") },
      }
    );

    setResrvations(reservations.data.data);
    // setSelectedReservation(reservations?.data?.data[0]?._id);
  };

  useEffect(
    () => {
      getReservations();
    },
    // eslint-disable-next-line
    []
  );
  return (
    <div className="rentals_page page">
      <div className="hdr">
        <div className="df acsa ffmr fs14 breadcrumbs">
          <Link to="/account">Account</Link>
          <span className="f_slash">/</span>
          <p>Units</p>
        </div>
        <h5 className="ffqsb fs25">Unit History</h5>
      </div>
      {reservations.length > 0 ? (
        <div className={`df acsa mobile_hidden`}>
          <RentalTabs
            reservations={reservations}
            selectedReservation={selectedReservation}
            setSelectedReservation={setSelectedReservation}
          ></RentalTabs>

          <p className="ffmr fs14 gate_key">
            Gate Key: {auth?.user?.gateKey || "N/A"}
          </p>
        </div>
      ) : undefined}
      {reservations.length <= 0 ? (
        <div className={`df ffc acc no_rentals_wrpr m-t-50`}>
          {/* <AnvilSignatureFrame
            signURL={
              "https://app.useanvil.com/api/etch/verify/kacMEUm8guE6Zz7YCuvj?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWduZXJJZCI6NzM2OTMsImNsaWVudFVzZXJJZCI6IlNobWlsaUIiLCJjcmVhdGVkQXQiOjE2Mjg1MjYwNTA4NDEsImV4dHJhIjoiV1JociIsImlhdCI6MTYyODUyNjA1MCwiZXhwIjoxNjI4NjEyNDUwfQ.KrIe7c7GrgHVjYoBYiUFqdW3mOFOeoM8vWHIz_tsV6c"
            }
            scroll="smooth"
            onLoad={() => console.log("Hello man")}
            onFinish={() => console.log("Done")}
          /> */}

          {/* <AnvilSignatureModal
            signURL={
              "https://app.useanvil.com/api/etch/verify/xynytGdI9kQC13Imlcyf?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaWduZXJJZCI6NzM3MDYsImNsaWVudFVzZXJJZCI6IlNobWlsaUIiLCJjcmVhdGVkQXQiOjE2Mjg1MjY2Nzk0ODUsImV4dHJhIjoiNUVhaCIsImlhdCI6MTYyODUyNjY3OSwiZXhwIjoxNjI4NjEzMDc5fQ.HIcDoOk6gHyjk2LZy7XlpCXd3cZTCGfMaaDBRCF_Kdo"
            }
            isOpen={true}
            onClose={() => console.log("Hello map")}
            onLoad={() => console.log("ByBy map")}
            onFinish={(redirectURL) => console.log(redirectURL)}
          /> */}
          <img src={NoRentalsSVG} alt="No Rentals" />

          <p className={`ffmr fs14 m-t-30`}>No rentals found!</p>
          <p className={`ffmr fs14 m-t-10`}>
            <Link className={`ffmb tdn ptc`} to="/">
              Click here
            </Link>{" "}
            to reserve your unit
          </p>
        </div>
      ) : undefined}

      <Route path="/account/rentals/:id" component={RentalDetails}></Route>
      {/* <RentalDetails
				selectedReservation={selectedReservation}
			></RentalDetails> */}
      {/* <div className="rentals_list"> */}
      {/* {reservations
					? reservations.map((reservation) => (
							<div
								className="df acsa rental_line"
								key={reservation._id}
							>
								<div className="rental_dets">
									<p className="ffmb fs16 size">
										{reservation.unit?.unitSize.length}’ x{" "}
										{reservation.unit?.unitSize.height}’
										{reservation.unit?.unitSize.label}
									</p>
									<p className="ffmr fs16">
										{reservation.unit?.unitSize.currency
											? "$"
											: "Other"}
										{reservation.unit?.unitSize.price}/mo
									</p>
								</div>

								<div
									className="df rental_ftrs"
									style={{
										gridTemplateColumns: `${
											reservation.unit?.unitFeatures
												.length &&
											getLength(
												reservation.unit?.unitFeatures
													.length
											)
										}`,
									}}
								>
									{reservation.unit?.unitFeatures.map(
										(ftr, i) => (
											<div
												key={i}
												className="df acsa ffmr fs14 ftr"
											>
												{ftr.label}
											</div>
										)
									)}
								</div>

								<div className="mla mba ffqsb fs14 ptc rental_opener">
									View Reservation
								</div>
							</div>
					  ))
					: undefined} */}
      {/* </div> */}
    </div>
  );
};

export default Rentals;
