import React from "react";

export const MapPin = (props) => (
  <svg
    width="34"
    height="42"
    viewBox="0 0 34 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d)">
      <path
        d="M16.9991 0C10.2094 0 4.68555 5.52381 4.68555 12.3135C4.68555 20.7396 15.7049 33.1097 16.1741 33.6322C16.6148 34.123 17.3842 34.1222 17.824 33.6322C18.2932 33.1097 29.3126 20.7396 29.3126 12.3135C29.3125 5.52381 23.7887 0 16.9991 0ZM16.9991 18.5087C13.583 18.5087 10.8039 15.7295 10.8039 12.3135C10.8039 8.89739 13.5831 6.11828 16.9991 6.11828C20.4151 6.11828 23.1942 8.89745 23.1942 12.3135C23.1942 15.7296 20.4151 18.5087 16.9991 18.5087Z"
        fill={props.fill || "red"}
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0.685547"
        y="0"
        width="32.6271"
        height="42"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
