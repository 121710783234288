import Axios from "axios";
import Cleave from "cleave.js/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SAInput from "../../../../components/ui/form-elements/stand-alone-input/Input";
import styles from "./AddCard.module.scss";

const AddCard = (props) => {
  const auth = useSelector((state) => state.auth);

  // eslint-disable-next-line
  const [primary, setPrimary] = useState(props.primary);
  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumberToken, setCardNumberToken] = useState("");
  const [cvvToken, setCvvToken] = useState("");
  const [cardDate, setCardDate] = useState("");
  const [
    // eslint-disable-next-line
    cardLoaded,
    setCardLoaded,
  ] = useState(false);

  const getCCTokens = () => {
    console.log("Getting tokens");
    window.getTokens(() => {
      let cardToken = document.querySelector(
        "[data-ifields-id='card-number-token']"
      ).value;
      let cvvToken = document.querySelector(
        "[data-ifields-id='cvv-token']"
      ).value;
      setCardNumberToken(cardToken);
      setCvvToken(cvvToken);
    });
  };

  useEffect(() => {
    window.addIfieldKeyPressCallback((data) => {
      // getCCTokens();
      if (data.cardNumberIsValid && data.cvvIsValid) getCCTokens();
    });

    window.setAccount(
      "ifields_ShmilicomDev_Test2_811847608264488db4",
      "Shmili.com",
      "0.1.2"
    );

    let style = {
      height: "44px",
      border: "1px solid rgba(31, 32, 65, 0.25)",
      "border-radius": "4px",
      // transition: "all 0.2s",
      padding: "0px 12px",
      width: "100%",
      outline: "none",
      "box-sizing": "border-box",
      "font-size": "14px",
      // "font-family": "mont-reg"
    };
    window.setIfieldStyle("card-number", style);
    window.setIfieldStyle("cvv", style);
    window.enableAutoFormatting(" ");
  }, []);

  const saveCard = async () => {
    console.log(props);
    let EXPArray = cardDate.split("/");

    let expMonth = EXPArray[0];
    let expYear = EXPArray[1];

    let method = await Axios.post("/api/payment-method", {
      userId: auth.user._id,
      cc_number: cardNumberToken,
      CVVToken: cvvToken,
      nameOnCard: nameOnCard,
      exp_m: expMonth,
      exp_y: expYear,
      primary: primary,
    });

    props.refreshCards && props.refreshCards();

    props.setCard && props.setCard(method?.data?.data?._id);

    props.close();
  };
  return (
    <div className={styles.add_card_popup}>
      <div className={styles.inpt_line}>
        <SAInput
          value={nameOnCard}
          ifHasError={false}
          onChange={(e) => setNameOnCard(e.target.value)}
          name="nameOnCard"
          label="Full name"
          placeholder=""
        ></SAInput>
      </div>
      <div className={styles.inpt_line}>
        <div className="__inpt_wrpr df ffc">
          <label className="ffmb fs12">Card number</label>
          <iframe
            className="hello"
            onLoad={() => setCardLoaded(true)}
            title="cc_num"
            width="100%"
            height="44px"
            data-ifields-id="card-number"
            data-ifields-placeholder="Card Number"
            src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
          ></iframe>
          <input
            data-ifields-id="card-number-token"
            name="xCardNum"
            type="hidden"
          ></input>
        </div>
      </div>

      <div className={`df acsa ${styles.inpt_line} ${styles.df}`}>
        <div className={styles.inpt_sec_wrpr}>
          {/* <SAInput
            value={cardDate}
            ifHasError={false}
            onChange={(e) => setCardDate(e.target.value)}
            name="cardDate"
            label="Expire date"
            placeholder=""
          ></SAInput> */}
          <div className="__inpt_wrpr df ffc">
            <label className="ffmb fs12">Expire date</label>
            <div className="inner_inpt_wrpr ">
              <Cleave
                className={`ffnsb fs14`}
                value={cardDate}
                onChange={(e) => {
                  console.log(e.target.value);
                  setCardDate(e.target.value);
                }}
                options={{
                  date: true,
                  datePattern: ["m", "y"],
                }}
                id="dob"
              />
            </div>
          </div>
        </div>
        <div className={styles.inpt_sec_wrpr}>
          <div className="__inpt_wrpr df ffc">
            <label className="ffmb fs12">CVV</label>
            <iframe
              title="cc_cvv"
              width="100%"
              height="44px"
              data-ifields-id="cvv"
              data-ifields-placeholder="CVV"
              src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
            ></iframe>
            <input
              data-ifields-id="cvv-token"
              name="xCVV"
              type="hidden"
            ></input>
          </div>
        </div>
      </div>

      <div className={`df jcfe ${styles.ftr}`}>
        <button
          className="df ffmsb fs14 ttuc button secondary"
          onClick={() => props.close()}
        >
          Cancel
        </button>

        <button
          className="df ffmsb fs14 ttuc button primary"
          onClick={saveCard}
        >
          Save Card
        </button>
      </div>
    </div>
  );
};

export default AddCard;
