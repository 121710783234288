const NewMapPinSVG = (props) => (
  <svg
    width="35"
    height="48"
    viewBox="0 0 35 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.3838 0C7.79831 0 0 7.79831 0 17.3837C0 29.2794 15.5568 46.7431 16.2191 47.4807C16.8412 48.1736 17.9274 48.1724 18.5484 47.4807C19.2108 46.7431 34.7676 29.2794 34.7676 17.3837C34.7674 7.79831 26.9692 0 17.3838 0ZM17.3838 26.1299C12.5611 26.1299 8.63766 22.2064 8.63766 17.3837C8.63766 12.561 12.5612 8.63756 17.3838 8.63756C22.2064 8.63756 26.1298 12.5611 26.1298 17.3838C26.1298 22.2065 22.2064 26.1299 17.3838 26.1299Z"
      fill={props.fill || "#30BE76"}
    />
  </svg>
);

export default NewMapPinSVG;
