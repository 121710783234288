export const PhoneIconSVG = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.25 18.6667L0.25 14.875C0.25 14.2792 0.7375 13.7917 1.33333 13.7917C2.6875 13.7917 3.9875 13.575 5.20083 13.1742C5.58 13.055 6.0025 13.1417 6.30583 13.445L8.68917 15.8283C11.755 14.2683 14.2575 11.755 15.8283 8.68917L13.445 6.30583C13.2392 6.08917 13.1308 5.81833 13.1308 5.53667C13.1308 5.42833 13.1417 5.30917 13.185 5.20083C13.5858 3.9875 13.8025 2.67667 13.8025 1.33333C13.8025 0.737499 14.29 0.249999 14.8858 0.249999L18.6667 0.249999C19.2625 0.249999 19.75 0.737498 19.75 1.33333C19.75 11.5058 11.5058 19.75 1.33333 19.75C0.7375 19.75 0.25 19.2625 0.25 18.6667ZM2.41667 15.915C3.38083 15.85 4.32333 15.6875 5.2225 15.4275L6.5225 16.7275C5.2225 17.1717 3.84667 17.4533 2.41667 17.5508L2.41667 15.915ZM15.4384 5.23342C15.6984 4.31259 15.8609 3.37009 15.9259 2.41676L17.5401 2.41676C17.4426 3.84676 17.1609 5.22259 16.7276 6.53342L15.4384 5.23342Z"
      fill={props.fill || "#ddd"}
    />
  </svg>
);
