const ToolsTypeSVG = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.42175 32.0002H9.42107C8.71892 32.0002 8.01292 31.8544 7.32268 31.566C7.14849 31.4936 7.02195 31.3394 6.98509 31.154C6.94913 30.969 7.00666 30.778 7.14017 30.6447L9.57885 28.2065L7.97967 26.6078L5.58167 29.0058C5.44816 29.1393 5.25644 29.1975 5.07168 29.1608C4.88671 29.124 4.73252 28.9979 4.66015 28.8239C3.83256 26.8379 4.17691 24.8141 5.58169 23.4101C6.54208 22.449 7.82008 21.9199 9.17858 21.9199C10.5375 21.9199 11.8157 22.449 12.7761 23.4101C13.7372 24.3705 14.2667 25.6485 14.2667 27.0076C14.2667 28.3665 13.7372 29.644 12.7761 30.6051C11.8638 31.5174 10.7031 32.0002 9.42174 32.0002L9.42175 32.0002ZM8.59911 30.7847C8.87377 30.8407 9.14866 30.8697 9.42106 30.8697H9.42173C10.3967 30.8697 11.2809 30.5015 11.9768 29.8057C12.7241 29.0579 13.1361 28.064 13.1361 27.0076C13.1361 25.9503 12.7241 24.9566 11.9768 24.2093C11.2295 23.462 10.2356 23.0504 9.17851 23.0504C8.12192 23.0504 7.12822 23.462 6.38091 24.2093C5.48997 25.0996 5.16384 26.2857 5.43801 27.5502L7.57979 25.4081C7.80028 25.1879 8.15812 25.1879 8.3793 25.4081L10.7778 27.8068C10.8841 27.9125 10.9434 28.0568 10.9434 28.2065C10.9434 28.3564 10.8841 28.4998 10.7778 28.6061L8.59911 30.7847Z"
      fill={props.fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.785 14.5262C25.4268 14.5262 24.1429 13.9897 23.1688 13.0158C22.2068 12.0536 21.6703 10.7847 21.6584 9.44301C21.6471 8.09263 22.1684 6.82073 23.1288 5.86074C24.5279 4.46116 26.5719 4.11794 28.5426 4.93943C28.7162 5.0118 28.8427 5.16599 28.8796 5.35142C28.9162 5.5364 28.858 5.72745 28.7247 5.86073L26.3663 8.21915L27.9648 9.81787L30.3635 7.41918C30.4974 7.28634 30.6892 7.22768 30.8735 7.26499C31.0582 7.30118 31.2126 7.42772 31.285 7.60191C32.1126 9.58724 31.753 11.6263 30.3232 13.0558C29.3754 14.0038 28.1188 14.5262 26.785 14.5262L26.785 14.5262ZM26.4081 5.62322C25.4641 5.62322 24.6064 5.98172 23.928 6.66008C23.184 7.40337 22.7795 8.38872 22.7889 9.43277C22.7981 10.4764 23.2169 11.4651 23.9682 12.2162C24.7286 12.9766 25.7291 13.3955 26.785 13.3955C27.8166 13.3955 28.7889 12.9907 29.5239 12.2558C30.4198 11.3605 30.7626 10.1412 30.5026 8.87873L28.3646 11.0167C28.2588 11.123 28.1152 11.1823 27.9648 11.1823C27.8151 11.1823 27.6715 11.123 27.5652 11.0167L25.1672 8.61865C24.9462 8.39771 24.9462 8.03986 25.1672 7.81897L27.2689 5.71701C26.9796 5.65498 26.6917 5.62329 26.408 5.62329L26.4081 5.62322Z"
      fill={props.fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.38208 16.999C4.11462 16.999 3.86311 16.8949 3.6743 16.7061L0.292722 13.325C-0.0974617 12.935 -0.0974617 12.2997 0.292048 11.9097L1.67477 10.5269C2.05237 10.1493 2.71272 10.1487 3.09035 10.5269L3.39895 10.8355C3.46525 10.9012 3.54212 10.9113 3.58168 10.9113C3.62236 10.9113 3.69923 10.9012 3.76531 10.8355L5.27301 9.32783C5.07454 8.7648 5.14849 7.85294 6.433 6.56866L10.031 2.97114C10.0955 2.89023 12.4703 0.14586 18.6958 0.00966484L19.1469 0L19.3932 0.888691L18.9925 1.122C16.8082 2.26737 13.1661 4.79639 13.3496 7.02321C13.3797 7.38957 13.2575 7.73842 13.0149 7.98094L10.449 10.5473C10.2688 10.7622 9.29262 11.8669 8.23758 11.8669C8.03305 11.8669 7.83683 11.826 7.65431 11.7464C7.65363 11.7458 7.65318 11.7458 7.65251 11.7453L6.16369 13.2337C6.06232 13.3348 6.06232 13.4989 6.16369 13.6L6.47161 13.9082C6.8618 14.2986 6.86112 14.9335 6.47229 15.3235L5.08887 16.7063C4.90008 16.8951 4.64924 16.9991 4.38178 16.9991L4.38208 16.999ZM1.18372 12.6175L4.38208 15.8152L5.5812 14.6156L5.36453 14.3991C4.82307 13.8577 4.82307 12.9757 5.36453 12.4343L6.88753 10.9113C7.19164 10.6054 7.68295 10.5258 8.10684 10.7101C8.4804 10.8729 9.23921 10.2393 9.59365 9.80792L12.2159 7.18138C12.2177 7.17868 12.2256 7.15666 12.2227 7.11597C12.0312 4.78921 14.5207 2.63604 16.4579 1.32697C12.467 1.92508 10.8795 3.71576 10.8608 3.73688L7.23232 7.36811C6.41595 8.18448 6.24581 8.7441 6.34811 8.97472C6.51106 9.344 6.43802 9.76162 6.16179 10.038L4.56462 11.635C4.04002 12.1596 3.12435 12.1602 2.59977 11.635L2.38265 11.4181L1.18372 12.6175Z"
      fill={props.fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.1076 30.0093C28.5032 30.0093 27.9357 29.7742 27.5088 29.3474L8.7793 10.6178L11.9767 7.41992L30.7063 26.1495C31.1336 26.5763 31.3687 27.1445 31.3687 27.7482C31.3687 28.3526 31.1336 28.9201 30.7063 29.3474C30.2788 29.7742 29.7113 30.0093 29.1076 30.0093ZM10.378 10.6181L28.3084 28.5484C28.7356 28.9752 29.4796 28.9752 29.9069 28.5484C30.1206 28.3346 30.2382 28.051 30.2382 27.7485C30.2382 27.4466 30.1206 27.1627 29.9069 26.9492L11.9771 9.01945L10.378 10.6181Z"
      fill={props.fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1705 17.9798C21.0202 17.9798 20.8766 17.9205 20.7709 17.8142L18.3722 15.4157C18.152 15.1952 18.152 14.8374 18.3722 14.6162L21.9697 11.0187C22.1909 10.7984 22.5481 10.7984 22.7693 11.0187L25.1677 13.4174C25.274 13.523 25.3334 13.6673 25.3334 13.817C25.3334 13.9674 25.274 14.111 25.1677 14.2167L21.5702 17.8142C21.4639 17.9205 21.3205 17.9798 21.1706 17.9798L21.1705 17.9798ZM19.5718 15.0161L21.1705 16.6153L23.9688 13.817L22.3697 12.2183L19.5718 15.0161Z"
      fill={props.fill || "black"}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5753 25.573C13.4305 25.573 13.2858 25.5177 13.1757 25.4074L10.777 23.0094C10.6707 22.9033 10.6113 22.7597 10.6113 22.6093C10.6113 22.4594 10.6707 22.3157 10.777 22.2097L15.9733 17.0134C16.1942 16.7924 16.5521 16.7924 16.7726 17.0134L19.1716 19.4114C19.2775 19.5177 19.3373 19.6613 19.3373 19.8117C19.3373 19.9614 19.2775 20.105 19.1716 20.2113L13.9749 25.4074C13.8648 25.5177 13.7201 25.573 13.5753 25.573L13.5753 25.573ZM11.9761 22.6093L13.5753 24.2085L17.9728 19.8117L16.3729 18.2123L11.9761 22.6093Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default ToolsTypeSVG;
