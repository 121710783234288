import { combineReducers } from "redux";
import auth from "./authR";
import payMethods from "./payMethodsR";
import reservation from "./reservationR";
import reservationUnit from "./reservationUnitR";
import storageType from "./storageType";
import unitSearch from "./unitSearchR";
import units from "./unitsR";
import user from "./user";
import websiteProfile from "./websiteProfileR";
import feature from "./featuresR";
import toast from "./toastR";
import pins from "./pinsR";

export default combineReducers({
  user,
  storageType: storageType,
  websiteProfile,
  units,
  unitSearch,
  auth,
  reservation,
  payMethods,
  reservationUnit,
  feature,
  toast,
  pins,
});
