import React from "react";

// import images
import logoGreen from "../../assets/images/demo-logo-green.png";
import { TwitterSvg } from "../../assets/images/svgs/twitter.svg";
import { FacebookSvg } from "../../assets/images/svgs/facebokk.svg";
import { InstagramSvg } from "../../assets/images/svgs/instagram.svg";

// import scss
import "./Footer.scss";

import { ThemeContext } from "../../App";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "../../helper";

const Footer = (props) => {
  const { websiteProfile } = useSelector(({ websiteProfile }) => ({
    websiteProfile,
  }));

  // console.log(websiteProfile);

  return (
    <ThemeContext.Consumer>
      {(val) => (
        <div className="df acc footer_sec" style={{ borderColor: val }}>
          <div className="logo_wrpr">
            <img
              src={websiteProfile?.theme?.logoURL || logoGreen}
              alt="Logo"
            ></img>
          </div>
          <div className="df ffc acsa ffmr fs14 text_wrpr">
            <p>
              Secure, Convenient, and Affordable - Your Ultimate Storage
              Solution
            </p>
            <p>Store with Confidence and Peace of Mind</p>
            <p>{formatPhoneNumber(websiteProfile.profilePhoneNumber)}</p>
          </div>
          <div className="df socials_wrpr">
            {websiteProfile?.social?.twitter ? (
              <div className="df acc social_wrpr">
                <TwitterSvg fill={val}></TwitterSvg>
              </div>
            ) : undefined}
            {websiteProfile?.social?.instagram ? (
              <div className="df acc social_wrpr">
                <InstagramSvg fill={val}></InstagramSvg>
              </div>
            ) : undefined}
            {websiteProfile?.social?.facebook ? (
              <div className="df acc social_wrpr">
                <FacebookSvg fill={val}></FacebookSvg>
              </div>
            ) : undefined}
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default Footer;
