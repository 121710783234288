import axios from "axios";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import FMKInput from "../components/ui/form-elements/stand-alone-input/formikInput";
// import ATAInput from "../components/ui/form-elements/stand-alone-input/addressTypeAheadInput";
import SwitchInput from "../components/ui/form-elements/switch/Switch";
import { getToken } from "../helper";
import { GET_UNIT } from "../store/actions/types";
import * as Yup from "yup";

// addIfieldCallback("input", function (data) {
//   setIfieldStyle(
//     "card-number",
//     data.cardNumberFormattedLength <= 0
//       ? defaultStyle
//       : data.cardNumberIsValid
//       ? validStyle
//       : invalidStyle
//   );
//   if (data.lastIfieldChanged === "cvv") {
//     setIfieldStyle(
//       "cvv",
//       data.issuer === "unknown" || data.cvvLength <= 0
//         ? defaultStyle
//         : data.cvvIsValid
//         ? validStyle
//         : invalidStyle
//     );
//   } else if (data.lastIfieldChanged === "card-number") {
//     if (data.issuer === "unknown" || data.cvvLength <= 0) {
//       setIfieldStyle("cvv", defaultStyle);
//     } else if (data.issuer === "amex") {
//       setIfieldStyle("cvv", data.cvvLength === 4 ? validStyle : invalidStyle);
//     } else {
//       setIfieldStyle("cvv", data.cvvLength === 3 ? validStyle : invalidStyle);
//     }
//   } else if (data.lastIfieldChanged === "ach") {
//     setIfieldStyle(
//       "ach",
//       data.achLength === 0
//         ? defaultStyle
//         : data.achIsValid
//         ? validStyle
//         : invalidStyle
//     );
//   }
// });

const PaymentSchema = Yup.object().shape({
  nameOnCard: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  //   cc_number: Yup.string().required("Card number is required"),
  exp_m: Yup.string().required("Required"),
  exp_y: Yup.string().required("Required"),
  //   cvv: Yup.string().required("Security code required"),
});

const Page2 = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [reservation, setReservation] = useState({});
  //   const [unitDetails, setUnitDetails] = useState();
  const [loading, setLoading] = useState(true);
  const [cvvToken, setCvvToken] = useState("");
  const [ccToken, setCcToken] = useState("");

  const { auth, reservationUnit } = useSelector(
    ({ auth, reservationUnit }) => ({
      auth,
      reservationUnit,
    })
  );

  const getReservationDetails = async () => {
    let reservationRes = await axios.get(
      `/api/reservation/${params.reservationId}`,
      {
        headers: { "x-auth": getToken() },
      }
    );

    setReservation(reservationRes.data.data);
  };

  const getUnitDetails = async () => {
    let unit = await axios.get(
      `/api/reserve-unit/?unitid=${reservation.unit._id}&moveindate=${reservation.expectedMoveInDate}`
    );

    // setUnitDetails(unit.data.data);

    dispatch({
      type: GET_UNIT,
      payload: unit.data.data,
    });
  };

  const setupCardknox = async () => {
    window.addIfieldKeyPressCallback((data) => {
      if (data.cardNumberIsValid && data.cvvIsValid) getCCTokens();
    });

    window.setAccount(
      "ifields_ShmilicomDev_Test2_811847608264488db4",
      "Shmili.com",
      "0.1.2"
    );

    let defaultStyle = {
      height: "44px",
      border: "1px solid rgba(31, 32, 65, 0.25)",
      "border-radius": "4px",
      transition: "all 0.2s",
      padding: "0px 12px",
      width: "100%",
      outline: "none",
      "box-sizing": "border-box",
      "font-size": "14px",
      // "font-family": "mont-reg"
    };

    let invalidStyle = {
      height: "44px",
      border: "1px solid red",
      "border-radius": "4px",
      transition: "all 0.2s",
      padding: "0px 12px",
      width: "100%",
      outline: "none",
      "box-sizing": "border-box",
      "font-size": "14px",
      // "font-family": "mont-reg"
    };

    // ***************************************
    // ***************************************

    window.addIfieldKeyPressCallback((data) => {
      window.setIfieldStyle(
        "card-number",
        data.cardNumberFormattedLength <= 0
          ? defaultStyle
          : data.cardNumberIsValid
          ? defaultStyle
          : invalidStyle
      );
      if (data.lastIfieldChanged === "cvv") {
        window.setIfieldStyle(
          "cvv",
          data.issuer === "unknown" || data.cvvLength <= 0
            ? defaultStyle
            : data.cvvIsValid
            ? defaultStyle
            : invalidStyle
        );
      } else if (data.lastIfieldChanged === "card-number") {
        if (data.issuer === "unknown" || data.cvvLength <= 0) {
          window.setIfieldStyle("cvv", defaultStyle);
        } else if (data.issuer === "amex") {
          window.setIfieldStyle(
            "cvv",
            data.cvvLength === 4 ? defaultStyle : invalidStyle
          );
        } else {
          window.setIfieldStyle(
            "cvv",
            data.cvvLength === 3 ? defaultStyle : invalidStyle
          );
        }
      } else if (data.lastIfieldChanged === "ach") {
        window.setIfieldStyle(
          "ach",
          data.achLength === 0
            ? defaultStyle
            : data.achIsValid
            ? defaultStyle
            : invalidStyle
        );
      }
    });

    // ***************************************
    // ***************************************

    await window.setIfieldStyle("card-number", defaultStyle);
    await window.setIfieldStyle("cvv", defaultStyle);
    await window.enableAutoFormatting(" ");
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const getCCTokens = () => {
    window.getTokens(() => {
      let cardToken = document.querySelector(
        "[data-ifields-id='card-number-token']"
      ).value;
      let cvvToken = document.querySelector(
        "[data-ifields-id='cvv-token']"
      ).value;

      setCcToken(cardToken);
      setCvvToken(cvvToken);
    });
  };

  useEffect(() => {
    getReservationDetails();
    setupCardknox();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!reservation?.unit?._id) return;
    getUnitDetails();
    // eslint-disable-next-line
  }, [reservation]);

  return (
    <>
      {reservationUnit?.reserved && !reservationUnit?.isDraft ? (
        "Already reserved"
      ) : (
        <>
          {props.page !== "summary" && (
            <div className="df ffqsm fs16 sec_hdr">
              Billed monthly{" "}
              <span className="fwbold">
                ${reservationUnit?.unitSize?.price.toFixed(2)}
              </span>
            </div>
          )}
          <div>
            <Formik
              initialValues={{
                nameOnCard:
                  (props.reservation && props.reservation.nameOnCard) ||
                  `${auth.user.fullName}`,
                cc_number:
                  (props.reservation && props.reservation.cc_number) || "",
                exp_m: (props.reservation && props.reservation.exp_m) || "",
                exp_y: (props.reservation && props.reservation.exp_y) || "",
                cvv: (props.reservation && props.reservation.cvv) || "",
                b_address:
                  (props.reservation && props.reservation.b_address) || "",
                city: (props.reservation && props.reservation.city) || "",
                state: (props.reservation && props.reservation.state) || "",
                zip: (props.reservation && props.reservation.zip) || "",
                address_2:
                  (props.reservation && props.reservation.address_2) || "",
                saveForLater: true,
                sameAsBilling: true,
              }}
              validationSchema={PaymentSchema}
              onSubmit={async (values, b) => {
                console.log("*** PAYING NOW ***");
                values.cc_number = ccToken;
                values.cvv = cvvToken;

                console.log(values);
                //     this.setState({
                //       submitting: true,
                //     });
                //     Axios.post("/api/test-doc-creation");
                //     // return console.log(
                //     // 	"Hey U"
                //     // );
                //     this.setState({
                //       submitting: true,
                //     });
                //     // values.primary = true;
                let re = await axios
                  .post("/api/payment-method", {
                    ...values,
                    primary: true,
                    userId: auth.user._id,
                  })
                  .catch((e) => e);
                //     this.setState({
                //       submitting: false,
                //     });
                if (re.response) {
                  window.clearIfield("card-number");
                  window.clearIfield("cvv");
                  return console.log("Error saving payment method");
                }

                console.log("HUE^&*^G*B*N)(");
                console.log("HUE^&*^G*B*N)(");
                console.log("HUE^&*^G*B*N)(");
                console.log(re);
                console.log("HUE^&*^G*B*N)(");
                console.log("HUE^&*^G*B*N)(");
                console.log("HUE^&*^G*B*N)(");

                // "/reserve/summary/:reservationId/:payMethodId?/:addressId?"
                return history.push(
                  `/reserve/summary/${params.reservationId}/${re.data?.data?._id}`
                );
                //     if (re.data.message === "Payment method added") {
                //       // let reservation = await Axios.post(
                //       // 	"/api/reservation",
                //       // 	{
                //       // 		user: this
                //       // 			.props
                //       // 			.auth
                //       // 			.user
                //       // 			._id,
                //       // 		unit: this
                //       // 			.props
                //       // 			.match
                //       // 			.params
                //       // 			.unitId,
                //       // 		locationId: this
                //       // 			.props
                //       // 			.match
                //       // 			.params
                //       // 			.locationId,
                //       // 		webProfileId: this
                //       // 			.props
                //       // 			.auth
                //       // 			.user
                //       // 			._id,
                //       // 		accountId: this
                //       // 			.props
                //       // 			.websiteProfile
                //       // 			.account,
                //       // 		expectedMoveInDate: this
                //       // 			.props
                //       // 			.match
                //       // 			.params
                //       // 			.selectedDate,
                //       // 		initialPayment: false,
                //       // 	}
                //       // );
                //       props.history.push(
                //         // /:sizeId/:unitId/:selectedDate/:locationId
                //         `/reserve/summary/${props.match.params.sizeId}/${props.match.params.unitId}/${props.match.params.selectedDate}/${props.match.params.locationId}/${re.data.data._id}`
                //       );
                //     } else {
                //       console.log("_______________");
                //       window.clearIfield("cvv");
                //       console.log("error saving card");
                //     }
                //     b.setFieldValue("cvv", undefined);
                //     b.setFieldValue("cc_number", undefined);
              }}
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="page2">
                    <div className="left_sec">
                      <div className="df inpt_line">
                        <div className="inpt_wrpr">
                          {/* <Field type="checkbox" name="primary"></Field> */}
                        </div>
                      </div>

                      <div className="df inpt_line">
                        <div className="input_wrpr full">
                          <Field
                            // icon="user"
                            name="nameOnCard"
                            label="name on card"
                            id="cc_name"
                            component={FMKInput}
                            placeholder="Type here..."
                          />
                        </div>
                      </div>

                      <div
                        className="inpt_line"
                        style={{ opacity: loading ? 0 : 1 }}
                      >
                        <div className="input_wrpr full">
                          <div className="__inpt_wrpr df ffc">
                            <label className="ffmb fs12">Card number</label>
                            <iframe
                              title="cc_num"
                              width="100%"
                              height="44px"
                              data-ifields-id="card-number"
                              data-ifields-placeholder="Card Number"
                              src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
                            ></iframe>
                            <input
                              data-ifields-id="card-number-token"
                              name="xCardNum"
                              type="hidden"
                            ></input>
                          </div>
                        </div>
                      </div>

                      <div className="df inpt_line cc_details">
                        <div className="input_wrpr">
                          <Field
                            // icon="cal"
                            name="exp_m"
                            label="Expire month (MM)"
                            placeholder="MM"
                            id="exp_m"
                            component={FMKInput}
                          />
                        </div>
                        <div className="input_wrpr">
                          <Field
                            // icon="cal"
                            name="exp_y"
                            label="Expire year (YY)"
                            placeholder="YY"
                            id="exp_y"
                            component={FMKInput}
                          />
                        </div>
                        <div
                          className="mla input_wrpr"
                          style={{ opacity: loading ? 0 : 1 }}
                        >
                          <div className="__inpt_wrpr df ffc">
                            <label className="ffmb fs12">CVV</label>
                            <iframe
                              title="cc_cvv"
                              width="100%"
                              height="44px"
                              data-ifields-id="cvv"
                              data-ifields-placeholder="CVV"
                              src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
                            ></iframe>
                            <input
                              data-ifields-id="cvv-token"
                              name="xCVV"
                              type="hidden"
                            ></input>
                          </div>
                        </div>
                      </div>

                      {/* {console.log(props)} */}
                      <div className="switch_wrpr">
                        {/* <SwitchInput></SwitchInput> */}
                        <Field
                          // icon="home"
                          name="saveForLater"
                          label="Save for later"
                          id="saveForLater"
                          component={SwitchInput}
                          placeholder=""
                          setfieldvalue={props.setFieldValue}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="df jcfe reservation_ftr">
                    {/* <button
                      className="ffqsb tac ttuc fs14 button secondary_primary ptc"
                      type="submit"
                    >
                      Pay Later
                    </button> */}
                    <button
                      className={`df ffmb fs14 ttuc fwbold srch_btn button primary continue_btn`}
                      type="submit"
                      //   onClick={(event) =>
                      //     handleSubmit({ next: true })(props.values)
                      //   }
                    >
                      Continue
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
    //   <div className="page2">
    //     <div className="left_sec">
    //       <div className="df inpt_line">
    //         <div className="inpt_wrpr">
    //           {/* <Field type="checkbox" name="primary"></Field> */}
    //         </div>
    //       </div>

    //       <div className="df inpt_line">
    //         <div className="input_wrpr full">
    //           <Field
    //             // icon="user"
    //             name="nameOnCard"
    //             label="name on card"
    //             id="cc_name"
    //             component={FMKInput}
    //             placeholder="Type here..."
    //           />
    //         </div>
    //       </div>

    //       <div
    //         className="inpt_line"
    //         style={{ opacity: this.state.loading ? 0 : 1 }}
    //       >
    //         <div className="input_wrpr full">
    //           <div className="__inpt_wrpr df ffc">
    //             <label className="ffmb fs12">Card number</label>
    //             <iframe
    //               title="cc_num"
    //               width="100%"
    //               height="44px"
    //               data-ifields-id="card-number"
    //               data-ifields-placeholder="Card Number"
    //               src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
    //             ></iframe>
    //             <input
    //               data-ifields-id="card-number-token"
    //               name="xCardNum"
    //               type="hidden"
    //             ></input>
    //           </div>
    //         </div>
    //       </div>

    //       <div className="df inpt_line cc_details">
    //         <div className="input_wrpr">
    //           <Field
    //             // icon="cal"
    //             name="exp_m"
    //             label="Expire month (MM)"
    //             placeholder="MM"
    //             id="exp_m"
    //             component={FMKInput}
    //           />
    //         </div>
    //         <div className="input_wrpr">
    //           <Field
    //             // icon="cal"
    //             name="exp_y"
    //             label="Expire year (YY)"
    //             placeholder="YY"
    //             id="exp_y"
    //             component={FMKInput}
    //           />
    //         </div>
    //         <div
    //           className="mla input_wrpr"
    //           style={{ opacity: this.state.loading ? 0 : 1 }}
    //         >
    //           <div className="__inpt_wrpr df ffc">
    //             <label className="ffmb fs12">CVV</label>
    //             <iframe
    //               title="cc_cvv"
    //               width="100%"
    //               height="44px"
    //               data-ifields-id="cvv"
    //               data-ifields-placeholder="CVV"
    //               src="https://cdn.cardknox.com/ifields/2.5.1905.0801/ifield.htm"
    //             ></iframe>
    //             <input
    //               data-ifields-id="cvv-token"
    //               name="xCVV"
    //               type="hidden"
    //             ></input>
    //           </div>
    //         </div>
    //       </div>

    //       {/* {console.log(props)} */}
    //       <div className="switch_wrpr">
    //         {/* <SwitchInput></SwitchInput> */}
    //         <Field
    //           // icon="home"
    //           name="saveForLater"
    //           label="Save for later"
    //           id="saveForLater"
    //           component={SwitchInput}
    //           placeholder=""
    //           setfieldvalue={props.setFieldValue}
    //         />
    //       </div>
    //     </div>
    //   </div>
  );
};

export default Page2;
