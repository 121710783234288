import React, { useRef, useContext } from "react";

// import scss
import "./Explore.scss";
import { MapPin } from "../../assets/images/svgs/map-pin.svg";
import { ThemeContext } from "../../App";
import { Link } from "react-router-dom";

export const Explore = (props) => {
  const bgColor = useContext(ThemeContext);

  const inputEl = useRef(null);
  // const [position, updatePosition] = useState("hidden");

  const onButtonClick = () => {
    // `current` points to the mounted text input element
    console.log(inputEl.current.getBoundingClientRect());
  };

  //   useEffect(() => {
  //     // console.log(inputEl.current.getBoundingClientRect().top);

  //     if (inputEl.current.getBoundingClientRect().top <= 400)
  //       updatePosition("visible");
  //     if (inputEl.current.getBoundingClientRect().top > 400)
  //       updatePosition("hidden");
  //   }, [
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //     inputEl && inputEl.current && inputEl.current.getBoundingClientRect().top,
  //   ]);

  const location =
    props.websiteProfile?.websiteProfile?.locations?.length &&
    props.websiteProfile?.websiteProfile?.locations[0];
  return (
    <div className={`explore_sec`} ref={inputEl}>
      {console.log(props)}
      <div className="df acsa content_wrpr">
        <div className="inner_cntnt_wrpr">
          <h3 className="ffmsb fs20">Explore {location?.name}</h3>
          <div className="df cards_wrpr" onClick={onButtonClick}>
            <div className="df card_wrpr">
              <div
                className="img_wrpr"
                style={{
                  backgroundImage:
                    "url(https://selfstorageimages.blob.core.windows.net/images/dummy-image.png)",
                }}
              ></div>
              <div className="df ffc cntnt_wrpr">
                {/* <h5 className="ffqsb fs19">
									Explore Plaza 15 storage
								</h5> */}
                <div className="df loc_wrpr">
                  <div className="icon_wrpr">
                    <MapPin fill={bgColor}></MapPin>
                  </div>
                  <div className="ffqsb fs19 lc_content_wrpr">
                    <p>{location?.name}</p>
                    <p>{location?.address.deliveryLine1}</p>
                    <p>{location?.address?.lastLine}</p>
                  </div>
                </div>
                <div className="text_wrpr">
                  <p className="ffmr fs14">
                    {
                      props.websiteProfile?.websiteProfile?.landingPage
                        ?.squareOneText
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="df card_wrpr">
              <div
                className="img_wrpr"
                style={{
                  backgroundImage:
                    "url(https://selfstorageimages.blob.core.windows.net/images/dummy-image.png)",
                }}
              ></div>
              <div className="df ffc cntnt_wrpr">
                <h5 className="ffqsb fs19">Find Available Storage Space</h5>
                <div className="text_wrpr">
                  <p className="ffmr fs14">
                    {
                      props.websiteProfile?.websiteProfile?.landingPage
                        ?.squareTwoText
                    }
                  </p>
                </div>

                <Link
                  className="df acc ffqsb fs14 explore_link pbgc full"
                  to={`/locations/?location=${
                    props.websiteProfile?.websiteProfile?.locations
                      ?.length[0] &&
                    props.websiteProfile?.websiteProfile?.locations?._id
                  }`}
                >
                  Explore
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
