import { CLEAR_AUTH, CURRENT_USER } from "../actions/types";

const reducer = (state = { status: "loading" }, action) => {
  switch (action.type) {
    case CURRENT_USER:
      return {
        ...state,
        status: "logged-in",
        user: action.payload,
      };

    case CLEAR_AUTH:
      return { status: "logged-out" };
    default:
      return state;
  }
};

export default reducer;
