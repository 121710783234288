import React from "react";
import {Link} from "react-router-dom";

// import scss
import "./WelcomePopup.scss";

export const WelcomePopup = (props) => (
	<>
		{props.firstName ? (
			<div className="df welcome_popup">
				<div className="df ffc inner_cntnt">
					<div className="check_icon"></div>
					<p className="ffqsb fs22 welcome_name">
						Welcome, {props.firstName}
					</p>
					<p className="ffmr fs14">You're all set.</p>
					<Link
						to={`/reserve/info/${localStorage.getItem(
							"reservationId"
						)}`}
						className="ffmb fs14 ttuc rsrv_btn button"
					>
						Continue booking
					</Link>
				</div>
			</div>
		) : (
			"Loading..."
		)}
	</>
);
