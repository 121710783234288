import Axios from "axios";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { AmazonLoginSVG } from "../../../assets/images/svgs/social/login/amazon.svg";
import { FacebookLoginSVG } from "../../../assets/images/svgs/social/login/facebook.svg";
import { GoogleLoginSVG } from "../../../assets/images/svgs/social/login/google.svg";
import { TwitterLoginSVG } from "../../../assets/images/svgs/social/login/twitter.svg";
import SAInput from "../../ui/form-elements/stand-alone-input/Input";
// import scss
import "./SignInPopup.scss";

const BASE_URL =
  process.env.NODE_ENV === "production" ? "https://api.slfstrg.com" : "";

class SignIn extends Component {
  state = {
    loginError: undefined,
    email: "",
    password: "",
    showSignin: true,
    signupName: "",
    signupEmail: "",
    signupPassword: "",
    signupPasswordCopy: "",
    fullUrl: window.location.href
      .replace(/:/g, "(")
      .replace(/\//g, "*")
      .replace(/\?/g, "!")
      .replace(/&/g, "@"),
  };
  async componentDidMount() {}

  updateEmailValue = (val) => this.setState({ email: val });
  updatePasswordValue = (val) => this.setState({ password: val });

  updateValue = (val, field) => {
    this.setState({ [field]: val });
  };

  toggleShowSignin = () =>
    this.setState((prevState) => ({ showSignin: !prevState.showSignin }));

  isSignupDisabled = () => {
    return !this.state.signupName.length ||
      !this.state.signupEmail.length ||
      !this.state.signupPassword.length ||
      this.state.signupPasswordCopy !== this.state.signupPassword
      ? true
      : false;
  };

  sendSignup = async () => {
    let saved = await Axios.post(`/auth/local/create`, {
      fullName: this.state.signupName,
      email: this.state.signupEmail,
      password: this.state.signupPassword,
      account: this.props.accountId,
      webProfileId: this.props.webProfileId,
      locations: this.props.webProfile.locations.map((lo) => lo._id),
    }).catch((e) => e);

    console.log(saved.response);

    if (saved.data === "ok") return this.setState({ showSignin: true });

    return this.setState({
      signUpEmailError: true,
      signUpError: saved.response.data,
    });
  };

  localSignin = async () => {
    this.setState({ loginError: false });
    let res = await Axios.post(
      `${BASE_URL}/auth/local?redirectUrl=${
        this.props.redirectUrl
          ?.replace(/:/g, "(")
          .replace(/\//g, "*")
          .replace(/\?/g, "!")
          .replace(/&/g, "@") || this.state.fullUrl
      }&baseUrl=${window.location.origin}`,
      {
        email: this.state.email,
        password: this.state.password,
      }
    ).catch((e) => e);

    // "Unauthorized"
    if (res?.response?.status === 401) {
      if (res.response.data === "Unauthorized")
        this.setState({ loginError: true });
      return console.log("Error logging in");
    }

    if (res?.status === 200) return (window.location = res.data);
  };

  getEmailSignupError = () => {
    // console.log("*&*&*&*&*&*&*&*&*");
    return this.state.getEmailSignupError;
  };
  render() {
    const fullUrl = window.location.href
      .replace(/:/g, "(")
      .replace(/\//g, "*")
      .replace(/\?/g, "!")
      .replace(/&/g, "@");

    return (
      <div className="df si_su_popup">
        {/* {console.log(this.props.webProfile.locations[0]._id)} */}
        <div className="inner_cntnt_wrpr">
          <h4 className="df acsa ffqsb fs22">
            {this.state.showSignin ? "Sign in" : "Sign up"}
          </h4>
          {this.state.showSignin ? (
            <>
              <div className="email_signin_sec">
                <div className="inpt_line">
                  <SAInput
                    value={this.state.email}
                    ifHasError={
                      this.state.signinEmailError || this.state.loginError
                    }
                    onChange={(e) => {
                      this.setState({ loginError: false });
                      return this.updateEmailValue(e.target.value);
                    }}
                    name="email"
                    label="email"
                    placeholder="Enter your email"
                  ></SAInput>
                </div>
                <div className="inpt_line">
                  <SAInput
                    value={this.state.password}
                    ifHasError={
                      this.state.signinPasswordError || this.state.loginError
                    }
                    onChange={(e) => {
                      this.setState({ loginError: false });
                      return this.updatePasswordValue(e.target.value);
                    }}
                    type="password"
                    name="password"
                    label="password"
                    placeholder="Enter your password"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") this.localSignin();
                    }}
                  ></SAInput>
                </div>

                <button
                  onClick={this.localSignin}
                  className="df ffmsb fs14 w100 ttuc button has_arrow sign_in_btn primary pbgc"
                >
                  Sign In
                </button>

                {this.props.showForgot ? (
                  <p className="ffmm fs13 forgot_password">Forgot Password?</p>
                ) : undefined}
                {this.props.showSignup ? (
                  <>
                    <p className="ffmr fs13 no_account_btn">
                      Don’t have an account with us?{" "}
                      <span
                        onClick={this.toggleShowSignin}
                        className="ffmsb fs13 cursor_pointer"
                      >
                        Sign up
                      </span>
                    </p>
                  </>
                ) : undefined}
              </div>

              {this.props.showSocial ? (
                <>
                  <p className="ffmr fs16 tac or_text">Quick access with</p>
                  <div className="df acsa socials_wrpr">
                    <div className="social_wrpr">
                      <a
                        className="df acsa fs14 ttuc fb button"
                        href={`${BASE_URL}/auth/facebook/?redirectUrl=${fullUrl}&baseUrl=${window.location.origin}&webProfileId=${this.props.webProfileId}&accountId=${this.props.accountId}`}
                      >
                        <FacebookLoginSVG></FacebookLoginSVG>
                      </a>
                    </div>
                    {console.log(fullUrl)}
                    <div className="social_wrpr">
                      <a
                        className="df acsa ffmm fs14 ttuc goog button"
                        href={`${BASE_URL}/auth/google/?redirectUrl=${fullUrl}&baseUrl=${window.location.origin}&webProfileId=${this.props.webProfileId}&accountId=${this.props.accountId}`}
                      >
                        <GoogleLoginSVG></GoogleLoginSVG>
                      </a>
                    </div>

                    <div className="social_wrpr">
                      <a
                        className="df acsa ffmm fs14 ttuc amz button"
                        // onClick={() => {
                        //   this.auth.signIn();
                        //   this.props.continueAction();
                        //   this.props.closeAction();
                        // }}
                        href={`${BASE_URL}/auth/amazon/?redirectUrl=${window.location.href}&baseUrl=${window.location.origin}&webProfileId=${this.props.webProfileId}&accountId=${this.props.accountId}`}
                      >
                        <AmazonLoginSVG></AmazonLoginSVG>
                      </a>
                    </div>
                    <div className="social_wrpr">
                      <a
                        className="df acsa ffmm fs14 ttuc twtr button"
                        // onClick={() => {
                        //   this.auth.signIn();
                        //   this.props.continueAction();
                        //   this.props.closeAction();
                        // }}
                        href={`${BASE_URL}/auth/twitter/?redirectUrl=${window.location.href}&baseUrl=${window.location.origin}&webProfileId=${this.props.webProfileId}&accountId=${this.props.accountId}`}
                      >
                        <TwitterLoginSVG></TwitterLoginSVG>
                      </a>
                    </div>
                  </div>
                </>
              ) : undefined}

              {/* <a
					className="df acsa ffmm fs14 ttuc amz button"
					// onClick={() => {
					//   this.auth.signIn();
					//   this.props.continueAction();
					//   this.props.closeAction();
					// }}
					href={`${BASE_URL}/auth/amazon/?redirectUrl=${window.location.href}&baseUrl=${window.location.origin}`}
				>
					<span className="icon">
						<LoginAmazon></LoginAmazon>
					</span>
					Continue with Amazon
				</a> */}
              {/*  */}

              {/* <button onClick={() => this.auth.signIn()}>Sign in</button>
					<button onClick={() => this.auth.signOut()}>Sign out</button> */}
              {/* <div className="df or_wrpr">
					<span className="ffmr fs16">or</span>
				</div>
				<button className="df acsa ffmm fs14 ttuc email">
					<span className="icon"></span>
					Sign up with email
				</button>

				<div className="hr"></div>

				<p className="ffmr fs13 tac sign_in">
					Already have an account with us?{" "}
					<span className="ffmsb ">Log In</span>
				</p> */}
            </>
          ) : (
            <>
              <div className="sign_up_wrpr">
                <p>{this.state.signUpError}</p>
                <div className="inpt_line">
                  <SAInput
                    value={this.state.signupName}
                    ifHasError={this.state.signUpNameError}
                    onChange={(e) =>
                      this.updateValue(e.target.value, "signupName")
                    }
                    name="signupName"
                    label="Full Name"
                    placeholder=""
                  />
                </div>

                <div className="inpt_line">
                  <SAInput
                    value={this.state.signupEmail}
                    ifHasError={this.state.signUpEmailError}
                    onChange={(e) =>
                      this.updateValue(e.target.value, "signupEmail")
                    }
                    name="signupEmail"
                    label="Email"
                    placeholder=""
                  />
                </div>

                <div className="inpt_line">
                  <SAInput
                    type="password"
                    value={this.state.signupPassword}
                    ifHasError={this.state.signUpPasswordError}
                    onChange={(e) =>
                      this.updateValue(e.target.value, "signupPassword")
                    }
                    name="signupPassword"
                    label="Password"
                    placeholder=""
                  />
                </div>

                <div className="inpt_line">
                  <SAInput
                    type="password"
                    value={this.state.signupPasswordCopy}
                    ifHasError={
                      this.state.signUpPasswordError ||
                      this.state.signUpPasswordCopyError
                    }
                    onChange={(e) =>
                      this.updateValue(e.target.value, "signupPasswordCopy")
                    }
                    name="signupPasswordCopy"
                    label="Confirm password"
                    placeholder=""
                  />
                </div>

                <div className="ftr_wrpr">
                  <button
                    onClick={this.sendSignup}
                    disabled={this.isSignupDisabled()}
                    className="df ffmsb fs14 w100 ttuc button primary pbgc"
                  >
                    Sign Up
                  </button>
                </div>
                {/* {this.props.show ? ( */}
                <>
                  <p className="ffmr fs13 no_account_btn m-t-5">
                    Already have an account with us?{" "}
                    <span
                      onClick={this.toggleShowSignin}
                      className="ffmsb fs13 cursor_pointer"
                    >
                      Sign in
                    </span>
                  </p>
                </>
                {/* ) : undefined} */}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SignIn);
