import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddItemButton } from "../../../../components/ui/buttons/add-item-button/AddItemButton";
import AddPhone from "../../../../containers/account/settings/add-phone/AddPhone";
import { formatPhoneNumber } from "../../../../helper";
import { CURRENT_USER } from "../../../../store/actions/types";
import styles from "./PhoneNumber.module.scss";

const PhoneNumber = ({ value, onUpdate }) => {
  const dispatch = useDispatch();

  const { auth } = useSelector(({ auth }) => ({
    auth,
  }));

  const [numbers, setNumbers] = useState(undefined);
  const [numberId, setNumberId] = useState(value);
  const [ranDefault, setRanDefault] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);

  const getNumberDisplay = () => {
    if (!numbers?.length) return;
    return numbers.find((elem) => elem._id === numberId);
  };

  const getNumbers = async () => {
    let localNumbers = await axios(`/api/phone/${auth?.user?._id}`);
    setNumbers(localNumbers.data);
  };

  useEffect(() => {
    getNumbers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!auth?.user?.defaultPhoneNumber || !numbers?.length || ranDefault)
      return;
    setNumberId(auth?.user?.defaultPhoneNumber._id);
    if (!value) {
      onUpdate(auth?.user?.defaultPhoneNumber._id);
    }
    setRanDefault(true);
    // eslint-disable-next-line
  }, [numbers, auth?.user?.defaultPhoneNumber]);

  return (
    <div className={`${styles.sec}`}>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>Contact Information</h5>

      {numberId ? (
        <p className={`ffmr fs14 m-b-5`}>
          {formatPhoneNumber(getNumberDisplay()?.number)}
        </p>
      ) : (
        <>
          {showPhonePopup ? (
            <AddPhone
              close={() => setShowPhonePopup(false)}
              updatedPhone={(phoneNumber) => {
                dispatch({
                  type: CURRENT_USER,
                  payload: {
                    ...auth.user,
                    defaultPhoneNumber: phoneNumber,
                  },
                });
              }}
            />
          ) : undefined}
          <AddItemButton
            text="Add phone number"
            action={() => setShowPhonePopup(!showPhonePopup)}
          />
        </>
      )}
    </div>
  );
};

export default PhoneNumber;
