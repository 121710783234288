const FurnitureTypeSVG = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.1132 10.5927H10.901V0H0V32H31.3254V20.9507H21.1132V10.5927ZM10.2091 31.3081H0.69251V0.691892H10.2091V31.3081ZM20.4213 31.3081H10.901V11.2846H20.4213V31.3081ZM30.6335 21.6426V31.3081H21.1132V21.6426H30.6335Z"
      fill={props.fill || "black"}
    />
    <path
      d="M1.16211 10.8698H9.73786V1.5293H1.16211V10.8698ZM1.85394 2.22119H9.04653V10.1779H1.85394V2.22119Z"
      fill={props.fill || "black"}
    />
    <path
      d="M1.16211 20.698H9.73786V11.3574H1.16211V20.698ZM1.85394 12.0493H9.04653V20.0061H1.85394V12.0493Z"
      fill={props.fill || "black"}
    />
    <path
      d="M1.16211 30.53H9.73786V21.1895H1.16211V30.53ZM1.85394 21.8813H9.04653V29.8381H1.85394V21.8813Z"
      fill={props.fill || "black"}
    />
    <path
      d="M11.4688 30.4441H19.8579V12.1504H11.4688V30.4441ZM12.1606 12.846H19.166V29.7522H12.1606V12.846Z"
      fill={props.fill || "black"}
    />
    <path
      d="M8.55449 6.00893C8.55449 6.25531 8.35468 6.45512 8.1083 6.45512C7.86192 6.45512 7.66211 6.25531 7.66211 6.00893C7.66211 5.76231 7.86192 5.5625 8.1083 5.5625C8.35468 5.5625 8.55449 5.76231 8.55449 6.00893Z"
      fill={props.fill || "black"}
    />
    <path
      d="M8.66972 16.0204C8.66972 16.2668 8.46992 16.4666 8.22353 16.4666C7.97715 16.4666 7.77734 16.2668 7.77734 16.0204C7.77734 15.774 7.97715 15.5742 8.22353 15.5742C8.46992 15.5742 8.66972 15.774 8.66972 16.0204Z"
      fill={props.fill || "black"}
    />
    <path
      d="M18.5606 21.296C18.5606 21.5424 18.3608 21.7422 18.1144 21.7422C17.8678 21.7422 17.668 21.5424 17.668 21.296C17.668 21.0494 17.8678 20.8496 18.1144 20.8496C18.3608 20.8496 18.5606 21.0494 18.5606 21.296Z"
      fill={props.fill || "black"}
    />
    <path
      d="M29.6856 26.4757C29.6856 26.7221 29.4858 26.9219 29.2392 26.9219C28.9928 26.9219 28.793 26.7221 28.793 26.4757C28.793 26.2291 28.9928 26.0293 29.2392 26.0293C29.4858 26.0293 29.6856 26.2291 29.6856 26.4757Z"
      fill={props.fill || "black"}
    />
    <path
      d="M8.66972 26.0324C8.66972 26.2787 8.46992 26.4786 8.22353 26.4786C7.97715 26.4786 7.77734 26.2787 7.77734 26.0324C7.77734 25.7857 7.97715 25.5859 8.22353 25.5859C8.46992 25.5859 8.66972 25.7857 8.66972 26.0324Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default FurnitureTypeSVG;
