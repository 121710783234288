export const isOdd = (num) => num % 2;

export const saveUserToLocalstorage = (val) => {
  // console.log("val");
  localStorage.setItem("userForm", JSON.stringify(val));
};

export const getToken = () => {
  let token = localStorage.getItem("x-auth");
  return token;
};

export const formatPhoneNumber = (number) => {
  if (!number) return;
  let string = number.toString().replaceAll("+1", "");

  // console.log(string.length);

  try {
    if (string.length !== 10) return "";
    // console.log(string);
    const firstSeg = string.substring(0, 3);

    const secondSeg = string.substring(3, 6);
    const thirdSeg = string.substring(6, 10);

    return `(${firstSeg})${secondSeg}-${thirdSeg}`;
  } catch (e) {
    console.log(e);
  }
};

export const getLast4 = (str) => str.substring(str.length - 4, str.length);

export const formatMoney = (amount = 0) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    trailingZeroDisplay: "stripIfInteger",
  }).format(Number(amount));
};

export const formatFromCurrencyToMoney = (amount = 0) => {
  amount = Number(amount);
  amount = amount / 100;
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(Number(amount));
};
