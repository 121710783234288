import axios from "axios";
import _ from "lodash";
import React, { Component, createRef } from "react";
import "react-day-picker/lib/style.css";
import Media from "react-media";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { formatFromCurrencyToMoney } from "../../helper";
import { getUnitSizes, setUnitSearch } from "../../store/actions";
import DatePicker from "../datepicker/DatePicker";
import SADropDown from "../ui/form-elements/drop-down/DropDown";
// import components
import SAInput from "../ui/form-elements/stand-alone-input/Input";
import { StorageLocation } from "../ui/storage-address/StorageAddress";
// import bImg from "https://selfstorageimages.blob.core.windows.net/images/landing-image.png"
// import scss
import "./Landing.scss";

const bannerText = {
  desktop:
    " We pride ourselves in our<br /> top rated storage facilities.<br /> Come on, check them out yourself.",
  mobile: "Find Available Storage Space in our top rated storage facility",
};

class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.pageWidth = createRef();
  }

  state = {
    locationsList: [],
    searchErrors: [],
    unitsSizes: [],
    selectedDay: undefined,
    startDate: Number(new Date()),
    selectedDate: undefined,
    selectedSize: undefined,
  };

  handleDayChange = (day) => {
    this.setState({ selectedDay: day });
  };

  async componentDidMount() {
    await this.props.getUnitSizes();

    // console.log(this.props.units.unitSizes);
    let data = this.props.units?.unitSizes
      ? [...this.props.units.unitSizes]
      : [];

    // console.log(data);

    data = data.sort((a, b) => b.score - a.score);

    let transformed = await data.map((el) => ({
      display: `${el.label} staring at ${formatFromCurrencyToMoney(
        el.price
      )}/mo`,
      value: el._id,
      unitLocationId: el.locationId,
    }));

    // window.addEventListener("resize", this.handleResize);
    this.setState({
      unitsSizes: transformed,
    });
  }

  selectedStorageSize = (sizeId, locationId) => {
    this.setState({ selectedSize: sizeId, selectedUnitLocation: locationId });
  };

  getLocations = async (event) => {
    if (event.target.value.length < 5) return;
    let result = await axios.get(
      `/api/location-from-zip/${event.target.value}`
    );

    if (!result.data.data && this.state.searchErrors.indexOf("zip") === -1) {
      this.setState((prevState) => {
        return { searchErrors: [...prevState.searchErrors, "zip"] };
      });
    } else {
      if (this.state.searchErrors.indexOf("zip") !== -1)
        this.setState((prevState) => {
          let errors = [...prevState.searchErrors];
          errors.splice(errors.indexOf("zip", 1));
          // console.log(errors);
          return { searchErrors: errors };
        });
    }
    this.setState({
      locationsList:
        (result.data.data && result.data.data.postcode_localities) || [],
    });
  };

  handleResize = () => console.log("LOLO", this.pageWidth.current.offsetWidth);

  ifHasError = (val) => {
    return _.includes(this.state.searchErrors, val);
  };

  updateDatePicker = (val) => {
    this.setState({ selectedDate: Number(val) });
  };

  goToSearch = () => {
    if (!this.state.selectedDate || !this.state.selectedSize) {
      return this.setState({ triedGoing: true });
    }
    this.props.history.push(
      `/locations/?location=${this.state.selectedUnitLocation}&size=${this.state.selectedSize}&date=${this.state.selectedDate}`
    );
    // `/locations/?location=${this.state.selectedUnitLocation}&size=${this.state.selectedSize}&date=${this.state.selectedDate}`
  };

  render() {
    return (
      <div
        ref={this.pageWidth}
        className="landing_page"
        style={{
          backgroundImage: `url(${
            this.pageWidth &&
            this.pageWidth.current &&
            this.pageWidth.current.offsetWidth >= 600
              ? this.props?.websiteProfile?.theme?.landingImageURL
              : ""
          })`,
        }}
      >
        {/* {console.log(this.pageWidth)} */}
        <div className="df acsa content_wrpr">
          <div
            className="ffmb slogan"
            style={{
              backgroundImage: `url(${
                this.pageWidth &&
                this.pageWidth.current &&
                this.pageWidth.current.offsetWidth < 600
                  ? "https://selfstorageimages.blob.core.windows.net/images/landing-image.png"
                  : ""
              })`,
            }}
          >
            <Media
              queries={{
                small: "(max-width: 599px)",
                medium: "(min-width: 600px) and (max-width: 1199px)",
                large: "(min-width: 1200px)",
              }}
            >
              {(matches) => (
                <>
                  {matches.small && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: bannerText.mobile,
                      }}
                    ></p>
                  )}
                  {(matches.medium || matches.large) && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: bannerText.desktop,
                      }}
                    ></p>
                  )}
                </>
              )}
            </Media>
          </div>

          <div className="mla srch_sec_wrpr">
            <h4 className="ffqsb fs19 location_title">
              Find Available Storage Space
            </h4>
            {this.props?.websiteProfile?.locations?.length <= 1 && (
              <StorageLocation
                location={this.props?.websiteProfile?.locations[0]}
                // name={this.props?.websiteProfile?.locations[0].name}
              ></StorageLocation>
            )}

            {this.props?.websiteProfile?.locations?.length > 1 && (
              <div className="inpt_wrpr">
                <SAInput
                  onChange={this.getLocations}
                  ifHasError={this.ifHasError}
                  name="zip"
                  label="Zip code"
                  placeholder="Enter zip code"
                ></SAInput>
              </div>
            )}

            <div className="inpt_wrpr">
              {/* <SAInput
                ifHasError={this.ifHasError}
                label="Move in date"
              ></SAInput> */}

              <DatePicker
                ifHasError={this.state.triedGoing && !this.state.selectedDate}
                label="Move in date"
                placeholder="Select date"
                startDate={this.state.startDate}
                onChange={console.log}
                value={this.state.selectedDate}
                readonly={true}
                selectedDate={this.state.selectedDate}
                updateDate={this.updateDatePicker}
                earliestDate={new Date()}
              />
            </div>
            <div className="inpt_wrpr">
              <SADropDown
                label="Storage size"
                placeholder="Select size"
                click={this.selectedStorageSize}
                list={this.state.unitsSizes}
              />
            </div>

            <button
              onClick={() => {
                return this.goToSearch();
                // localStorage.setItem(
                //   "moveInDate",
                //   Number(this.state.selectedDate)
                // );
                // localStorage.setItem("size", this.state.selectedSize);
                // this.props.setUnitSearch(
                //   this.state.selectedDate,
                //   this.state.selectedSize,
                //   this.state.selectedUnitLocation
                // );
              }}
              className={`df ffmb fs14 srch_btn button pbgc ${
                !this.state.selectedSize || !this.state.selectedDate
                  ? "disabled"
                  : ""
              }`}
              // to={`/locations/?location=${this.state.selectedUnitLocation}&size=${this.state.selectedSize}&date=${this.state.selectedDate}`}
            >
              Search
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ websiteProfile, units }) => ({
  websiteProfile,
  units,
});

export default connect(mapStateToProps, { getUnitSizes, setUnitSearch })(
  withRouter(LandingPage)
);
