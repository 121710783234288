import Axios from "axios";
import React, { useEffect, useState } from "react";
import { ThemeContext } from "../../../../App";
// import scss
import "./Input.scss";

// eslint-disable-next-line
var filter = /^[a-z0-9]+\@[a-z0-9]+\.[a-z]+$/;

const ATAInput = ({ field, form, ...props }) => {
  const [list, setList] = useState([]);
  const [address, setAddress] = useState({});
  const [loadList, setLoadList] = useState(true);
  const [focused, setFocused] = useState(false);
  const [focusedLine, setFocusedLine] = useState(undefined);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (!focused || !field.value || !loadList) return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // if (!loadList) return;

    async function fetchData() {
      let results = await Axios.get(
        `/api/address/auto-complete/${field.value}`,
        {
          headers: { "Access-Control-Allow-Origin": "*" },
        }
      );
      setList(results.data.predictions);
    }
    fetchData();
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    field.value,
    focused,
    loadList,
  ]);

  useEffect(
    () => {
      // console.log(address);
      setLoadList(false);
      async function fetchPlace() {
        if (!address.place_id) return;
        let place = await Axios.get(`/api/place/${address.place_id}`);

        // let state = _.filter(place.data.result.address_components, (o) =>
        // 	_.includes(o.types, "administrative_area_level_1")
        // );

        // let zip = _.filter(place.data.result.address_components, (o) =>
        // 	_.includes(o.types, "postal_code")
        // );

        // let zipSuffix = _.filter(
        // 	place.data.result.address_components,
        // 	(o) => _.includes(o.types, "postal_code_suffix")
        // );

        // //   postal_code_suffix

        // let city = _.filter(
        // 	place.data.result.address_components,
        // 	(o) =>
        // 		_.includes(o.types, "sublocality_level_1") ||
        // 		_.includes(o.types, "neighborhood") ||
        // 		_.includes(o.types, "locality")
        // );

        // let streetNumber = _.filter(
        // 	place.data.result.address_components,
        // 	(o) => _.includes(o.types, "street_number")
        // );

        // let street = _.filter(place.data.result.address_components, (o) =>
        // 	_.includes(o.types, "route")
        // );

        // eslint-disable-next-line react-hooks/exhaustive-deps
        props.setfieldvalue(
          // eslint-disable-next-line react-hooks/exhaustive-deps
          field.name,
          `${place.data.street_number || ""} ${place.data.route || ""}`
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
        props.setfieldvalue(
          "city",
          place.data.locality ||
            place.data.neighborhood ||
            place.data.sublocality_level_1
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
        props.setfieldvalue(
          "state",
          place.data.administrative_area_level_1 || ""
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
        props.setfieldvalue(
          "zip",
          `${place?.data?.postal_code.trim() || ""} ${
            ""
            // 	zipSuffix && zipSuffix.length > 0
            // 		? " - " + zipSuffix[0].long_name
            // 		: ""
            //
          }
				`
        );
      }

      fetchPlace();

      setTimeout(() => {
        setList([]);
      }, 0);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [address]
  );
  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          {/* {console.log("::::::::::::::")}
          {console.log("::::::::::::::")}
          {console.log("::::::::::::::")}
          {console.log(list)}
          {console.log("::::::::::::::")}
          {console.log("::::::::::::::")}
          {console.log("::::::::::::::")} */}
          <style>
            {`
							.address_line:hover,
							.address_line.focused {
								background-color: ${val};
								color: white;
							}
						}
					`}
          </style>

          <div className="address_ta_wrpr">
            <div className="__inpt_wrpr df ffc">
              <label className="ffmb fs12" htmlFor={props.id}>
                {props.label}
              </label>
              <div
                className={`inner_inpt_wrpr ${props.icon ? "has_icon" : ""}`}
              >
                <input
                  className={`ffmr fs14 ${
                    list?.length > 0 && focused ? "streight_bottom" : ""
                  }`}
                  {...field}
                  {...props}
                  onFocus={() => setFocused(true)}
                  onBlur={() => setFocused(false)}
                  onKeyUp={(e) => {
                    setLoadList(true);
                    if (focusedLine !== undefined && e.key === "Enter")
                      setAddress(list[focusedLine]);
                    if (
                      list.length &&
                      (e.key === "ArrowUp" || e.key === "ArrowDown")
                    ) {
                      if (
                        e.key === "ArrowDown" &&
                        (focusedLine === undefined ||
                          focusedLine < list.length - 1)
                      ) {
                        setFocusedLine(
                          focusedLine === undefined ? 0 : focusedLine + 1
                        );
                      }

                      if (
                        e.key === "ArrowUp" &&
                        focusedLine !== undefined &&
                        focusedLine > 0
                      ) {
                        setFocusedLine(focusedLine - 1);
                      }
                    }

                    if (e.key.length === 1) {
                      setFocusedLine(undefined);
                    }

                    props.setfieldvalue(
                      // eslint-disable-next-line react-hooks/exhaustive-deps
                      field.name,
                      (e && e.target && e.target.value) || ""
                    );
                  }}
                  autoComplete="somedumbname"
                />
              </div>
            </div>

            {list.length > 0 && (
              <div className="address_list_wrpr">
                {list.map((line, i) => {
                  return (
                    <div
                      key={i}
                      onClick={() => setAddress(line)}
                      className={`ffmr fs14 address_line ${
                        focusedLine === i ? "focused" : ""
                      }`}
                      onMouseEnter={() => setFocusedLine(i)}
                    >
                      {line.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default ATAInput;
