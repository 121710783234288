import React from "react";

export const PlusSVG = (props) => {
	return (
		<svg
			width={props.height}
			height={props.width}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10.8965 0.99694L8.90247 0.99694V8.90239L0.997021 8.90239V10.8964L8.90247 10.8964V18.8019L10.8965 18.8019L10.8965 10.8964H18.802V8.90239H10.8965L10.8965 0.99694Z"
				fill={props.fill}
			/>
		</svg>
	);
};
