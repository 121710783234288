import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ArrowSvg } from "../../assets/images/svgs/arrow.svg";
import { NotFoundSVG } from "../../assets/images/svgs/not-found.svg";
import styles from "./AlreadyReserved.module.scss";

const AlreadyReserved = ({ isDraft, reservationUserId, reservationId }) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  // eslint-disable-next-line
  const [isReservationUser, setIsReservationUser] = useState(
    auth?.user._id === reservationUserId
  );

  // eslint-disable-next-line
  const [isReservationDraft, setIsReservationDraft] = useState(isDraft);

  console.log(auth?.user._id === reservationUserId);
  return (
    <div className={`df acc ffc ${styles.alreadyReserved}`}>
      {/* {console.log("****************")}
      {console.log(isReservationDraft, unit)}
      {console.log("****************")} */}
      <NotFoundSVG fill="#15698e" fillOpacity="1" />
      <p className={`ffqsb fs22 m-t-20 ${styles.title}`}>Ooops!</p>

      <p className={`ffmr fs14 m-t-20`}>
        {isReservationUser && isReservationDraft
          ? "You are already in the middle of reserving this unit"
          : isReservationUser
          ? "You already reserved this unit"
          : "This unit is no longer available"}
      </p>
      <p className={`ffmr fs14 m-t-4`}>
        {isReservationUser && isReservationDraft ? (
          "Please click the button below to complete your reservation"
        ) : isReservationUser ? (
          <span>
            <Link
              className={styles.reservationTextLink}
              to={`/account/rentals/${reservationId}`}
            >
              Click here
            </Link>{" "}
            to view your reservation
          </span>
        ) : (
          "Please check out our other available units"
        )}
      </p>

      <Link
        className={`df ffmsb fs14 p-l-30 p-r-30 m-t-50 ttuc button primary pbgc`}
        to={`${
          isReservationUser && isReservationDraft
            ? `/v2/reserve/?reservationid=${reservationId}`
            : "/locations"
        }`}

        // "/reserve/summary/:reservationId/:payMethodId?/:addressId?"
      >
        {isReservationUser && isReservationDraft
          ? "Complete my reservation"
          : " See other available units"}{" "}
        <span className={`m-l-10`}>
          <ArrowSvg fill="white" />
        </span>
      </Link>
    </div>
  );
};

export default AlreadyReserved;
