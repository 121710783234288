import React from "react";

export const CloseXSVG = (props) => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.7778 3.22222C16.6991 1.14352 13.9398 0 11 0C8.06019 0 5.30093 1.14352 3.22222 3.22222C1.14352 5.30093 0 8.06019 0 11C0 13.9398 1.14352 16.6991 3.22222 18.7778C5.30093 20.8565 8.06019 22 11 22C13.9398 22 16.6991 20.8565 18.7778 18.7778C20.8565 16.6991 22 13.9398 22 11C22 8.06019 20.8565 5.30093 18.7778 3.22222ZM17.8935 17.8935C16.0509 19.7361 13.6019 20.75 11 20.75C8.39815 20.75 5.94907 19.7361 4.10648 17.8935C0.305555 14.0926 0.305555 7.90741 4.10648 4.10648C5.94907 2.26389 8.39815 1.25 11 1.25C13.6019 1.25 16.0509 2.26389 17.8935 4.10648C21.6944 7.90741 21.6944 14.0926 17.8935 17.8935Z"
				fill="#1F2041"
				fillOpacity="0.5"
			/>
			<path
				d="M15.8472 6.15278C15.6019 5.90741 15.2083 5.90741 14.963 6.15278L11 10.1157L7.03704 6.15278C6.79167 5.90741 6.39815 5.90741 6.15278 6.15278C5.90741 6.39815 5.90741 6.79167 6.15278 7.03704L10.1157 11L6.15278 14.963C5.90741 15.2083 5.90741 15.6019 6.15278 15.8472C6.27315 15.9676 6.43519 16.0324 6.59259 16.0324C6.75 16.0324 6.91204 15.9722 7.03241 15.8472L10.9954 11.8843L14.9583 15.8472C15.0787 15.9676 15.2407 16.0324 15.3981 16.0324C15.5602 16.0324 15.7176 15.9722 15.838 15.8472C16.0833 15.6019 16.0833 15.2083 15.838 14.963L11.8843 11L15.8472 7.03704C16.0926 6.79167 16.0926 6.39815 15.8472 6.15278Z"
				fill="#1F2041"
				fillOpacity="0.5"
			/>
		</svg>
	);
};
