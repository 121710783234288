import React, { Component } from "react";
import SAInput from "../ui/form-elements/stand-alone-input/Input";
import moment from "moment";

//import scss
import "./DatePicker.scss";
import CalPortal from "./CalPortal";
import { createRef } from "react";

import { ThemeContext } from "../../App";
import { ArrowSvg } from "../../assets/images/svgs/arrow.svg";

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.datePickerPos = createRef(null);
    this.datePickerPopup = createRef(null);
  }

  state = {
    month: undefined,
    currentMonthDay: undefined,
    showPopup: false,
  };

  getMonth = async (coreDate) => {
    // console.log(this.props.startDate, this.props.selectedDate);
    let startOfMonth = moment(coreDate).startOf("month").startOf("week");
    let endOfMonth = moment(coreDate).endOf("month").endOf("week");

    let weeksLength = endOfMonth.diff(startOfMonth, "weeks");

    // console.log(weeksLength);

    let weeks = [];
    let currentDate = startOfMonth;
    for (let i = 0; i < weeksLength + 1; i++) {
      //   let days = [];
      let week = [];

      for (let i = 0; i < 7; i++) {
        week.push(currentDate);
        currentDate = moment(currentDate).add(1, "day");
      }
      weeks.push(week);
    }

    await this.setState({ month: weeks, currentMonthDay: weeks[1][0] });
  };

  moveDate = async (val) => {
    let date =
      (await val) === "next"
        ? moment(this.state.currentMonthDay).add(1, "month")
        : moment(this.state.currentMonthDay).subtract(1, "month");

    await this.setState({ currentMonthDay: date });

    this.getMonth(this.state.currentMonthDay);
  };

  togglePopup = () =>
    this.setState((prevState) => ({
      showPopup: !prevState.showPopup,
    }));

  isSelected = (day) =>
    Number(moment(day)) === Number(moment(this.props.selectedDate));
  componentDidMount() {
    this.getMonth(
      this.props.selectedDate ? this.props.selectedDate : this.props.startDate
    );
  }

  onRefChange = (node) => {
    // same as Hooks example, re-render on changes
    this.setState({ datePickerPopup: node });
  };

  render() {
    return (
      <ThemeContext.Consumer>
        {(val) => (
          <>
            <style>
              {`
							.day_wrpr.selected {
								background-color:${val};
								color: white;
							}
							`}
            </style>
            <div className="__date_picker_wrpr">
              <div ref={this.datePickerPos}>
                <SAInput
                  hideLabel={this.props.hideLabel}
                  ifHasError={this.props.ifHasError}
                  label={this.props.label}
                  onChange={this.props.onChange}
                  readonly={this.props.readonly}
                  value={
                    this.props.value
                      ? moment(this.props.value).format("MM/DD/YYYY")
                      : ""
                  }
                  formatType="date"
                  onClick={this.togglePopup}
                  updateButtonAction={this.props.updateAction}
                  disabled={this.props.disabled}
                  updateDisabled={this.props.updateDisabled}
                  field={this.props.field}
                  placeholder={this.props.placeholder}
                  icon={this.props.icon}
                />
              </div>
              {/* {console.log(this.props.disabled)} */}
              {this.state.showPopup && (
                <>
                  <CalPortal>
                    <div
                      className="calenderOverlay"
                      onClick={this.togglePopup}
                    ></div>
                    <div
                      ref={this.onRefChange}
                      className="df ffc acc __date_picker_popup"
                      style={{
                        position: "fixed",
                        top: `${
                          this.state.datePickerPopup?.clientHeight +
                            this.state.datePickerPopup?.getBoundingClientRect()
                              ?.top >
                          window?.innerHeight
                            ? "initial"
                            : `${
                                this.datePickerPos.current.getBoundingClientRect()
                                  .top +
                                this.datePickerPos.current.clientHeight +
                                10
                              }px`
                        }`,
                        bottom: `${
                          this.state.datePickerPopup?.clientHeight +
                            this.state.datePickerPopup?.getBoundingClientRect()
                              ?.top >
                          window?.innerHeight
                            ? "10px"
                            : `initial`
                        }`,
                        // top: `${this.datePickerPopup?.current?.clientHeight}px`,
                        left: `${
                          this.datePickerPos.current.getBoundingClientRect()
                            .left +
                          +this.datePickerPos.current.clientWidth / 2
                        }px`,
                        zIndex: 99999999,
                      }}
                    >
                      {console.log(
                        12345,
                        this.state.datePickerPopup?.clientHeight +
                          this.state.datePickerPopup?.getBoundingClientRect()
                            ?.top >
                          window?.innerHeight
                      )}
                      <div className="df acsa jcsb  __date_picker_hdr">
                        <div
                          className="df acc btn prev"
                          onClick={() => this.moveDate("prev")}
                        >
                          <ArrowSvg fill={val}></ArrowSvg>
                        </div>
                        <p className="tac ffqsb fs19">
                          {moment(this.state.currentMonthDay).format(
                            "MMMM YYYY"
                          )}
                        </p>
                        <div
                          className="df acc btn next"
                          onClick={() => this.moveDate("next")}
                        >
                          <ArrowSvg fill={val}></ArrowSvg>
                        </div>
                      </div>
                      <div className="df weekdays_wrpr">
                        <div className="day_wrpr ffmb fs12 ptc">S</div>
                        <div className="day_wrpr ffmb fs12 ptc">M</div>
                        <div className="day_wrpr ffmb fs12 ptc">T</div>
                        <div className="day_wrpr ffmb fs12 ptc">W</div>
                        <div className="day_wrpr ffmb fs12 ptc">T</div>
                        <div className="day_wrpr ffmb fs12 ptc">F</div>
                        <div className="day_wrpr ffmb fs12 ptc">S</div>
                      </div>
                      {this.state.month &&
                        this.state.month.length &&
                        this.state.month.map((week, i) => (
                          <div className="df week_wrpr" key={i}>
                            {week.map((day, idx) => (
                              <div
                                key={idx}
                                className={`day_wrpr ffmr fs12 ${
                                  this.isSelected(day) ? "selected" : ""
                                }  ${
                                  moment(day)
                                    .startOf("day")
                                    .diff(
                                      moment(new Date()).startOf("day"),
                                      "day"
                                    ) === 0
                                    ? "today"
                                    : ""
                                } ${
                                  moment(day)
                                    .startOf("day")
                                    .diff(
                                      moment(this.props.earliestDate).startOf(
                                        "day"
                                      ),
                                      "day"
                                    ) < 0
                                    ? "disabled"
                                    : ""
                                }`}
                                onClick={async () => {
                                  await this.props.updateDate(day);
                                  this.togglePopup();
                                }}
                              >
                                {moment(day).format("D")}
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                  </CalPortal>
                </>
              )}
            </div>
          </>
        )}
      </ThemeContext.Consumer>
    );
  }
}

export default DatePicker;
