import NavSVG from "../../../../../assets/images/svgs/nav-arrow.svg";

import { ThemeContext } from "../../../../../App";

import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";
import { AddItemButton } from "../../../../../components/ui/buttons/add-item-button/AddItemButton";
import UnitIconSVG from "../../../../../assets/images/svgs/unit-icon.svg";
import styles from "./Rentals.module.scss";
import ordinal from "ordinal";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

SwiperCore.use([Navigation]);

const Rentals = (props) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));
  const [reservations, setReservations] = useState([]);

  const getReservations = async () => {
    let data = await axios.get(`/api/user-reservations/${auth.user._id}`);

    setReservations(data.data.data);
  };

  useEffect(() => {
    if (!auth?.user) return;
    // console.log(auth);
    getReservations();
    // eslint-disable-next-line
  }, [auth]);

  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          <div className={styles.rentals}>
            <div className={`df acsa ${styles.hdr}`}>
              <h4 className={`ffqsb fs20`}>Rentals</h4>
              {/* <div className={`mla ffqsb fs12 ${styles.seeAll}`}>
                See all <NavSVG></NavSVG>
              </div> */}
            </div>

            <Swiper
              centeredSlidesBounds
              slidesPerView={"auto"}
              spaceBetween={24}
              navigation={true}
              pagination={{ clickable: true }}
              className={`${styles.slider} customized`}
              // initialSlide={props.initialSlide}
            >
              {reservations.map((itm, idx) => (
                <SwiperSlide
                  className={styles.slide}
                  style={{ width: "318px" }}
                  key={idx}
                >
                  <Link to={`/account/rentals/${itm._id}`}>
                    <div className={`${styles.slideContent}`}>
                      <div className={`df acsa ${styles.slideHdr}`}>
                        <UnitIconSVG></UnitIconSVG>
                        <p className={`ffmb fs16 m-l-8`}>
                          Unit <span className={`ttuc`}>{itm.unit.unitId}</span>
                        </p>
                        <div className={`mla ${styles.slideActionsWrpr}`}>
                          <AddItemButton text="Make a Payment" />
                        </div>
                      </div>
                      <div className={`df acsa ${styles.line}`}>
                        <div className={`df acc ${styles.icon}`}></div>
                        <p className={`ffmr fs14`}>
                          Rate: ${itm?.unit?.unitSize?.price?.toFixed(2)}
                        </p>
                      </div>
                      <div className={`df acsa ${styles.line}`}>
                        <div className={`df acc ${styles.icon}`}></div>
                        <p className={`ffmr fs14`}>
                          Balance: ${itm?.reservationGrandTotal?.toFixed(2)}
                        </p>
                      </div>
                      <div className={`df acsa ${styles.line}`}>
                        <div className={`df acc ${styles.icon}`}></div>
                        <p className={`ffmr fs14`}>
                          Billing Cycle:{" "}
                          {itm.invoiceDay !== undefined
                            ? ordinal(itm.invoiceDay)
                            : "N/A"}{" "}
                          of month
                        </p>
                      </div>
                    </div>

                    <p
                      className={`df acsa ffqsb fs14 ptc hidden_text ${styles.viewDetails}`}
                    >
                      View Unit Details{" "}
                      <NavSVG fill={val} fillOpacity={1}></NavSVG>
                    </p>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default Rentals;
