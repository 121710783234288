const UnitIconSVG = (props) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 0V3H20V16H12V12H8V16H0V3H3V0H17ZM14 14H18V5H15V2H5V5H2V14H6V10H14V14Z"
      fill="#1F2041"
      fillOpacity="0.8"
    />
  </svg>
);

export default UnitIconSVG;
