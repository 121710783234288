import Axios from "axios";
import axiosDefaults from "axios/lib/defaults";
import { Field, Formik } from "formik";
// import { useState } from "react";
import ATAInput from "../components/ui/form-elements/stand-alone-input/addressTypeAheadInput";
import FMKInput from "../components/ui/form-elements/stand-alone-input/formikInput";
import * as Yup from "yup";
import { getToken } from "../helper";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GET_UNIT } from "../store/actions/types";
import axios from "axios";

const InfoSchema = Yup.object().shape({
  fullName: Yup.string()
    .min(2, "Too Short!")
    .max(55, "Too Long!")
    .required("Required"),
  phone: Yup.string().required("Phone is required"),
  email: Yup.string().required("Email is required"),
  address: Yup.string().required("Enter address"),
  city: Yup.string().required("Enter city"),
  state: Yup.string().required("Enter state"),
  zip: Yup.string().required("Enter zip"),
  // address_2: Yup.boolean().required("Setect availability")
});

if (process.env.NODE_ENV && process.env.NODE_ENV === "production") {
  axiosDefaults.baseURL = "https://api.slfstrg.com/";
}

// const FMKInput = ({ field, form, ...props }) => (
// const renderCleaveField = ({ field, form, handleChange, ...props }) => <></>;

const Page1 = (props) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   const unblock = history.block((location, action) => {
  //     if (true) {
  //       return window.confirm("Navigate Back?");
  //     }
  //     return true;
  //   });

  //   return () => {
  //     unblock();
  //   };
  // }, []);

  const { auth, websiteProfile, reservationUnit } = useSelector(
    ({ auth, websiteProfile, reservationUnit }) => ({
      auth,
      websiteProfile,
      reservationUnit,
    })
  );

  const getUnitDetails = async () => {
    let unit = await axios.get(
      `/api/reserve-unit/?unitid=${params.unitId}&moveindate=${params.selectedDate}`
    );

    dispatch({
      type: GET_UNIT,
      payload: unit.data.data,
    });
  };

  useEffect(() => {
    getUnitDetails();
    // eslint-disable-next-line
  }, []);

  // const [currentAction, setCurrentAction] = useState("");
  // const [errorSaving, setErrorSaving] = useState(false);

  // state = {
  //   addressSearch: "",
  // };

  // searchAddress = async (val) => {
  //   setState({ addressSearch: val });
  //   let results = await Axios.get(`/api/address/auto-complete/${val}`, {
  //     headers: { "Access-Control-Allow-Origin": "*" },
  //   });
  //   setState({ searchResults: results.data.predictions });
  // };

  // getPlace = async (id) => {
  //   // setState({ addressSearch: val });
  //   let place = await Axios.get(`/api/place/${id}`);
  //   console.log(place.data.result.address_components);
  //   let state = _.filter(place.data.result.address_components, (o) =>
  //     // console.log(o)
  //     _.includes(o.types, "administrative_area_level_1")
  //   );

  //   let zip = _.filter(place.data.result.address_components, (o) =>
  //     // console.log(o)
  //     _.includes(o.types, "postal_code")
  //   );

  //   let city = _.filter(
  //     place.data.result.address_components,
  //     (o) =>
  //       _.includes(o.types, "sublocality_level_1") ||
  //       _.includes(o.types, "neighborhood") ||
  //       _.includes(o.types, "locality")
  //   );

  //   let streetNumber = _.filter(place.data.result.address_components, (o) =>
  //     _.includes(o.types, "street_number")
  //   );

  //   let street = _.filter(place.data.result.address_components, (o) =>
  //     _.includes(o.types, "route")
  //   );

  //   props.setFieldValue(
  //     "address",
  //     `${
  //       (streetNumber && streetNumber.length && streetNumber[0].long_name) || ""
  //     } ${(street && street.length > 0 && street[0].long_name) || ""}`
  //   );

  //   props.setFieldValue(
  //     "city",
  //     (city && city.length > 0 && city[city.length - 1].long_name) || ""
  //   );

  //   props.setFieldValue(
  //     "state",
  //     (state && state.length > 0 && state[0].short_name) || ""
  //   );

  //   props.setFieldValue(
  //     "zip",
  //     (zip && zip.length > 0 && zip[0].long_name) || ""
  //   );

  // };
  // setVal = (val) => props.setFieldValue("address", "LPLP");

  const handleSubmit =
    ({ next }) =>
    async (values) => {
      let reservation;
      await Promise.all([
        Axios.post(`/api/phone`, {
          number: values.phone,
          accountId: websiteProfile.account,
          webUserId: auth.user._id,
          primary: true,
        }),
        Axios.post(`/api/address`, {
          line1: values.address,
          city: values.city,
          state: values.state,
          zip: values.zip,
          line2: values.address_2,
          accountId: websiteProfile.account,
          webUserId: auth.user._id,
          primary: true,
        }),

        (reservation = await Axios.post(
          "/api/reservation/false/?setupPrice=true",
          {
            user: auth?.user?._id,
            unit: params.unitId,
            locationId: params.locationId,
            webProfileId: auth.user._id,
            accountId: websiteProfile.account,
            expectedMoveInDate: params.selectedDate,
            initialPayment: next ? undefined : false,
            isDraft: next,
            // address: auth.user.defaultAddress._id,
            // phoneNumber: auth.user.defaultPhoneNumber._id,
          },
          {
            headers: {
              "x-auth": getToken(),
            },
          }
        )),
      ]);
      // console.log("All Done", reservation);

      if (!next && reservation.data.message === "ok")
        return history.push(`/thank-you/${reservation?.data?.data?._id}`);

      if (next && reservation.data.message === "ok") {
        console.log("OOOOOK");
        return history.push(`/reserve/payment/${reservation?.data?.data?._id}`);
      }
      // return history.push(`/thank-you/${reservation?.data?.data?._id}`);

      // locationId: "5def08c3bbd5014276c9d08c";
      // selectedDate: "1627617600000";
      // sizeId: "XL";
      // unitId: "5fbb4511d4038b2b9c411128";
    };

  return (
    <>
      {reservationUnit?.reserved && !reservationUnit?.isDraft ? (
        "Already reserved"
      ) : (
        <>
          {props.page !== "summary" && (
            <div className="df ffqsm fs16 sec_hdr">
              Billed monthly{" "}
              <span className="fwbold">
                ${reservationUnit?.unitSize?.price.toFixed(2)}
              </span>
            </div>
          )}
          <div>
            <Formik
              initialValues={{
                fullName:
                  (props.reservation && props.reservation.fullName) ||
                  auth.user.fullName ||
                  "",
                phone:
                  (props.reservation && props.reservation.phone) ||
                  auth.user.phoneNumber,
                phoneDefault: true,
                addressDefault: true,
                email:
                  (props.reservation && props.reservation.email) ||
                  auth.user.email,
                comment: (props.reservation && props.reservation.comment) || "",
                address:
                  (props.reservation &&
                    props.reservation.address &&
                    props.reservation.address.line1) ||
                  "",
                city:
                  (props.reservation &&
                    props.reservation.address &&
                    props.reservation.address.city) ||
                  "",
                state:
                  (props.reservation &&
                    props.reservation.address &&
                    props.reservation.address.state) ||
                  "",
                zip:
                  (props.reservation &&
                    props.reservation.address &&
                    props.reservation.address.zip) ||
                  "",
                address_2:
                  (props.reservation &&
                    props.reservation.address &&
                    props.reservation.address.line2) ||
                  "",
              }}
              validationSchema={InfoSchema}
              // START SUBMIT ********
              onSubmit={
                handleSubmit({ next: false })
                // async (values) => {
                // /api/address/:userId/:accountId
                // // setState({
                // //   currentAction: "Saving your phone number",
                // // });
                // const phone = await Axios.post(`/api/phone`, {
                //   number: values.phone,
                //   accountId: props.websiteProfile.account,
                //   webUserId: auth.user._id,
                //   primary: true,
                // });
                // // if (!phone?.data?.message === "ok")
                // // return setState({
                // //   errorSaving: true,
                // // });
                // // setState({
                // //   currentAction: "Saving your address",
                // // });
                // const address = await Axios.post(`/api/address`, {
                //   line1: values.address,
                //   city: values.city,
                //   state: values.state,
                //   zip: values.zip,
                //   line2: values.address_2,
                //   accountId: props.websiteProfile.account,
                //   webUserId: auth.user._id,
                //   primary: true,
                // });
                // // if (!address?.data?.message === "ok")
                // //   return setState({
                // //     errorSaving: true,
                // //   });
                // await props.getUser();
                // // if (state.done) {
                // let reservation = await Axios.post(
                //   "/api/reservation/false",
                //   {
                //     user: auth?.user?._id,
                //     unit: props.match.params.unitId,
                //     locationId: props.match.params.locationId,
                //     webProfileId: auth.user._id,
                //     accountId: props.websiteProfile.account,
                //     expectedMoveInDate: props.match.params.selectedDate,
                //     initialPayment: false,
                //     address: auth.user.defaultAddress._id,
                //     phoneNumber: auth.user.defaultPhoneNumber._id,
                //   },
                //   {
                //     headers: {
                //       "x-auth": getToken(),
                //     },
                //   }
                // );
                // return props.history.push(
                //   `/thank-you/${reservation?.data?.data?._id}`
                // );
                // // }
                // // setState({
                // //   currentAction: "",
                // // });
                // props.history.push(
                //   `/reserve/payment/${props.match.params.sizeId}/${props.match.params.unitId}/${props.match.params.selectedDate}/${props.match.params.locationId}`
                // );
                // }
              }

              // END SUBMIT ********
            >
              {(props) => (
                <form onSubmit={props.handleSubmit}>
                  <div className="df ffc page1">
                    <div className="inpt_line">
                      <div className="input_wrpr">
                        <Field
                          // icon="user"
                          name="fullName"
                          label="full name"
                          id="fullName"
                          component={FMKInput}
                          onChange={props.onChange}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                      {/* <div className="input_wrpr"> */}
                      {/* <Field
                // icon="user"
                name="firstName"
                label="first name"
                id="firstName"
                component={FMKInput}
                onChange={props.onChange}
                placeholder="Type here..."
              ></Field> */}

                      {/* <div className="input_wrpr">
              <Field
                // icon="user"
                name="lastName"
                label="last name"
                id="lastName"
                component={FMKInput}
                placeholder="Type here..."
              ></Field>
            </div> */}
                    </div>
                    <div className="inpt_line">
                      <div className="input_wrpr">
                        <Field
                          // icon="phone"
                          inputStyle="phone"
                          name="phone"
                          label="Phone"
                          id="phone"
                          handleChange={props.handleChange}
                          component={FMKInput}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                    </div>

                    <div className="inpt_line">
                      <div className="input_wrpr">
                        <Field
                          disabled={true}
                          // icon="email"
                          name="email"
                          label="Email"
                          id="email"
                          component={FMKInput}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                    </div>
                    {/* <div className="address_ta_wrpr">
              <input
                type="text"
                value={state.addressSearch}
                onChange={e => searchAddress(e.target.value)}
              ></input>
              <div className="address_list_wrpr">
                {state.searchResults &&
                  state.searchResults.length &&
                  state.searchResults.map(result => (
                    <div
                      className="address_line"
                      onClick={() => {
                        getPlace(result.place_id);
                        // TouchList.props.setFieldValue("address", "");
                      }}
                    >
                      {result.description}
                    </div>
                  ))}
              </div>
            </div> */}

                    <div className="inpt_line">
                      <div className="input_wrpr">
                        <Field
                          name="address"
                          label="Address"
                          id="address"
                          component={ATAInput}
                          placeholder="Type here..."
                          setfieldvalue={props.setFieldValue}
                        ></Field>
                      </div>
                    </div>

                    <div className="inpt_line">
                      <div className="input_wrpr">
                        <Field
                          // icon="address_2"
                          name="address_2"
                          label="Address line 2"
                          id="address_2"
                          component={FMKInput}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                    </div>

                    <div className="df inpt_line has_3">
                      <div className="input_wrpr">
                        <Field
                          // icon="city"
                          name="city"
                          label="city"
                          id="city"
                          component={FMKInput}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                      <div className="input_wrpr">
                        <Field
                          // icon="state"
                          name="state"
                          label="state"
                          id="state"
                          component={FMKInput}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                      <div className="input_wrpr">
                        <Field
                          // icon="zip"
                          name="zip"
                          label="zip"
                          id="zip"
                          component={FMKInput}
                          placeholder="Type here..."
                        ></Field>
                      </div>
                    </div>
                    {/* <div className="right_sec">
            <Field
              name="comment"
              id="comment"
              label="Comment"
              component={FMKTextArea}
            ></Field>
          </div> */}
                  </div>

                  <div className="df jcfe reservation_ftr">
                    <button
                      className="ffqsb tac ttuc fs14 button secondary_primary ptc"
                      type="submit"
                    >
                      Pay Later
                    </button>
                    <button
                      className={`df ffmb fs14 ttuc fwbold srch_btn button primary`}
                      type="button"
                      onClick={(event) =>
                        handleSubmit({ next: true })(props.values)
                      }
                    >
                      Pay now
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  );
};

export default Page1;
