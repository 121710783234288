const DocumentTypeSVG = (props) => (
  <svg
    width="26"
    height="32"
    viewBox="0 0 26 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.8985 8.15693C25.8552 8.05088 25.7905 7.955 25.7087 7.87485L18.0609 0.249397C17.9808 0.167558 17.8849 0.102907 17.7788 0.0595665C17.6844 0.0200991 17.5829 -0.000239613 17.4805 2.12997e-06H2.71186C1.99252 2.12997e-06 1.30293 0.285718 0.794158 0.79416C0.285685 1.30263 0 1.99246 0 2.71187V29.2881C0 30.0073 0.285716 30.6971 0.794158 31.2056C1.30287 31.7143 1.99246 32 2.71186 32H23.2458C23.9651 32 24.6547 31.7143 25.1635 31.2056C25.672 30.6971 25.9577 30.0073 25.9577 29.2881V8.46102C25.9579 8.35666 25.9376 8.25351 25.8981 8.15715L25.8985 8.15693ZM18.3053 2.76035L23.1866 7.64171H18.3053V2.76035ZM24.331 29.266C24.331 29.5536 24.2167 29.8296 24.0133 30.033C23.8099 30.2364 23.5339 30.3507 23.2462 30.3507H2.71229C2.42464 30.3507 2.1486 30.2364 1.94522 30.033C1.74183 29.8296 1.62754 29.5536 1.62754 29.266V2.68969C1.62754 2.40204 1.74183 2.12601 1.94522 1.92262C2.1486 1.71923 2.42464 1.60494 2.71229 1.60494H16.6621V8.46054C16.6621 8.90969 17.0263 9.2741 17.4757 9.2741H24.3313L24.331 29.266Z"
      fill={props.fill || "black"}
    />
    <path
      d="M5.30965 12.2951H12.9791C13.4285 12.2951 13.7927 11.9309 13.7927 11.4815C13.7927 11.0324 13.4285 10.668 12.9791 10.668H5.30965C4.8605 10.668 4.49609 11.0324 4.49609 11.4815C4.49609 11.9309 4.8605 12.2951 5.30965 12.2951V12.2951Z"
      fill={props.fill || "black"}
    />
    <path
      d="M4.49609 15.9776C4.49609 16.427 4.8605 16.7912 5.30965 16.7912H20.648C21.0971 16.7912 21.4615 16.427 21.4615 15.9776C21.4615 15.5282 21.0971 15.1641 20.648 15.1641H5.30965C4.8605 15.1641 4.49609 15.5282 4.49609 15.9776V15.9776Z"
      fill={props.fill || "black"}
    />
    <path
      d="M20.648 19.6602H5.30965C4.8605 19.6602 4.49609 20.0243 4.49609 20.4737C4.49609 20.9229 4.8605 21.2873 5.30965 21.2873H20.648C21.0971 21.2873 21.4615 20.9229 21.4615 20.4737C21.4615 20.0243 21.0971 19.6602 20.648 19.6602Z"
      fill={props.fill || "black"}
    />
    <path
      d="M20.648 24.1523H5.30965C4.8605 24.1523 4.49609 24.5165 4.49609 24.9659C4.49609 25.4151 4.8605 25.7795 5.30965 25.7795H20.648C21.0971 25.7795 21.4615 25.4151 21.4615 24.9659C21.4615 24.5165 21.0971 24.1523 20.648 24.1523Z"
      fill={props.fill || "black"}
    />
  </svg>
);

export default DocumentTypeSVG;
