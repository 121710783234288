import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Portal from "../../../../components/ui/portal/Portal";
import dayjs from "dayjs";
import AmexImg from "../../../../assets/images/credit-cards/american-express.svg";
import CitiImg from "../../../../assets/images/credit-cards/citi.svg";
import Discover from "../../../../assets/images/credit-cards/discover.svg";
import MasterImg from "../../../../assets/images/credit-cards/mastercard.svg";
import VisaImg from "../../../../assets/images/credit-cards/visa.svg";
import { CircleCheckSvg } from "../../../../assets/images/svgs/circle-check.svg";

import CurrencyInput from "../../../../components/ui/form-elements/stand-alone-input/CurrencyInput";

// import * as creditCards from "../../../../assets/images/credit-cards";

import styles from "./Payment.module.scss";

import { ThemeContext } from "../../../../App";
import { ADD_TOAST } from "../../../../store/actions/types";
import { formatFromCurrencyToMoney } from "../../../../helper";

const images = {
  Visa: VisaImg,
  Amex: AmexImg,
  Citi: CitiImg,
  MasterCard: MasterImg,
  Discover,
};

const PaymentsPopup = (props) => {
  const dispatch = useDispatch();

  const params = useParams();

  const { auth } = useSelector(({ auth }) => ({
    auth,
  }));

  // console.log(params);

  const [loading, setLoading] = useState(true);
  const [balances, setBalances] = useState([]);
  const [selectedPayMethod, setSelectedPayMethod] = useState();
  const [payAmount, setPayAmount] = useState(0);
  const [processDisabled, setProcessDisabled] = useState(false);
  // eslint-disable-next-line
  const [processError, setProcessError] = useState(false);
  const [payMethods, setPayMethods] = useState([]);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const getBalances = async () => {
    let balances = await Axios.get(
      `/api/reservation-balance/${auth.user._id}/${params.id}`
    );
    setBalances(balances.data.data[0]);
    setLoading(false);
  };

  const getPayMethods = async () => {
    let methods = await Axios.get(`/api/user-pay-methods/${auth.user._id}`);
    setPayMethods(methods.data.paymentMethods);
  };

  useEffect(() => {
    getBalances();
    getPayMethods();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!balances) return;
    setPayAmount(balances.reservationTotalOutstanding);
  }, [balances]);

  const processPayment = async () => {
    setProcessError(false);
    setProcessDisabled(true);
    let payment = await Axios.post(
      `/api/portal-payment/?reservationid=${params.id}`,
      {
        amount: payAmount,
        payMethod: selectedPayMethod,
        userId: auth.user._id,
        location: props?.unit?.unitLocation,
        account: auth.user.account,
      }
    );

    if (payment.data?.message === "payment processed") {
      setProcessDisabled(false);
      setPaymentSuccess(true);
      dispatch({
        type: ADD_TOAST,
        payload: {
          type: "success",
          message: `Your payment of ${formatFromCurrencyToMoney(
            payAmount
          )} was processed.`,
          id: Number(new Date()),
        },
      });
      setTimeout(() => {
        props.close();
      }, 1000);
    } else {
      dispatch({
        type: ADD_TOAST,
        payload: {
          type: "error",
          message: `We had a issue processing your payment.`,
          errors: [payment.data.message],
          id: Number(new Date()),
        },
      });
      setProcessError(true);
      setProcessDisabled(false);
    }
  };

  const title = (
    <p>
      Payment
      <span style={{ fontSize: "0.7em" }}>
        {" "}
        (unit {props.unit?.unitId?.toUpperCase()})
      </span>
    </p>
  );

  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          <Portal
            zIndex="101"
            width="424px"
            height="auto"
            title={title}
            noCustomScrollbar
            close={() => props.close()}
            opacity="0.4"
          >
            {paymentSuccess ? (
              <div className={`df ffc ${styles.successWrpr}`}>
                <div className={`df ffc acc ${styles.messageWrpr}`}>
                  <div className={styles.circleWrpr}>
                    <CircleCheckSvg height="44" width="44" fill={val} />
                  </div>

                  <h5 className={`ptc ffqsb fs22 m-t-10`}>
                    Thank You, {auth.user.fullName?.split(" ")[0]}
                  </h5>

                  <p className={`ffmr fs14 m-t-6 m-b-15`}>
                    Your payment was processed successfully.
                  </p>
                </div>
                <div className={`df mta ffc jcfe ftr m-b-10`}>
                  <button
                    className={`df ffmsb  fs14 ttuc button primary`}
                    onClick={() => {
                      // props.selectAction(payMethod);
                      props.close();
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            ) : !loading ? (
              <div className={`${styles.paymentsPopup}`}>
                <div className={`${styles.rentals_list}`}>
                  <div className="df acsa hdr ffnsb fs10 ttuc">
                    {/* <div className={`ffmb fs12 m-b-10 ttuc ${styles.title}`}>
                Rentals
              </div> */}
                    {/* <div className="df acsa mla actns_wrpr">
								<p className="">All</p>
								<p className="">None</p>
							</div> */}
                  </div>
                  <div className={`${styles.list_wrpr}`}>
                    {balances?.invoices?.length ? (
                      balances.invoices.map((invoiceItem, idx) => (
                        <label
                          key={idx}
                          className={`ffnr fs14 df acsa ${styles.line}`}
                        >
                          {/* ${
                    selected.includes(reservation._id) ? "selected" : ""
                  } */}
                          <p className={`ffmr fs14`}>
                            {dayjs(invoiceItem.invoice.createdAt).format(
                              "MMM/DD/YYYY"
                            )}
                          </p>
                          <p className={`ffmr fs14 mla`}>
                            $
                            {invoiceItem.invoiceItemTotalOutstanding.toFixed(2)}
                          </p>
                        </label>
                      ))
                    ) : (
                      <div>
                        <p className={`ffmr fs14 m-b-4`}>
                          No outstanding balances
                        </p>
                        <p className={`ffmr fs9`}>
                          You can still make a payment, it will be applied as
                          account credit
                        </p>
                      </div>
                    )}
                  </div>
                </div>

                <div className={`${styles.payments_section}`}>
                  <p className={`ffmr fs14 ${styles.title}`}>
                    Balance Due:{" "}
                    <span className="sub">
                      {formatFromCurrencyToMoney(
                        balances?.reservationTotalOutstanding
                      ) || formatFromCurrencyToMoney(0)}
                    </span>
                  </p>

                  <CurrencyInput
                    label="Amount"
                    id="paymentAmount"
                    value={payAmount || 0}
                    onChange={(val) => setPayAmount(val.fullNumber)}
                  />
                  {/* <SAInput
                    label="Amount"
                    value={payAmount || 0}
                    onChange={setPayAmountAction}
                    id="amt"
                    ifHasError={processError}
                  ></SAInput> */}
                  <div className={styles.payMethodsWrprs}>
                    {payMethods.map((payMethod, idx) => (
                      <div
                        key={idx}
                        className={`df acsa ffmm fs14 ${styles.paymentLine} ${
                          selectedPayMethod === payMethod._id
                            ? styles.selected
                            : ""
                        }`}
                        onClick={() => setSelectedPayMethod(payMethod._id)}
                      >
                        <div className={`df acc ${styles.radio}`}>
                          {selectedPayMethod === payMethod._id ? (
                            <div className={styles.bullet}></div>
                          ) : undefined}
                        </div>

                        <div className={`df acc ${styles.logoWrpr}`}>
                          <img src={images[payMethod.xCardType]} alt="hello" />
                        </div>

                        <div className={`${styles.textWrpr}`}>
                          <div className={`df acsa ${styles.cardNumber}`}>
                            {/* <p>{payMethod.xCardType}</p> */}
                            <p className={styles.bullets}>
                              &bull; &bull; &bull; &bull;
                            </p>
                            <p>
                              {payMethod.xMaskedCardNumber.substring(
                                payMethod.xMaskedCardNumber.length - 4
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  {/* {!selectedPayMethod ? (
              <>
                <div className={`${styles.p_types_wrpr}`}>
                  <div
                    onClick={() => setSelectedPaymentType(0)}
                    className={`df acsa ${styles.p_type}`}
                    
                  >
                  

                    <div
                      className={`${styles.radio_icon} ${
                        selctedPaymentType == 0 ? "selected" : ""
                      }`}
                    ></div>
                    <p className="ffnr fs14">Card</p>
                  </div>

                  <div className="df acsa p_type">
                    <SARadio
                      value={selctedPaymentType == 1}
                      id="cash"
                      label="Cash"
                      notBold
                      field="1"
                      onChange={setType}
                      name="p_type"
                    ></SARadio>
                  </div>
                  <div className="df acsa p_type">
                    <SARadio
                      value={selctedPaymentType == 2}
                      id="check"
                      label="Check"
                      notBold
                      field="2"
                      onChange={setType}
                      name="p_type"
                    ></SARadio>
                  </div>
                </div>

                <button disabled className="secondary small swipe_btn">
                  Begin Swipe
                </button>
              </>
            ) : (
              <>
            
                {selectedPayMethodDetails ? (
                  <>
                    {" "}
                    <div className="df acsa selected_pm_wrpr">
                      <div className={`df acsa ffnr fs14`}>
                        <div className="icon_wrpr">{getIcon()}</div>
                        Visa ****
                        {selectedPayMethodDetails.xMaskedCardNumber.substring(
                          selectedPayMethodDetails.xMaskedCardNumber.length - 4,
                          selectedPayMethodDetails.xMaskedCardNumber.length
                        )}
                      </div>
                    </div>
                    <button
                      className="secondary small swipe_btn"
                      onClick={() => setSelectedPayMethod(undefined)}
                    >
                      Change payment method
                    </button>
                  </>
                ) : undefined}
              </>
            )}

            {selctedPaymentType == 0 ? (
              <SelectPayment
                setAddCardAction={() => console.log("Hei")}
                close={() => setSelectedPaymentType(undefined)}
                setCardType={setSelectedPayMethod}
                selected={selectedPayMethod}
              ></SelectPayment>
            ) : undefined} */}
                </div>

                <div className="billing_section ffnr fs14 m-b-30">
                  <p className="df acsa ffqsb fs14 ttuc m-b-20 m-t-25">
                    Billing Information
                  </p>
                  <p className="ffmb ttuc fs12 m-b-10">Shmili Breuer</p>
                  <p className="ffmr fs14 m-b-5">20 Herrick Ave #113</p>
                  <p className="ffmr fs14">Spring Valley NY, 10977</p>
                </div>

                <div className="footer m-b-35">
                  <button
                    className="df ffmb fs14 ttuc fwbold srch_btn button primary w100 m-b-12"
                    onClick={() => processPayment()}
                    disabled={
                      !selectedPayMethod || !payAmount || processDisabled
                    }
                  >
                    Process payment
                  </button>

                  {/* <button className="primary swipe_btn">Save</button> */}
                </div>
              </div>
            ) : (
              "Loading"
            )}
          </Portal>{" "}
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default PaymentsPopup;
