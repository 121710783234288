export const NotFoundSVG = (props) => (
  <svg
    width="56"
    height="48"
    viewBox="0 0 56 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2288 5.31335C9.49875 5.31335 0 14.8334 0 26.5634C0 38.2934 9.49875 47.8134 21.2288 47.8134C32.98 47.8134 42.5 38.2934 42.5 26.5634C42.5 14.8334 32.98 5.31335 21.2288 5.31335ZM10.625 21.2509C10.625 19.4904 12.0521 18.0634 13.8125 18.0634C15.5729 18.0634 17 19.4904 17 21.2509C17 23.0113 15.5729 24.4384 13.8125 24.4384C12.0521 24.4384 10.625 23.0113 10.625 21.2509ZM28.6875 18.0634C26.9271 18.0634 25.5 19.4904 25.5 21.2509C25.5 23.0113 26.9271 24.4384 28.6875 24.4384C30.4479 24.4384 31.875 23.0113 31.875 21.2509C31.875 19.4904 30.4479 18.0634 28.6875 18.0634ZM14.875 34.0009V30.8134H27.625V34.0009H14.875ZM4.25 26.5634C4.25 35.9559 11.8575 43.5634 21.25 43.5634C30.6425 43.5634 38.25 35.9559 38.25 26.5634C38.25 17.1709 30.6425 9.56335 21.25 9.56335C11.8575 9.56335 4.25 17.1709 4.25 26.5634Z"
      fill={props.fill || "#1F2041"}
      fillOpacity={props.fillOpacity || "0.75"}
    />
    <path
      d="M39.9229 9.75637C41.6757 8.97621 42.4599 6.89901 41.6797 5.14615C41.3709 4.45241 40.8582 3.89122 40.197 3.52287L36.447 1.4203C36.3 1.33809 36.0932 1.43014 36.0558 1.59439L35.1079 5.78764C34.939 6.52545 35.0129 7.28223 35.3217 7.97597C36.1019 9.72882 38.17 10.5365 39.9229 9.75637Z"
      fill={props.fill || "#1F2041"}
      fillOpacity={props.fillOpacity || "0.75"}
    />
    <g clipPath="url(#tearClip)">
      <path
        d="M50.2551 16.6189C52.591 15.5792 53.636 12.8109 52.5964 10.475C52.1849 9.55042 51.5016 8.80254 50.6204 8.31164L45.6229 5.50961C45.4269 5.40006 45.1513 5.52273 45.1016 5.74163L43.8383 11.3298C43.6132 12.3131 43.7117 13.3216 44.1232 14.2462C45.1629 16.5821 47.9191 17.6585 50.2551 16.6189Z"
        fill={props.fill || "#1F2041"}
        fillOpacity={props.fillOpacity || "0.75"}
      />
    </g>
    <defs>
      <clipPath id="tearClip">
        <rect
          width="12.1677"
          height="12.1677"
          fill="white"
          transform="translate(39.75 7.97595) rotate(-23.9928)"
        />
      </clipPath>
    </defs>
  </svg>
);
