import Axios from "axios";
import { SET_RESERVATION, RESERVATION_ERROR } from "./types";
// import {values} from "lodash";

export const setReservation = (val) => async (dispatch, getState) => {
  const { auth, websiteProfile } = getState();
  if (auth.user && auth.user.authId) val.user = auth.user._id;
  val.webProfileId = websiteProfile._id;
  val.accountId = websiteProfile.account;

  let res = await Axios.post("/api/reservation", val, {
    headers: { "x-auth": localStorage.getItem("x-auth") },
  });

  localStorage.setItem("reservationId", res.data.data._id);

  dispatch({ type: SET_RESERVATION, payload: res.data.data });
};

export const updateReservation =
  (val, action, reservationId) => async (dispatch, getState) => {
    console.log("Hello updating", action, reservationId);

    const { websiteProfile } = getState();
    val.accountId = websiteProfile.account;
    val.webProfileId = websiteProfile._id;
    // const reservationId = await localStorage.getItem("reservationId");

    if (!reservationId) return;
    let res = await Axios.put(
      `/api/reservation/${reservationId}/${action}`,
      val,
      {
        headers: {
          "x-auth": localStorage.getItem("x-auth"),
        },
      }
    );

    if (res.data.message === "error")
      return dispatch({ type: RESERVATION_ERROR, payload: res.data.data });

    localStorage.setItem("reservationId", res.data.data._id);

    dispatch({ type: SET_RESERVATION, payload: res.data.data });
  };

export const getCurrentReservation = (resId) => async (dispatch) => {
  console.log(resId);

  let reservation = await Axios.get(`/api/reservation/${resId}`, {
    headers: { "x-auth": localStorage.getItem("x-auth") },
  });

  // console.log("+++++++++++++++++++", reservation);

  dispatch({ type: SET_RESERVATION, payload: reservation.data.data });
};

// export const getReservationPayments = (reservationId) => async (dispatch) => {
// 	Axios.get(`/api/reservation-payments/${reservationId}`);
// };

export const submitAutoPay = (vals, reservationId) => async (dispatch) => {
  await Axios.put(`/api/reservation-auto-pay/${reservationId}`, vals, {
    headers: { "x-auth": localStorage.getItem("x-auth") },
  });
};

export const updateWebUser = (values, userId) => async (dispatch) => {
  let d = await Axios.put(`/api/web-user/${userId}`, values);
  console.log("Hello world?????????", d.data);
};
