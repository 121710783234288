import React, { Component } from "react";
import ReactDOM from "react-dom";

class DDPortal extends Component {
  constructor(props) {
    super(props);
    this.rootSelector = document.getElementById("root");
    this.container = document.createElement("div");
    this.container.classList.add("dd_popup");
    this.myRef = React.createRef();

    this.handleClickOutside = (e) => {
      if (this.myRef?.current?.contains(e.target)) {
        return;
      }

      if (this.props?.close) this.props.close();
    };
  }
  componentDidMount() {
    this.rootSelector.appendChild(this.container);

    // add event listener
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    this.rootSelector.removeChild(this.container);
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return ReactDOM.createPortal(
      <div className="kokoko" ref={this.myRef}>
        {this.props.children}
      </div>,
      this.container
    );
  }
}

export default DDPortal;
