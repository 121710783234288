import React from "react";

export const ClimateSvg = (props) => (
	<svg
		width="48"
		height="94"
		viewBox="0 0 48 94"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M39.6666 52.9839V15.6666C39.6666 7.0142 32.6524 0 24 0C15.3476 0 8.3334 7.0142 8.3334 15.6666V52.9837C3.52563 57.2868 0.5 63.5401 0.5 70.5C0.5 83.4788 11.0214 94 24 94C36.9786 94 47.5 83.4788 47.5 70.5C47.5 63.5401 44.4744 57.2868 39.6666 52.9839ZM24 86.1666C15.3476 86.1666 8.3334 79.1524 8.3334 70.5C8.3334 65.9768 10.2503 61.7808 13.5576 58.8207L16.1668 56.4854V15.6666C16.1668 11.3404 19.674 7.83321 24.0002 7.83321C28.3264 7.83321 31.8336 11.3402 31.8336 15.6666V56.4854L34.4428 58.8207C37.7501 61.7808 39.667 65.9766 39.667 70.5C39.6666 79.1524 32.6524 86.1666 24 86.1666Z"
			fill={props.fill || "red"}
		/>
		<path
			d="M27.9174 31.3334H20.084V78.3334H27.9174V31.3334Z"
			fill={props.fill || "red"}
		/>
		<path
			d="M24 82.25C30.4893 82.25 35.75 76.9893 35.75 70.5C35.75 64.0107 30.4893 58.75 24 58.75C17.5107 58.75 12.25 64.0107 12.25 70.5C12.25 76.9893 17.5107 82.25 24 82.25Z"
			fill={props.fill || "red"}
		/>
	</svg>
);
