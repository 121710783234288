import axios from "axios";
import Axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
  generatePath,
  // Prompt,
} from "react-router-dom";
import AmexImg from "../../assets/images/credit-cards/american-express.svg";
import CitiImg from "../../assets/images/credit-cards/citi.svg";
import Discover from "../../assets/images/credit-cards/discover.svg";
import MasterImg from "../../assets/images/credit-cards/mastercard.svg";
import VisaImg from "../../assets/images/credit-cards/visa.svg";
import Portal from "../../components/ui/portal/Portal";
import SelectPayment from "../../containers/account/payments/select-payment/SelectPayment";
// import DocumentPopup from "../../containers/account/rentals/documents/DocumentsPopup";
import { formatFromCurrencyToMoney, getToken } from "../../helper";
import { ADD_TOAST, GET_UNIT } from "../../store/actions/types";
// import {getDefaultPaymentMethod} from "../../store/actions";
// import AnvilSignatureFrame from "@anvilco/react-signature-frame";
// import AnvilSignatureModal from "@anvilco/react-signature-modal";
// import "@anvilco/react-signature-modal/dist/styles.css";
import "./ReservationSummary.scss";
import AnvilDocument from "../../containers/account/rentals/documents/anvil/AnvilDocumnet";
import AlreadyReserved from "../../summary/already-reserved/AlreadyReserved";

const images = {
  Visa: VisaImg,
  Amex: AmexImg,
  Citi: CitiImg,
  MasterCard: MasterImg,
  Discover,
};
const ReservationSummery = (props) => {
  // // ************ START COMMENTING
  // getProRatedRate = (price) => {
  //   let today = moment(new Date()).startOf("day");
  //   let startOfMonth = moment(today).startOf("month");
  //   let endOfMonth = moment(today).endOf("month").startOf("day");
  //   let daysInMonth = endOfMonth.diff(startOfMonth, "days", true) + 1;
  //   let daysLeftInMonth = endOfMonth.diff(today, "days", true) + 1;
  //   return ((price / daysInMonth) * daysLeftInMonth).toFixed(2);
  // };
  // ****************** END COMMENTING
  const { auth, reservation, reservationUnit, websiteProfile } = useSelector(
    ({ auth, reservation, reservationUnit, websiteProfile }) => ({
      auth,
      reservation,
      reservationUnit,
      websiteProfile,
    })
  );

  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showSelectPopup, setShowSelectPopup] = useState(false);
  const [payMethod, setPayMethod] = useState({});
  const [dataLoading, setDataLoading] = useState(true);
  const [reservationDetails, setReservation] = useState();
  const [docId, setDocId] = useState(undefined);
  const [sigId, setSigId] = useState(undefined);
  const [showSignaturePopup, setShowSignaturePopup] = useState(false);
  const [signatureCompleted, setSignatureCompleted] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [creatingContract, setCreatingContract] = useState(true);

  // const refreshUser = async () => {
  //   let token = await localStorage.getItem("x-auth");
  //   let user = await Axios.get("/api/web-user", {
  //     headers: { "x-auth": token },
  //   });

  //   dispatch({ type: CURRENT_USER, payload: user.data });
  //   console.log("*** REFRESHNG USER ***", user.data);
  // };
  const getData = async () => {
    let method;
    if (params.payMethodId && params.payMethodId !== "pm") {
      // console.log("HaHa");
      method = await Axios.get(`/api/payment-method/${params.payMethodId}`);
      // /api/payment-method/:methodId
    } else {
      method = await Axios.get(`/api/default-pay-method/${auth.user._id}`);
    }

    // *** CONSIDER UNCOMMENTING NEXT LINE *** //
    // if (!auth?.user?.defaultAddress) await refreshUser();
    // *** CONSIDER UNCOMMENTING PREV LINE *** //
    // this.setState({ payMethod: method.data.data, dataLoading: false });
    setPayMethod(method.data.data);
    setDataLoading(false);
  };

  const getReservationDetails = async () => {
    let reservationRes = await Axios.get(
      `/api/reservation/${params.reservationId}`,
      {
        headers: { "x-auth": getToken() },
      }
    );

    setReservation(reservationRes.data.data);
  };

  const getOrCreateContract = async () => {
    setCreatingContract(true);
    if (!reservationDetails.address || !reservationDetails.phoneNumber) {
      await axios.put(
        `/api/reservation/${params.reservationId}`,
        {
          address: auth?.user?.defaultAddress?._id,
          phoneNumber: auth?.user?.defaultPhoneNumber?._id,
        },

        { headers: { "x-auth": getToken() } }
      );
    }

    let doc = await axios.post(
      `/api/create-get-contract/${params.reservationId}`
    );

    // console.log(
    //   doc.data.data._id,
    //   doc.data.data.eSigSignatures[0].signature_id
    // );

    setDocId(doc.data.data._id);
    setSigId(doc.data.data.eSigSignatures[0].eid);
    setSignatureCompleted(doc.data.data.completed);
    setCreatingContract(false);
  };

  useEffect(() => {
    getData();
    getReservationDetails();
    // eslint-disable-next-line
  }, []);

  const selectedCard = async (cardId) => {
    setDataLoading(true);
    // this.props.history.replace({
    //   pathname: generatePath(
    //     "/reserve/summary/:sizeId/:unitId/:selectedDate/:locationId/:payMethodId",
    //     {
    //       sizeId: this.props.match.params.sizeId,
    //       unitId: this.props.match.params.unitId,
    //       selectedDate: this.props.match.params.selectedDate,
    //       locationId: this.props.match.params.locationId,
    //       payMethodId: cardId,
    //     }
    //   ),
    // });

    // *****************
    // *****************
    // *****************

    history.replace({
      pathname: generatePath(
        "/reserve/summary/:reservationId/:payMethodId?/:addressId?",
        {
          reservationId: params.reservationId,
          payMethodId: cardId,
          addressId: params.addressId,
        }
      ),
    });

    // *****************
    // *****************
    // *****************

    let method = await Axios.get(`/api/payment-method/${cardId}`);

    setPayMethod(method.data.data);
    setDataLoading(false);

    // this.setState({ payMethod: method.data.data, dataLoading: false });
    // this.setState({ dataLoading: false });
  };

  const getUnitDetails = async () => {
    let unit = await Axios.get(
      `/api/reserve-unit/?unitid=${reservationDetails.unit._id}&moveindate=${reservationDetails.expectedMoveInDate}&reservationid=${params.reservationId}`
    );

    // setUnitDetails(unit.data.data);

    dispatch({
      type: GET_UNIT,
      payload: unit.data.data,
    });
  };

  useEffect(() => {
    // console.log("BEFORE CHECK");
    if (!reservationDetails?.unit?._id) return;
    // console.log("AFTER CHECK ###################################");
    // console.log(reservationDetails);
    // console.log("AFTER CHECK ###################################");
    getUnitDetails();
    getOrCreateContract();
    // eslint-disable-next-line
  }, [reservationDetails]);

  // useEffect(() => {
  //   console.log("())&*%*^&(%**&*^%/*-+-");
  //   if (!docId || !sigId) return;
  //   console.log("{}\\\\\\");
  //   setShowSignaturePopup(true);
  // }, [docId, sigId]);

  const setDocSignedAction = async () => {
    // console.log("Hello");

    await axios.put(`/api/document-details/${docId}`, {
      completed: true,
    });
    // setSignatureCompleted(res.data.data.completed);

    history.push(`/thank-you/${params.reservationId}`);
  };

  return (
    <>
      {/* {reservationDetails?.initialPayment ? ( */}
      {!reservationDetails?.documents[0]?.completed &&
      reservationDetails?.initialPayment ? (
        <div
          className="df acc h100 p-b-100 p-t-50"
          onClick={() => setShowSignaturePopup(true)}
        >
          <button
            className={`df ffmsb fs14 ttuc button primary pbgc m-t-40 __has_arrow`}
          >
            Please sign your contract
          </button>
        </div>
      ) : // <div>
      //   <p>Looks like you already prepaid for this reservation</p>{" "}
      //   <p>
      //     <Link to={`/account/rentals/${params?.reservationId}`}>
      //       See your reservation
      //     </Link>
      //   </p>
      // </div>
      // <AlreadyReserved
      //   isDraft={reservationDetails?.isDraft}
      //   reservationUserId={reservationDetails?.user?._id}
      //   reservationId={reservationDetails?._id}
      //   unit={reservationDetails}
      // />
      reservationDetails?.initialPayment ? (
        <AlreadyReserved
          isDraft={reservationDetails?.isDraft}
          reservationUserId={reservationDetails?.user?._id}
          reservationId={reservationDetails?._id}
          unit={reservationDetails}
        />
      ) : (
        <div className="df sum_page_wrpr">
          {reservation && !dataLoading ? (
            <>
              <div className="left_sec">
                <div className="cntnt">
                  <h4 className="ffqsb fs16 ttuc section_hdr">
                    Billing information
                  </h4>

                  <div className="address_wrpr">
                    <p className="ffmm fs16 add_line_1">
                      {auth?.user?.fullName}
                    </p>
                    <p className="ffmm fs16 add_line_1">
                      {auth?.user?.defaultAddress?.deliveryLine1}
                    </p>
                    <p className="ffmm fs16 add_line_2">
                      {auth?.user?.defaultAddress?.lastLine}
                    </p>

                    {/* <p
                      className="ffqsb fs14 chng_btn ptc"
                      onClick={console.log}
                    >
                      Change
                    </p> */}
                  </div>

                  <h4 className="ffqsb fs16 ttuc section_hdr m-t-75">
                    Payment Method
                  </h4>

                  {payMethod !== "err" ? (
                    <div className="card_wrpr">
                      <p
                        className={`df acsa ffmm fs16 cc_num_wrpr 
										
										`}

                        // ${payMethod.xCardType.toLowerCase()}
                      >
                        <span className="card_img_wrpr">
                          <img
                            src={images[payMethod.xCardType]}
                            alt="card-img"
                          ></img>
                        </span>
                        <span className="fwbold fs16">
                          &bull; &bull; &bull; &bull;
                        </span>{" "}
                        {payMethod?.xMaskedCardNumber?.substring(
                          payMethod?.xMaskedCardNumber?.length - 4
                        )}
                      </p>

                      {/* <p
                        className="ffqsb fs14 chng_btn ptc"
                        onClick={() => setShowSelectPopup(true)}
                      >
                        Change
                      </p> */}

                      {showSelectPopup ? (
                        <Portal
                          zIndex="101"
                          title="Select Card"
                          noCustomScrollbar
                          opacity=".08"
                          width="498px"
                          close={() => setShowSelectPopup(false)}
                          height="auto"
                        >
                          <SelectPayment
                            selectedId={payMethod._id}
                            userId={auth.user._id}
                            selectAction={selectedCard}
                            close={() => setShowSelectPopup(false)}
                          ></SelectPayment>
                        </Portal>
                      ) : undefined}
                    </div>
                  ) : undefined}
                </div>
              </div>
              <div className="right_sec">
                <div className="cntnt">
                  <h4 className="ffqsb fs16 ttuc section_hdr">Order summary</h4>

                  {reservationUnit.unitSize ? (
                    <div className="list_wrpr fs15">
                      {/* This Month (prorated) */}
                      {reservationUnit?.proRatedMonth ? (
                        <>
                          <div className="line_wrpr df acsa ffmsb">
                            <div className="lbl">This Month (prorated)</div>
                            <div className="mla val">
                              {formatFromCurrencyToMoney(
                                reservationUnit?.proRatedMonth
                              )}
                            </div>
                          </div>
                          {reservationUnit?.proRatedInsurance ? (
                            <div className="line_wrpr df acsa ffmsb">
                              <div className="lbl">
                                Tenant Protection (prorated)
                              </div>
                              <div className="mla val">
                                {formatFromCurrencyToMoney(
                                  reservationUnit?.proRatedInsurance
                                )}
                              </div>
                            </div>
                          ) : undefined}
                        </>
                      ) : undefined}

                      <div className="line_wrpr df acsa ffmsb">
                        <div className="lbl">
                          {reservationUnit?.proRatedMonth ? "Next" : "This"}{" "}
                          Months Rent
                        </div>
                        <div className="mla val">
                          {formatFromCurrencyToMoney(
                            reservationUnit?.monthlyCost
                          )}
                        </div>
                      </div>
                      {reservationUnit?.monthlyInsurance ? (
                        <div className="line_wrpr df acsa ffmsb">
                          <div className="lbl">Tenant Protection</div>
                          <div className="mla val">
                            {formatFromCurrencyToMoney(
                              reservationUnit?.monthlyInsurance
                            )}
                          </div>
                        </div>
                      ) : undefined}
                      {Number(reservationUnit.unitLocation.securityDeposit) >
                      0 ? (
                        <div className="line_wrpr df acsa ffmsb">
                          <div className="lbl">Security Deposit</div>
                          <div className="mla val">
                            {formatFromCurrencyToMoney(
                              reservationUnit.unitLocation.securityDeposit
                            )}
                          </div>
                        </div>
                      ) : undefined}

                      <div className="line_wrpr df acsa ffmsb">
                        <div className="lbl">Admin Fee</div>
                        <div className="mla val">
                          {formatFromCurrencyToMoney(
                            reservationUnit.unitLocation.adminFee
                          )}
                        </div>
                      </div>
                      {/* <div className="line_wrpr df acsa ffmsb">
                        <div className="lbl">Insurance</div>
                        <div className="mla val">ADD</div>
                      </div> */}
                      <div className="line_wrpr df acsa ffmsb">
                        <div className="lbl">Taxes</div>
                        <div className="mla val">
                          {formatFromCurrencyToMoney(reservationUnit?.taxes) ||
                            formatFromCurrencyToMoney(0)}
                        </div>
                      </div>
                      <div className="line_wrpr total df acsa ffmb">
                        <div className="lbl">Total</div>
                        <div className="mla val">
                          {formatFromCurrencyToMoney(reservationUnit?.total) ||
                            formatFromCurrencyToMoney(0)}
                          {/* {reservation
													.unitLocation
													.securityDeposit +
													this.getProRatedRate(
														reservation
															.unitSize.price
													)}{" "}
												- */}
                          {/* {this.getTotal()} */}
                        </div>
                      </div>
                    </div>
                  ) : undefined}

                  {!signatureCompleted ? (
                    <>
                      {console.log(params)}
                      <button
                        disabled={
                          signatureCompleted ||
                          creatingContract ||
                          disableSubmit
                        }
                        onClick={async () => {
                          setDisableSubmit(true);
                          const reservation = await Axios.post(
                            `/api/reservation-initial-payment/${params.reservationId}`,
                            {
                              payDetails: {
                                payMethod: payMethod._id,
                                expectedTotal: reservationUnit?.total,
                              },
                              reservationDetails: {
                                user: auth.user._id,
                                unit: reservationDetails.unit._id,
                                locationId: reservationDetails.unitLocation._id,
                                webProfileId: auth.user._id,
                                accountId: websiteProfile.account,
                                expectedMoveInDate:
                                  reservationDetails.expectedMoveInDate,
                                initialPayment: false,
                                address: auth.user?.defaultAddress?._id,
                                phoneNumber: auth.user.defaultPhoneNumber._id,
                              },
                            },
                            {
                              headers: {
                                "x-auth": getToken(),
                              },
                            }
                          );

                          if (reservation.data.message === "ok") {
                            setReservation(reservation.data.data);
                            setShowSignaturePopup(true);
                          } else if (reservation.data.status === 500) {
                            setDisableSubmit(false);
                            setPaymentError(true);
                            dispatch({
                              type: ADD_TOAST,
                              payload: {
                                message:
                                  "We were unable to process your payment.",
                                errors: [reservation.data.message],
                                type: "error",
                              },
                            });
                          }
                        }}
                        className={`df ffmsb fs14 w100 ttuc button primary pbgc m-t-40 __has_arrow`}
                      >
                        Pay and Sign contract
                      </button>

                      {/* <p
                        className={`ffqsb tac w100 fs14 ptc m-t-18 m-b-22 cursor_pointer skipBtn  ${
                          paymentError ? "error" : ""
                        }`}
                        onClick={async () => {
                          setPaymentError(false);
                          const reservation = await Axios.post(
                            `/api/reservation-initial-payment/${params.reservationId}`,
                            {
                              payDetails: {
                                payMethod: payMethod._id,
                                expectedTotal: reservationUnit?.total,
                              },
                              reservationDetails: {
                                user: auth.user._id,
                                unit: reservationDetails.unit._id,
                                locationId: reservationDetails.unitLocation._id,
                                webProfileId: auth.user._id,
                                accountId: websiteProfile.account,
                                expectedMoveInDate:
                                  reservationDetails.expectedMoveInDate,
                                initialPayment: false,
                                address: auth.user?.defaultAddress?._id,
                                phoneNumber: auth.user.defaultPhoneNumber._id,
                              },
                            },
                            {
                              headers: {
                                "x-auth": getToken(),
                              },
                            }
                          );

                          if (reservation.data.message === "ok") {
                            history.push(
                              `/thank-you/${reservation?.data?.data?._id}`
                            );
                          } else if (reservation.data.status === 500) {
                            setPaymentError(true);
                            dispatch({
                              type: ADD_TOAST,
                              payload: {
                                message:
                                  "We were unable to process your payment.",
                                errors: [reservation.data.message],
                                type: "error",
                              },
                            });
                          }
                        }}
                      >
                        Skip signature and process payment
                      </p> */}
                    </>
                  ) : (
                    <p
                      className={`df ffmsb fs14 w100 ttuc button primary pbgc m-t-40 ${
                        paymentError ? "error" : ""
                      }`}
                      onClick={async () => {
                        setPaymentError(false);
                        const reservation = await Axios.post(
                          `/api/reservation-initial-payment/${params.reservationId}`,
                          {
                            payDetails: {
                              payMethod: payMethod._id,
                              expectedTotal: reservationUnit?.total,
                            },
                            reservationDetails: {
                              user: auth.user._id,
                              unit: reservationDetails.unit._id,
                              locationId: reservationDetails.unitLocation._id,
                              webProfileId: auth.user._id,
                              accountId: websiteProfile.account,
                              expectedMoveInDate:
                                reservationDetails.expectedMoveInDate,
                              initialPayment: false,
                              address: auth.user.defaultAddress._id,
                              phoneNumber: auth.user.defaultPhoneNumber._id,
                            },
                          },
                          {
                            headers: {
                              "x-auth": getToken(),
                            },
                          }
                        );

                        if (reservation.data.message === "ok") {
                          history.push(
                            `/thank-you/${reservation?.data?.data?._id}`
                          );
                        } else if (reservation.data.status === 500) {
                          setPaymentError(true);
                          dispatch({
                            type: ADD_TOAST,
                            payload: {
                              message:
                                "We were unable to process your payment.",
                              errors: [reservation.data.message],
                              type: "error",
                            },
                          });
                        }
                      }}
                    >
                      Process payment
                    </p>
                  )}
                </div>
              </div>
            </>
          ) : (
            "Loading"
          )}
        </div>
      )}
      {showSignaturePopup ? (
        <AnvilDocument
          close={() => setShowSignaturePopup(false)}
          signerName={auth.user.fullName}
          signerEid={sigId}
          onFinish={setDocSignedAction}
        ></AnvilDocument>
      ) : undefined}
    </>
  );
};

// const mapStateToProps = ({
//   websiteProfile,
//   reservation,
//   auth,
//   reservationUnit,
// }) => ({
//   websiteProfile,
//   reservation,
//   auth,
//   reservationUnit,
// });

export default ReservationSummery;

// _id:"5e816ce400999c8f0c98cb4f"
// line1  :"20 Herrick Avenue"
// line2:"113"
// city:"Spring Valley"
// state:"NY"
// zip:"10977 - 4705"
// accountId:"5de08bbfb1759958473471f7"
// webUserId:"5e366cb41f6c6c977cc0b880"
// webProfileId:"5e1697b71aa0903a845bef8d"
// __v:0
