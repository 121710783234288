import React from "react";

export const SafeLockSvg = (props) => (
	<svg
		width="90"
		height="90"
		viewBox="0 0 90 90"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M69.7578 36.0001H20.2422C16.5386 36.0001 13.5 39.0131 13.5 42.73V83.2701C13.5 86.9811 16.5185 90 20.2422 90H69.758C73.4615 90 76.5002 86.987 76.5002 83.2701V42.73C76.5 39.019 73.4815 36.0001 69.7578 36.0001ZM67.5 81H22.5001V44.9999H67.5V81Z"
			fill={props.fill || "red"}
		/>
		<path
			d="M45.7909 0H44.2092C32.2248 0 22.5 9.7394 22.5 21.7219V38.9439H31.5001V21.7219C31.5001 14.707 37.1983 9.00006 44.2092 9.00006H45.7909C52.798 9.00006 58.5 14.7032 58.5 21.7249V27H67.5001V21.7246C67.5001 9.73286 57.7689 0 45.7909 0Z"
			fill={props.fill || "red"}
		/>
		<path
			d="M44.8886 48C39.4283 48 35 52.4264 35 57.8886C35 61.5504 36.9964 64.7399 39.9557 66.4504V77.444H49.8215V66.4504C52.7809 64.7406 54.7773 61.5504 54.7773 57.8886C54.7773 52.4264 50.3496 48 44.8886 48Z"
			fill={props.fill || "red"}
		/>
	</svg>
);
