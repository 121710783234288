import {GET_USER} from "../actions/types";

const reducer = (state = {}, actions) => {
	switch (actions.type) {
		case GET_USER:
			return {name: "Shmili", lastName: "Breuer"};
		default:
			return state;
	}
};

export default reducer;
