import React from "react";

export const DeleteIcon = (props) => (
	<svg
		width="14"
		height="18"
		viewBox="0 0 14 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.5 0L10.5 1H14V3H0V1H3.5L4.5 0H9.5ZM1 16C1 17.1 1.9 18 3 18H11C12.1 18 13 17.1 13 16V4H1V16ZM3 6H11V16H3V6Z"
			fill="#1F2041"
			fillOpacity="0.5"
		/>
	</svg>
);
