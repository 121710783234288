import color from "color";
import { ThemeContext } from "../../../../App";
import styles from "./AddItemButton.module.scss";

export const AddItemButton = ({
  text,
  action,
  loading,
  setLoading,
  children,
  colorStyle,
  type,
}) => {
  return (
    <ThemeContext.Consumer>
      {(val) => (
        <span
          onClick={action}
          className={`acsa ffmsb fs14 p-t-2 p-b-2 p-r-15 p-l-15 ${styles.button}`}
          style={{
            backgroundColor:
              type === "white"
                ? "#fff"
                : type === "warning"
                ? color("#e18718").fade(0.8)
                : colorStyle === "dark"
                ? val
                : color(val).fade(0.9),
            color:
              type === "warning"
                ? "#e18718"
                : colorStyle === "dark"
                ? "#ffffff"
                : val,
          }}
        >
          {text} {loading ? "Loading" : ""}
          {children}
        </span>
      )}
    </ThemeContext.Consumer>
  );
};
