import Axios from "axios";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
// import components
// import { BookingProcess } from "../booking-process/BookingProcess";
// import { StorageLocation } from "../components/ui/storage-address/StorageAddress";
import { Popup } from "../components/ui/popup/popup";
import SignIn from "../components/ui/signin-popup/SignInPopup";
import {
  getUnit,
  getUnitSizes,
  setAuth,
  setReservation,
} from "../store/actions";
// import scss
import "./Summary.scss";
import { WelcomePopup } from "./welcome-popup/WelcomePopup";
import RCPopup from "../reserve/returning-customer-popup/RCPopup";
import AlreadyReserved from "./already-reserved/AlreadyReserved";
import { formatFromCurrencyToMoney } from "../helper";

// import DatePicker from "../components/datepicker/DatePicker";
// import SADropDown from "../components/ui/form-elements/drop-down/DropDown";
// import Header from "../components/header/Header";

class Summary extends Component {
  state = {
    signinPopup: false,
    welcomePopup: false,
    selectedDay: undefined,
    startDate: Number(new Date()),
    selectedDate: undefined,
    selectedSize: undefined,
    disabledDate: true,
    disabledSize: true,
    saving: false,
    showReturningCustomerPopup: false,
  };

  updateDisabled = (field) => {
    this.setState((prevState) => ({ [field]: !prevState[field] }));
  };

  updateDatePicker = (val) => {
    this.setState({ selectedDate: Number(val) });
  };

  async componentDidMount() {
    // this.auth = window.gapi.auth2.getAuthInstance();
    // this.auth.isSignedIn.listen(this.updateGoogleAuth);

    await this.setState({
      selectedDate: Number(this.props.match.params.selectedDate),
    });

    this.props.getUnit(
      this.props.match.params.unitId,
      this.props.match.params.selectedDate
    );
  }

  selectedStorageSize = (sizeId, locationId) => {
    this.setState({ selectedSize: sizeId, selectedUnitLocation: locationId });
  };

  toggleSignIn = () =>
    this.setState((prevState) => ({ signinPopup: !prevState.signinPopup }));

  toggleWelcome = () =>
    this.setState((prevState) => ({ welcomePopup: !prevState.welcomePopup }));

  updateDate = async (val) => {
    const { sizeId, unitId, locationId } = this.props.match.params;

    await this.props.history.push(
      `/summary/${sizeId}/${unitId}/${this.state.selectedDate}/${locationId}`
    );
  };

  updateSize = async (val) => {
    const { unitId, selectedDate, locationId } = this.props.match.params;

    await this.props.history.push(
      `/summary/${this.state.selectedSize}/${unitId}/${selectedDate}/${locationId}`
    );

    this.props.getUnit(this.props.match.params.unitId);
    // console.log(this.state.selectedSize);
  };

  setReservation = async () => {
    this.setState({ saving: true });
    const { unitId, selectedDate, locationId } = this.props.match.params;

    const email = this.props?.auth?.user?.email;

    if (!email) return this.toggleSignIn();
    let data = {
      unit: unitId,
      expectedMoveInDate: selectedDate,
      email,
      locationId,
    };

    let reservationId = await localStorage.getItem("reservationId");

    if (reservationId && email)
      return this.props.history.push(`/reserve/info/${reservationId}`);
    if (!reservationId) await this.props.setReservation(data);
    reservationId = await localStorage.getItem("reservationId");
    this.props.history.push(`/reserve/info/${reservationId}`);
  };

  continueToDetails = () => {
    // eslint-disable-next-line
    const { sizeId, unitId, selectedDate, locationId } =
      this.props.match.params;
    const email = this.props?.auth?.user?.email;

    if (!email) return this.toggleSignIn();

    // if (this.props.auth?.user.defaultAddress) {
    return this.setState({ showReturningCustomerPopup: true });
    // }

    // this.props.history.push(
    //   `/reserve/info/${sizeId}/${unitId}/${selectedDate}/${locationId}`
    // );
  };

  tempContinueToDetails = async () => {
    const email = this.props?.auth?.user?.email;

    if (!email) return this.toggleSignIn();

    const { unitId, selectedDate, locationId } = this.props.match.params;

    let post = await Axios.post(
      `/api/reservation/false/?setupPrice=true`,
      {
        isDraft: true,
        unit: unitId,
        locationId,
        expectedMoveInDate: selectedDate,
        user: this.props.auth.user._id,
        webProfileId: this.props.auth.user.webProfileId,
      },
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );

    if (post?.data?.data?._id) {
      console.log(post.data.data);

      this.props.history.push(
        `/v2/reserve/?reservationid=${post?.data?.data?._id}`
      );
    }

    // this.props.history.push(
    //   `/v2/reserve/?unitid=${unitId}&moveindate=${selectedDate}&unitlocation=${locationId}`
    // );

    return "OK";
  };

  render() {
    const unit = this.props.reservationUnit;

    return (
      <div className="summary_page">
        {/* {console.log(this.props)} */}
        <div className="info_wrpr">
          {/* {console.log(this.props.match.params.selectedDate)} */}
          <div className="df acc ffc content_wrpr">
            <h3
              className="ffqsm df acc fs20 pbgc"
              onClick={() =>
                Axios.get("/api/hello-test", {
                  headers: {
                    "x-auth": localStorage.getItem("x-auth"),
                  },
                })
              }
            >
              Rental Information !!!
            </h3>
            {unit?.reserved === true ? (
              // <p>
              // unit.reservationUser
              //  unit?.currentOrPendingReservation?.isDraft
              //  unit.currentOrPendingReservation._id
              <AlreadyReserved
                isDraft={unit?.currentOrPendingReservation?.isDraft}
                reservationUserId={unit.reservationUser}
                reservationId={unit.currentOrPendingReservation._id}
              />
            ) : (
              //   {console.log(unit.reservationUser == this.props.auth.user._id)}
              // </p>
              unit._id && (
                <div className="df inner_wrpr">
                  <div className="pricing_info_wrpr">
                    <div className="sec_hdr">
                      <div className="df ffmb fs22 w100 size_price_wrpr">
                        <p>
                          <span>
                            {unit.unitSize.width}' x {unit.unitSize.length}'{" "}
                          </span>
                          <span className="flcap">{unit.unitSize.label}</span>
                        </p>

                        <p className="mla">
                          {formatFromCurrencyToMoney(unit.unitSize.price)}
                          /mo
                        </p>
                      </div>

                      <div className="ffmm fs14 move_in_date">
                        Move in:{" "}
                        {moment(
                          Number(this.props.match.params.selectedDate)
                        ).format("MM/DD/YYYY")}
                      </div>

                      <div className="df features_wrpr">
                        {unit.unitFeatures.map((ftr, i) => (
                          <div key={i} className="df ffmr fs14 feature_wrpr">
                            <span className="flcap">{ftr.label}</span>
                          </div>
                        ))}
                      </div>
                      {/* <div className="left_sec">
                        <div className="ffmb fs22 size">
                          <span>
                            {unit.unitSize.width}' x {unit.unitSize.length}'
                          </span>{" "}
                          <span className="flcap">{unit.unitSize.label}</span>
                        </div>
                        <div className="df features_wrpr">
                          {unit.unitFeatures.map((ftr, i) => (
                            <div key={i} className="df ffmr fs14 feature_wrpr">
                              <span className="flcap">{ftr.label}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="mla tar right_sec">
                        <div className="ffmr fs22 price">
                          {
                            CURRENCIES[
                              this.props.units.unit.unitLocation.currency
                            ]
                          }
                          {unit.unitSize.price.toFixed(0)}/mo
                        </div>
                      </div> */}
                    </div>

                    <div className="list_wrpr">
                      {unit.proRatedMonth ? (
                        <div className="df ffmsb fs16 line">
                          This Month (prorated)
                          <div className="mla tar">
                            {formatFromCurrencyToMoney(unit.proRatedMonth)}
                          </div>
                        </div>
                      ) : undefined}

                      <div className="df ffmsb fs16 line">
                        {unit.proRatedMonth ? "Second" : "First"} Month{" "}
                        {unit.proRatedMonth ? "s" : ""} Rent
                        <div className="mla tar">
                          {formatFromCurrencyToMoney(unit.monthlyCost)}
                        </div>
                      </div>

                      {Number(unit.unitLocation.securityDeposit) > 0 ? (
                        <div className="df ffmsb fs16 line">
                          Security Deposit{" "}
                          <div className="mla tar">
                            {formatFromCurrencyToMoney(
                              unit.unitLocation.securityDeposit
                            )}
                          </div>
                        </div>
                      ) : undefined}

                      <div className="df ffmsb fs16 line">
                        Admin Fee{" "}
                        <div className="mla tar">
                          {formatFromCurrencyToMoney(
                            unit.unitLocation.adminFee
                          )}
                        </div>
                      </div>
                      <div className="df ffmsb fs16 line">
                        Insurance <div className="mla tar add">Add</div>
                      </div>
                      <div className="df ffmsb fs16 line">
                        Taxes{" "}
                        <div className="mla tar">
                          {formatFromCurrencyToMoney(unit.taxes) ||
                            formatFromCurrencyToMoney(0)}
                        </div>
                      </div>
                      <div className="df ffmb fs16 line">
                        Total{" "}
                        <div className="mla tar total">
                          {formatFromCurrencyToMoney(unit.total)}
                        </div>
                      </div>
                    </div>
                    {/* <div className="ffqsb fs14 cntct_us">Contact us ></div> */}
                    <div className="df ffc acsa reservation_ftr">
                      <div
                        className="df ffmsb fs14 w100 ttuc button primary pbgc"
                        // to="/locations"
                        disabled={this.state.saving}
                        onClick={() => this.tempContinueToDetails()}
                        // onClick={() => this.continueToDetails()}
                      >
                        Continue
                      </div>
                      <p className="ffmr tac fs12">You won’t be charged yet</p>

                      {this.state.signinPopup && (
                        <Popup
                          customClass="signin_popup"
                          zIndex="6"
                          overlay={true}
                          closeAction={this.toggleSignIn}
                        >
                          <SignIn
                            // redirectUrl={`${window.location.href.replace(
                            //   "summary",
                            //   "reserve/info"
                            // )}`}
                            continueAction={this.toggleWelcome}
                            closeAction={this.toggleSignIn}
                            setAuth={this.props.setAuth}
                            accountId={this.props.websiteProfile.account}
                            webProfileId={this.props.websiteProfile._id}
                            webProfile={this.props.websiteProfile}
                            showForgot={true}
                            showSignup={true}
                            showSocial={false}
                          ></SignIn>
                        </Popup>
                      )}
                      {this.state.welcomePopup && (
                        <Popup
                          customClass="signin_popup"
                          zIndex="8"
                          overlay={true}
                        >
                          <WelcomePopup
                            firstName={
                              this.props.auth &&
                              this.props.auth.user &&
                              this.props.auth.user.firstName
                            }
                          ></WelcomePopup>
                        </Popup>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {this.state.showReturningCustomerPopup ? (
          <RCPopup
            unitSize={this.props.match.params?.sizeId}
            unitId={this.props.match.params?.unitId}
            locationId={this.props.match.params?.locationId}
            selectedDate={this.props.match.params?.selectedDate}
            // reservationId={this.props.match.params?._id}
          ></RCPopup>
        ) : undefined}
      </div>
    );
  }
}

const mapStateToProps = ({ websiteProfile, reservationUnit, units, auth }) => ({
  websiteProfile,
  reservationUnit,
  units,
  auth,
});
export default connect(mapStateToProps, {
  getUnit,
  getUnitSizes,
  setAuth,
  setReservation,
})(Summary);

// this.props.auth &&
// this.props.auth.user &&
// this.props.auth.user.email
//   ? () => this.props.history.push("/reserve/info")
//   : this.toggleSignIn
