import axios from "axios";
import Cleave from "cleave.js/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Portal from "../../../../components/ui/portal/Portal";

import styles from "./AddPhone.module.scss";

const AddPhone = ({ updatedPhone, close }) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  const [phone, setPhone] = useState("");
  // eslint-disable-next-line
  const [canText, setCanText] = useState(false);

  const save = async () => {
    let re = await axios.post(
      `/api/phone/${auth.user._id}/${auth.user.accountId}`,
      {
        number: `+1${phone}`,
        canText: canText,
        accountId: auth.user.accountId,
        webProfileId: auth.user.webProfileId,
        webUserId: auth.user._id,
        primary: true,
      }
    );

    updatedPhone(re?.data?.data);
    close();
  };

  return (
    <Portal
      height="auto"
      zIndex="999"
      opacity="0.35"
      title="Add phone number"
      close={close}
    >
      <div className={styles.addPhoneContent}>
        <div className={`m-t-20 m-b-10`}>
          <div className="__inpt_wrpr df ffc">
            <label className={`ffmb fs12`}>Phone number</label>
            <div className={`inner_inpt_wrpr`}>
              <Cleave
                className="ffmr fs14"
                options={{
                  blocks: [0, 3, 3, 4],
                  delimiters: ["(", ") ", "-"],
                  numericOnly: true,
                }}
                value={phone}
                onChange={(event) => {
                  const tempEvent = event;
                  tempEvent.target.value = event.target.rawValue;
                  setPhone(event.target.rawValue);
                }}
              />
            </div>
          </div>
        </div>
        <div className={`p-b-20 ${styles.ftr}`}>
          <button
            onClick={save}
            className={`df ffmsb fs14 p-l-30 p-r-30 m-t-30 w100 ttuc button primary pbgc`}
          >
            Save
          </button>

          <button
            onClick={close}
            className={`df ffmsb fs14 p-l-30 p-r-30 m-t-10 w100 ttuc secondary text`}
          >
            Cancel
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default AddPhone;
