import styles from "./MobileNav.module.scss";
import Payments from "./payments/Payments";
import Rentals from "./rentals/Rentals";
import Settings from "./setup/Setup";

const MobileNav = (props) => {
  return (
    <div className={styles.mobileNav}>
      <Rentals></Rentals>
      <Payments></Payments>
      <Settings></Settings>
    </div>
  );
};

export default MobileNav;
