const PhoneFilledSVG = (props) => (
  <svg
    width="35"
    height="36"
    viewBox="0 0 35 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4100_12894)">
      <path
        d="M34.2153 28.1598L28.8101 22.754C27.7335 21.6817 25.9506 21.7142 24.8365 22.8288L22.1133 25.5513C21.9413 25.4565 21.7632 25.3575 21.576 25.2524C19.8563 24.2996 17.5027 22.9936 15.026 20.5151C12.5419 18.0313 11.2348 15.6741 10.279 13.9533C10.1782 13.771 10.0815 13.5952 9.9861 13.4283L11.8137 11.6034L12.7123 10.7038C13.8281 9.58762 13.8589 7.80523 12.7848 6.72982L7.37958 1.32341C6.30548 0.249513 4.52178 0.282092 3.40595 1.39822L1.88258 2.93034L1.92421 2.97167C1.4134 3.62345 0.986555 4.37518 0.66891 5.18583C0.376101 5.95747 0.1938 6.69382 0.110442 7.43167C-0.60328 13.3486 2.10058 18.7562 9.43849 26.0942C19.5817 36.2367 27.7558 35.4705 28.1085 35.4331C28.8765 35.3413 29.6125 35.1578 30.3605 34.8673C31.1642 34.5534 31.9154 34.1271 32.5668 33.6174L32.6 33.647L34.1433 32.1358C35.2568 31.0199 35.2889 29.2369 34.2153 28.1598Z"
        fill={props.fill || "#30BE76"}
      />
    </g>
    <defs>
      <clipPath id="clip0_4100_12894">
        <rect
          width="35"
          height="35"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default PhoneFilledSVG;
