import React from "react";

// import scss
import "./Input.scss";

const SATextArea = (props) => (
  <div className="__inpt_wrpr df ffc">
    <label className="ffmb fs12" htmlFor={props.id}>
      {props.label}
    </label>
    <textarea
      style={{ height: `${props.height || 60}px` }}
      className="ffmr fs14"
      id={props.id}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange && props.onChange(e.target.value)}
    ></textarea>
  </div>
);

export default SATextArea;
