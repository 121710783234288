import {GET_WEBSITE_PROFILE} from "../actions/types";

const reducer = (state = {}, action) => {
	switch (action.type) {
		case GET_WEBSITE_PROFILE:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
