import {SET_UNIT_SEARCH} from "../actions/types";

const reducer = (state = {}, action) => {
	switch (action.type) {
		case SET_UNIT_SEARCH:
			return action.payload;
		default:
			return state;
	}
};

export default reducer;
