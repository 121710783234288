import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AnvilDocument from "../account/rentals/documents/anvil/AnvilDocumnet";

const DocumentSignature = (props) => {
  const params = useParams();

  const [docDetails, setDocDetails] = useState();

  const getDocument = async () => {
    let { data } = await axios.post(
      `/api/create-get-contract/${params.reservationId}`
    );
    setDocDetails(data.data);
  };

  useEffect(() => {
    getDocument();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!docDetails) return;
  }, [docDetails]);

  return (
    <div>
      {docDetails ? (
        <AnvilDocument
          showIconClose={false}
          close={() => console.log("CLOSED")}
          signerName={docDetails.eSigSignatures[0].name}
          signerEid={docDetails.eSigSignatures[0].eid}
          onFinish={() => console.log("FINISHED SIGNING")}
        ></AnvilDocument>
      ) : undefined}
    </div>
  );
};

export default DocumentSignature;
