import { Route } from "react-router-dom";
import ResetPassword from "./reset-password/ResetPassword";
import styles from "./Settings.module.scss";

const SettingsPage = (props) => {
  return (
    <div className={`${styles.settingsPage} page`}>
      {/* <ResetPassword /> */}
      <Route
        path="/account/settings/reset-password"
        component={ResetPassword}
      />
    </div>
  );
};

export default SettingsPage;
