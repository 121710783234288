import HoursSVG from "../../assets/images/svgs/hours.svg";
import PhoneFilledSVG from "../../assets/images/svgs/phone-filled.svg";
import NewMapPinSVG from "../../assets/images/svgs/NewMapPin.svg";
import Header from "../../components/header/Header";

import styles from "./Contact.module.scss";
import { useSelector } from "react-redux";
import SAInput from "../../components/ui/form-elements/stand-alone-input/Input";
import SATextArea from "../../components/ui/form-elements/stand-alone-input/textarea";
import Footer from "../../components/footer/Footer";
import { formatPhoneNumber } from "../../helper";

const Contact = (props) => {
  const { websiteProfile } = useSelector(({ websiteProfile }) => ({
    websiteProfile,
  }));

  console.log(websiteProfile.locations[0].name);

  return (
    <div className={styles.contactPage}>
      <Header />
      <div className={`content_wrpr ${styles.contactWrapper}`}>
        <h2 className={`ffqsb tac fs25 p-t-75`}>Have A Question?</h2>
        <p className={`ffmr fs16 tac m-t-15`}>
          Leave us a line and we'll get back to you very soon.
        </p>
        <div className={`df acsa m-t-50 p-b-100 ${styles.contentWrpr}`}>
          <div
            className={`p-t-95 p-b-95 p-l-80 p-r-80 ffqsb fs16 ${styles.sec} ${styles.details}`}
          >
            <div className={`df ${styles.infoSec}`}>
              <div className={`df acc m-r-15 ${styles.iconWrpr}`}>
                <NewMapPinSVG fill={websiteProfile.theme.primaryTextColor} />
              </div>
              <div>
                <p>{websiteProfile.locations[0].name}</p>
                <p>{websiteProfile.locations[0].address.deliveryLine1}</p>
                <p>{websiteProfile.locations[0].address.lastLine}</p>
                {/* address_line_1 */}
              </div>
            </div>
            <div className={`df ${styles.infoSec}`}>
              <div className={`df acc m-r-15 ${styles.iconWrpr}`}>
                <PhoneFilledSVG fill={websiteProfile.theme.primaryTextColor} />
              </div>
              <div>
                <p>{formatPhoneNumber(websiteProfile.profilePhoneNumber)}</p>
                <p>{websiteProfile.email}</p>
              </div>
            </div>
            <div className={`df ${styles.infoSec}`}>
              <div className={`df acc m-r-15 ${styles.iconWrpr}`}>
                <HoursSVG fill={websiteProfile.theme.primaryTextColor} />
              </div>
              <div>
                {websiteProfile.hours.map((hour, idx) => (
                  <p key={idx}>
                    {hour.label}
                    {hour.startTime ? `:${hour.startTime}` : ""}{" "}
                    {hour.endTime ? `- ${hour.endTime}` : ""}
                  </p>
                ))}
              </div>
            </div>
            <div className={`df ${styles.infoSec}`}>
              <div className={`df acc m-r-15 ${styles.iconWrpr}`}></div>
              <div className={`ptc`}>Gate access: 24/7</div>
            </div>
          </div>
          <div className={`p-t-50 p-b-50 ${styles.sec} ${styles.form}`}>
            <div>
              <SAInput label="Name" placeholder="Enter your name" />
            </div>
            <div>
              <SAInput label="Phone" placeholder="Enter your phone number" />
            </div>
            <div>
              <SAInput label="Email" placeholder="Enter your email" />
            </div>
            <div>
              <SATextArea
                label="Comment"
                placeholder="Enter your comment"
                height="110"
              />
            </div>

            <button
              className={`df ffmb fs14 ttuc fwbold srch_btn button primary w100 m-t-20`}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
