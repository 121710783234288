export const GET_USER = "GET_USER";
export const GET_WEBSITE_PROFILE = "GET_WEBSITE_PROFILE";
export const GET_UNIT_SIZES = "GET_UNIT_SIZES";
export const SET_UNIT_SEARCH = "SET_UNIT_SEARCH";
export const GET_UNIT_SEARCH = "GET_UNIT_SEARCH";
export const GET_BEST_UNIT_MATCH = "GET_BEST_UNIT_MATCH";
export const GET_UNIT = "GET_UNIT";
export const CURRENT_USER = "CURRENT_USER";
export const CLEAR_AUTH = "CLEAR_AUTH";
export const SET_RESERVATION = "SET_RESERVATION";
export const RESERVATION_ERROR = "RESERVATION_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const GET_USER_PAY_METHODS = "GET_USER_PAY_METHODS";
export const GET_DEFAULT_PAY_METHODS = "GET_DEFAULT_PAY_METHODS";
export const SET_FEATURES = "SET_FEATURES";
export const ADD_TOAST = "ADD_TOAST";
export const REMOVE_TOAST = "REMOVE_TOAST";
export const ADD_PIN = "ADD_PIN";
export const REMOVE_PIN = "REMOVE_PIN";
