import React from "react";

export const TwitterSvg = (props) => (
	<svg
		width="24"
		height="20"
		viewBox="0 0 24 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M21.5156 5.125C21.5469 5.25 21.5625 5.45312 21.5625 5.73438C21.5625 7.39062 21.25 9.04688 20.625 10.7031C20 12.3281 19.1094 13.8281 17.9531 15.2031C16.8281 16.5469 15.3594 17.6406 13.5469 18.4844C11.7344 19.3281 9.73438 19.75 7.54688 19.75C4.79688 19.75 2.28125 19.0156 0 17.5469C0.34375 17.5781 0.734375 17.5938 1.17188 17.5938C3.45312 17.5938 5.5 16.8906 7.3125 15.4844C6.21875 15.4844 5.25 15.1719 4.40625 14.5469C3.59375 13.8906 3.03125 13.0781 2.71875 12.1094C3.03125 12.1406 3.32812 12.1562 3.60938 12.1562C4.04688 12.1562 4.48438 12.1094 4.92188 12.0156C4.17188 11.8594 3.5 11.5469 2.90625 11.0781C2.3125 10.6094 1.84375 10.0469 1.5 9.39062C1.15625 8.70312 0.984375 7.96875 0.984375 7.1875V7.09375C1.67188 7.5 2.40625 7.71875 3.1875 7.75C1.71875 6.75 0.984375 5.375 0.984375 3.625C0.984375 2.75 1.21875 1.92188 1.6875 1.14062C2.90625 2.67188 4.39062 3.89063 6.14062 4.79688C7.92188 5.70312 9.8125 6.20312 11.8125 6.29688C11.75 5.92188 11.7188 5.54688 11.7188 5.17188C11.7188 3.82812 12.1875 2.67188 13.125 1.70312C14.0938 0.734375 15.25 0.25 16.5938 0.25C18.0312 0.25 19.2344 0.765625 20.2031 1.79688C21.3281 1.57812 22.375 1.1875 23.3438 0.625C22.9688 1.78125 22.25 2.6875 21.1875 3.34375C22.125 3.21875 23.0625 2.95313 24 2.54688C23.3125 3.54687 22.4844 4.40625 21.5156 5.125Z"
			fill={props.fill || "red"}
		/>
	</svg>
);
