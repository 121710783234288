import React from "react";

//import scss
import "./Switch.scss";
import { ThemeContext } from "../../../../App";

const SwitchInput = ({
  field: { name, value, onChange, onBlur },
  form: { errors, touched, setFieldValue },
  id,
  label,
  className,
  ...props
}) => {
  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          <style>
            {`
              .__switch.selected label.display_lbl {
                color: ${val}
              }
              .__switch.selected .__switch_label {
                border-color: ${val}
              }

              .__switch.selected .__switch_label:after {
                background-color: ${val}
              }
            `}
          </style>
          <div className={`df acsa __switch ${value ? "selected" : ""}`}>
            <label className={`__switch_label`} htmlFor={id}></label>
            <label htmlFor={id} className="ff14 ffmr ttcaps display_lbl">
              {label}
            </label>
            <input
              name={name}
              id={id}
              type="checkbox"
              value={value}
              checked={value}
              onChange={onChange}
              onBlur={onBlur}
              className="dn"
            ></input>
          </div>
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default SwitchInput;
