const HoursSVG = (props) => (
  <svg
    width="40"
    height="41"
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="39" height="39" rx="19.5" fill="#30BE76" />
    <g clipPath="url(#clip0_7635_2122)">
      <rect x="13" y="5" width="17" height="25" fill="white" />
      <path
        d="M19.4997 0C8.73043 0 0 8.73069 0 19.4997C0 30.2687 8.73043 39 19.4997 39C30.2689 39 39 30.2687 39 19.4997C39 8.73069 30.2689 0 19.4997 0ZM27.9299 23.2085H19.6628C19.6349 23.2085 19.6089 23.2017 19.5812 23.2007C19.5535 23.202 19.5276 23.2085 19.4995 23.2085C18.7548 23.2085 18.1509 22.6046 18.1509 21.8599V8.09251C18.1509 7.34772 18.7548 6.74386 19.4995 6.74386C20.2443 6.74386 20.8482 7.34772 20.8482 8.09251V20.5112H27.9294C28.6742 20.5112 29.278 21.1151 29.278 21.8599C29.278 22.6046 28.6747 23.2085 27.9299 23.2085Z"
        fill={props.fill || "#30BE76"}
      />
    </g>
    <defs>
      <clipPath id="clip0_7635_2122">
        <rect width="39" height="39" fill="white" transform="translate(0 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default HoursSVG;
