import Portal from "../../../../components/ui/portal/Portal";
import SAInput from "../../../../components/ui/form-elements/stand-alone-input/Input";

import styles from "./ResetPassword.module.scss";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { CURRENT_USER } from "../../../../store/actions/types";

const ResetPassword = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { auth } = useSelector(({ auth }) => ({ auth }));

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const submit = async () => {
    const token = await localStorage.getItem("x-auth");
    let res = await axios.put(
      `/api/web-user/${auth?.user?._id}/?simple=true`,
      { password: password },
      { headers: { "x-auth": token } }
    );
    if (res?.data?.email) {
      console.log(res?.data);
      dispatch({ type: CURRENT_USER, payload: res?.data });
      history.push("/account/settings");
    }
  };

  return (
    <Portal
      height="auth"
      width="320px"
      title="Set up"
      close={() => history.push("/account/settings")}
      opacity="0.3"
    >
      <div className={styles.cntnt}>
        <p className={`tac ffmm fs12 m-t-5 m-b-25`}>Enter new password</p>
        <div className={`${styles.line}`}>
          <SAInput
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            name="password"
          />
        </div>

        <div className={`${styles.line}`}>
          <SAInput
            label="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            name="password"
          />
        </div>

        <button
          disabled={
            !password?.length ||
            !confirmPassword?.length ||
            password !== confirmPassword
          }
          onClick={submit}
          className={`df ffmsb fs14 w100 ttuc button primary pbgc m-t-30 m-b-10`}
        >
          Save Password
        </button>
      </div>
    </Portal>
  );
};

export default ResetPassword;
