import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { REMOVE_TOAST } from "../../store/actions/types";
import styles from "./Toast.module.scss";

const Toast = (props) => {
  const dispatch = useDispatch();

  const [reducing, setReducing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setReducing(true);
    }, 5);
    setTimeout(() => {
      dispatch({
        type: REMOVE_TOAST,
        payload: props.data.id,
      });
    }, 5900);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${styles[props.data.type]} ${styles.toast}`}>
      <p className={`ffmr fs14`}>{props.data.message}</p>
      {props.data.errors?.map((e) => (
        <p className={`ffmr fs12 m-t-8 ${styles.errorText}`}>{e}</p>
      ))}
      <div className={styles.meter}>
        <div
          style={{ width: reducing ? "0%" : "100%" }}
          className={styles.lever}
        ></div>
      </div>
    </div>
  );
};

export default Toast;
