import React from "react";

export const ArrowSvg = (props) => (
	<svg
		width="17"
		height="17"
		viewBox="0 0 17 17"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.83594 0.682251L16.8516 8.69788L8.83594 16.7135L7.42969 15.3073L13.0078 9.68225H0.820312V7.7135H13.0078L7.42969 2.0885L8.83594 0.682251Z"
			fill={props.fill || "red"}
		/>
	</svg>
);
