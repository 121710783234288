import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ExpandMore } from "../../../../assets/images/svgs/expand-more.svg";
import { AddItemButton } from "../../../../components/ui/buttons/add-item-button/AddItemButton";
import AddAddress from "../../../../containers/account/settings/add-address/AddAddress";
import { CURRENT_USER } from "../../../../store/actions/types";
import styles from "./Address.module.scss";

const Address = ({ label, onUpdate }) => {
  const dispatch = useDispatch();

  const { auth } = useSelector(({ auth }) => ({ auth }));

  const [showAddressPopup, setShowAddressPopup] = useState(false);
  return (
    <div className={`${styles.sec}`}>
      <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
        {label}
        <span className="mla" onClick={() => setShowAddressPopup(true)}>
          <ExpandMore />
        </span>
      </h5>
      {auth?.user?.defaultAddress ? (
        <>
          <p className={`ffmr fs14 m-b-5`}>
            {auth?.user?.defaultAddress?.deliveryLine1}
          </p>
          <p className={`ffmr fs14`}>{auth?.user?.defaultAddress?.lastLine}</p>
        </>
      ) : (
        <>
          <AddItemButton
            text="Add billing address"
            action={() => setShowAddressPopup(true)}
          />
        </>
      )}

      {showAddressPopup ? (
        <AddAddress
          close={() => setShowAddressPopup(false)}
          savedAddress={auth?.user?.defaultAddress}
          updatedAddress={(address) => {
            dispatch({
              type: CURRENT_USER,
              payload: { ...auth.user, defaultAddress: address },
            });
          }}
        />
      ) : undefined}
    </div>
  );
};

export default Address;
