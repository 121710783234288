import React from "react";
import { Overlay } from "../overlay/overlay";

// import scss
import "./popup.scss";

export const Popup = props => (
  <>
    <div
      className={`__popup ${props.customClass}`}
      style={{ zIndex: props.zIndex, width: props.width }}
    >
      {props.closeAction && (
        <div
          className="__pp_close"
          onClick={props.closeAction}
          title="close"
        ></div>
      )}

      <div className="__pp_cntnt">{props.children}</div>
    </div>
    <Overlay
      bgColor="rgba(54, 56, 55, 0.87)"
      zIndex={props.zIndex - 1}
    ></Overlay>
  </>
);
