import React from "react";

export const CircleArrowSvg = (props) => (
	<svg
		width="44"
		height="44"
		viewBox="0 0 44 44"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22 43C10.402 43 1 33.5979 0.999999 22C0.999999 10.402 10.402 0.999969 22 0.999969C33.598 0.999968 43 10.402 43 22C43 33.5979 33.598 43 22 43Z"
			stroke={props.fill || "red"}
			strokeWidth="2"
		/>
		<path
			d="M22 13.9843L30.0156 22L22 30.0156L20.5937 28.5625L26.1719 22.9843L13.9844 22.9843L13.9844 21.0156L26.1719 21.0156L20.5937 15.3906L22 13.9843Z"
			fill={props.fill || "red"}
		/>
		<path
			d="M22 13.9843L22.7071 13.2772L22 12.5701L21.2929 13.2772L22 13.9843ZM30.0156 22L30.7227 22.7071L31.4298 22L30.7227 21.2929L30.0156 22ZM22 30.0156L21.2814 30.711L21.9883 31.4415L22.7071 30.7227L22 30.0156ZM20.5937 28.5625L19.8866 27.8554L19.191 28.551L19.8751 29.2579L20.5937 28.5625ZM26.1719 22.9843L26.879 23.6915L28.5861 21.9843L26.1719 21.9843L26.1719 22.9843ZM13.9844 22.9843L12.9844 22.9843L12.9844 23.9843L13.9844 23.9843L13.9844 22.9843ZM13.9844 21.0156L13.9844 20.0156L12.9844 20.0156L12.9844 21.0156L13.9844 21.0156ZM26.1719 21.0156L26.1719 22.0156L28.5719 22.0156L26.8819 20.3115L26.1719 21.0156ZM20.5937 15.3906L19.8866 14.6835L19.1825 15.3876L19.8837 16.0947L20.5937 15.3906ZM21.2929 14.6915L29.3085 22.7071L30.7227 21.2929L22.7071 13.2772L21.2929 14.6915ZM29.3085 21.2929L21.2929 29.3085L22.7071 30.7227L30.7227 22.7071L29.3085 21.2929ZM22.7186 29.3202L21.3124 27.867L19.8751 29.2579L21.2814 30.711L22.7186 29.3202ZM21.3009 29.2696L26.879 23.6915L25.4648 22.2772L19.8866 27.8554L21.3009 29.2696ZM26.1719 21.9843L13.9844 21.9843L13.9844 23.9843L26.1719 23.9843L26.1719 21.9843ZM14.9844 22.9843L14.9844 21.0156L12.9844 21.0156L12.9844 22.9843L14.9844 22.9843ZM13.9844 22.0156L26.1719 22.0156L26.1719 20.0156L13.9844 20.0156L13.9844 22.0156ZM26.8819 20.3115L21.3038 14.6865L19.8837 16.0947L25.4618 21.7197L26.8819 20.3115ZM21.3009 16.0977L22.7071 14.6915L21.2929 13.2772L19.8866 14.6835L21.3009 16.0977Z"
			fill={props.fill || "red"}
		/>
	</svg>
);
