import React, { Component } from "react";
import { isOdd } from "../helper";

import { ThemeContext } from "../App";

import me from "../assets/images/me.jpg";

// import scss
import "./FeedbackCarousel.scss";

class FBCarousel extends Component {
  constructor(props) {
    super(props);
    this.container = React.createRef();
  }

  state = {
    wrprWidth: null,
    reviewsList: [
      {
        name: "Carol Morrell",
        text: "Haven't been to my unit in 6 months when I opened it up they're were mouse droppings all over. The unit has been fine till this time. I do normally go to it more often though. I'm not sure if that has anything to do with it.",
      },
      {
        name: "Jason Saville",
        text: "Northway self storage facility is the best storage unit for the neighborhood.",
      },
      {
        name: "Jared Buell",
        text: "When I first looked for a safe place to store my home, while awaiting a purchase, I wanted to find a place where I trusted the people and felt secure. Over the period I have stored with them, I found excellent service and the facility always very clean/secure.",
      },
    ],
    reviewPages: 0,
    currentPage: 0,
  };

  movePage = (val) => this.setState({ currentPage: val });

  handleResize = (e) =>
    this.setState({ wrprWidth: this.container.current.clientWidth });
  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.setState({ wrprWidth: this.container.current.clientWidth });

    const reviewsListLength = !isOdd(this.state.reviewsList.length)
      ? new Array(this.state.reviewsList.length / 2)
      : new Array((this.state.reviewsList.length + 1) / 2);
    this.setState({
      reviewPages: reviewsListLength.fill(),
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    return (
      <div className="carousel_sec">
        <div className="df ffc content_wrpr">
          <h3 className="ffqsb fs20">What Our Customers Say</h3>

          <div className="list_wrpr" ref={this.container}>
            <div
              className="df inner_list_wrpr"
              style={{
                left: `-${this.state.wrprWidth * this.state.currentPage}px`,
              }}
            >
              {this.state.reviewsList.map((val, i) => (
                <div
                  key={i}
                  className={`df item ${
                    this.container &&
                    this.container.current &&
                    this.container.current.clientWidth >= 600
                      ? ""
                      : "ffc acsa"
                  }`}
                  style={{
                    width: `${
                      this.container &&
                      this.container.current &&
                      this.container.current.clientWidth >= 600
                        ? "calc(" + this.state.wrprWidth / 2 + "px - 50px)"
                        : "100%"
                    }`,
                    alignItems: `${
                      this.container &&
                      this.container.current &&
                      this.container.current.clientWidth >= 600
                        ? "flex-start"
                        : "center"
                    }`,
                  }}
                >
                  <div className="df acc img_wrpr">
                    {val.imageURL ? (
                      <img src={me} alt=""></img>
                    ) : (
                      <p className="ffqsb fs28 ptc reviewInitial">
                        {val.name.split(" ")[0].slice(0, 1)}
                      </p>
                    )}
                  </div>
                  <div className="cntnt_wrpr">
                    <q className="fs14">{val.text}</q>

                    <p className="ffqsb fs14 name ptc">{val.name}</p>
                  </div>
                </div>
              ))}
            </div>
            <div className="df car_nav_wrpr">
              {this.state.reviewPages.length &&
                this.state.reviewPages.map((itm, i) => (
                  <React.Fragment key={i}>
                    <ThemeContext.Consumer>
                      {(value) => (
                        <span
                          className={`nav_dot ${
                            this.state.currentPage === i ? "active" : ""
                          }`}
                          style={{
                            backgroundColor: `${
                              this.state.currentPage === i ? value : "#828282"
                            }`,
                          }}
                          onClick={() => this.movePage(i)}
                        ></span>
                      )}
                    </ThemeContext.Consumer>
                  </React.Fragment>
                ))}
            </div>

            <div className="ffqsb lur ptc">Leave Us A Review &gt;</div>
          </div>
        </div>
      </div>
    );
  }
}

export default FBCarousel;
