import Axios from "axios";
import React, { useEffect, useState } from "react";
import AmexImg from "../../../../assets/images/credit-cards/american-express.svg";
import CitiImg from "../../../../assets/images/credit-cards/citi.svg";
import Discover from "../../../../assets/images/credit-cards/discover.svg";
import MasterImg from "../../../../assets/images/credit-cards/mastercard.svg";
import VisaImg from "../../../../assets/images/credit-cards/visa.svg";
import Portal from "../../../../components/ui/portal/Portal";
import AddCard from "../add-card/AddCard";
import styles from "./SelectPayment.module.scss";
// import ordinal from "ordinal";
import { formatFromCurrencyToMoney } from "../../../../helper";

const images = {
  Visa: VisaImg,
  Amex: AmexImg,
  Citi: CitiImg,
  MasterCard: MasterImg,
  Discover,
};

const SelectPayment = (props) => {
  const [payMethods, setPayMethods] = useState([]);
  const [payMethod, setPayMethod] = useState(props.selectedId);
  const [showAddCard, setShowAddCard] = useState(false);

  const toggleAddPopup = () => setShowAddCard(!showAddCard);

  const getPayMethods = async () => {
    let methods = await Axios.get(
      `/api/user-pay-methods/${props.userId}${
        props.includeAutopays ? "/?includeAutopays=true" : ""
      }`
    );
    setPayMethods(methods.data.paymentMethods);
  };

  useEffect(
    () => {
      getPayMethods();
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className={`df ${styles.popup}`}>
      <h4 className={`ffmb fs12 m-b-25 ttuc`}>saved cards</h4>
      <div className={styles.linesWrpr}>
        {payMethods.map((method) => {
          return (
            <div
              className={`df acsa ffmm fs16 ${styles.line}`}
              key={method._id}
              onClick={() => setPayMethod(method._id)}
            >
              <div
                className={`df acc ${styles.radio}${
                  payMethod === method._id ? ` ${styles.selected}` : ""
                }`}
              >
                {payMethod === method._id ? (
                  <div className={styles.bullet} />
                ) : undefined}
              </div>
              <div className={`df acc ${styles.logoWrpr}`}>
                <img src={images[method.xCardType]} alt="hello" />
              </div>
              <div className={styles.textWrpr}>
                <div className={`df acsa ${styles.cardNumber}`}>
                  <p>{method.xCardType}</p>
                  <div className={`df acsa ${styles.dotsWrpr}`}>
                    <div className={`${styles.dot}`}></div>
                    <div className={`${styles.dot}`}></div>
                    <div className={`${styles.dot}`}></div>
                    <div className={`${styles.dot}`}></div>
                  </div>
                  <p>
                    {method.xMaskedCardNumber.substring(
                      method.xMaskedCardNumber.length - 4
                    )}
                  </p>
                </div>
                {method?.autopays?.length ? (
                  <div className={`df acsa ffmr fs12`}>
                    Autopay unit
                    {method.autopays.map((autopay, idx) => (
                      <div className={`m-l-6 ${styles.unitAutoPay}`} key={idx}>
                        <p className={`ttuc tdul`}>{autopay.unit[0].unitId}</p>

                        <div className={styles.popover}>
                          <div className={`df acsa ${styles.popoverLine}`}>
                            <p className={styles.label}>Unit:</p>
                            <p className={`ttuc ${styles.value}`}>
                              {autopay.unit[0].unitId}
                            </p>
                          </div>

                          <div className={`df acsa ${styles.popoverLine}`}>
                            <p className={styles.label}>Rate:</p>
                            <p className={`${styles.value}`}>
                              {formatFromCurrencyToMoney(
                                autopay.unit[0].unitSize[0].price
                              )}
                            </p>
                          </div>

                          {/* <div className={`df acsa ${styles.popoverLine}`}>
                            <p className={styles.label}>Pay Date:</p>
                            <p className={`${styles.value}`}>
                              {autopay.autoPayDay
                                ? ordinal(autopay.autoPayDay)
                                : autopay.autoPayDay}{" "}
                              of the month
                            </p>
                          </div> */}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : undefined}
              </div>
            </div>
          );
        })}
      </div>

      <div className="df ffc jcfe ftr m-t-44 m-b-10">
        <button
          disabled={!payMethod}
          className="df ffmsb  fs14 ttuc button primary"
          onClick={() => {
            props.selectAction(payMethod);
            if (props.close) props.close();
          }}
        >
          {props.selectText ? props.selectText : "Select Card"}
        </button>
        <button
          className="df m-r-5 m-t-8 ffmsb fs14 ttuc button secondary"
          onClick={() => toggleAddPopup()}
        >
          Add {payMethods.length ? "another" : ""} Card
        </button>
        {showAddCard ? (
          <Portal
            zIndex="104"
            title="Add Card"
            noCustomScrollbar
            opacity=".08"
            width="498px"
            close={() => setShowAddCard(false)}
            height="auto"
          >
            <AddCard
              close={toggleAddPopup}
              refreshCards={getPayMethods}
            ></AddCard>
          </Portal>
        ) : undefined}
      </div>
    </div>
  );
};

export default SelectPayment;
