import React from "react";

export const GoogleLoginSVG = (props) => (
	<svg
		width="44"
		height="45"
		viewBox="0 0 44 45"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g clipPath="url(#googleClip)">
			<path
				d="M10.1405 27.0373L8.62634 32.6899L3.09207 32.807C1.43814 29.7393 0.5 26.2295 0.5 22.4997C0.5 18.893 1.37714 15.4919 2.93192 12.4971H2.93311L7.86017 13.4004L10.0185 18.2979C9.56678 19.6148 9.32056 21.0286 9.32056 22.4997C9.32073 24.0963 9.60994 25.626 10.1405 27.0373Z"
				fill="#FBBB00"
			/>
			<path
				d="M43.6198 18.4365C43.8696 19.7522 43.9999 21.111 43.9999 22.4997C43.9999 24.0569 43.8361 25.5758 43.5243 27.0409C42.4655 32.0267 39.6989 36.3803 35.8664 39.4611L35.8652 39.4599L29.6593 39.1433L28.781 33.6603C31.324 32.1689 33.3114 29.835 34.3583 27.0409H22.728V18.4365H34.528H43.6198Z"
				fill="#518EF8"
			/>
			<path
				d="M35.8659 39.4602L35.8671 39.4613C32.1398 42.4573 27.4049 44.2499 22.2507 44.2499C13.9678 44.2499 6.7664 39.6203 3.09277 32.8073L10.1412 27.0376C11.978 31.9397 16.7068 35.4293 22.2507 35.4293C24.6336 35.4293 26.866 34.7851 28.7816 33.6606L35.8659 39.4602Z"
				fill="#28B446"
			/>
			<path
				d="M36.1332 5.75726L29.0871 11.5258C27.1045 10.2865 24.761 9.57065 22.2502 9.57065C16.5808 9.57065 11.7636 13.2203 10.0188 18.2982L2.93332 12.4974H2.93213C6.55197 5.51826 13.8442 0.75 22.2502 0.75C27.5276 0.75 32.3663 2.62985 36.1332 5.75726Z"
				fill="#F14336"
			/>
		</g>
		<defs>
			<clipPath id="googleClip">
				<rect
					width="43.5"
					height="43.5"
					fill="white"
					transform="translate(0.5 0.75)"
				/>
			</clipPath>
		</defs>
	</svg>
);
