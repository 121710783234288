// import { NavLink } from "react-router-dom";
// import NavSVG from "../../../../../assets/images/svgs/nav-arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Navigation } from "swiper/core";

import styles from "./Setup.module.scss";

import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";
import { useSelector } from "react-redux";
import { formatPhoneNumber } from "../../../../../helper";

const Settings = (props) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  const fullAddress = () => {
    console.log(auth.user.defaultAddress);
    if (!auth.user || !auth.user.defaultAddress) {
      return "";
    }

    const { deliveryLine1, lastLine } = auth.user.defaultAddress;

    return `${deliveryLine1} ${lastLine}`;
  };

  return (
    <div className={`m-b-15 ${styles.setup}`}>
      <div className={`df acsa ${styles.hdr}`}>
        <h4 className={`ffqsb fs20`}>Settings</h4>
        {/* <NavLink
          to="/account/payments"
          className={`mla ffqsb fs12 tdn ${styles.seeAll}`}
        >
          See all <NavSVG></NavSVG>
        </NavLink> */}
      </div>

      <Swiper
        centeredSlidesBounds
        slidesPerView={"auto"}
        spaceBetween={14}
        // navigation={true}
        pagination={{ clickable: true }}
        className={`${styles.slider} customized`}
        initialSlide={2}
      >
        <SwiperSlide className={styles.slide} style={{ width: "230px" }}>
          <div className={styles.slideContent}>
            <h5 className={`ffmb fs16 m-t-18 m-b-8`}>About</h5>
            <p className={`ffmr fs14 m-b-4`}>{auth?.user?.email}</p>
            <p className={`ffmr fs14 m-b-4`}>{auth?.user?.fullName}</p>
          </div>
        </SwiperSlide>

        <SwiperSlide className={styles.slide} style={{ width: "250px" }}>
          <div className={styles.slideContent}>
            <h5 className={`ffmb fs16 m-t-18 m-b-8`}>About</h5>
            <p className={`ffmr fs14 m-b-4`}>
              {auth?.user?.defaultPhoneNumber.number
                ? formatPhoneNumber(auth?.user?.defaultPhoneNumber.number)
                : undefined}
            </p>
            <p className={`ffmr fs14 m-b-4`}>{fullAddress()}</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Settings;

// Settings
