import axios from "axios";
import Axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import dayjs from "dayjs";
import ordinal from "ordinal";
import AmexImg from "../../assets/images/credit-cards/american-express.svg";
import CitiImg from "../../assets/images/credit-cards/citi.svg";
import Discover from "../../assets/images/credit-cards/discover.svg";
import MasterImg from "../../assets/images/credit-cards/mastercard.svg";
import VisaImg from "../../assets/images/credit-cards/visa.svg";
import { ExpandMore } from "../../assets/images/svgs/expand-more.svg";
import { AddItemButton } from "../../components/ui/buttons/add-item-button/AddItemButton";
import Portal from "../../components/ui/portal/Portal";
import AddAddress from "../../containers/account/settings/add-address/AddAddress";
import AddCard from "../../containers/account/payments/add-card/AddCard";
import SelectPayment from "../../containers/account/payments/select-payment/SelectPayment";
import {
  formatFromCurrencyToMoney,
  formatPhoneNumber,
  getToken,
} from "../../helper";
import { CURRENT_USER, GET_UNIT } from "../../store/actions/types";
import styles from "./RCPopup.module.scss";
import AddPhone from "../../containers/account/settings/add-phone/AddPhone";
import BrokenPipeSVG from "../../assets/images/svgs/broken-pipe.svg";
import RandomSVG from "../../assets/images/svgs/random.svg";
import SAInput from "../../components/ui/form-elements/stand-alone-input/Input";
import TrailerSVG from "../../assets/images/svgs/trailer.svg";
import WatercraftSVG from "../../assets/images/svgs/watercraft.svg";
import AutoComplete from "../../components/ui/form-elements/drop-down/AutoComplete";

// Content types
import HouseholdItemsTypeSVG from "../../assets/images/svgs/storage-types/household-items.svg";
import FurnitureTypeSVG from "../../assets/images/svgs/storage-types/furniture.svg";
import BoxesTypeSVG from "../../assets/images/svgs/storage-types/boxes.svg";
import DocumentTypeSVG from "../../assets/images/svgs/storage-types/document.svg";
import ToolsTypeSVG from "../../assets/images/svgs/storage-types/tools.svg";
import MotorVehicleSVG from "../../assets/images/svgs/motor-vehicle.svg";

const images = {
  Visa: VisaImg,
  Amex: AmexImg,
  Citi: CitiImg,
  MasterCard: MasterImg,
  Discover,
};

const contentTypes = [
  {
    label: "Household items",
    value: "householdItems",
  },
  {
    label: "Furniture",
    value: "furniture",
  },
  {
    label: "Documents",
    value: "documents",
  },
  {
    label: "Tools",
    value: "tools",
  },
  {
    label: "Boxes",
    value: "boxes",
  },
  {
    label: "Motor vehicle",
    value: "motorVehicle",
  },
];
const RCPopup = ({
  locationId,
  reservationId,
  selectedDate,
  unitId,
  unitSize,
  hidePayLaterButton,
}) => {
  const dispatch = useDispatch();

  const [insuranceBrackets, setInsuranceBrackets] = useState([]);
  const [addCard, setAddCard] = useState(false);
  const [payMethod, setPayMethod] = useState(null);
  const [showSelectPayMethod, setShowSelectPayMethod] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showPhonePopup, setShowPhonePopup] = useState(false);
  const [showNoUnits, setShowNoUnits] = useState(false);

  const [dob, setDob] = useState("");
  const [lastFourSSN, setLastFourSSN] = useState("");
  const [lastFourDriversLicense, setLastFourDriversLicense] = useState("");
  const [licenseState, setLicenseState] = useState("");
  const [licenseExpiration, setLicenseExpiration] = useState("");
  // Vehicle details
  // const [isVehicle, setIsVehicle] = useState(false);
  const [vehicleType, setVehicleType] = useState(null);
  const [vehicleMake, setVehicleMake] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleVin, setVehicleVin] = useState("");
  const [vehicleLength, setVehicleLength] = useState("");
  const [vehicleColor, setVehicleColor] = useState("");
  const [vehicleDescription, setVehicleDescription] = useState("");

  // Alternate contact details
  const [alternateContact, setAlternateContact] = useState(false);
  const [altName, setAltName] = useState("");
  const [alt_delivery_line_1, setAltDeliveryLine1] = useState("");
  const [alt_last_line, setAltLastLine] = useState("");
  const [altPhone, setAltPhone] = useState("");

  // Autopay details
  const [isAutopay, setIsAutopay] = useState(false);
  const [storedContent, setStoredContent] = useState([]);

  // Insurance details
  const [insuranceCompany, setInsuranceCompany] = useState(undefined);
  const [insuranceBracket, setInsuranceBracket] = useState(undefined);
  const [outsideInsurancePolicyNumber, setOutsideInsurancePolicyNumber] =
    useState("");

  // Service member
  const [serviceMember, setServiceMember] = useState(undefined);

  // const setCardAction = (val) => {
  //   console.log(val);
  //   setPayMethod(val);
  // };

  const { auth, websiteProfile, reservationUnit } = useSelector(
    ({ auth, websiteProfile, reservationUnit }) => ({
      auth,
      websiteProfile,
      reservationUnit,
    })
  );

  const history = useHistory();
  const params = useParams();

  const getDefaultPayMethod = async () => {
    // console.log("Payment action");

    let method = await Axios.get(`/api/default-pay-method/${auth.user._id}`);
    // console.log(method.data);

    setPayMethod(method.data.data);
  };

  const getAndSetPayMethod = async (methodId) => {
    // console.log(methodId);
    let method = await Axios.get(`/api/payment-method/${methodId}`);
    setPayMethod(method.data.data);
    return;
  };

  // useEffect(() => {
  // 	console.log("Getting pay method");
  // 	getDefaultPayMethod();
  // }, [payMethod]);

  const getReservationUnit = async () => {
    let unit = await axios.get(
      `/api/reserve-unit/?unitid=${unitId}&moveindate=${selectedDate}`
    );

    // setUnitDetails(unit.data.data);

    dispatch({
      type: GET_UNIT,
      payload: unit.data.data,
    });
  };

  useEffect(
    () => {
      if (!reservationUnit?.total) getReservationUnit();
      // console.log("Getting pay method");
      getDefaultPayMethod();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (insuranceBracket === null) return;

    setOutsideInsurancePolicyNumber("");
  }, [insuranceBracket]);

  const getInsuranceBrackets = async () => {
    // unitLocation

    let insuranceProvider = await axios.get(
      `/api/insurance-company/${reservationUnit.unitLocation._id}`
    );

    console.log(insuranceProvider?.data?.data[0]?._id);

    let insuranceProviderId = insuranceProvider?.data?.data[0]?._id;

    if (!insuranceProviderId) return;

    let localInsuranceBrackets = await axios.get(
      `/api/insurance-brackets/${insuranceProviderId}`
    );

    setInsuranceBrackets(localInsuranceBrackets?.data?.data);
  };

  useEffect(() => {
    if (!reservationUnit) return;

    getInsuranceBrackets();
    // console.log("RESERVATION UNIT _-_-_-_ ", reservationUnit);
    // eslint-disable-next-line
  }, [reservationUnit]);

  const setShowSelectPayMethodAction = async (id) => {
    if (!id) return setShowSelectPayMethod(false);

    await getAndSetPayMethod(id);

    setShowSelectPayMethod(false);
  };

  const isVehicleType = (type) => vehicleType === type;

  const handleSelectStoredContent = (type) => {
    let includes = storedContent.includes(type);
    if (includes) {
      setStoredContent(storedContent.filter((item) => item !== type));
    } else {
      setStoredContent([...storedContent, type]);
    }
  };

  const isTypeSelected = (type) => {
    return storedContent.includes(type);
  };

  const getIcon = (value, isSelected) => {
    switch (value) {
      case "householdItems":
        return <HouseholdItemsTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
      case "furniture":
        return <FurnitureTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
      case "documents":
        return <DocumentTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
      case "tools":
        return <ToolsTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
      case "boxes":
        return <BoxesTypeSVG fill={isSelected ? "#fff" : "#15698e"} />;
      case "motorVehicle":
        return <MotorVehicleSVG fill={isSelected ? "#fff" : "#15698e"} />;
      default:
        return null;
    }
  };
  return (
    <Portal opacity="0.3" height="100%" width="486px" zIndex="101" customHeader>
      <div className={styles.rcPopup}>
        <div className={`ffqsb ${styles.header}`}>
          <p className={`fs14 m-b-6`}>
            Hi {auth?.user?.fullName?.split(" ")[0]},
          </p>
          <p className={`fs22 m-b-2`}>
            Reserve Your Unit {reservationUnit.unitId}
          </p>
          <p className={`fs12 ttac`}>
            Total $ {formatFromCurrencyToMoney(reservationUnit?.total)}
          </p>
        </div>

        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
            Contact Information
          </h5>
          {auth?.user?.defaultPhoneNumber ? (
            <>
              <p className={`ffmr fs14 m-b-5`}>
                {formatPhoneNumber(auth?.user?.defaultPhoneNumber?.number)}
              </p>
            </>
          ) : (
            <>
              {showPhonePopup ? (
                <AddPhone
                  close={() => setShowPhonePopup(false)}
                  updatedPhone={(phoneNumber) => {
                    dispatch({
                      type: CURRENT_USER,
                      payload: {
                        ...auth.user,
                        defaultPhoneNumber: phoneNumber,
                      },
                    });
                  }}
                />
              ) : undefined}

              <AddItemButton
                text="Add phone number"
                action={() => setShowPhonePopup(!showAddressPopup)}
              />
            </>
          )}
        </div>

        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc`}>
            Content to be stored
            {/* <span className="mla">
            <ExpandMore></ExpandMore>
          </span> */}
          </h5>
          <p className={`df acsa ffqsb fs10 ttuc m-b-20`}>
            Select all that apply
          </p>
          <div className={`df jcc w100 ${styles.itemTypesWrpr}`}>
            {/* {console.log(storedContent)} */}
            {contentTypes.map((item, index) => (
              <div
                className={`df ffc acc ${styles.typeItem} ${
                  isTypeSelected(item.value) ? styles.selected : ""
                }`}
                key={index}
                onClick={() => {
                  handleSelectStoredContent(item.value);
                }}
              >
                {getIcon(item.value, isTypeSelected(item.value))}
                <p className={`ttuc fs8 ffmb m-t-8`}>{item.label}</p>
              </div>
            ))}
          </div>
          {/* <p className={`ffmb ttuc fs12 m-b-10`}>Are you storing a vehicle?</p>
          <div className={`df aic`}>
            <div className={`df aic m-r-20`}>
              <input
                type="radio"
                name="vehicle"
                id="yes"
                checked={isVehicle}
                onChange={() => setIsVehicle(true)}
              />
              <label className={`ffqsm fs14 m-l-4`} htmlFor="yes">
                Yes
              </label>
            </div>
            <div className={`df aic`}>
              <input
                type="radio"
                name="vehicle"
                id="no"
                checked={!isVehicle}
                onChange={() => setIsVehicle(false)}
              />
              <label className={`ffqsm fs14 m-l-4`} htmlFor="no">
                No
              </label>
            </div>
          </div> */}

          {/* **** VEHICLE INFORMATION */}
          {isTypeSelected("motorVehicle") ? (
            <div className={`${styles.vehicleDetailsWrpr}`}>
              <h5 className={`df acsa ffqsb fs14 ttuc m-b-20 m-t-30`}>
                Vehicle Information
                {/* <span className="mla" onClick={() => setShowAddressPopup(true)}>
              <ExpandMore></ExpandMore>
            </span> */}
              </h5>
              <p className={`ffmb fs12 ttuc m-t-20`}>Vehicle type</p>
              <div className={`df aic ${styles.vehicleTypes}`}>
                <div
                  className={`df acc ffc ${styles.typeOption} ${
                    isVehicleType("car") ? "pbgc" : ""
                  }`}
                  onClick={() => setVehicleType("car")}
                >
                  <MotorVehicleSVG
                    fill={isVehicleType("car") ? "#fff" : "#15698e"}
                  />
                  <p
                    className={`m-t-8 fs9 ttuc ffmb tac`}
                    style={{
                      color: `${isVehicleType("car") ? "#fff" : "#000"}`,
                    }}
                  >
                    Car
                  </p>
                </div>
                <div
                  className={`df acc ffc ${styles.typeOption} ${
                    isVehicleType("trailer") ? "pbgc" : ""
                  }`}
                  onClick={() => setVehicleType("trailer")}
                >
                  <TrailerSVG
                    fill={isVehicleType("trailer") ? "#fff" : "#15698e"}
                  />
                  <p
                    className={`m-t-8 fs9 ttuc ffmb tac`}
                    style={{
                      color: `${isVehicleType("trailer") ? "#fff" : "#000"}`,
                    }}
                  >
                    Trailer
                  </p>
                </div>
                <div
                  className={`df acc ffc ${styles.typeOption} ${
                    isVehicleType("watercraft") ? "pbgc" : ""
                  }`}
                  onClick={() => setVehicleType("watercraft")}
                >
                  <WatercraftSVG
                    fill={isVehicleType("watercraft") ? "#fff" : "#15698e"}
                  />
                  <p
                    className={`m-t-8 fs9 ttuc ffmb tac`}
                    style={{
                      color: `${isVehicleType("watercraft") ? "#fff" : "#000"}`,
                    }}
                  >
                    Watercraft
                  </p>
                </div>
                <div
                  className={`df acc ffc ${styles.typeOption} ${
                    isVehicleType("other") ? "pbgc" : ""
                  }`}
                  onClick={() => setVehicleType("other")}
                >
                  <RandomSVG
                    fill={isVehicleType("other") ? "#fff" : "#15698e"}
                  />
                  <p
                    className={`m-t-8 fs9 ttuc ffmb tac`}
                    style={{
                      color: `${isVehicleType("other") ? "#fff" : "#000"}`,
                    }}
                  >
                    Other
                  </p>
                </div>
              </div>
              <div className={`df aic `}>
                <div className={`m-t-10 m-r-5`}>
                  <SAInput
                    label="Make"
                    value={vehicleMake}
                    onChange={(e) => setVehicleMake(e.target.value)}
                  />
                </div>
                <div className={`m-t-10 m-l-5`}>
                  <SAInput
                    label="Model"
                    value={vehicleModel}
                    onChange={(e) => setVehicleModel(e.target.value)}
                  />
                </div>
              </div>

              <div className={`m-t-10`}>
                <SAInput
                  label="VIN"
                  value={vehicleVin}
                  onChange={(e) => setVehicleVin(e.target.value)}
                />
              </div>
              <div className={`df aic `}>
                <div className={`m-t-10 m-r-5`}>
                  <SAInput
                    label="Length"
                    value={vehicleLength}
                    onChange={(e) => setVehicleLength(e.target.value)}
                  />
                </div>

                <div className={`m-t-10 m-l-5`}>
                  <SAInput
                    label="Color(s)"
                    value={vehicleColor}
                    onChange={(e) => setVehicleColor(e.target.value)}
                  />
                </div>
              </div>

              <div className={`m-t-10`}>
                <SAInput
                  label="Description"
                  value={vehicleDescription}
                  onChange={(e) => setVehicleDescription(e.target.value)}
                />
              </div>
            </div>
          ) : undefined}
          {/* {showVehicleDetails ? (
            <VehicleDetails close={() => setShowVehicleDetails(false)} />
          ) : undefined} */}
        </div>

        {/* **** ENC VEHICLE INFORMATION */}

        {/* **** PERSONAL INFO ***** */}
        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
            Personal Information
            {/* <span className="mla">
            <ExpandMore></ExpandMore>
          </span> */}
          </h5>
          <div className={`df aic`}>
            <div className={`m-t-10 m-r-10`}>
              <div className={`__inpt_wrpr df ffc`}>
                <label className={`ffmb fs12`}>DOB (MM/DD/YYYY)</label>
                <div className={`inner_inpt_wrpr `}>
                  <Cleave
                    className={`ffmr fs14 ${styles.input}`}
                    value={dob}
                    onChange={(e) => setDob(e.target.value)}
                    options={{
                      date: true,
                      datePattern: ["m", "d", "Y"],
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={`m-t-10`}>
              <div className={`__inpt_wrpr df ffc`}>
                <label className={`ffmb fs12`}>SS or Passport (last 4)</label>
                <div className={`inner_inpt_wrpr `}>
                  <Cleave
                    className={`ffmr fs14 ${styles.input}`}
                    value={lastFourSSN}
                    onChange={(e) => setLastFourSSN(e.target.value)}
                    options={{
                      numericOnly: true,
                      blocks: [4],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`m-t-10`}>
            <div className={`__inpt_wrpr df ffc`}>
              <label className={`ffmb fs12`}>Driver's License #</label>
              <div className={`inner_inpt_wrpr `}>
                <Cleave
                  className={`ffmr fs14 ${styles.input}`}
                  value={lastFourDriversLicense}
                  onChange={(e) => setLastFourDriversLicense(e.target.value)}
                  options={{
                    numericOnly: true,
                    blocks: [4],
                  }}
                />
              </div>
            </div>
          </div>
          <div className={`df aic`}>
            <div className={`m-t-10 m-r-10`}>
              <div className={`__inpt_wrpr df ffc`}>
                <label className={`ffmb fs12`}>License state</label>
                <div className={`inner_inpt_wrpr `}>
                  <Cleave
                    className={`ffmr fs14 ${styles.input}`}
                    value={licenseState}
                    onChange={(e) => setLicenseState(e.target.value)}
                    options={{
                      blocks: [2],
                      uppercase: true,
                    }}
                    // }}
                  />
                </div>
              </div>
            </div>
            <div className={`m-t-10`}>
              <div className={`__inpt_wrpr df ffc`}>
                <label className={`ffmb fs12`}>Exp. date</label>
                <div className={`inner_inpt_wrpr `}>
                  <Cleave
                    className={`ffmr fs14 ${styles.input}`}
                    value={licenseExpiration}
                    onChange={(e) => setLicenseExpiration(e.target.value)}
                    options={{
                      date: true,
                      datePattern: ["m", "d", "Y"],
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className={`m-t-20`}>
            <p className={`df acsa ffqsb fs14 ttuc m-b-20`}>
              Are you a service member
            </p>
            <div className={`df aic`}>
              <div className={`df aic m-r-20`}>
                <input
                  type="radio"
                  name="serviceMember"
                  id="yessm"
                  onChange={() => setServiceMember(true)}
                  checked={serviceMember === true}
                />
                <label className={`ffqsm fs14 m-l-4`} htmlFor="yessm">
                  Yes
                </label>
              </div>
              <div className={`df aic`}>
                <input
                  type="radio"
                  name="serviceMember"
                  id="nosm"
                  onChange={() => setServiceMember(false)}
                  checked={serviceMember === false}
                />
                <label className={`ffqsm fs14 m-l-4`} htmlFor="nosm">
                  No
                </label>
              </div>
            </div>
          </div>
        </div>
        {/* **** END PERSONAL INFO **** */}

        {/* **** ADDITIONAL CONTACT */}

        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
            Alternate Contact
            {/* <span className="mla" onClick={() => setShowAddressPopup(true)}>
              <ExpandMore></ExpandMore>
            </span> */}
          </h5>

          <p className={`ffmb ttuc fs12 m-b-10`}>Add an alternate contact?</p>
          <div className={`df aic`}>
            <div className={`df aic m-r-20`}>
              <input
                type="radio"
                name="altcontact"
                id="yesalt"
                checked={alternateContact}
                onChange={() => setAlternateContact(true)}
              />
              <label className={`ffqsm fs14 m-l-4`} htmlFor="yesalt">
                Yes
              </label>
            </div>
            <div className={`df aic`}>
              <input
                type="radio"
                name="altcontact"
                id="noalt"
                checked={!alternateContact}
                onChange={() => {
                  setAltName("");
                  setAltDeliveryLine1("");
                  setAltLastLine("");
                  setAltPhone("");

                  setAlternateContact(false);
                }}
              />
              <label className={`ffqsm fs14 m-l-4`} htmlFor="noalt">
                No
              </label>
            </div>
          </div>

          {alternateContact ? (
            <>
              <div className={`m-t-20`}>
                <SAInput
                  label="Contacts full name"
                  value={altName}
                  onChange={(e) => setAltName(e.target.value)}
                />
              </div>

              <div className={`m-t-10`}>
                <AutoComplete
                  onUpdate={(e) => {
                    // console.log(e);
                    setAltDeliveryLine1(e.deliveryLine1);
                    setAltLastLine(e.lastLine);
                  }}
                  savedAddress={(e) => console.log(e)}
                />
              </div>

              <div className={`m-t-10`}>
                <div className={`__inpt_wrpr df ffc`}>
                  <label className={`ffmb fs12`}>Contacts phone number</label>
                  <div className={`inner_inpt_wrpr `}>
                    <Cleave
                      className={`ffmr fs14 ${styles.input}`}
                      options={{
                        blocks: [0, 3, 3, 4],
                        delimiters: ["(", ") ", "-"],
                        numericOnly: true,
                      }}
                      value={altPhone}
                      onChange={(event) => {
                        // const tempEvent = event;
                        // tempEvent.target.value = `+${event.target.rawValue}`;
                        setAltPhone(`+${event.target.rawValue}`);
                      }}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : undefined}
        </div>

        {/* **** END ADDITIONAL CONTACT */}

        {/* Insurance info */}
        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
            Tenant protection
            {/* <span className="mla" onClick={() => setShowAddressPopup(true)}>
              <ExpandMore></ExpandMore>
            </span> */}
          </h5>
          {insuranceBrackets.map((bracket) => (
            <div
              className={`df acsa ffqsb fs16 ${styles.insuranceBracket} ${
                bracket._id === insuranceBracket ? styles.selectedBracket : ""
              }`}
              onClick={() => {
                setInsuranceCompany(bracket.insuranceCompanyId);
                setInsuranceBracket(bracket._id);
              }}
            >
              <div>
                <p className={`ffmr fs12 m-b-4`}>Coverage</p>
                <p>{formatFromCurrencyToMoney(bracket.coverageAmount)}</p>
              </div>
              <div className={`mla tar`}>
                <p className={`ffmr fs12 m-b-4`}>Monthly premium</p>
                <p>{formatFromCurrencyToMoney(bracket.premium)}</p>
              </div>
            </div>
          ))}
          <div
            onClick={() => {
              setInsuranceCompany(null);
              setInsuranceBracket(null);
            }}
            className={`df acsa ffqsb fs16 p-t-20 p-b-20 ${
              styles.insuranceBracket
            } ${insuranceBracket === null ? styles.selectedBracket : ""}`}
          >
            <div>I have my own protection</div>
          </div>
          {insuranceBracket === null ? (
            <div className={`m-t-20`}>
              <SAInput
                label="Insurance policy number"
                value={outsideInsurancePolicyNumber}
                onChange={(e) =>
                  setOutsideInsurancePolicyNumber(e.target.value)
                }
              />
            </div>
          ) : undefined}
        </div>

        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-20`}>
            Billing Information
            <span className="mla" onClick={() => setShowAddressPopup(true)}>
              <ExpandMore></ExpandMore>
            </span>
          </h5>

          <p className={`ffmb ttuc fs12 m-b-10`}>{auth?.user?.fullName}</p>
          {auth?.user?.defaultAddress ? (
            <>
              <p className={`ffmr fs14 m-b-5`}>
                {auth?.user?.defaultAddress?.deliveryLine1}
              </p>
              <p className={`ffmr fs14`}>
                {auth?.user?.defaultAddress?.lastLine}
              </p>
            </>
          ) : (
            <>
              <AddItemButton
                text="Add billing address"
                action={() => setShowAddressPopup(true)}
              />
            </>
          )}
        </div>

        {showAddressPopup ? (
          <AddAddress
            close={() => setShowAddressPopup(false)}
            savedAddress={auth?.user?.defaultAddress}
            updatedAddress={(address) => {
              dispatch({
                type: CURRENT_USER,
                payload: { ...auth.user, defaultAddress: address },
              });
            }}
          />
        ) : undefined}

        <div className={`${styles.sec}`}>
          <h5 className={`df acsa ffqsb fs14 ttuc m-b-30`}>
            Payment Method
            {payMethod ? (
              <span
                className="mla"
                onClick={() => setShowSelectPayMethod(true)}
              >
                <ExpandMore></ExpandMore>
              </span>
            ) : undefined}
            {showSelectPayMethod ? (
              <Portal
                zIndex="102"
                title="Select Card"
                noCustomScrollbar
                opacity=".5"
                width="498px"
                close={() => setShowSelectPayMethodAction(false)}
                height="auto"
              >
                <SelectPayment
                  selectedId={payMethod._id}
                  userId={auth?.user?._id}
                  selectAction={setShowSelectPayMethodAction}
                  close={null}
                  // closeOnselect={false}
                  // includeAutopays={true}
                ></SelectPayment>
              </Portal>
            ) : undefined}
          </h5>

          {payMethod?._id ? (
            <div>
              <div className={`df acsa ${styles.cardWrpr}`}>
                <img
                  src={images[payMethod.xCardType]}
                  alt={payMethod.xCardType}
                />
                <div className={`df acsa ${styles.dotsWrpr}`}>
                  <div className={`${styles.dot}`}></div>
                  <div className={`${styles.dot}`}></div>
                  <div className={`${styles.dot}`}></div>
                  <div className={`${styles.dot}`}></div>
                </div>

                <p className={`ffmr fs14`}>
                  {payMethod.xMaskedCardNumber.substring(
                    payMethod.xMaskedCardNumber.length - 4
                  )}
                </p>
              </div>
              <div className={`df aic m-t-10`}>
                <input
                  id="setautopay"
                  type="checkbox"
                  checked={isAutopay}
                  onChange={() => {
                    // console.log(reservationUnit);
                    setIsAutopay(!isAutopay);
                  }}
                />
                <label className={`m-l-5 m-t-2 ffmr fs12`} htmlFor="setautopay">
                  Autopay{" "}
                  <span className={`fs10`}>
                    (every month on the{" "}
                    {ordinal(
                      Number(
                        dayjs(new Date(Number(params.selectedDate))).format(
                          "DD"
                        )
                      )
                    )}
                    )
                  </span>
                </label>
              </div>
            </div>
          ) : (
            <AddItemButton
              text="Add Payment"
              action={() => setAddCard(true)}
            ></AddItemButton>
          )}
          {addCard ? (
            <Portal
              zIndex="104"
              title="Add Card"
              noCustomScrollbar
              opacity=".08"
              width="498px"
              close={() => setAddCard(false)}
              height="auto"
            >
              <AddCard
                setCard={getAndSetPayMethod}
                close={setAddCard}
                primary={true}
              ></AddCard>
            </Portal>
          ) : null}
        </div>

        <div className={`${styles.footer}`}>
          <button
            disabled={
              !payMethod ||
              !auth?.user?.defaultAddress ||
              !auth?.user?.defaultPhoneNumber ||
              // !dob ||
              // !lastFourDriversLicense ||
              // !lastFourSSN ||
              (alternateContact && !altName)
            }
            className={`df ffmb fs14 ttuc fwbold srch_btn button primary w100 m-b-12`}
            onClick={async () => {
              let reservation;
              if (!reservationId) {
                reservation = await Axios.post(
                  "/api/reservation/false/?setupPrice=true",
                  {
                    user: auth?.user?._id,
                    unit: params.unitId,
                    locationId: params.locationId,
                    webProfileId: auth.user.webProfileId,
                    accountId: websiteProfile.account,
                    expectedMoveInDate: params.selectedDate,
                    initialPayment: false,
                    isDraft: true,
                    dob: dob ? Number(new Date(dob)) : undefined,
                    lastFourSSN,
                    lastFourDriversLicense,
                    licenseState,
                    licenseExpiration: licenseExpiration
                      ? Number(new Date(licenseExpiration))
                      : undefined,
                    address: auth.user.defaultAddress._id,
                    phoneNumber: auth.user.defaultPhoneNumber._id,
                    isVehicle: isTypeSelected("motorVehicle"),
                    vehicleType: isTypeSelected("motorVehicle")
                      ? vehicleType
                      : undefined,
                    vehicleMake: isTypeSelected("motorVehicle")
                      ? vehicleMake
                      : undefined,
                    vehicleModel: isTypeSelected("motorVehicle")
                      ? vehicleModel
                      : undefined,
                    vehicleVin: isTypeSelected("motorVehicle")
                      ? vehicleVin
                      : undefined,
                    vehicleLength: isTypeSelected("motorVehicle")
                      ? vehicleLength
                      : undefined,
                    vehicleColor: isTypeSelected("motorVehicle")
                      ? vehicleColor
                      : undefined,
                    vehicleDescription: isTypeSelected("motorVehicle")
                      ? vehicleDescription
                      : undefined,

                    autoPay: isAutopay,
                    autoPayDay: isAutopay ? 0 : undefined,
                    autoPayMethod: isAutopay ? payMethod._id : undefined,
                    alternateContact,
                    altName: alternateContact ? altName : undefined,
                    alt_delivery_line_1: alternateContact
                      ? alt_delivery_line_1
                      : undefined,
                    alt_last_line: alternateContact ? alt_last_line : undefined,
                    altPhone: alternateContact ? altPhone : undefined,
                    storedContent,
                    insuranceBracket,
                    insuranceCompany,
                    outsideInsurancePolicyNumber,
                    serviceMember,
                  },
                  {
                    headers: {
                      "x-auth": getToken(),
                    },
                  }
                );

                // return;

                if (reservation?.data?.message === "no units available") {
                  return setShowNoUnits(true);
                }
                console.log("::::=============::::");
                reservationId = reservation.data.data._id;
              }

              history.push(
                `/reserve/summary/${reservationId}/${payMethod?._id}/${auth?.user?.defaultAddress?._id}`
              );
            }}

            // reservation = await Axios.post(
            //   "/api/reservation/false",
            //   {
            //     user: auth?.user?._id,
            //     unit: params.unitId,
            //     locationId: params.locationId,
            //     webProfileId: auth.user.webProfileId,
            //     accountId: websiteProfile.account,
            //     expectedMoveInDate: params.selectedDate,
            //     initialPayment: next ? undefined : false,
            //     isDraft: next,
            //     // address: auth.user.defaultAddress._id,
            //     // phoneNumber: auth.user.defaultPhoneNumber._id,
            //   },
            //   {
            //     headers: {
            //       "x-auth": getToken(),
            //     },
            //   }
            // )
          >
            Continue to checkout
            {/* locationId: "5def08c3bbd5014276c9d08c"
reservationId: "607651e40f49d651402ecffc"
selectedDate: 1618372800000
unitId: "5e1e8d5d2bb59f49c0ecd41c"
unitSize: "5e0eb7d93f3a714d6412a03c" */}
            {/* path="/reserve/summary/:sizeId/:unitId/:selectedDate/:locationId/:payMethodId/:reservationId?" */}
          </button>

          {!hidePayLaterButton ? (
            <button
              disabled={
                // !payMethod ||
                !auth?.user?.defaultAddress ||
                !auth?.user?.defaultPhoneNumber ||
                // !dob ||
                // !lastFourDriversLicense ||
                // !lastFourSSN ||
                (alternateContact && !altName)
              }
              className={`ffqsb tac ttuc fs14 button secondary_primary ptc w100`}
              onClick={async () => {
                let reservation;
                if (!reservationId) {
                  reservation = await Axios.post(
                    "/api/reservation/false/?setupPrice=true",
                    {
                      user: auth?.user?._id,
                      unit: params.unitId,
                      locationId: params.locationId,
                      webProfileId: auth.user.webProfileId,
                      accountId: websiteProfile.account,
                      expectedMoveInDate: params.selectedDate,
                      initialPayment: false,
                      isDraft: false,

                      // ******************
                      dob: dob ? Number(new Date(dob)) : undefined,
                      lastFourSSN,
                      lastFourDriversLicense,
                      licenseState,
                      licenseExpiration: licenseExpiration
                        ? Number(new Date(licenseExpiration))
                        : undefined,
                      address: auth.user.defaultAddress._id,
                      phoneNumber: auth.user.defaultPhoneNumber._id,
                      isVehicle: isTypeSelected("motorVehicle"),
                      vehicleType: isTypeSelected("motorVehicle")
                        ? vehicleType
                        : undefined,
                      vehicleMake: isTypeSelected("motorVehicle")
                        ? vehicleMake
                        : undefined,
                      vehicleModel: isTypeSelected("motorVehicle")
                        ? vehicleModel
                        : undefined,
                      vehicleVin: isTypeSelected("motorVehicle")
                        ? vehicleVin
                        : undefined,
                      vehicleLength: isTypeSelected("motorVehicle")
                        ? vehicleLength
                        : undefined,
                      vehicleColor: isTypeSelected("motorVehicle")
                        ? vehicleColor
                        : undefined,
                      vehicleDescription: isTypeSelected("motorVehicle")
                        ? vehicleDescription
                        : undefined,
                      alternateContact,
                      altName: alternateContact ? altName : undefined,
                      alt_delivery_line_1: alternateContact
                        ? alt_delivery_line_1
                        : undefined,
                      alt_last_line: alternateContact
                        ? alt_last_line
                        : undefined,
                      altPhone: alternateContact ? altPhone : undefined,
                      storedContent,
                      insuranceBracket,
                      insuranceCompany,
                      outsideInsurancePolicyNumber,
                      serviceMember,
                      // ******************
                      //  address: auth.user.defaultAddress._id,
                      //  phoneNumber: auth.user.defaultPhoneNumber._id,
                    },
                    {
                      headers: {
                        "x-auth": getToken(),
                      },
                    }
                  );
                  reservationId = reservation?.data?.data?._id;
                }

                console.log(reservationId);

                // reservationId = reservation?.data?.data?._id;

                history.push(`/thank-you/${reservationId}`);
              }}
            >
              Reserve now, pay later
            </button>
          ) : undefined}

          <p className={`ffmr fs10 tac ${styles.disclaimer}`}>
            Upon clicking, I agree to all terms and policies
          </p>
        </div>
        {showNoUnits ? (
          <Portal
            zIndex="104"
            height="auto"
            width="560px"
            title="No available units"
            opacity="0.4"
            close={() => setShowNoUnits(false)}
          >
            <div className={`p-t-40 p-l-44 p-r-44 p-b-35 df acc ffc`}>
              <BrokenPipeSVG fill="#e24c4b" />
              <p className={`ffmr fs18 m-t-40 m-b-40`}>
                Sorry, there are no more available units for this size.
              </p>
              <Link to="/locations" className={`ffmm fs16 m-b-20 tdn ptc`}>
                Please select a different size.
              </Link>
            </div>
          </Portal>
        ) : undefined}
      </div>
    </Portal>
  );
};

export default RCPopup;
