import React, {Component} from "react";
import ReactDOM from "react-dom";

class CalPortal extends Component {
	constructor(props) {
		super(props);
		this.rootSelector = document.getElementById("root");
		this.container = document.createElement("div");
		this.container.classList.add("cal_popup");
	}
	componentDidMount() {
		this.rootSelector.appendChild(this.container);
	}

	componentWillUnmount() {
		this.rootSelector.removeChild(this.container);
	}

	render() {
		return ReactDOM.createPortal(
			<>{this.props.children}</>,
			this.container
		);
	}
}

export default CalPortal;
