import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import {
  getCurrentReservation,
  getUnit,
  getUser,
  updateReservation,
  updateWebUser,
} from "../store/actions";
// import components
// import {StorageLocation} from "../components/ui/storage-address/StorageAddress";
// import SAInput from "../components/ui/form-elements/stand-alone-input/Input";
import Page1 from "./page1";
import Page2 from "./page2";
import ReservationSummary from "./reservation-summary/ReservationSummary";
// import scss
import "./Reserve.scss";

// ********* match params **********//
// sizeId: "5e0eb7b23f3a714d6412a03a"
// unitId: "5e1d37e27b8bb9588cffcc8b"
// selectedDate: "1614488400000"
// locationId: "5def08c3bbd5014276c9d08c"

// nameOnCard: `${this.props.auth.user.firstName} ${this.props.auth.user.lastName}`,
// cc_number: "",
// exp_m: "",
// exp_y: "",
// cc_sc: "",
// b_address: "",
// city: "",
// state: "",
// zip: "",
// address_2: ""

// const PaymentSchema = Yup.object().shape({
//   nameOnCard: Yup.string()
//     .min(2, "Too Short!")
//     .max(50, "Too Long!")
//     .required("Required"),
//   cc_number: Yup.string().required("Card number is required"),
//   exp_m: Yup.string().required("Required"),
//   exp_y: Yup.string().required("Required"),
//   cvv: Yup.string().required("Security code required"),
// });

class ReservePage extends Component {
  constructor(props) {
    super(props);
    this.sbmtBtn = createRef();
  }
  state = {
    page: 1,
    loading: true,
    errorSaving: false,
  };

  componentDidMount() {
    const resId = this.props.match.params.id;

    if (resId) this.props.getCurrentReservation(resId);

    // console.log(this.props.match.params);
    // if (this.props.match.params.unitId && this.props.match.params.selectedDate)
    //   this.props.getUnit(
    //     this.props.match.params.unitId,
    //     this.props.match.params.selectedDate
    //     // this.props.match.params.reservationId
    //   );
  }

  movePage = (val) => this.setState((prevState) => ({ page: val }));
  render() {
    return (
      <>
        {this.props.auth && this.props.auth.user ? (
          <div className="reserve_page">
            <Header
              auth={this.props.auth}
              websiteProfile={this.props.websiteProfile}
            ></Header>

            <div className="info_wrpr">
              <div className="df ffc content_wrpr">
                <h3 className="ffqsm df acc fs20 pbgc">
                  {this.props.match.params.page === "info"
                    ? "Billing Information"
                    : this.props.match.params.page === "payment"
                    ? "Payment Information"
                    : "Review your order"}
                </h3>
                {/* {console.log(params)} */}

                <p>{this.state.currentAction}</p>

                <div className="df inner_wrpr">
                  {this.props.reservationUnit?.reserved &&
                  !this.props.reservationUnit?.isDraft ? (
                    <p className="tac">Already reserved</p>
                  ) : (
                    // <div className="form_wrpr">
                    //   <div className="stts_bar">
                    //     <div
                    //       className="progress pbgc"
                    //       style={{
                    //         width: `${
                    //           this.props.match.params.page === "info"
                    //             ? "calc(100% / 3)"
                    //             : this.props.match.params.page === "payment"
                    //             ? "calc(100% / 3 * 2)"
                    //             : "100%"
                    //         }`,
                    //       }}
                    //     ></div>
                    //   </div>
                    //   {this.props.match.params.page !== "summary" && (
                    //     <div className="df ffqsm fs16 sec_hdr">
                    //       Billed monthly{" "}
                    //       <span className="fwbold">
                    //         ${this.props.reservationUnit?.unitSize?.price}
                    //       </span>
                    //     </div>
                    //   )}

                    //   <div
                    //     className={`form_cntnt_wrpr ${
                    //       this.props.match.params.page === "summary"
                    //         ? "full"
                    //         : ""
                    //     }`}
                    //   >
                    //     <Route
                    //       path="/reserve/info/:sizeId/:unitId/:selectedDate/:locationId"
                    //       render={() => (
                    //         <div>
                    //           <Formik
                    //             initialValues={{
                    //               fullName:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.fullName) ||
                    //                 this.props.auth.user.fullName ||
                    //                 "",
                    //               phone:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.phone) ||
                    //                 this.props.auth.user.phoneNumber,
                    //               phoneDefault: true,
                    //               addressDefault: true,
                    //               email:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.email) ||
                    //                 this.props.auth.user.email,
                    //               comment:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.comment) ||
                    //                 "",
                    //               address:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.address &&
                    //                   this.props.reservation.address.line1) ||
                    //                 "",
                    //               city:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.address &&
                    //                   this.props.reservation.address.city) ||
                    //                 "",
                    //               state:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.address &&
                    //                   this.props.reservation.address.state) ||
                    //                 "",
                    //               zip:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.address &&
                    //                   this.props.reservation.address.zip) ||
                    //                 "",
                    //               address_2:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.address &&
                    //                   this.props.reservation.address.line2) ||
                    //                 "",
                    //             }}
                    //             validationSchema={InfoSchema}
                    //             // START SUBMIT ********
                    //             onSubmit={async (values) => {
                    //               // /api/address/:userId/:accountId

                    //               this.setState({
                    //                 currentAction: "Saving your phone number",
                    //               });

                    //               const phone = await Axios.post(`/api/phone`, {
                    //                 number: values.phone,
                    //                 accountId:
                    //                   this.props.websiteProfile.account,
                    //                 webUserId: this.props.auth.user._id,
                    //                 primary: true,
                    //               });

                    //               if (!phone?.data?.message === "ok")
                    //                 return this.setState({
                    //                   errorSaving: true,
                    //                 });

                    //               this.setState({
                    //                 currentAction: "Saving your address",
                    //               });

                    //               const address = await Axios.post(
                    //                 `/api/address`,
                    //                 {
                    //                   line1: values.address,
                    //                   city: values.city,
                    //                   state: values.state,
                    //                   zip: values.zip,
                    //                   line2: values.address_2,
                    //                   accountId:
                    //                     this.props.websiteProfile.account,
                    //                   webUserId: this.props.auth.user._id,
                    //                   primary: true,
                    //                 }
                    //               );

                    //               if (!address?.data?.message === "ok")
                    //                 return this.setState({
                    //                   errorSaving: true,
                    //                 });

                    //               await this.props.getUser();

                    //               if (this.state.done) {
                    //                 let reservation = await Axios.post(
                    //                   "/api/reservation/false",
                    //                   {
                    //                     user: this.props.auth.user._id,
                    //                     unit: this.props.match.params.unitId,
                    //                     locationId:
                    //                       this.props.match.params.locationId,
                    //                     webProfileId: this.props.auth.user._id,
                    //                     accountId:
                    //                       this.props.websiteProfile.account,
                    //                     expectedMoveInDate:
                    //                       this.props.match.params.selectedDate,
                    //                     initialPayment: false,
                    //                     address:
                    //                       this.props.auth.user.defaultAddress
                    //                         ._id,
                    //                     phoneNumber:
                    //                       this.props.auth.user
                    //                         .defaultPhoneNumber._id,
                    //                   },
                    //                   {
                    //                     headers: {
                    //                       "x-auth": getToken(),
                    //                     },
                    //                   }
                    //                 );
                    //                 return this.props.history.push(
                    //                   `/thank-you/${reservation?.data?.data?._id}`
                    //                 );
                    //               }
                    //               this.setState({
                    //                 currentAction: "",
                    //               });
                    //               this.props.history.push(
                    //                 `/reserve/payment/${this.props.match.params.sizeId}/${this.props.match.params.unitId}/${this.props.match.params.selectedDate}/${this.props.match.params.locationId}`
                    //               );
                    //             }}

                    //             // END SUBMIT ********
                    //           >
                    //             {(props) => (
                    //               <form onSubmit={props.handleSubmit}>
                    //                 {console.log(props)}
                    //                 <Page1
                    //                   reservationId={this.props.match.params.id}
                    //                   setFieldValue={props.setFieldValue}
                    //                   onChange={props.onChange}
                    //                   handleChange={props.handleChange}
                    //                 ></Page1>
                    //                 <button
                    //                   className="dn"
                    //                   ref={this.sbmtBtn}
                    //                   type="submit"
                    //                   onClick={(e) =>
                    //                     console.log("Clicked submit")
                    //                   }
                    //                 >
                    //                   Submit
                    //                 </button>
                    //               </form>
                    //             )}
                    //           </Formik>
                    //         </div>
                    //       )}
                    //     ></Route>
                    //     <Route
                    //       path="/reserve/payment/:sizeId/:unitId/:selectedDate/:locationId"
                    //       render={() => (
                    //         <div>
                    //           <Formik
                    //             initialValues={{
                    //               nameOnCard:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.nameOnCard) ||
                    //                 `${this.props.auth.user.fullName}`,
                    //               cc_number:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.cc_number) ||
                    //                 "",
                    //               exp_m:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.exp_m) ||
                    //                 "",
                    //               exp_y:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.exp_y) ||
                    //                 "",
                    //               cvv:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.cvv) ||
                    //                 "",
                    //               b_address:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.b_address) ||
                    //                 "",
                    //               city:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.city) ||
                    //                 "",
                    //               state:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.state) ||
                    //                 "",
                    //               zip:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.zip) ||
                    //                 "",
                    //               address_2:
                    //                 (this.props.reservation &&
                    //                   this.props.reservation.address_2) ||
                    //                 "",
                    //               saveForLater: true,
                    //               sameAsBilling: true,
                    //             }}
                    //             validationSchema={PaymentSchema}
                    //             onSubmit={async (values, b) => {
                    //               this.setState({
                    //                 submitting: true,
                    //               });

                    //               Axios.post("/api/test-doc-creation");

                    //               // return console.log(
                    //               // 	"Hey U"
                    //               // );
                    //               this.setState({
                    //                 submitting: true,
                    //               });

                    //               // values.primary = true;

                    //               let re = await Axios.post(
                    //                 "/api/payment-method",
                    //                 {
                    //                   ...values,
                    //                   primary: true,
                    //                   userId: this.props.auth.user._id,
                    //                 }
                    //               ).catch((e) => e);

                    //               this.setState({
                    //                 submitting: false,
                    //               });

                    //               if (re.response) {
                    //                 window.clearIfield("card-number");
                    //                 window.clearIfield("cvv");
                    //                 return console.log(
                    //                   "Error saving payment method"
                    //                 );
                    //               }

                    //               if (
                    //                 re.data.message === "Payment method added"
                    //               ) {
                    //                 // let reservation = await Axios.post(
                    //                 // 	"/api/reservation",
                    //                 // 	{
                    //                 // 		user: this
                    //                 // 			.props
                    //                 // 			.auth
                    //                 // 			.user
                    //                 // 			._id,
                    //                 // 		unit: this
                    //                 // 			.props
                    //                 // 			.match
                    //                 // 			.params
                    //                 // 			.unitId,
                    //                 // 		locationId: this
                    //                 // 			.props
                    //                 // 			.match
                    //                 // 			.params
                    //                 // 			.locationId,
                    //                 // 		webProfileId: this
                    //                 // 			.props
                    //                 // 			.auth
                    //                 // 			.user
                    //                 // 			._id,
                    //                 // 		accountId: this
                    //                 // 			.props
                    //                 // 			.websiteProfile
                    //                 // 			.account,
                    //                 // 		expectedMoveInDate: this
                    //                 // 			.props
                    //                 // 			.match
                    //                 // 			.params
                    //                 // 			.selectedDate,
                    //                 // 		initialPayment: false,
                    //                 // 	}
                    //                 // );

                    //                 this.props.history.push(
                    //                   // /:sizeId/:unitId/:selectedDate/:locationId
                    //                   `/reserve/summary/${this.props.match.params.sizeId}/${this.props.match.params.unitId}/${this.props.match.params.selectedDate}/${this.props.match.params.locationId}/${re.data.data._id}`
                    //                 );
                    //               } else {
                    //                 console.log("_______________");
                    //                 window.clearIfield("cvv");
                    //                 console.log("error saving card");
                    //               }

                    //               b.setFieldValue("cvv", undefined);
                    //               b.setFieldValue("cc_number", undefined);
                    //             }}
                    //           >
                    //             {(props) => (
                    //               <form onSubmit={props.handleSubmit}>
                    //                 <Page2
                    //                   sameAsBilling={props.values.sameAsBilling}
                    //                   setFieldValue={props.setFieldValue}
                    //                 ></Page2>
                    //                 <button
                    //                   ref={this.sbmtBtn}
                    //                   className="dn"
                    //                   type="submit"
                    //                 >
                    //                   Submit 2
                    //                 </button>
                    //               </form>
                    //             )}
                    //           </Formik>
                    //         </div>
                    //       )}
                    //     ></Route>{" "}
                    //     <Route
                    //       path="/reserve/summary/:sizeId/:unitId/:selectedDate/:locationId/:payMethodId/:reservationId?"
                    //       // component={ReservationSummary}

                    //       render={(props) => (
                    //         <ReservationSummary
                    //           {...props}
                    //           refreshData={getUser}
                    //         />
                    //       )}
                    //     ></Route>
                    //   </div>

                    //   {this.props.match.params.page !== "summary" && (
                    //     <div className="df jcfe reservation_ftr">
                    //       {this.props.match.params.page === "info" && (
                    //         <p
                    //           className="ffqsb tac ttuc fs14 button secondary_primary ptc"
                    //           onClick={async () => {
                    //             console.log("onSubmit={async (");
                    //             await this.setState({ done: true });
                    //             this.sbmtBtn.current.click();
                    //           }}
                    //         >
                    //           Pay Later
                    //         </p>
                    //       )}

                    //       {(this.props.match.params.page === "info" ||
                    //         this.props.match.params.page === "payment") && (
                    //         <p
                    //           // to={"/reserve/payment"}
                    //           className={`df ffmb fs14 ttuc fwbold srch_btn button primary ${
                    //             this.state.submitting ? "submitting" : ""
                    //           }`}
                    //           onClick={() => {
                    //             if (!this.state.submitting) {
                    //               this.sbmtBtn.current.click();
                    //             }
                    //           }}
                    //         >
                    //           {this.props.match.params.page === "info"
                    //             ? "Pay now"
                    //             : "Continue"}
                    //         </p>
                    //       )}

                    //       {this.props.match.params.page === "summary" && (
                    //         <p className="df ffmb fs14 ttuc fwbold srch_btn button primary">
                    //           Process payment!!
                    //         </p>
                    //       )}
                    //     </div>
                    //   )}
                    // </div>
                    <div className="form_wrpr">
                      <div className="stts_bar">
                        <div
                          className="progress pbgc"
                          style={{
                            width: `${
                              this.props.match.params.page === "info"
                                ? "calc(100% / 3)"
                                : this.props.match.params.page === "payment"
                                ? "calc(100% / 3 * 2)"
                                : "100%"
                            }`,
                          }}
                        ></div>
                      </div>

                      <div
                        className={`form_cntnt_wrpr ${
                          this.props.match.params.page === "summary"
                            ? "full"
                            : ""
                        }`}
                      >
                        <Route
                          path={
                            "/reserve/info/:sizeId/:unitId/:selectedDate/:locationId"
                          }
                          component={(props) => (
                            <Page1
                              {...props}
                              reservation={{}}
                              page={this.props.match.params.page}
                            />
                          )}
                        />
                        <Route
                          path="/reserve/payment/:reservationId"
                          component={(props) => (
                            <Page2
                              {...props}
                              page={this.props.match.params.page}
                            />
                          )}
                        />

                        <Route
                          path="/reserve/summary/:reservationId/:payMethodId?/:addressId?"
                          component={(props) => (
                            <ReservationSummary {...props} />
                          )}
                        ></Route>
                      </div>
                    </div>
                    // path="/reserve/info/:sizeId/:unitId/:selectedDate/:locationId"
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          "loading"
        )}
        <Footer logoUrl={this.props?.websiteProfile?.theme?.logoURL}></Footer>
      </>
    );
  }
}

const maapStateToProps = ({
  websiteProfile,
  auth,
  reservation,
  // reservationUnit,
}) => ({
  websiteProfile,
  auth,
  reservation,
  // reservationUnit,
});

export default connect(maapStateToProps, {
  getUnit,
  updateReservation,
  getCurrentReservation,
  updateWebUser,
  getUser,
})(ReservePage);
