import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import { GET_UNIT } from "../../store/actions/types";
import Reserve from "./reserve/Reserve";

const V2 = (props) => {
  const location = useLocation();

  const dispatch = useDispatch();

  let params = new URLSearchParams(location.search);

  const { auth, websiteProfile } = useSelector(({ auth, websiteProfile }) => ({
    auth,
    websiteProfile,
  }));

  const [flow, setFlow] = useState(undefined);
  const [reservation, setReservation] = useState(undefined);

  const getFlow = async () => {
    let localFlow = await axios.get(`/api/flow/${reservation?.locationId}`);

    setFlow(localFlow.data.data);
  };

  const getReservationUnit = async () => {
    const localReservation = await axios.get(
      `/api/reservation/${params.get("reservationid")}`,
      { headers: { "x-auth": localStorage.getItem("x-auth") } }
    );

    setReservation(localReservation.data.data);

    let unit = await axios.get(
      `/api/reserve-unit/?unitid=${localReservation?.data?.data?.unit?._id}&moveindate=${localReservation?.data?.data?.expectedMoveInDate}`
    );

    // setUnitDetails(unit.data.data);

    dispatch({
      type: GET_UNIT,
      payload: unit.data.data,
    });
  };

  useEffect(() => {
    getReservationUnit();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!reservation) return;
    getFlow();
    // eslint-disable-next-line
  }, [reservation]);
  return (
    <div>
      <Header
        websiteProfile={websiteProfile}
        // headerOpacity={route}
        auth={auth}
        // allProps={props}
        location={location}
      />
      <Route
        path="/v2/reserve"
        component={() => (
          <Reserve
            flow={flow}
            params={params}
            unitId={reservation?.unitId}
            reservation={reservation}
          />
        )}
      />
    </div>
  );
};

export default V2;
