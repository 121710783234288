import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import MapsSec from "../components/maps/Maps";
import { StoreWithUs } from "../components/store-with-us/StoreWithUs";
import FBCarousel from "../feedback-carousel/FeedbackCarousel";
import LocationsPage from "../locations/LocationsNew";
// import LocationsPage from "../locations/Locations";
import Summary from "../summary/Summary";
import { LandingWrpr } from "./LandingWrpr";

const HomePage = (props) => {
  return (
    <>
      <Header
        websiteProfile={props.websiteProfile}
        headerOpacity={props.route}
        auth={props.auth}
        allProps={props}
        location={props.location}
      ></Header>

      <div className="">
        <Route exact path="/" component={LandingWrpr}></Route>
        <Route exact path="/locations" component={LocationsPage}></Route>
        <Route
          exact
          path="/summary/:sizeId/:unitId/:selectedDate/:locationId?"
          component={Summary}
        ></Route>
      </div>

      <StoreWithUs></StoreWithUs>
      <FBCarousel></FBCarousel>
      <MapsSec></MapsSec>
      <Footer logoUrl={props?.websiteProfile?.theme?.logoURL}></Footer>
    </>
  );
};

const mapStateToProps = ({ auth, websiteProfile }) => ({
  auth,
  websiteProfile,
});

export default connect(mapStateToProps)(HomePage);
