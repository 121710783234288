import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
import { Link } from "react-router-dom";

// import scss
import "./Maps.scss";

const Pin = (props) => (
  <p
    style={{
      backgroundColor: "white",
      padding: "16px 22px",
      width: "120px",
      textAlign: "center",
      fontSize: "15px",
    }}
  >
    Plaza 15 storage
  </p>
);

class MapsSec extends Component {
  state = {
    center: {
      lat: 43.10592,
      lng: -73.74996,
    },
    zoom: 14,
  };

  render() {
    return (
      <div className="maps_sec">
        <div className="map_wrpr">
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyCIAjRoSK9lAZ5lfTVe1kYgJGEggap2-Ow",
            }}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
          >
            <Pin lat={43.10592} lng={-73.74996} text="My Marker" />
          </GoogleMapReact>
        </div>
        <div className="df acc ffc info_wrpr">
          <h4 className="ffqsb fs25">Book your space today</h4>

          <p className="ffmr fs14 tac">
            Join our satisfied uesrs who are enjoying our space
          </p>

          <Link to="/locations" className="ffmb fs14 ttuc pbgc button">
            Reserve your unit
          </Link>
        </div>
      </div>
    );
  }
}

export default MapsSec;
