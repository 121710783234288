import axios from "axios";
import { useEffect, useRef, useState } from "react";
import DDPortal from "./DDPortal";

import { ThemeContext } from "../../../../App";
import SAInput from "../stand-alone-input/Input";

const AutoComplete = ({ onUpdate, savedAddress }) => {
  const inputRef = useRef(null);

  const [search, setSearch] = useState(savedAddress?.deliveryLine1 || "");
  const [addressList, setAddressList] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [lastLine, setLastLine] = useState(savedAddress?.lastLine || "");

  const getSmarty = async () => {
    // /api/smarty-autocomplete/:q

    let re = await axios.get(`/api/smarty-autocomplete/${search}`);

    setAddressList(re.data.suggestions);
  };

  useEffect(() => {
    if (!isEditing || !search || search.length < 4) return;
    getSmarty();
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (isEditing === false) {
      // console.log("isEditing", isEditing);
      setSearch();
      setAddressList([]);
    }
    // eslint-disable-next-line
  }, [isEditing]);

  return (
    <ThemeContext.Consumer>
      {(val) => (
        <>
          <style>
            {`
            .__dd_list_wrpr .line:hover {
              background-color: ${val}
            }
            `}
          </style>
          <div className="__inpt_wrpr drop_down m-b-15">
            <label className={`ffmb fs12 m-b- db`}>
              Address (including apt.)
            </label>
            <div className={`inner_inpt_wrpr`} ref={inputRef}>
              <input
                className={`df ffmr fs14 input ${
                  addressList?.length ? "open" : ""
                }`}
                value={search}
                onChange={(e) => {
                  onUpdate({ deliveryLine1: e.target.value });
                  setSearch(e.target.value);
                }}
                onFocus={() => setIsEditing(true)}
                //   value={line1}
                //   onChange={(e) => setLine1(e.target.value)}
              />
              {/* {isEditing ? "ED" : "NO"}
              {addressList.length} */}
              {addressList?.length ? (
                <DDPortal close={() => setIsEditing(false)}>
                  <div
                    className="__dd_list_wrpr"
                    style={{
                      position: "fixed",
                      width: `${
                        inputRef?.current?.getBoundingClientRect().width
                      }px`,
                      top: `${
                        inputRef?.current?.getBoundingClientRect().top +
                        inputRef?.current?.clientHeight
                      }px`,
                      left: `${
                        inputRef?.current?.getBoundingClientRect().left +
                        +inputRef?.current?.clientWidth / 2
                      }px`,
                      zIndex: 99999999,
                    }}
                  >
                    {addressList.map((add) => {
                      return (
                        <div
                          className="df ffc acma ffmr fs14 line"
                          onClick={(e) => {
                            e.stopPropagation();
                            onUpdate({
                              deliveryLine1: `${add.street_line} ${add.secondary}`,
                              lastLine: `${add.city}, ${add.state} ${add.zipcode}`,
                            });

                            setSearch(`${add.street_line} ${add.secondary}`);
                            setLastLine(
                              `${add.city}, ${add.state} ${add.zipcode}`
                            );
                            setIsEditing(false);
                          }}
                        >
                          <p>
                            {add.street_line} {add.secondary}
                          </p>
                          {true ? (
                            <p className="sub m-t-2">
                              {add.city}, {add.state} {add.zipcode}
                            </p>
                          ) : undefined}
                        </div>
                      );
                    })}
                    {/* <div className="df ffc acma ffmr fs14 line">
              <p>Hello</p>
              {true ? <p className="sub m-t-2">Sub hello</p> : undefined}
            </div> */}
                  </div>
                </DDPortal>
              ) : undefined}
            </div>
          </div>
          <SAInput
            label="City, state, zip"
            type="text"
            value={lastLine}
            onChange={(e) => {
              onUpdate({
                lastLine: e.target.value,
              });
              setLastLine(e.target.value);
            }}
          />
        </>
      )}
    </ThemeContext.Consumer>
  );
};

export default AutoComplete;
