export const ExpandMore = (props) => (
	<svg
		width="10"
		height="16"
		viewBox="0 0 10 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M0.752198 13.4551L2.42212 15.125L9.54712 8L2.42212 0.875L0.752197 2.54492L6.20728 8L0.752198 13.4551Z"
			fill="#363837"
		/>
	</svg>
);
