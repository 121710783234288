import {GET_BEST_UNIT_MATCH, GET_UNIT_SIZES} from "../actions/types";

const reducer = (state = {}, action) => {
	switch (action.type) {
		case GET_UNIT_SIZES:
			return {...state, unitSizes: action.payload};
		case GET_BEST_UNIT_MATCH:
			return {...state, ...action.payload};
		default:
			return state;
	}
};

export default reducer;
