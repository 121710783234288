import React from "react";
// import {
//   isAndroid,
//   isChromium,
//   isEdgeChromium,
//   isLegacyEdge,
//   isMobile,
// } from "react-device-detect";
import { useSelector } from "react-redux";
import { NavLink, Redirect, Route } from "react-router-dom";
import Media from "react-media";

// import scss
import "./AccountNav.scss";
import MobileNav from "./mobile-nav/MobileNav";
// import NewFeature from "../../../components/ui/new-feature/NewFeature";
// import axios from "axios";
// import { GET_USER } from "../../../store/actions/types";

const AccountNav = (props) => {
  // const dispatch = useDispatch();
  // const { feature } = useSelector(({ feature }) => ({ feature }));
  // const history = useHistory();
  const { auth } = useSelector(({ auth }) => ({ auth }));

  // const updateUser = () => {
  //   const user = axios.put(
  //     `/api/web-user/${auth.user._id}/?simple=true`,
  //     { requireOnboarding: false },
  //     { headers: { "x-auth": localStorage.getItem("x-auth") } }
  //   );

  //   dispatch({ type: GET_USER, payload: user.data });
  // };

  // const getFeature = (_id) => feature.find((el) => el._id === _id);

  return (
    <div className="account_nav">
      <div className="df ffc acc hdr pbgc ">
        <p className="ffqsb fs28 greeting_wrpr">
          Hi, {auth.user.fullName.split(" ")[0]}
        </p>

        <p className="ffmm fs14 member_since">Customer Since August 2017</p>
      </div>

      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <>
            {matches.small && (
              <Route
                path="/account"
                exact={true}
                component={() => <MobileNav></MobileNav>}
              />
            )}
            {(matches.medium || matches.large) && (
              <div className="fs14 navs_wrpr">
                <Route exact path="/account">
                  <Redirect to="/account/rentals" />
                </Route>

                <NavLink
                  activeClassName="selected"
                  to="/account/rentals"
                  className="df acsa nav_wrpr"
                >
                  {/* {!auth.user.requirePasswordReset ? (
                    <NewFeature
                      feature={getFeature("6179b262a2a5fb1f91b536b2")}
                      clickAction={(e) => updateUser()}
                    />
                  ) : undefined} */}

                  <p className="ffmb lbl">Rentals</p>
                  <p className="ffmr mla val">Track &amp; Manage</p>
                </NavLink>

                <NavLink
                  activeClassName="selected"
                  to="/account/payments"
                  className="df acsa nav_wrpr"
                >
                  <p className="ffmb lbl">Payments</p>
                  {/* <p className="ffmr mla val">Visa....4400</p> */}
                </NavLink>

                <NavLink
                  activeClassName="selected"
                  to="/account/settings"
                  className="df acsa nav_wrpr"
                >
                  <p className="ffmb lbl">Settings</p>
                  <p className="ffmr mla val">Password, name, etc.</p>
                </NavLink>

                {/* <div>
					<p>{isAndroid ? "Android" : "NAN"}</p>
					<p>{isMobile ? "Mobile" : "NMobile"}</p>
					<p>{isChromium ? "Chromium" : "NCH"}</p>{" "}
					<p>{isEdgeChromium ? "Edge Chromium" : "NECH"}</p>
					<p>{isLegacyEdge ? "Edge Legacy" : "NEL"}</p>
				</div> */}
              </div>
            )}
            {/* {matches.large && <p>I am large!</p>} */}
          </>
        )}
      </Media>
    </div>
  );
};

export default AccountNav;
