import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import AutoComplete from "../../../../components/ui/form-elements/drop-down/AutoComplete";
import Portal from "../../../../components/ui/portal/Portal";

import styles from "./AddAddress.module.scss";

const AddAddress = ({ updatedAddress, close, savedAddress }) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  const [address, setAddress] = useState(savedAddress || {});

  const save = async () => {
    let re;
    if (savedAddress?._id) {
      re = await axios.put(`/api/address/${savedAddress._id}`, {
        ...address,
      });
    } else {
      re = await axios.post(
        `/api/address/${auth.user._id}/${auth.user.accountId}`,
        {
          ...address,
          accountId: auth.user.accountId,
          webUserId: auth.user._id,
          primary: true,
        }
      );
    }

    updatedAddress(re?.data?.data);
    close();
  };

  return (
    <Portal
      height="auto"
      zIndex="999"
      opacity="0.35"
      title="Add address"
      close={close}
    >
      <div className={styles.addAddressContent}>
        <div className={`m-b-10`}>
          <AutoComplete
            savedAddress={savedAddress}
            onUpdate={(a) => setAddress({ ...address, ...a })}
          />
        </div>
        <div className={`p-b-20 ${styles.ftr}`}>
          <button
            onClick={save}
            className={`df ffmsb fs14 p-l-30 p-r-30 m-t-30 w100 ttuc button primary pbgc`}
          >
            Save
          </button>

          <button
            onClick={close}
            className={`df ffmsb fs14 p-l-30 p-r-30 m-t-10 w100 ttuc secondary text`}
          >
            Cancel
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default AddAddress;
