import styles from "./Payments.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper/core";
import NavSVG from "../../../../../assets/images/svgs/nav-arrow.svg";

// import credit card images

import * as creditCards from "../../../../../assets/images/svgs/credit-cards/index.js";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { getLast4 } from "../../../../../helper";
import { NavLink } from "react-router-dom";

SwiperCore.use([Navigation]);

const Payments = (props) => {
  const { auth } = useSelector(({ auth }) => ({ auth }));

  const [payMethods, setPayMethods] = useState([]);

  const getPayMethods = async () => {
    let methods = await axios.get(`/api/user-pay-methods/${auth.user._id}`);
    setPayMethods(methods.data.paymentMethods);
  };

  useEffect(() => {
    if (!auth?.user) return;

    getPayMethods();
    // eslint-disable-next-line
  }, [auth]);

  const getIcon = (type) => {
    let CardLogo = creditCards[type];
    return <CardLogo />;
  };

  return (
    <div className={styles.payments}>
      <div className={`df acsa ${styles.hdr}`}>
        <h4 className={`ffqsb fs20`}>Payments</h4>
        <NavLink
          to="/account/payments"
          className={`mla ffqsb fs12 tdn ${styles.seeAll}`}
        >
          See all <NavSVG></NavSVG>
        </NavLink>
      </div>

      <Swiper
        centeredSlidesBounds
        slidesPerView={"auto"}
        spaceBetween={14}
        // navigation={true}
        pagination={{ clickable: true }}
        className={`${styles.slider} customized`}
        // initialSlide={props.initialSlide}
      >
        {payMethods.map((pm, idx) => (
          <SwiperSlide
            className={styles.slide}
            style={{ width: "186px" }}
            key={idx}
          >
            <div className={styles.slideContent}>
              <div className={`df acc ${styles.cardLogoWrpr}`}>
                {/* <creditCards.AmexSVG height="20" width="32" /> */}
                {getIcon(pm.xCardType)}
              </div>
              <div className={`df acsa ffmr fs15 m-t-8 ${styles.textWrpr}`}>
                <p>Visa </p>
                <p> &bull; &bull; &bull; &bull;</p>
                <p>{getLast4(pm.xMaskedCardNumber)}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Payments;
