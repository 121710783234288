import React from "react";

export const FacebookSvg = (props) => (
	<svg
		width="22"
		height="22"
		viewBox="0 0 22 22"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M19.25 0.5C19.875 0.5 20.4062 0.71875 20.8438 1.15625C21.2812 1.59375 21.5 2.125 21.5 2.75V19.25C21.5 19.875 21.2812 20.4062 20.8438 20.8438C20.4062 21.2812 19.875 21.5 19.25 21.5H12.8281V14.375H15.5469L16.0625 11H12.8281V8.79688C12.8281 7.60938 13.4531 7.01562 14.7031 7.01562H16.1562V4.15625C15.2812 4 14.4219 3.92188 13.5781 3.92188C12.6719 3.92188 11.8906 4.09375 11.2344 4.4375C10.6094 4.78125 10.1094 5.29687 9.73438 5.98438C9.35938 6.67188 9.17188 7.48438 9.17188 8.42188V11H6.21875V14.375H9.17188V21.5H2.75C2.125 21.5 1.59375 21.2812 1.15625 20.8438C0.71875 20.4062 0.5 19.875 0.5 19.25V2.75C0.5 2.125 0.71875 1.59375 1.15625 1.15625C1.59375 0.71875 2.125 0.5 2.75 0.5H19.25Z"
			fill={props.fill || "red"}
		/>
	</svg>
);
