import React, { useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, { Navigation } from "swiper/core";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.scss";

import { ThemeContext } from "../../App";

// import scss
import "./StoreWithUs.scss";
import { ClimateSvg } from "../../assets/images/svgs/climate.svg";
import { SafeLockSvg } from "../../assets/images/svgs/safe-lock.svg";
import { SizesSvg } from "../../assets/images/svgs/sizes.svg";
import { SetupSvg } from "../../assets/images/svgs/setup.svg";

SwiperCore.use([Navigation]);

export const StoreWithUs = (props) => {
  const Theme = useContext(ThemeContext);
  // const elemntRef = useRef(null);
  // const [position, updatePostion] = useState("hidden");

  // useEffect(() => {
  // 	if (elemntRef.current.getBoundingClientRect().top >= 500)
  // 		updatePostion("hidden");
  // 	if (elemntRef.current.getBoundingClientRect().top < 500)
  // 		updatePostion("visible");
  // }, [
  // 	// eslint-disable-next-line react-hooks/exhaustive-deps
  // 	elemntRef &&
  // 		elemntRef.current &&
  // 		elemntRef.current.getBoundingClientRect().top,
  // ]);
  return (
    <div className="store_with_us_sec">
      <div className="df acsa content_wrpr">
        <div className="inner_cntnt_wrpr">
          <h3 className="ffqsb fs20">Store With Us</h3>

          <div className="df cards_wrpr mobile_hidden">
            <div className={`df ffc acsa card_wrpr`}>
              <div
                className="df acc icon_wrpr"
                // style={{
                // 	backgroundImage:
                // 		"url(https://selfstorageimages.blob.core.windows.net/images/temp.png)",
                // }}
              >
                <ClimateSvg fill={Theme}></ClimateSvg>
              </div>
              <h5 className="ffqsb fs19 ttcaps">Climate controlled storage</h5>

              <p className="ffmr fs14 text">
                Climate controlled storage maintains stable temperature and
                humidity levels.
              </p>
            </div>
            <div className={`df ffc acsa card_wrpr`}>
              <div className="df acc icon_wrpr">
                <SafeLockSvg fill={Theme}></SafeLockSvg>
              </div>
              <h5 className="ffqsb fs19 ttcaps">Secure Premises</h5>

              <p className="ffmr fs14 text">
                Secure premises self storage protects against unauthorized
                access.
              </p>
            </div>
            <div className={`df ffc acsa card_wrpr`}>
              <div className="df acc icon_wrpr">
                <SizesSvg fill={Theme} />
              </div>
              <h5 className="ffqsb fs19 ttcaps">Many Sizes Available</h5>

              <p className="ffmr fs14 text">
                Find the perfect fit with our many available storage unit sizes!
              </p>
            </div>
            <div className={`df ffc acsa card_wrpr`}>
              <div className="df acc icon_wrpr">
                <SetupSvg fill={Theme} />
              </div>
              <h5 className="ffqsb fs19 ttcaps">Set Up Help Provided</h5>

              <p className="ffmr fs14 text">
                Get started with ease - we provide set up help for your storage!
              </p>
            </div>
          </div>
          <div className={`cards_wrpr mobile_only`}>
            <Swiper
              centeredSlidesBounds
              slidesPerView={1}
              // spaceBetween={28}
              navigation
              pagination={{ clickable: true }}
              className={`customized`}
              // initialSlide={props.initialSlide}
            >
              <SwiperSlide className={`df ffc acsa card_wrpr`}>
                <div
                  className="df acc icon_wrpr"
                  // style={{
                  // 	backgroundImage:
                  // 		"url(https://selfstorageimages.blob.core.windows.net/images/temp.png)",
                  // }}
                >
                  <ClimateSvg fill={Theme}></ClimateSvg>
                </div>
                <h5 className="ffqsb fs19 ttcaps">
                  Climate controlled storage
                </h5>

                <p className="ffmr fs14 text">
                  Climate controlled storage maintains stable temperature and
                  humidity levels.
                </p>
              </SwiperSlide>
              <SwiperSlide className={`df ffc acsa card_wrpr`}>
                <div className="df acc icon_wrpr">
                  <SafeLockSvg fill={Theme}></SafeLockSvg>
                </div>
                <h5 className="ffqsb fs19 ttcaps">Secure Premises</h5>

                <p className="ffmr fs14 text">
                  Secure premises self storage protects against unauthorized
                  access.
                </p>
              </SwiperSlide>
              <SwiperSlide className={`df ffc acsa card_wrpr`}>
                <div className="df acc icon_wrpr">
                  <SizesSvg fill={Theme} />
                </div>
                <h5 className="ffqsb fs19 ttcaps">Many Sizes Available</h5>

                <p className="ffmr fs14 text">
                  Find the perfect fit with our many available storage unit
                  sizes!
                </p>
              </SwiperSlide>
              <SwiperSlide className={`df ffc acsa card_wrpr`}>
                <div className="df acc icon_wrpr">
                  <SetupSvg fill={Theme} />
                </div>
                <h5 className="ffqsb fs19 ttcaps">Set Up Help Provided</h5>

                <p className="ffmr fs14 text">
                  Get started with ease - we provide set up help for your
                  storage!
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};
