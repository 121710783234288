import React from "react";

// import scss
import "./backdrop.scss";

export const backdrop = (props) => (
  <div
    className="page_backdrop"
    style={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
    onClick={() => props.click()}
  ></div>
);
