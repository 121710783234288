import React from "react";

export const MiniSizeSvg = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#miniSizesClip)">
      <path
        d="M7.3947 9.28813C7.31659 9.3662 7.2267 9.40527 7.12515 9.40527C7.02356 9.40527 6.93367 9.36624 6.8556 9.28813L2.96494 5.39747L1.27744 7.08517C1.12898 7.23335 0.953383 7.30768 0.750121 7.30768C0.54686 7.30768 0.371059 7.23335 0.222635 7.08517C0.0741707 6.93654 0 6.7607 0 6.55756V1.30745C0 1.10435 0.0741707 0.928635 0.222635 0.780129C0.371182 0.631828 0.546983 0.557493 0.750121 0.557493H6.00019C6.20333 0.557493 6.37905 0.631828 6.52751 0.780129C6.6759 0.928594 6.75015 1.10431 6.75015 1.30745C6.75015 1.51059 6.6759 1.68643 6.52751 1.83506L4.84002 3.52223L8.73067 7.41313C8.80874 7.49124 8.84778 7.58085 8.84778 7.68256C8.84778 7.78427 8.80866 7.87384 8.73067 7.95223L7.3947 9.28813Z"
        fill={props.fill || "red"}
      />
      <path
        d="M17.7776 18.335C17.6293 18.4834 17.4534 18.5576 17.2503 18.5576H12.0002C11.7971 18.5576 11.6214 18.4834 11.4729 18.335C11.3245 18.1866 11.2503 18.0108 11.2503 17.8077C11.2503 17.6045 11.3245 17.4287 11.4729 17.2803L13.1601 15.5928L9.26949 11.7022C9.19146 11.624 9.15234 11.5342 9.15234 11.4326C9.15234 11.3311 9.19146 11.2412 9.26949 11.1631L10.6056 9.82711C10.6837 9.749 10.7733 9.71 10.875 9.71C10.9767 9.71 11.0663 9.749 11.1444 9.82711L15.0353 13.7178L16.7225 12.0304C16.8711 11.882 17.047 11.8076 17.2501 11.8076C17.4533 11.8076 17.6289 11.882 17.7774 12.0304C17.9258 12.1787 18 12.3546 18 12.5577V17.8078C18.0001 18.0111 17.9264 18.1866 17.7776 18.335Z"
        fill={props.fill || "red"}
      />
    </g>
    <g clipPath="url(#clip1)">
      <path
        d="M10.6053 9.28813C10.6834 9.3662 10.7733 9.40527 10.8749 9.40527C10.9764 9.40527 11.0663 9.36624 11.1444 9.28813L15.0351 5.39747L16.7226 7.08517C16.871 7.23335 17.0466 7.30768 17.2499 7.30768C17.4531 7.30768 17.6289 7.23335 17.7774 7.08517C17.9258 6.93654 18 6.7607 18 6.55756V1.30745C18 1.10435 17.9258 0.928635 17.7774 0.780129C17.6288 0.631828 17.453 0.557493 17.2499 0.557493H11.9998C11.7967 0.557493 11.621 0.631828 11.4725 0.780129C11.3241 0.928594 11.2499 1.10431 11.2499 1.30745C11.2499 1.51059 11.3241 1.68643 11.4725 1.83506L13.16 3.52223L9.26933 7.41313C9.19126 7.49124 9.15222 7.58085 9.15222 7.68256C9.15222 7.78427 9.19134 7.87384 9.26933 7.95223L10.6053 9.28813Z"
        fill={props.fill || "red"}
      />
      <path
        d="M0.22239 18.335C0.370731 18.4834 0.546573 18.5576 0.749711 18.5576H5.99982C6.20292 18.5576 6.37856 18.4834 6.52714 18.335C6.67549 18.1866 6.74966 18.0108 6.74966 17.8077C6.74966 17.6045 6.67549 17.4287 6.52714 17.2803L4.83989 15.5928L8.73051 11.7022C8.80854 11.624 8.84766 11.5342 8.84766 11.4326C8.84766 11.3311 8.80854 11.2412 8.73051 11.1631L7.39437 9.82711C7.3163 9.749 7.22666 9.71 7.12498 9.71C7.02331 9.71 6.93367 9.749 6.8556 9.82711L2.96465 13.7178L1.27748 12.0304C1.1289 11.882 0.953013 11.8076 0.749875 11.8076C0.546737 11.8076 0.371059 11.882 0.222554 12.0304C0.074213 12.1787 4.1008e-05 12.3546 4.1008e-05 12.5577V17.8078C-8.2016e-05 18.0111 0.073638 18.1866 0.22239 18.335Z"
        fill={props.fill || "red"}
      />
    </g>
    <defs>
      <clipPath id="miniSizesClip">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="matrix(1 0 0 -1 0 18.5576)"
        />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="18"
          height="18"
          fill="white"
          transform="translate(18 18.5576) rotate(-180)"
        />
      </clipPath>
    </defs>
  </svg>
);
