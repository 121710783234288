import Cleave from "cleave.js/react";

import "./Input.scss";

const CurrencyInput = ({
  size,
  disabled,
  id,
  label,
  value,
  onChange,
  autocomplete,
}) => {
  return (
    <div className="__inpt_wrpr df ffc">
      <label htmlFor={id} className={`ffmb fs12`}>
        {label}
      </label>
      <div className={`inner_inpt_wrpr`}>
        <Cleave
          className={`ffmr fs14`}
          disabled={disabled}
          id={id}
          value={value ? value / 100 : ""}
          onChange={(e) => {
            onChange({ fullNumber: e.target.rawValue * 100 });
          }}
          options={{
            prefix: `${value?.toString().length > 0 ? "$" : ""}`,
            numeral: true,
            // numeralThousandsGroupStyle: "lakh",
            rawValueTrimPrefix: true,
          }}
        />
      </div>
    </div>
  );
};

export default CurrencyInput;
